import "./stock-management.scss";
import {StockManagementController} from "./stock-management.component";

class PendingShelfMovementActionListController {
    constructor(
        API,
        ToastService,
        $translate,
        $auth,
        $http,
        DialogService,
        $stateParams,
        $q,
        $state,
        $location,
        $scope,
        $rootScope
    ) {
        "ngInject";

        this.API = API;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$auth = $auth;
        this.$stateParams = $stateParams;
        this.$location = $location;
        this.$state = $state;
        this.$location = $location;

        this.DialogService = DialogService;

        this.$rootScope = $rootScope;

        this.productId = null;
        this.actionType = 'full';
        this.minify = false;
        this.locationId = null

        this.total = 0;
        this.page = 1;
        this.perPage = 5;

        this.actionList = [];
        this.pageList = [];

        this.totalRemaining = 0;
        this.waitPreStock = false;

    }

    $onInit() {
        this.getList()
    }

    $onChanges(changeList) {
        if (changeList?.productId && changeList?.productId?.currentValue) {
            this.getList()
        }
        if (changeList?.actionType && changeList?.actionType?.currentValue) {
            this.getList()
        }
    }

    getList() {
        let filter = {
            locationId : this.locationId
        }

        if (this.actionType !== 'full'){
            filter.listType = this.actionType
        }

        this.API.all("stock/analytics-not-assign-list/" + this.productId)
            .customGET(null, filter)
            .then((response) => {
                const {analyticsNotAssignList} = response.data;
                this.actionList = analyticsNotAssignList.data;
                this.filter();
            });
    }

    getListPage(page, perPage) {
        let start = 0;
        if (page !== 1) {
            start = page * perPage
        }
        this.pageList = this.actionList.filter(a => a.show).slice(start, start + perPage);
    }
    movementFilter(){
        if (this.actionType === 'full'){
            this.locationId = null
            this.getList()
            return true
        }

        this.filter()
    }

    filter() {

        this.page = 1;
        this.total = 0;
        this.totalRemaining = 0;
        this.waitPreStock = false;

        this.actionList.map(a => {
            a.show = this.actionType === 'full';

            if (this.actionType === 'reservedStock') {
                a.show = a.action_mode === '-';
            }
            if (this.actionType === 'preStock') {
                a.show = a.action_mode === '+';
            }

            if (a.action_mode === '+' && a.remainingQuantity > 0) {
                this.waitPreStock = true
            }

            return a;
        }).forEach(a => {
            this.total += a.show ? 1 : 0
            this.totalRemaining += a.show ? a.remainingQuantity : 0
        })

        this.getListPage(this.page, this.perPage)
    }


    showOrderDetail(id) {
        let options = {
            controller: 'InvoicesDetailController',
            controllerAs: 'vm',
            locals: {
                orderId: id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getOrderDetail(controller.orderId)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/invoices-detail/invoices-detail.component.html',
            options
        )
    }

    showActionDetail(action_id) {
        this.selectedDetailActionId = action_id;
        let options = {
            controller: 'ActionDetailController',
            controllerAs: 'vm',
            locals: {
                id: action_id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetail(controller.id)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/order-lists/partials/action-detail.component.html',
            options
        )
    }

    showListDetail(listId) {
        let options = {
            controller: "IssuesDetailController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                listId: listId
            },
            onShowing: function (scope, element, options, controller) {
                controller.getList()
            },
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/issues/partials/issues-detail.html", options)
            .then((response) => {
            })
    }




    quickMovementPreStock() {
        this.DialogService.fromTemplateV3({
            template: ` <pending-pre-stock-action-list-component product-id="${this.productId}"></pending-pre-stock-action-list-component>`,
        })
            .then(response => this.bulkAddItemResult(response))
            .catch(err=> console.log(err))
    }



    bulkAddItemResult(report) {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = d
                scope.report = report
                scope.tryErrorRows = false

                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item-result.html',
            options
        ).then((response) => {
            scope.DialogService.$mdDialog.hide()

        });

    }

}

PendingShelfMovementActionListController.$inject = ["API", "ToastService", "$translate", "$auth", "$http", "DialogService", "$stateParams", "$q", "$state", "$location", "$scope", "$rootScope"];

const PendingShelfMovementActionListComponent = {
    templateUrl: "./views/angular/app/components/stock-management/partials/pending-shelf-movement-action-list.component.html",
    controller: PendingShelfMovementActionListController,
    controllerAs: "vm",
    bindings: {
        productId: '<',
        actionType: '<',
        minify: '<',
        locationId:'<?'
    },
};

export {PendingShelfMovementActionListComponent, PendingShelfMovementActionListController}
