import {getToken} from "../../../utils/users";

class MoveProductBetweenShelvesController {
    constructor(API, ToastService, DialogService, $state, $translate, $location, $window, $sce, $timeout, $scope, $rootScope, $mdBottomSheet) {
        "ngInject";

        this.API = API;
        this.window = $window;
        this.$scope = $scope;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$translate = $translate;
        this.$location = $location;
        this.sce = $sce
        this.$timeout = $timeout
        this.$rootScope = $rootScope;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$window = $window;

        this.changeButton = true;
        this.locations = [];
        this.locationId = null;
        this.productBarcode = null
        this.product = null
        this.transferQuantity = 1;

        this.shelfBetween = {
            from: {
                shelfId: null,
                barcode: null,
                check: false,
                color: 'from-shelf'
            },
            to: {
                shelfId: null,
                barcode: null,
                check: false,
                color: 'to-shelf'
            }
        }
    }

    $onInit() {
        this.getLocation()
    }

    $onChanges(changeList) {
        let parent = this
        if (changeList?.locationId && changeList?.locationId?.currentValue) {
            parent.locationId = changeList.locationId;
        }
        if (changeList?.productBarcode && changeList?.productBarcode?.currentValue) {
            parent.productBarcode = changeList.productBarcode;
        }
    }

    getLocation() {
        var parent = this;
        this.API.all("location")
            .customGET()
            .then((response) => {
                if (response.errors === false) {
                    this.locations = response.data.location;
                    this.locationId = this.locations[0].id;
                    let shelf_code = this.$window.document.getElementById('shelf_code');
                    shelf_code.focus();
                }
            });
    }

    showCameraView() {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            parent.productBarcode = response;
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }

    checkProduct(){
        this.API.all('stocktaking/product/' + this.productBarcode)
            .customGET(null,{
                locationId    : this.locationId,
                active        : 1
            })
            .then((response) => {
                if (response.response.status === 204){
                    // this.getShelfDetail(this.stocktakingShelfId);
                    this.ToastService.error(this.$translate.instant('NO_PRODUCT_WITH_BARCODE'));
                    this.transferQuantity = 1
                    this.clearShelfForm(this.shelfBetween.from)
                    this.clearShelfForm(this.shelfBetween.to)
                    this.changeButton = true;
                    return;
                }
                this.product = response.data.list
                this.transferQuantity = 1
                this.clearShelfForm(this.shelfBetween.from)
                this.clearShelfForm(this.shelfBetween.to)
                this.changeButton = false;
            })
            .catch((response)=>{
                if(response.status === 422){
                    this.ToastService.error(response.data.errors.message[0]);
                }
            })

    }
    checkShelf(shelf){
        if (!shelf.barcode){
            this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));
            return;
        }

        this.API.all('shelf/' + shelf.barcode + '/location/' + this.locationId)
            .customGET()
            .then((response) => {
                if (response.response.status === 200) {
                    shelf.shelfId = response.list.shelfId;
                    shelf.check = true
                }

                if (response.response.status === 204) {
                    this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));
                }
            })
    }

    moveBetweenShelves() {
        this.changeButton = true;
        if (!this.shelfBetween.from.shelfId){
            this.ToastService.error(this.$translate.instant('SHElF_CHANGE_MSG1'));
           return false;
        }

        if (!this.shelfBetween.to.shelfId){
            this.ToastService.error(this.$translate.instant('SHElF_CHANGE_MSG2'));
           return false;
        }
        this.API.all(`location/${this.locationId}/shelf/${this.shelfBetween.from.shelfId}/product/${this.product.productId}/move`)
            .customPUT({
                lotId: this.product.lotId,
                quantity: this.transferQuantity,
                toShelfId: this.shelfBetween.to.shelfId,
            })
            .then((response) => {
                if (response.response.status === 200 || response.response.status === 204) {
                    this.ToastService.success(this.$translate.instant('SHELF_REPLACEMENT_SUCC'));
                }
                this.changeButton = false;
                this.checkProduct();
            })
    }



    shelfChangeAll(){
        this.product.otherShelves.forEach(shelf => {
            if(shelf.barcode === this.shelfBetween.from.barcode){
                this.transferQuantity = shelf.quantity
            }
        })
    }

    clearShelfForm(shelf){
        let barcode = shelf.barcode
        shelf.barcode=null;
        shelf.check=false;
        shelf.shelfId=null;

        this.product.otherShelves.forEach(s => {
            if(s.barcode === barcode){
                if (s.selected === 'from'){
                    this.transferQuantity = 1
                }
                s.selected = null
                s.color = null
            }
        })
    }

    selectShelf(shelfBarcode){
        let what = 'from'
        if (this.shelfBetween.from.check){
            what = 'to';
        }


        this.product.otherShelves.forEach(s => {
            if (s.barcode !== shelfBarcode){
                return 1
            }

            if(s.barcode === shelfBarcode && s.selected){
                this.clearShelfForm(this.shelfBetween[s.selected])
                return 1;
            }
            this.shelfBetween[what].barcode=shelfBarcode;
            this.shelfBetween[what].check=true;
            this.shelfBetween[what].shelfId=s.shelfId;
            s.color = this.shelfBetween[what].color
            s.selected = what;
        })
    }
}

MoveProductBetweenShelvesController.$inject = ["API", "ToastService", "DialogService", "$state", "$translate", "$location", "$window", "$sce", "$timeout", "$scope", "$rootScope", "$mdBottomSheet"];
const MoveProductBetweenShelvesComponent = {
    templateUrl: "./views/angular/app/components/location-management/partials/move-product-between-shelves.html",
    controller: MoveProductBetweenShelvesController,
    controllerAs: "vm",
    bindings: {
        productBarcode: '<?',
        locationId: '<?'
    },
};
export {MoveProductBetweenShelvesComponent, MoveProductBetweenShelvesController}
