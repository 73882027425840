class LoadingService {
  constructor($mdToast, $translate) {
    "ngInject";
    this.$mdToast = $mdToast;
    this.$translate = $translate;

    this.loadingCount = 0;
    this.isLoading = false;
  }

  start() {
    this.loadingCount++;
    return this.isLoading = true;
  }

  stop() {
    this.loadingCount = Math.max(0, this.loadingCount - 1);
    if (this.loadingCount === 0) {
      return this.isLoading = false;
    }
  }
}

LoadingService.$inject = ["$mdToast", "$translate"];

export { LoadingService };
