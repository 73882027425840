import { openIntroductionVideo } from "../../../utils/introduction-videos";
import {canPermission, getUserInfo, getUserSetting, me} from "../../../utils/users";
import "./order-lists.scss";
import {UserService} from "../../../services/user.service";
import Cookies from "js-cookie";
import {LoadingService} from "../../../services/loading.service";
class OrderListsController {
  constructor(
    $window, $auth, $rootScope, $scope,$translate, $timeout, $mdBottomSheet, $state, $stateParams, $location, $mdMenu, $q,
    API, UserService, ToastService, LoadingService, DialogService, TabActionService, $filter,
    i18nService,
    uiGridConstants
  ) {
    "ngInject";
    this.window = $window;
    this.loadingService = LoadingService;

    this.$auth = $auth;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$mdBottomSheet = $mdBottomSheet;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$translate = $translate;
    this.$mdMenu = $mdMenu;
    this.$q = $q;
    this.$filter = $filter

    this.API = API;
    this.UserService = UserService;
    this.ToastService = ToastService;
    this.DialogService = DialogService;
    this.TabActionService = TabActionService;


    this.basketProducts = {};
    this.basketProductsArr = [];
    this.editBasketProductsArr = [];
    this.basketSupplyDesc = [];

    this.customers = [];
    this.responsibleUsers = [];
    this.transportersForSelectbox = {};
    this.transporterSections = {};
    this.ownerUsers = this.UserService.getAllUsers();
    this.orderDetailPage = false;

    this.per_page = 25;
    this.pageNumber = 1;
    this.column = 'created_at';
    this.sortMode = 'desc';
    this.sortModeStr = 'arrow_drop_down';
    this.actions = undefined;
    this.createCompanyId = null;
    this.createLocationId = null;
    this.editPermission = false
    this.checkedDelete = false;
    this.edit_price = [];
    this.edit_quantity = []
    this.deleteUpdate = [];
    this.disabled = [];
    this.missingItem = null;
    this.activeTabIndex = 0;
    this.totalAmount = 0;
    this.actionLines = [];
    this.totalAmountWithVat = 0;
    this.updateTime = false
    this.selectedOrderId = null
    this.saveProcessing = false;
    this.selectedLots = [];
    this.channelInfoArr = [];
    this.attacmentActionOrderSection = null;
    this.showAttachmentForm = false;
    this.originatorEv;
    this.video = undefined;
    this.visibleOrderListTableDetailRows = false;
    this.tabDetail = {
      1: {
        name: 'create-stock-movements',
        scanType: 'manuel',
        scannerCameraId: null,
        scannerCookieName: 'polypus-create-stock-movements-barcode-scan',
        scannerIdCookieName: 'polypus-create-stock-movements-camera-id',
        terminalEvent: null,
        cameraEvent: null,
        cameraState:'stop',
        cameraTemplateId : 'create-stock-movements-barcode',
        barcodeReader : true,
      },
      0: {
        name: 'stock-movements',
        scanType: 'manuel',
        scannerCameraId: null,
        scannerCookieName: 'polypus-stock-movements-barcode-scan',
        scannerIdCookieName: 'polypus-stock-movements-camera-id',
        terminalEvent: null,
        cameraEvent: null,
        cameraState:'stop',
        cameraTemplateId : 'stock-movements-barcode',
        barcodeReader : false,
      }
    }
    this.scanTypeList = [
      {key: 'manuel',   label: this.$translate.instant('SCAN_TYPE_MANUAL') },
      {key: 'terminal', label: this.$translate.instant('SCAN_TYPE_TERMINAL') },
      {key: 'camera',   label: this.$translate.instant('SCAN_TYPE_CAMERA') }
    ]
    this.activeTab = null;
    this.barcodeScanner = $window.BarcodeScanner;
    this.scanTypeAccordionMenuOpen = false;
    this.userInfo = getUserInfo();
    this.currentUserId = this.userInfo.uid;
    this.endDate = new Date()
    this.startDate = new Date(this.endDate.getFullYear(), 0, 1)
    this.orderBulkSelection = false
    this.branches = [];
    this.branch = null
    this.selectActionList=null
    if (this.$stateParams.actionNu) {
      this.activeTabIndex = 1;
      this.searchValue = this.$stateParams.actionNu;
      this.searchOrderNumber = this.$stateParams.actionNu;
      this.searchFieldId = 4;
      // this.filter();
      this.getOrderListV2();
    }else {
      this.filterScopeType = 'daily'
    }

    var parent = this;
    this.collapsed= false;
    this.show_vat_included = true;

    this.prepareListPage = 1;
    this.prepareListLimit = 20
    this.prepareListLimitEndDate = new Date()
    this.prepareListLimitStartDate = new Date((new Date()).setDate((new Date()).getDate() - 5))
    this.prepareListName = null;
    this.accountingProcess = null
    this.shipmentProcess = null

    this.filterActionProcess = null;
    this.filterMarketplace = null;
    this.filterShipment = null;
    this.filterPaymentType = null;
    this.allSelected = false;
    this.API.all("action/get-payment-types")
      .post(null, {})
      .then((response) => {
        parent.paymentTypes = response.data.types;
        parent.paymentTypesDefaultChannel = response.data.types.filter(f => 'default' === f.channelProvider);
        parent.taxTypes = response.data.taxTypes;
        parent.refundTypes = response.data.refundTypes;
      });

    this.API.all("settings/get-channels")
      .post(null, {})
      .then((response) => {
        parent.channels = response.data.channels;
      });


    this.API.all("location")
        .customGET()
      .then((response) => {
        if (response.errors === false) {
          parent.locations = response.data.location;
          parent.locations.map(l=> l.show=true)
        }
      });

    this.inputChangedPromise = "";
    this.editCollapsed = false;
    this.menuOpen = false;
    this.showToolbarForEdit = false;
    this.sortButtonDisabled = false;

    this.nextImageIndexProductDefault = 0;
    this.nextImageIndexProduct = [];
    this.channelsDetail = {};

    this.actionStatusFilters = [
      "searchUnprepared",
      "searchOneProductOrder",
      "missingItem",
      "searchUnpreparedActionForPrepareModule",
      "searchActionInPackageProcess",
      "searchActionInFinishedPrepare",
      "notAssignedToTransporter",
    ];

    this.actionStatusSelect='';

    this.productId = null;
    this.canPermission = canPermission;

    this.view = 'advanced'

    this.otherLocations = getUserSetting('locations');

    this.timeRange = 'lastWeek';
  }
  onSelectTab(obj){
    this.$location.search(obj);
    this.video = undefined;
    if (obj.tab == 1) {
      this.video = 'orders-lists';
      this.activeTab = this.tabDetail[obj.tab];
      this.getActionType(true)
    }
    if (obj.tab == 0) {
      this.video = 'ordersList';
      if (this.filterScopeType && !this.searchValue) {
        //this.getOrderLists();
        this.getOrderListV2(this.pageNumber, this.filterArr, this.per_page);
        this.filterScopeType =null
      }
      this.activeTab = this.tabDetail[obj.tab];
      this.getActionType(false)
    }

    if (this.activeTab?.barcodeReader){
      this.activeTab.scanType = Cookies.get(this.activeTab.scannerCookieName)
      this.activeTab.scannerCameraId = Cookies.get(this.activeTab.scannerIdCookieName)
      this.scanCameraStop();
      if ('camera' === this.activeTab.scanType) {
        this.getCameraInfo()
      }
      if ('terminal' === this.activeTab.scanType && !this.activeTab.terminalEvent) {
        this.getTerminalInfo()
      }
      let selectedType = this.scanTypeList.filter(sT=> sT.key === this.activeTab.scanType)
      if (selectedType.length>0){
        this.selectedScanTypeLabel = selectedType[0]['label']
      }
    }
  }
  getPaginationAction(
    pageNumber = this.pageNumber,
    filterArr = this.filterArr,
    per_page = this.per_page) {
    //this.getOrderLists(pageNumber, filterArr, per_page)
    this.getOrderListV2(pageNumber, filterArr, per_page)
  }

  clearCreateActionForm() {
    //this.createCompanyId = null;
    //this.createCompanyLabel = null;
    this.createLocationId = null;
    //this.createUserId = null;
    this.createActionTypeId = null;
    this.createActionNote = null;
    this.createPaymentType = null;
    this.createTotalAmount = null;
    this.createInvoiceNumber = null;
    this.createPayExpirationDate = null;
    this.createResponsibleUserId = null;
    this.createTaxType = 1;
    this.createRefundType = null;
    this.selectedLots = [];

    this.basketProductsArr = [];
    this.basketProductStorage('set');
  }

  basketProductStorage(method) {
    if (method === 'set') {
      localStorage.setItem('basketProducts', JSON.stringify(this.basketProductsArr));
    } else {
      this.basketProductsArr = JSON.parse(localStorage.getItem("basketProducts")) || [];
    }
  }

  $onInit() {
    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
    this.basketProductStorage('get');

    this.tab1Active = true;
    this.tab2Active = false;

    if (this.$stateParams.actionNu != undefined) {
      this.tab1Active = false;
      this.tab2Active = true;
    }

    this.createCompanyLabel = this.$filter('translate')('DEFAULT_COMPANY_CUSTOMER');
    this.createCompanyId = 2;

    this.getFilterInventory()
    this.changeTimeRange();

    // ürün aramadan gelen ürünü sepete ekle
    let parent = this;
    this.$scope.$on('selectProductEvent', function (event, selectProduct){
      if (selectProduct?.sub_product_id){
        selectProduct.sub_product = {
          "sub_product_id": selectProduct.sub_product_id
        }
      }
      parent.addToCart(selectProduct);
    });

  }
  $onChanges(data){

    if (data?.productId){
      this.actions =null;
      this.getActionType(false)
      this.getOrderListV2(this.pageNumber, this.filterArr, this.per_page);

    }
  }


  visibleTableRow() {

    if (this.canPermission('orders-assign-step')){
      this.visibleOrderListTableDetailRows = true
    }else if (this.canPermission('orders-prepare-step','orders-control-step')){
      this.visibleOrderListTableDetailRows = false
    }
  }

  getMatches(query = "") {
    var self_ = this;
    var customerProm = this.$q.defer();
    this.API.all("user/customers")
      .post({
        query: query,
      })
      .then((response) => {
        customerProm.resolve((self_.customers = response.data.data));
      });

    return customerProm.promise;
  }

  addCustomer() {
    let parent = this;
    var options = {
      controller        : "CustomersController",
      controllerAs      : 'vm',
      locals            : {
        dialog          : true,
      },
      bindToController  : true,
    };
    let customerDialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/app/components/customers/partials/customer-search.component.html',
      options
    );
    customerDialogPromise.then(response => {
      parent.createCompanyLabel = response.customerCode + "- " + response.customerName;
      parent.createCompanyId = response.customerId;
    }).catch(err=>{
      console.log(err)
    });
  }

  editCustomer() {
    let parent = this;
    var options = {
      controller        : "CustomersController",
      controllerAs      : 'vm',
      locals            : {
        dialog          : true,
      },
      bindToController  : true,
    };
    let customerDialogPromise = this.DialogService.fromTemplateV2(
      './views/angular/app/components/customers/partials/customer-search.component.html',
      options
    );
    customerDialogPromise.then(response => {
      parent.editCompanyLabel = response.customerCode + "- " + response.customerName;
      parent.editCompanyId = response.customerId;
    }).catch(err=>{
      console.log(err)
    });
  }

  showItemRecipe(productName,recipeId){
    let parent = this;
    let d = this.DialogService;
    this.API.all('recipe/'+recipeId)
        .customGET()
        .then((response) => {
          let options = {
            controllerAs      : 'vm',
            bindToController  : true,
            clickOutsideToClose: true,
            escapeToClose: true,
            onShowing         : function (scope, element, options, controller){
              scope.productName = productName;
              scope.recipe = response.data.list;
              scope.items = response.data.list.items;
              scope.DialogService = d;
              scope.cancel = () => {
                scope.DialogService.$mdDialog.hide()
              }
              scope.goRecipe = (productId, recipeId=null) => {
                parent.$state.go('app.recipe',{tab:1,productId:productId, recipeId:recipeId}, {reload:true});
                scope.DialogService.$mdDialog.hide()
              }
            },
          }

          this.DialogService.fromTemplateV2(
              './views/angular/app/components/offer/partials/dialog/recipe-item.html',
              options
          )
        });
  }

  goRecipe(productId, recipeId = null){
    this.$state.go('app.recipe',{tab:1,productId:productId, recipeId:recipeId}, {reload:true});
  }

  getStock(mode = "insert") {
    var self_ = this;
    let reqData = {
      stockName: ''
    }
    if (mode == "insert") {
      reqData.stockName = self_.searchStockName ?? '';

      let update = false;
      let basket = JSON.parse(localStorage.getItem("basketProducts")).map(item => {

        if ('' !== reqData.stockName
            && (null !== item.ts_barcode || null !== item.ts_sub_barcode)
            && reqData.stockName === item.ts_barcode || reqData.stockName === item.ts_sub_barcode) {
          update = true;
          ++item.quantity;
          item.row_total_local_price += item.unit_price;
          item.row_total_local_price_with_vat += item.unit_vat;

          let amount = parseFloat(localStorage.getItem("basketAmount")) + item.unit_price;
          let vat = parseFloat(localStorage.getItem("basketAmountWithVat")) + item.unit_vat;
          localStorage.setItem('basketAmount', amount)
          localStorage.setItem('basketAmountWithVat', vat)
          self_.basketAmountWithVat = vat
          self_.basketAmount = amount
        }
        return item;
      });
      if (update) {
        localStorage.setItem('basketProducts', JSON.stringify(basket))
        this.ToastService.success(this.$translate.instant('PRODUCT_INCREASED_1'))
        this.searchResults = []
        this.basketProductsArr.map(bP => {
          if ((null !== bP.ts_barcode || null !== bP.ts_sub_barcode) && reqData.stockName === bP.ts_barcode || reqData.stockName === bP.ts_sub_barcode) {
            ++bP.quantity;
            bP.row_total_local_price += bP.unit_price;
            bP.row_total_local_price_with_vat += bP.unit_vat;
          }
        })
        this.refreshBasketV2(this.basketProductsArr)
        return true
      }
    } else {
      reqData.stockName = self_.searchStockNameEdit ?? '';
    }

    if (this.activeTab.scanType === 'camera' || this.activeTab.scanType === 'terminal') {
      reqData['type'] = 'barcode'
    }
    this.API.all("stock/search")
        .post(reqData)
        .then((response) => {
          if (mode == "insert") {

            let data = response.data.products;
            if ('barcode' === reqData.type) {
              data = data[0]
              if ('subProduct' === data.productType) {
                data['sub_product'] = data.sub_products.filter(sp => sp.id === data.selectedSubProductId)[0]
              }
              data['requirementQuantity'] = 1
              data['ts_product_price'] = data.currency_price
              data['ts_product_price_with_vat'] = data.currency_price_include_vat
              if (0 < data.channelsInfoArray.length) {
                data['ts_product_local_price'] = data.channelsInfoArray[0].selling_local_price
                data['ts_product_local_price_with_vat'] = data.channelsInfoArray[0].selling_local_price_include_vat
              } else {
                data['ts_product_local_price'] = data.local_price
                data['ts_product_local_price_with_vat'] = data.local_price_include_vat
              }
              this.addToCart(data)
              return;
            }
            self_.searchResults = response.data.products;
          } else {
            self_.searchResultsEdit = response.data.products;
            self_.searchResultsEdit.map(item => {
              this.edit_price[item.id] === item.channelsInfoArray[0].selling_price
              console.log(item.channelsInfoArray[0].selling_price , this.edit_price[item.id])
            })
            console.log(self_.searchResultsEdit)
          }
        })
      .then((response) => {
        angular.forEach(self_.searchResults, function (item, key) {
          self_.nextImageIndexProduct[item.id] = 0;
        });
      })
      .then((response) => {
      });


  }


  addToCart(product){
    if (product.sub_products && 0 < product?.sub_products.length && !product?.sub_product) {
      this.ToastService.error(this.$translate.instant('SELECT_PROD_VARIANT'));
      return;
    }
    let object = {
      quantity: '',
      product_id: '',
      ts_product_code: '',
      ts_supplier_product_code: '',
      ts_product_name: '',
      product_tracking_mode: '',
      sub_product_id: '',
      sub_product_string: '',
      selected_lots: '',
      supply_desc: '',
      ts_barcode: '',
      ts_sub_barcode: '',
      images : product.images,
      has_recipe : '',
      unit : '',
      recipe: [],
    };
    object.selected_lots = this.selectedLots.filter(sL => {
      if (product.sub_product) {
        return sL.product_id === product.id && sL.sub_product_id === product.sub_product.id
      } else {
        return sL.product_id === product.id
      }
    })

    object.quantity                      = product.requirementQuantity || 1;
    object.product_id                    = product.id;
    object.ts_product_code               = product.ts_product_code;
    object.ts_supplier_product_code      = product.ts_supplier_product_code;
    object.ts_product_name               = product.ts_product_name;
    object.product_tracking_mode         = product.sub_product ? product.sub_product.tracking_mode : product.product_tracking_mode;
    object.sub_product_id                = product.sub_product ? product.sub_product.id : null;
    //object.sub_product_string            = product.sub_product ? product.sub_product.ts_sub_product_code + ' ' + product.sub_product?.ts_property1 + ' ' + product.sub_product?.ts_property2 : null;
    object.sub_product_string            = null;
    object.ts_barcode                    = product.ts_barcode;
    object.ts_sub_barcode                = product.sub_product ? product.sub_product.ts_barcode : null;
    object.has_recipe                    = product.has_recipe;
    object.unit                          = product?.unit;

    let activeRecipe = null;
    if (product.recipe && Array.isArray(product.recipe.recipes)) {
      activeRecipe = product.recipe.recipes.find(recipe => recipe.default === 1);
    }
    object.recipe = activeRecipe ? activeRecipe : null;
    let exist = false;
    // varsa quantity artırırız
    this.basketProductsArr.map(item => {
      if (item.product_id === object.product_id && item.sub_product_id === object.sub_product_id) {
        exist = true;
        item.quantity++;
      }
    });
    // yoksa ekleriz
    if (!exist) {
      this.basketProductsArr.push(object);
    }

    this.basketProductStorage('set');
  }

  deleteToCartV2(product) {
    this.basketProductsArr = this.basketProductsArr.filter(item => {
      return !(item.product_id === product.product_id && item.sub_product_id === product.sub_product_id);
    });
    this.basketProductStorage('set');
  }

  refreshBasketV2(basket) {
    // sepetten kaldırıldıysa çıkaralım
    let basketFilter = JSON.parse(localStorage.getItem("basketProducts")).filter(bP => {
      return 0 < basket.filter(b => b.product_id === bP.product_id && b.sub_product_id === bP.sub_product_id).length
    })


    basketFilter.map(bF => {
      let product = basket.filter(b => b.product_id === bF.product_id && b.sub_product_id === bF.sub_product_id)[0]

      bF.selected_lots = this.selectedLots.filter(sL => {
        if (product.sub_product_id) {
          return sL.product_id === product.product_id && sL.sub_product_id === product.sub_product_id
        } else {
          return sL.product_id === product.product_id
        }
      })[0]
      bF.supply_desc          = product.supply_desc
      bF.quantity             = product.quantity;
      console.log("şurdan şuphe,  ", product.quantity);
    })

    localStorage.setItem("basketProducts", JSON.stringify(basketFilter));
    this.basketProductsArr = basketFilter;

  }

  save() {
    var self_ = this;
    this.saveProcessing = true;

    var reqData = {
      companyId: this.createCompanyId !== null ? this.createCompanyId : "",
      userId: this.currentUserId,

      actionTypeId: this.createActionTypeId,
      basketProducts: this.basketProductsArr,
      locationId: this.createLocationId,
      toLocationId: this.createToLocationId,
      actionNote: this.createActionNote,
      saveFromOrder: false,
      branch: this.branch,
      ts_application:'polypus',
      accounting : 'manuel',
      sourceShelfId : this.selectedSourceShelf,
      targetShelfId : this.selectedTargetShelf,
      dualShelfId : this.selectedDualShelf,
    };

    this.API.all("action/save")
      .post(reqData)
      .then((response) => {
        this.saveProcessing = false;
        if (response.data.error === false) {
          this.ToastService.success(this.$translate.instant('TRANSACTION_CREATED_SUCC'));
          self_.clearCreateActionForm();
        }
        this.saveProcessing = false;
      })
      .catch(function () {
        self_.saveProcessing = false;
      });
  }

  getOrderListV2(pageNumber = this.pageNumber, filterArr = this.filterArr, per_page = this.per_page) {

    let parent = this;

    if (this.timeRange == 'custom') {
      const startOfDay = new Date(this.startDate);
      startOfDay.setHours(0, 0, 0, 0);
      const formattedDate = startOfDay.toISOString();
      this.startDate = formattedDate;
    }
    if (this.timeRange == 'custom') {
      const startOfDay = new Date(this.endDate);
      startOfDay.setHours(0, 0, 0, 0);
      const formattedDate = startOfDay.toISOString();
      this.endDate = formattedDate;
    }
    // && = doğruysa sağdakini çalıtır yoksa bişey yapma
    let filter = {
      page                                      : pageNumber,
      per_page                                  : per_page,
      out                                       : filterArr && filterArr['out'],
      excelMode                                 : filterArr && filterArr['excelMode'],
      sortMode                                  : filterArr && filterArr['sortMode'],
      column                                    : filterArr && filterArr['column'],
      filterMode                                : this.filterScopeType ?? null,
      purchaseInvoiceNu                         : this.searchPurchaseInvoiceNu,
      searchTransactionId                       : this.searchOrderNumber,
      issueId                                   : this.issue,
      userId                                    : this.searchUserId,
      startDate                                 : this.startDate,
      endDate                                   : this.endDate,
      deliveryDate                              : this.deliveryDate,
      searchFieldId                             : this.searchFieldId,
      searchResponsibleUserId                   : this.searchResponsibleUserId,
      searchChannelId                           : this.searchActionChannelId,
      searchLocationId                          : this.searchLocationId,
      searchValue                               : this.searchValue,
      searchActionTypeId                        : this.searchActionTypeId && this.searchActionTypeId.join('|'),
      searchPaymentType                         : this.searchPaymentType && this.searchPaymentType.join('|'),
      withArchive                               : this.searchIsArchive ? 1:0,
      searchNoneInvoice                         : this.searchNoneInvoice ? 1:0,
      searchNoneTax                             : this.searchNoneTax ? 1:0,
      searchIsDeleted                           : this.searchIsDeleted ? 1:0,
      searchUnprepared                          : this.searchUnprepared ? 1:0,
      searchOneProductOrder                     : this.searchOneProductOrder ? 1:0,
      missingItem                               : this.missingItem ? 1 : 0 ,
      searchUnpreparedActionForPrepareModule    : this.searchUnpreparedActionForPrepareModule ? 1:0,
      searchActionInPackageProcess              : this.searchActionInPackageProcess ? 1:0,
      searchActionInFinishedPrepare             : this.searchActionInFinishedPrepare ? 1:0,
      notAssignedToTransporter                  : this.searchNotAssignedToTransporter ? 1:0,

      shipmentCode                              : this.shipmentCode,
      customerCode                              : this.customerCode,
      shipmentProcess                           : this.shipmentProcess,
      marketplace                               : this.filterMarketplace && this.filterMarketplace.join('|'),
      actionProcess                             : this.filterActionProcess && this.filterActionProcess.join('|'),
      paymentType                               : this.filterPaymentType && this.filterPaymentType.join('|'),
      shipmentProvider                          : this.filterShipment && this.filterShipment.join('|'),
      customerName                              : this.customerName,
      userName                                  : this.userName,
      email                                     : this.email,
    }
    if (filter['out'] == 'excel') {
      filter['token'] = window.localStorage.satellizer_token;
    }
    if (this.accountingProcess !== null){
      filter.accountingProcess = this.accountingProcess && this.accountingProcess.join('|');
    }

    if (this.productId){
      filter['productId'] = this.productId;
    }

    return this.API.all("action")
        .customGET("filter", filter)
        .then((response) => {
          this.filterScopeType = null;
          this.filterArr = {...this.filterArr,out:'json'};
          if (filter.out === 'excel') {
            //window.location.assign(window.__env.apiUrl + "/api/action/exportExcel/" + response.data.fileName + "?token=" + window.localStorage.satellizer_token);
            this.ToastService.success(response.data.message);
            return true
          }

          let parent = this;
          parent.actions = response.data.actions;

          parent.selectedActions = [];
          angular.forEach(response.data.actions.data, function (action, key) {
            parent.selectedActions[action.transaction_id] = false;
          });

          parent.periodTotalCount = response.data.actions.periodTotalCount;
          parent.totalCount = response.data.actions.totalCount;
          parent.totalCountCacheDate = response.data.actions.totalCountCacheDate;
          parent.totalUpdatable = response.data.actions.totalUpdatable;

          parent.current_page = response.data.actions.current_page;
          parent.per_page = response.data.actions.per_page;
          parent.last_page = response.data.actions.last_page;
          parent.prev_page_url = response.data.actions.prev_page_url;
          parent.next_page_url = response.data.actions.next_page_url;
          parent.totalAmount = response.data.totalAmount;
          parent.totalAmountWithVat = response.data.totalAmountWithVat;

          parent.pagesArray = response.data.actionPagesArray;

          parent.prev_index = response.data.prevIndex;
          parent.next_index = response.data.nextIndex;

          parent.selectedActions = [];
          parent.selectedLines = [];
          parent.showToolbar = false;
          parent.selectAllAction = false;
          parent.selectAllLines = false;
          parent.sortButtonDisabled = false;
          parent.orderBulkSelection = false
          parent.visibleTableRow()
        });
  }

  changeSortMode() {
    this.sortMode = this.sortMode === 'asc' ? 'desc': 'asc';
    if (this.sortMode === 'asc') {
      this.sortModeStr = "arrow_drop_up";
    }
    if (this.sortMode === 'desc') {
      this.sortModeStr = "arrow_drop_down";
    }
    this.filterArr = {...this.filterArr, column: this.column, sortMode: this.sortMode}

    this.getOrderListV2(1, this.filterArr, this.per_page);
  }

  deleteAction(actionId) {
    this.selectedDetailActionId = actionId;
    this.DialogService.confirm(
        this.$translate.instant('CONFIRM_MESSAGE'),
        this.$translate.instant('WANT_TO_DELETE_TRANSACTION')
    ).then(
      () => {
        var reqData = {
          action_id: actionId,
          per_page: this.per_page,
          page: this.pageNumber,
        };

        var parent = this;

        this.API.all("action-process/returned/"+actionId)
          .customDELETE()
          .then((response) => {
            parent.actions = response.data.actions;
            parent.total = response.data.actions.total;
            parent.current_page = response.data.actions.current_page;
            parent.per_page = response.data.actions.per_page;
            parent.last_page = response.data.actions.last_page;
            parent.prev_page_url = response.data.actions.prev_page_url;
            parent.next_page_url = response.data.actions.next_page_url;

            parent.pagesArray = response.data.actionPagesArray;

            parent.prev_index = response.data.prevIndex;
            parent.next_index = response.data.nextIndex;
          }).catch(err => {
          this.ToastService.error(err.data.errors.message[0])
        });
      },
      () => {}
    );
  }

  /**
   * @deprecated burası önceden localstorage de yaızıyordu yavaş yavaş kaldıralım
   * @param data
   * @param edit
   */
  accordion(data, edit = false) {
    this.collapsed = true;
    this.editCollapsed = true;
  }

  parseBoolean(data) {
    if (data == "false" || !data) {
      return false;
    } else if (data == "true" || data) {
      return true;
    }
  }

  quickSearch(orderNu) {
    if (orderNu) {
      if (this.inputChangedPromise) {
        this.$timeout.cancel(this.inputChangedPromise);
      }

      var edited = orderNu.replace("?", "_");
      var edited = edited.replace("*", "-");

      this.searchOrderNumber = edited;
//      this.inputChangedPromise = this.$timeout(this.filter(), 10);
      this.inputChangedPromise = this.$timeout(this.getOrderListV2(), 10);
    }
  }

  selectAllContent($event) {
    $event.target.select();
  }

  paginationDataGenerator(parent, response) {
    parent.actions = response.data.actions;
    parent.total = response.data.actions.total;
    parent.current_page = response.data.actions.current_page;
    parent.per_page = response.data.actions.per_page;
    parent.last_page = response.data.actions.last_page;
    parent.prev_page_url = response.data.actions.prev_page_url;
    parent.next_page_url = response.data.actions.next_page_url;

    parent.pagesArray = response.data.actionPagesArray;

    parent.prev_index = response.data.prevIndex;
    parent.next_index = response.data.nextIndex;
  }



  clearForm() {
    this.startDate = null;
    this.endDate = null;
    this.deliveryDate = null;
    this.filterArr = {};
    this.searchFieldId = null;
    this.searchValue = null;
    this.searchActionTypeId = null;
    this.searchUserId = null;
    this.searchActionChannelId = null;
    this.searchPaymentType = null;
    this.missingItem = null;
    this.searchActionTypeId = null;
    this.searchLocationId = null;
    this.searchIsArchive = null;
    this.searchOrderNumber = null;
    this.searchOneProductOrder = false;
    this.searchUnprepared = null;
    this.searchPurchaseInvoiceNu = null;
    this.searchNoneInvoice = null;
    this.searchNoneTax = null;
    this.searchIsDeleted = null;
    this.searchResponsibleUserId = null
    this.searchNotAssignedToTransporter = null
    this.orderBulkSelection = false

    this.shipmentCode = null;
    this.customerCode = null;
    this.shipmentProcess = null
    this.filterMarketplace = null
    this.filterActionProcess = null
    this.filterPaymentType = null
    this.filterShipment = null
    this.customerName = null
    this.userName = null
    this.email = null
    this.accountingProcess = null
    this.issueId = null
    this.timeRange = 'lastWeek';
    this.changeTimeRange();
    //this.getOrderLists();
    this.getOrderListV2(this.pageNumber,this.filterArr,this.per_page);
  }

  forceOneTag(tags) {
    if (tags !== null) {
      return tags.length === 0;
    } else {
      return true;
    }
  }

  edit(actionId) {
    this.selectedDetailActionId = actionId;
    var parent = this;
    this.disabled[actionId] = true;

    if (0 === this.branches.length){
      this.branchesList()
    }

    this.API.all("action/"+this.selectedDetailActionId)
      .doGET()
      .then((response) => {
        let paymentTypeArrFlat = response.data.paymentTypeArr.map(pr=>pr.typeId)
        parent.editOrderList = [
          {
            title: response.data.action.transaction_id,
            actionObject: response.data.action,
            actionLine: response.data.actionLine,
            customer: response.data.customer,
            paymentTypes: response.data.paymentTypes
                .filter(f=> f.channelId === response.data.action.channel_id || (response.data.action.channel_id === null && 'default' === f.channelProvider))
                .map(p=>{p.selected = paymentTypeArrFlat.findIndex(pr => pr === p.id) !== -1;return p;}),
            paymentTypeArr: response.data.paymentTypeArr,
          },
        ];
        parent.activeTabIndex = 3;
        parent.editCompanyId = response.data.customer[0].customer_id;
        parent.editCompanyLabel = response.data.customer[0].ts_customer_name;
        parent.disabled[actionId] = false;
      });
  }


  showDetail(action_id) {
    this.selectedDetailActionId = action_id;
    let options = {
      controller: 'ActionDetailController',
      controllerAs: 'vm',
      locals: {
        id: action_id
      },
      bindToController: true,
      onShowing: function (scope, element, options, controller) {
        controller.getDetail(controller.id)
      },
    }
    this.DialogService.fromTemplateV2(
      './views/angular/app/components/order-lists/partials/action-detail.component.html',
      options
    )
  }

  removeTab() {
    this.editOrderList = {};
  }

  deleteToActionLine(index) {
     this.deleteUpdate[index] = true;
      this.editPermission = true;
  }

  comeBackToActionLine(index){
        this.deleteUpdate[index] = false;
  }

  update() {
    var self_ = this;

    _.forEach(this.editBasketProductsArr, function (value, key) {
      if (self_.editBasketDiscount !== undefined) {
        self_.editBasketProductsArr[key].discount =
          self_.editBasketDiscount[value.product_id];
      }

      if (self_.editBasketQuantity !== undefined) {
        self_.editBasketProductsArr[key].quantity =
          self_.editBasketQuantity[value.product_id];
      }

      if (self_.edit_price_with_discount !== undefined) {
        self_.editBasketProductsArr[key].price_with_discount =
          self_.edit_price_with_discount[value.product_id];
      }

      if (self_.editBasketSupplyDesc !== undefined) {
        self_.editBasketProductsArr[key].supply_desc =
          self_.editBasketSupplyDesc[value.product_id];
      }
    });


    if (this.editPaymentType.length === 1) {
      this.editTotalAmount = [];
    }

    var reqData = {
      //companyId: this.editCompanyId !== null ? this.editCompanyId[0].id : "",
      companyId: this.editCompanyId !== null ? this.editCompanyId : "",
      userId: this.editUserId,
      responsibleUserId: this.editResponsibleUserId,
      transactionId: this.editTransactionId,
      actionTypeId: this.editActionTypeId,
      basketProducts: this.editBasketProductsArr,
      locationId: this.editLocationId,
      toLocationId: this.editToLocationId,
      currencyId: this.editCurrencyId,
      paymentType: this.editPaymentType,
      editActionNote: this.editActionNote,
      totalAmount: this.editTotalAmount,
      invoiceNu: this.editInvoiceNumber,
      taxType: this.editTaxType,
      refundType: this.editRefundType,
      deliveryType: this.editDeliveryType,
      branch      : this.editBranch,
    };

    this.API.all("action/update")
      .post(reqData)
        .then((response) => {
          if (response.data.error === false) {
            this.ToastService.success(this.$translate.instant('STOCK_MOVEMENT_UPDATED_SUCC'));
            this.editBasketProductsArr = [];

            self_.editOrderList[0].actionLine = response.data.actionLines;
            self_.editOrderList[0].actionObject = response.data.actionObject;
            self_.diff = false;
            self_.disableEditField();
          }
        }).catch(err => {
          this.ToastService.error(err.data.errors.message[0])
    });
  }

  exportExcel(actionId) {
    this.selectedDetailActionId = actionId;
    var reqData = {
      actionId: actionId,
    };

    this.API.all("action/export")
      .post(reqData)
      .then((response) => {
        window.location.assign(window.__env.apiUrl+"/api/action/exportExcel/"+response.data.fileName+"?token="+window.localStorage.satellizer_token);
        this.ToastService.success(this.$translate.instant('DOWNLOADING_FILE'));
      });
  }

  exportPDF(actionId,type=null) {
    var reqData = {
      actionId: actionId,
      type:type // geçici olarak eklendi
    };

    this.API.all("action/export/pdf")
      .post(reqData)
      .then((response) => {
        window.open(window.__env.apiUrl+"/api/action/exportPdf/"+response.fileName+"?token="+window.localStorage.satellizer_token, '_blank');
    });
  }

  exportPDFV2(actionId, type){
    this.selectedDetailActionId = actionId;
    var options = {
      controller        : 'PrintPreviewDialogController',
      controllerAs      : 'vm',
      //  scope             : this.$scope,
      locals            : {
        actionId     : actionId,
        type: type
      },
      bindToController  : true,
      multiple          : true,
      onShowing         : function (scope, element, options, controller){
        controller.printActionPdf(actionId, type)
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
      './views/angular/dialogs/printPreview/printPreview.dialog.html',
      options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })

  }

  updateAllLines(tab){

    this.updateTime = true;
    tab.actionLine.map((item,index) => {
      if (this.lineQuantity[item.product_id] <= 0 || parseInt(this.price_with_discount[item.product_id]) <= 0) {
        this.ToastService.error(this.$translate.instant('ENTERED_INCORRECT_PRODUCT'));
        return;
      }
      if (this.deleteUpdate[index]) return;
      var reqData = {
        actionLineId: item?.id,
        actionId: tab.actionObject.id,
        productId: item?.product_id,
        subProductId: item?.sub_product_id,
        price:  parseInt(this.price_with_discount[item.product_id]) >= 0 ? parseInt(this.price_with_discount[item.product_id]) : parseInt(this.edit_price[item.product_id]),
        quantity: this.lineQuantity[item.product_id],
      };
      this.actionLines.push(reqData)
      this.checkDisabled = true;
     // this.updateActionLine(item.id,tab.actionObject.id,this.price_with_discount[item.id],this.lineQuantity[item.id],tab.actionLine.product_id)
    })
    this.API.all(`action/${tab.actionObject.id}/lines`)
        .customPUT({
          'lines':this.actionLines
        })
        .then((response) => {
          this.diff = response.data.diff;
          this.checkDisabled = false;
          this.actionLines = [];
          this.deleteUpdate = []
          this.editPermission = false
          this.updateTime = false;
        }).catch(err=>{
          this.checkDisabled = false;
       // this.ToastService.error(err.data.errors.message[0]);
      this.deleteUpdate = []
      this.actionLines = [];
      this.editPermission = false
      this.updateTime = false;
    });
  }
  turnBackAllLines(tab){
    this.deleteUpdate = []
    this.editPermission = false;
     this.updateTime = false
    tab.actionLine.map((item,index) => {
      this.qtyInput[item.product_id] = false
      this.price_with_discount[item.product_id] = item.price;
      this.lineQuantity[item.product_id] = item.quantity
    })
  }
  printCargoBarcode(actionId) {
    this.selectedDetailActionId = actionId;
    var options = {
      controller: 'PrintPreviewDialogController',
      controllerAs: 'vm',
      locals: {
        productId: actionId
      },
      bindToController: true,
      multiple: true,
      onShowing: function (scope, element, options, controller) {
        controller.printCargoBarcode(actionId)
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
      './views/angular/dialogs/printPreview/printPreview.dialog.html',
      options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })

  }

  priceControl($event, value) {
    var delimiterExist = value.indexOf(",");

    if (
      ($event.keyCode >= 48 && $event.keyCode <= 57) ||
      ($event.keyCode == 44 && delimiterExist == -1)
    ) {
      return true;
    } else {
      $event.preventDefault();
      return false;
    }
  }

  getSelectedActions() {

  }

  toggleCheckAction(transactionId, status) {
    this.attacmentUserId = 1 === this.responsibleUsers.length ? this.responsibleUsers[0].id:0

    this.selectedDetailActionId = null;
    var keys = Object.keys(this.selectedActions);
    this.showToolbar = false;
    for (var i = 0; i < keys.length; i++) {
      if (this.selectedActions[keys[i]]) {
        this.showToolbar = true;
      }
    }
  }

  toggleCheckLine(transactionId, status) {
    var keys = Object.keys(this.selectedLines);
    this.showToolbarForEdit = false;
    for (var i = 0; i < keys.length; i++) {
      if (this.selectedLines[keys[i]]) {
        this.showToolbarForEdit = true;
      }
    }
  }

  toggleAllAction() {
    var newArr = [];

    var parent = this;
    if (!parent.selectAllAction == false) {
      this.selectedActions = [];
      this.showToolbar = false;
    } else {
      this.selectedActions = [];
      this.showToolbar = true;
      angular.forEach(this.actions.data, function (action, key) {
        parent.selectedActions[action.transaction_id] = !parent.selectAllAction;
      });
    }
  }

  toggleAllLines() {
    var newArr = [];

    var parent = this;
    if (!parent.selectAllLines == false) {
      this.selectedLines = [];
      this.showToolbarForEdit = false;
    } else {
      this.selectedLines = [];
      this.showToolbarForEdit = true;

      angular.forEach(
        this.editOrderList[0].actionLine,
        function (actionLine, key) {
          parent.selectedLines[actionLine.id] = !parent.selectAllLines;
        }
      );
    }
  }

  actionBatchPrintV2() {
    var options = {
      controller        : 'PrintPreviewDialogController',
      controllerAs      : 'vm',
      //  scope             : this.$scope,
      locals            : {
        selectedActions: this.selectedActions
      },
      bindToController  : true,
      multiple          : true,
      onShowing         : function (scope, element, options, controller){
        controller.actionBatchPrint(controller.selectedActions);
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/dialogs/printPreview/printPreview.dialog.html',
        options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })

  }

  printActionLinesV2() {
    var options = {
      controller        : 'PrintPreviewDialogController',
      controllerAs      : 'vm',
      //  scope             : this.$scope,
      locals            : {
        selectedLines: this.selectedLines
      },
      bindToController  : true,
      multiple          : true,
      onShowing         : function (scope, element, options, controller) {
        controller.printActionLines(controller.selectedLines);
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/dialogs/printPreview/printPreview.dialog.html',
        options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })

  }

  printActionLineV2(actionLineId) {
    var options = {
      controller        : 'PrintPreviewDialogController',
      controllerAs      : 'vm',
      //  scope             : this.$scope,
      locals            : {
        actionLineId: actionLineId
      },
      bindToController  : true,
      multiple          : true,
      onShowing         : function (scope, element, options, controller) {
        controller.printActionLine(controller.actionLineId);
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/dialogs/printPreview/printPreview.dialog.html',
        options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })

  }

  copyAction(actionId) {
    this.selectedDetailActionId = actionId;
    if (confirm(actionId + " işlemi kopyalanacaktır. Emin misiniz?")) {
      var reqData = {
        transactionId: actionId,
      };

      this.API.all("action/copy")
        .post(reqData)
        .then((response) => {
          this.ToastService.success(this.$translate.instant('TRANSACTION_COPIED'));
          this.getOrderListV2();
        });
    } else {
    }
  }

  closeMdMenu() {
    this.$mdMenu.hide();
  }

  exportActionExcelV2(excelMode) {
    this.filterArr = {...this.filterArr, excelMode: excelMode, out: 'excel'};
    this.getOrderListV2(this.pageNumber, this.filterArr, this.per_page)
  }

  /**
   * @deprecated eski servis
   * yeni servis exportActionExcelV2
   * @param onlyAction
   */
  exportActionExcel(onlyAction) {
    var filter = {
      searchFieldId: this.searchFieldId,
      searchValue: this.searchValue,
      searchTransactionId: this.searchOrderNumber,
      searchActionTypeId: !_.isEmpty(this.searchActionTypeId)
        ? JSON.stringify(this.searchActionTypeId)
        : null,
      searchLocationId: this.searchLocationId,
      searchChannelId: this.searchActionChannelId,
      searchPaymentType: !_.isEmpty(this.searchPaymentType)
        ? JSON.stringify(this.searchPaymentType)
        : null,
      searchIsDeleted: this.searchIsDeleted,
      searchUnprepared: this.searchUnprepared,
      userId: this.searchUserId,
      startDate: this.startDate,
      endDate: this.endDate,
      per_page: this.per_page,
      withArchive: this.searchIsArchive,
      purchaseInvoiceNu: this.searchPurchaseInvoiceNu,
      pageNumber: 1,
      excelMode: 1,
      onlyActions: onlyAction,
    };
    this.onlyAction = onlyAction;
    this.excelMode = filter.excelMode;
    var parent = this;
    this.filterArr = filter;

    this.API.all("action")
        .customGET('filter',filter)
      .then((response) => {
        window.location.assign(window.__env.apiUrl+"/api/action/exportExcel/"+response.data.fileName+"?token="+window.localStorage.satellizer_token);
        this.ToastService.success(this.$translate.instant('STOCK_MOVEMENT_DOWNLOADED_EXCEL'));
      });
  }

  enableEditField(actionLineId) {
    this.updateTime = false;

    this.editPermission = true
    this.qtyInput = [];
    this.qtyInput[actionLineId] = true;
  }

  disableEditField() {
    this.qtyInput = false;
  }

  addLot(product_id, must_qty, product_name, sub_product_id = null) {
    var findedIndex = this.selectedLots.indexOf(
      _.find(this.selectedLots, function (selectedLot) {
        if (sub_product_id == null)
          return selectedLot.product_id === product_id;
        else
          return (
            selectedLot.product_id === product_id &&
            selectedLot.sub_product_id === sub_product_id
          );
      })
    );

    let reqData = {
      product_id: product_id,
      sub_product_id: sub_product_id,
      must_qty: must_qty,
      channel_product_info: product_name,
      location_id: this.createLocationId,
      selected_lot_data:
        findedIndex !== -1 ? this.selectedLots[findedIndex]["lot_data"] : [],
    };
    var options = {
      locals: { reqData: reqData },
      controller: "SelectLotOrSeriController",
    };
    var lotDialogPromise = this.DialogService.fromTemplate(
      "selectLotOrSeri",
      options
    );
    var parent = this;

    lotDialogPromise.then(
      function () {
        let lotData = parent.DialogService.getContainerData();
        let lotObj = {
          product_id: product_id,
          sub_product_id: sub_product_id,
          lot_data: lotData,
        };

        if (findedIndex !== -1) parent.selectedLots.splice(findedIndex, 1);

        parent.selectedLots.push(lotObj);
      },
      function () {}
    );
  }

  editLot(
    product_id,
    must_qty,
    ts_product_name,
    selected_lots,
    sub_product_id = null
  ) {
    if (this.selectedLots !== undefined) {
      var findedIndex = this.selectedLots.indexOf(
        _.find(this.selectedLots, function (selectedLot) {
          if (sub_product_id == null)
            return selectedLot.product_id === product_id;
          else
            return (
              selectedLot.product_id === product_id &&
              selectedLot.sub_product_id === sub_product_id
            );
        })
      );
    } else {
      var findedIndex = -1;
    }

    let channel_product_info_arr = [];

    channel_product_info_arr.push({ ts_product_name: ts_product_name });

    let reqData = {
      product_id: product_id,
      sub_product_id: sub_product_id,
      must_qty: must_qty,
      location_id: this.createLocationId,
      channel_product_info: channel_product_info_arr,
      selected_lot_data: !_.isEmpty(selected_lots)
        ? selected_lots.lot_data
        : [],
    };

    var options = {
      locals: { reqData: reqData },
      controller: "SelectLotOrSeriController",
    };
    var lotDialogPromise = this.DialogService.fromTemplate(
      "selectLotOrSeri",
      options
    );
    var parent = this;

    lotDialogPromise.then(
      function (response) {
        let lotData = parent.DialogService.getContainerData();
        let lotObj = {
          product_id: product_id,
          sub_product_id: sub_product_id,
          lot_data: lotData,
        };

        if (findedIndex !== -1) parent.selectedLots.splice(findedIndex, 1);

        if (!_.isEmpty(lotData)) {
          parent.selectedLots.push(lotObj);
          parent.refreshBasketV2(parent.basketProductsArr);
        }
      },
      function () {}
    );
  }

  openIntroductionVideoModal(video) {
    openIntroductionVideo(video);
  }

  openMenu($mdMenu, ev) {
    this.originatorEv = ev;
    $mdMenu.open(ev);
  };


  setScanType() {
    Cookies.set(this.activeTab.scannerCookieName, this.activeTab.scanType)

    if ('camera' === this.activeTab.scanType) {
      this.getCameraInfo()
    } else if ('terminal' === this.activeTab.scanType) {
      if (!this.activeTab.terminalEvent) {
        this.activeTab.terminalEvent = true;
        this.getTerminalInfo()
      }
    }
    this.selectedScanTypeLabel = this.scanTypeList.filter(sT=> sT.key === this.activeTab.scanType)[0]['label']
  }

  getCameraInfo() {
    let parent = this
    this.barcodeScanner.getCameras().then(devices => {
      if (0 === devices.length){
        parent.ToastService.error(this.$translate.instant('NO_AVAILABLE_CAMERA_FOUND'))
      }
      parent.cameraDevices = devices
    }).catch(err => {
      parent.ToastService.error(this.$translate.instant('PLEASE_ENABLE_BROWSER_PERMISSION'))
    })
  }

  getTerminalInfo() {
    this.ToastService.success(this.$translate.instant('POLYPUS_READY_HANDHELD_TERMINAL'))
    let parent = this;
    let barcode = '';
    let interval;
    this.activeTab.terminalEvent = (e) => {
      if (interval) {
        clearInterval(interval)
      }
      if (e.code == 'Enter' && barcode) {
        parent.scanCompleteCallback(barcode)
        barcode = '';
        return true;
      }
      if ('Shift' !== e.key) {
        barcode += e.key
      }
      interval = setInterval(() => barcode = '', 20)
    }
    this.window.document.addEventListener('keydown',this.activeTab.terminalEvent  ,true)
  }

  setSelectedCamera() {
    Cookies.set(this.activeTab.scannerIdCookieName, this.activeTab.scannerCameraId)
  }

  scanCompleteCallback = (decodedText, decodedResult) => {
    if (!this.scanTypeAccordionMenuOpen){
      this.ToastService.error(this.$translate.instant('NOT_STARTED_READING'))
      return
    }

    if ('camera' !== this.activeTab.scanType && 'terminal' !== this.activeTab.scanType) {
      return;
    }
    if ('camera' === this.activeTab.scanType && this.activeTab.cameraState !== 'pause') {
      this.scanCameraPause()
    }
    this.searchStockName = decodedText;
    this.getStock()
  };

  scanCameraStart() {
    let parent = this;
    this.activeTab.cameraState = 'resume'
    this.activeTab.cameraEvent = new parent.barcodeScanner(this.activeTab.cameraTemplateId);
    // telefon için {width: 200, height: 150} ideal
    // pc için {width: 250, height: 250}
    //ekrana göre ayar yapmak lazım
    const config = {fps: 10, qrbox: {width: 200, height: 150}, experimentalFeatures: {useBarCodeDetectorIfSupported: true}}
    this.activeTab.cameraEvent.start(
        parent.activeTab.scannerCameraId,
        config,
        parent.scanCompleteCallback,
    ).catch((err) => {
    });
  }

  scanCameraStop(){
    this.activeTab.cameraEvent?.stop();
    this.activeTab.cameraState = 'stop'
  }

  scanCameraPause() {
    this.activeTab.cameraEvent?.pause()
    this.activeTab.cameraState = 'pause'
  }

  scanCameraResume() {
    this.activeTab.cameraEvent?.resume()
    this.activeTab.cameraState = 'resume'
  }

  // todo : bunu genel kullanıma çevirmek lazım
  refreshTotalInfo() {
    this.API.all("settings/scheduler")
        .doGET()
        .then((response) => {
          let commandId = response.data.schedulers.filter(sch=> sch.command_name === 'row-count-cache')[0].id;
          this.API.all("queue/call")
              .post({
                command_id: commandId
              })
              .then((response) => {
                this.ToastService.success(this.$translate.instant('TOTAL_NUMBERS_INFO'));
              });
        });
  }

  getResponsibleUsers () {
    this.API.all('user/getResponsible').doGET()
        .then((response) => {
          this.responsibleUsers = response.data.data
          this.attacmentUserId = 1 === this.responsibleUsers?.length ? this.responsibleUsers[0].id:0
          this.createResponsibleUserId = 1 === this.responsibleUsers?.length ? this.responsibleUsers[0].id:0
        })
  }

  showChannelsDetail(product_id) {
    this.channelsDetail[product_id] = undefined === this.channelsDetail[product_id] ? true : undefined;
  }

  /* Burası üç fonksiyonla birlikte component olabilir template i ile birlikte  */
  prevImage(id, length) {
    let index = this.nextImageIndexProduct[id] - 1;
    this.changeImageIndex(id, index, length);
  }

  nextImage(id, length) {
    let index = this.nextImageIndexProduct[id] + 1;
    this.changeImageIndex(id, index, length);
  }

  changeImageIndex(id, index, length) {
    if (index < 0) {
      this.nextImageIndexProduct[id] = 0;
    }else if (index > length-1) {
      this.nextImageIndexProduct[id] = length-1;
    } else {
      this.nextImageIndexProduct[id] = index;
    }
  }

  branchesList(){
    this.API.all("settings/branch").doGET()
        .then((response) => {
          if (204 === response.response.status) return;
          this.branches = response.data.list

          this.branch = response.data.list.filter(f => f.default)[0].id
          this.editBranch = this.branch
        });

  }
  actionList(selectedName=null){
    const priority ={
      1:'normal',
      2:'high',
      3:'very-high',
      4:'urgent',
    }
    let parent=this;
    this.API.all("action-process/prepare").doGET()
        .then((response) => {
          if (204 === response.response.status) return;
          this.actionLists = response.data.list.map(list=>{
            if(selectedName == list.name){
              parent.selectActionList = list.listId
            }
            list.priorityCode = priority[list.priority]
            return list
          })

        });
  }

  getActionPrepareList() {
    this.API.all("action-process/assignList")
        .doGET(null, {
          startDate : !this.prepareListName ? this.prepareListLimitStartDate : null,
          endDate   : !this.prepareListName ? this.prepareListLimitEndDate : null,
          page      : this.prepareListPage,
          limit     : this.prepareListLimit,
          name      : this.prepareListName,
        })
        .then((response) => {
          if (response.response.status ===204){
            this.prepareList = null;
            return
          }
          this.prepareList = response.data.list
          console.log(this.prepareList);
        });
  }

  prepareListModal(list) {
    this.TabActionService.setTabName(list.name)
    this.TabActionService.show()
    this.TabActionService.setReturnTab(2)
    this.TabActionService.setProps({
      listId: list.listId
    })

  }

  showProductBottomSheet(productId) {
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
      controller: "BottomViewController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        id: productId
      }
    })
  }
  showBottomSheet(isShowCamera) {
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
      controller: "BottomViewController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        isShowCamera: isShowCamera
      }
    })
  }
  goCreateStockTab(){
    this.$state.go("app.stock_management", {tab: 1}, {reload:true});
  }


  showRelation(table){

    this.DialogService.fromTemplateV2(
        './views/angular/app/components/invoices/partials/dialog/relation-table.html',
        {
          onShowing: function (scope, element, options, controller) {
            scope.table = table;
          }
        }
    ).catch(err => {
      console.log(err)
    });
  }


  showOrderDetail(id) {
    var currentPath = this.$location.path();
    if (currentPath && currentPath === '/stock-management'){
      let options = {
        controller: 'InvoicesDetailController',
        controllerAs: 'vm',
        locals: {
          orderId: id,
          productId:this.productId
        },
        bindToController: true,
      }
      this.DialogService.fromTemplateV2(
          './views/angular/app/components/invoices-detail/invoices-detail.component.html',
          options
      )
      return;
    }
    this.selectedTabIndex = 3;
    this.orderDetailPage = true;
    this.selectedOrderId = id;
  }
  showOrderDetailDialog(id) {
    let options = {
      controller: 'InvoicesDetailController',
      controllerAs: 'vm',
      locals: {
        orderId: id,
        productId:this.productId
      },
      bindToController: true,
    }
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/invoices-detail/invoices-detail.component.html',
        options
    )
  }
  removeOrderDetailTab() {
    this.orderDetailPage = false;
    this.selectedTabIndex = 0;
  }
  showActionDetail(action_id) {
    this.selectedDetailActionId = action_id;
    let options = {
      controller: 'ActionDetailController',
      controllerAs: 'vm',
      locals: {
        id: action_id
      },
      bindToController: true,
      onShowing: function (scope, element, options, controller) {
        controller.getDetail(controller.id)
      },
    }
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/order-lists/partials/action-detail.component.html',
        options
    )
  }


  getActionType(safe = false){

    this.API.all("action-type")
        .customGET(null,{safe:safe?1:0})
        .then((response) => {
          this.actionTypes = response.data.types;
        });
  }

  selectActionType() {
    this.selectedActionType = this.actionTypes.filter(a => a.actionId === this.createActionTypeId)[0]
    if (this.createLocationId) {
      this.changeLocation()
    }

    if (this.createLocationId) {
      this.changeLocation()
    }

    this.locations.map(l => {
      l.show = true;

      // arızalılarla iligli özel actiontypelar var
      if (!this.selectedActionType.isFaultyActionType || this.selectedActionType.faultyTransfer){
        l.show = l.location_type !== 2
      }

      // arzalıdan gerçeğe trasnfer olacak location  olarak sadece action seçilsin
      if (this.selectedActionType.isFaultyActionType && this.selectedActionType.faultyReturnTransfer) {
        l.show = l.location_type === 2
      }

    })

    if (this.selectedActionType.isFaultyActionType){
      this.createLocationId = null;
      this.createToLocationId = null;
    }

    this.otherLocations.map(o=>{
      o.show = true;

      // arızalılarla iligli özel actiontypelar var
      if (!this.selectedOtherLocation.isFaultyActionType || this.selectedOtherLocation.faultyReturnTransfer){
        o.show = o.location_type !== 2
      }

      // arzalıdan gerçeğe trasnfer olacak location  olarak sadece action seçilsin
      if (this.selectedOtherLocation.isFaultyActionType && this.selectedOtherLocation.faultyTransfer) {
        o.show = o.location_type === 2
      }

    })
  }

  changeLocation() {

    if (this.selectedActionType.isSourceShelf) {
      this.selectedSourceShelf = null;
      this.getShelf(this.createLocationId).then(r=>{
        this.sourceShelfList = r
      })
    }

    if (this.selectedActionType.isDualShelf) {
      this.selectedDualShelf = null;
      this.getShelf(this.createLocationId).then(r=>{
        this.dualShelfList = r
      })
    }

    if (this.selectedActionType.isTargetShelf && !this.selectedActionType.isTargetShelfInToLocation){
      this.selectedTargetShelf = null;

      this.getShelf(this.createLocationId).then(r=>{
        this.targetShelfList = r
      })

    }

    this.selectedLocation = this.locations.filter(l=> l.id ===this.createLocationId)[0]
  }

  changeToLocation() {
    this.selectedTargetShelf = null;

    if (this.selectedActionType.isTargetShelf && this.selectedActionType.isTargetShelfInToLocation){
      this.getShelf(this.createToLocationId).then(r=>{
        this.targetShelfList = r
      })
    }
    this.selectedOtherLocation = this.otherLocations.filter(l=> l.id ===this.createToLocationId)[0]
  }


  getShelf(locationId) {
    return  this.API.all(`location/${locationId}/shelf`)
        .customGET(null, {page: 'all'})
        .then((response) => {
          return response.data.shelves.data
        });
  }

  getFilterInventory() {

    this.API.all("action/inventory")
        .customGET(null, {filter: 1})
        .then((response) => {
          this.actionFilterInventory = response.data.list
        });
  }

  accountingManuelComplete(actionId){
    var options = {
      controller: "OrderListsController",
      controllerAs: 'vm',
    };
    var dialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/app/components/order-lists/partials/manuel-transfer-erp-confirm.component.html',
        options
    );
    dialogPromise.then(shelf=>{
      this.API.all(`action-process/accounting/${actionId}/manuel`)
          .customPUT()
          .then((response) => {
            this.getOrderListV2()
          });
    })
    dialogPromise.catch(err=>{
      console.log(err)
    })
  }
  countingConfirm() {
    this.DialogService.hide(true);
  }
  showListDetail(listId) {
    let options = {
      controller: "IssuesDetailController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        listId: listId
      },
      onShowing: function (scope, element, options, controller) {
        controller.getList()
      },
    }
    this.DialogService.fromTemplateV2("./views/angular/app/components/issues/partials/issues-detail.html",options)
        .then((response)=>{
        })
  }
  selectAllOptionsByModel(model) {

    if (model === 'filterPaymentType'){
      this.filterPaymentType = this.actionFilterInventory.paymentTypes.data.map(d=>d.paymentId)
    }
    if (model === 'filterShipment'){
      this.filterShipment = this.actionFilterInventory.shipments.data.map(d=>d.code)
    }
    if (model === 'filterMarketplace'){
      this.filterMarketplace = this.actionFilterInventory.marketplaces.data.map(d=>d)
    }
    if (model === 'searchActionTypeId'){
      this.searchActionTypeId = this.actionTypes.map(d=>d.actionId)
    }
    if (model === 'searchActionTypeIdPlus'){
      this.searchActionTypeId = this.actionTypes.filter(d => d.mode === '+').map(d => d.actionId);
    }
    if (model === 'searchActionTypeIdMinus'){
      this.searchActionTypeId = this.actionTypes.filter(d => d.mode === '-').map(d => d.actionId);
    }
    if (model === 'accountingProcess'){
      this.accountingProcess = [0,1,2,3]
    }
  }
  toggleSelectAll() {
    if (this.allSelected) {
      this.searchActionTypeId = [];
    } else {
      this.searchActionTypeId = this.actionTypes.map(d => d.actionId);
    }
    this.allSelected = !this.allSelected;
  };

  deselectAllOptionsByModel(model) {
    this[model] = [];
  }

  printActionDocument(actionId) {
    this.API.all("action/barcode")
      .post({actionId: actionId})
      .then((response) => {
        document.getElementById('printContent').innerHTML = response.data.document;
        window.print();
        document.getElementById('printContent').innerHTML = '';
        /*
        const printWindow = window.open('', '_blank');
        printWindow.document.write(response.data.document);
        printWindow.document.close();
        printWindow.onload = function() {
          printWindow.print();
          printWindow.close();
        };*/
      })
  }

  changeTimeRange() {
    var now = new Date();
    switch (this.timeRange) {
      case 'lastDay':
        this.startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        this.endDate = now;
        break;
      case 'thisDay':
        this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        this.endDate = now;
        break;
      case 'lastWeek':
        this.startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        this.endDate = now;
        break;
      case 'thisWeek':
        var firstDayOfWeek = now.getDate() - now.getDay();
        this.startDate = new Date(now.setDate(firstDayOfWeek));
        this.endDate = new Date();
        break;
      case 'lastMonth':
        this.startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        this.endDate = now;
        break;
      case 'thisMonth':
        this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        this.endDate = new Date();
        break;
      case 'lastYear':
        this.startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        this.endDate = now;
        break;
      case 'thisYear':
        this.startDate = new Date(now.getFullYear(), 0, 1);
        this.endDate = now;
        break;
      case 'all':
        this.startDate = null;
        this.endDate = now;
        break;
    }
  }

}
OrderListsController.$inject = ["$window", "$auth", "$rootScope", "$scope", "$translate", "$timeout", "$mdBottomSheet", "$state", "$stateParams", "$location", "$mdMenu", "$q","API", "UserService", "ToastService", "LoadingService", "DialogService", "TabActionService","$filter","i18nService","uiGridConstants"];
const OrderListsComponent = {
  templateUrl: "./views/angular/app/components/order-lists/order-lists.component.html",
  controller: OrderListsController,
  controllerAs: "vm",
  bindings: {},
};

const ActionListComponent = {
  templateUrl: "./views/angular/app/components/order-lists/partials/action-list.component.html",
  controller: OrderListsController,
  controllerAs: "vm",
  bindings: {
    productId: '<',
    view: '<'
  },
};
export {OrderListsComponent,OrderListsController,ActionListComponent}
