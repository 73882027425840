
class AdminController {
  constructor(
    $auth, ToastService, API, $window, $state, $location, $sce) {
    'ngInject';

    this.$auth = $auth;
    this.$window = $window;
    this.ToastService = ToastService;
    this.API = API;
    this.users = {};
    this.$state = $state;
    this.$location = $location;
    this.$sce = $sce;

    this.redisKeys = [];
    this.cpuProcesses = [];
    this.mysqlProcesses = [];
    this.mysqlSchemas = [];
    this.performansDigest = [];
    this.redisTranslate = [];
  }

  $onInit() {
    this.per_page = 10;
    this.filterArr = {};
    this.per_page = 10;
    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
    this.satellizer_token = this.$window.localStorage.satellizer_token;
    if (this.satellizer_token) {
      this.userInfo = this.parseJwt(this.satellizer_token);
    }

    this.dashboard();
    this.wmsSettings();
  }

  onSelectTab(obj) {
    this.$location.search(obj);
  }

  clearForm() {

  }

  dashboard() {
    let parent = this;
    this.API.all('admin/dashboard')
      .customGET()
      .then((response) => {
        parent.redisKeys = response.data.redisKeys;
        parent.cpuLoads = response.data.cpuLoads;
        parent.cpuProcesses = response.data.cpuProcesses;
        parent.mysqlProcesses = response.data.mysqlProcesses;
        parent.mysqlSchemas = response.data.mysqlSchemas;
        parent.performansDigest = response.data.performansDigest;
        parent.redisTranslate = response.data.redisTranslate;
      })
      .catch((response) => {
        this.ToastService.error(response.data.errors.message[0]);
      });
  }

  wmsSettings(){
    let parent = this;
    this.API.all('admin/wms-settings')
        .customGET()
        .then((response) => {
          this.types = response.data.types;
        })
        .catch((response) => {
          this.ToastService.error(response.data.errors.message[0]);
        });
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

}

AdminController.$inject = ["$auth", "ToastService", "API", "$window", "$state", "$location","$sce"];

export const AdminComponent = {
  templateUrl: './views/angular/app/components/admin/admin.component.html',
  controller: AdminController,
  controllerAs: 'vm',
  bindings: {}
};
