import {FocusInputDirective} from './directives/focusInput/focusInput.directive';
import {PermissionDirective} from './directives/permission/permission.directive';
import {PasteEventDirective} from './directives/pasteEvent/pasteEvent.directive';

angular.module('app.directives')
    .directive('focusInput', FocusInputDirective)
    .directive('permission', PermissionDirective)
    .directive('ppPaste', PasteEventDirective)
    .directive("fileRead", [function () {
        return {
            restrict: 'A',
            scope: {
                fileRead: '=',
                fileReadCallBackFun: '&',
            },
            link: function (scope, element, attributes) {
                element.bind("change", function (changeEvent) {
                    scope.$apply(function () {
                        scope.fileRead = changeEvent.target.files;
                        //todo: fonksiyon gönderirken hangi isimde argümanın varsa onu işaretlemen gerekiyormuş "file"
                        scope.fileReadCallBackFun({file: changeEvent.target.files})
                    });
                });
            }
        }
    }])
    .directive('myEnter', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                const keyHandler = function(event) {
                    if (event.which === 13) {
                        scope.$apply(() => {
                            scope.$eval(attrs.myEnter);
                        });
                        event.preventDefault();
                    }
                };

                element.on("keydown keypress", keyHandler);

                // Memory leak önleme
                scope.$on('$destroy', () => {
                    element.off("keydown keypress", keyHandler);
                });
            }
        };
    })

    .directive('focusScroll', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                const focusoutHandler = () => document.body.style.overflow = 'auto';
                const focusinHandler = () => document.body.style.overflow = 'auto';

                element[0].addEventListener('focusout', focusoutHandler);
                element[0].addEventListener('focusin', focusinHandler);

                // Memory leak önleme
                scope.$on('$destroy', () => {
                    element[0].removeEventListener('focusout', focusoutHandler);
                    element[0].removeEventListener('focusin', focusinHandler);
                });
            }
        };
    })
    .directive('decimalLimit',function (){
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelCtrl) {
                const DECIMAL_REGEX = /^\d+(\.\d{0,5})?$/;

                ngModelCtrl.$parsers.unshift(function (value) {
                    if (!value) return value;

                    if (DECIMAL_REGEX.test(value)) {
                        return value;
                    }

                    const validValue = value.slice(0, -1);
                    ngModelCtrl.$setViewValue(validValue);
                    ngModelCtrl.$render();
                    return validValue;
                });

                // Input validasyonu için
                element.on('keypress', function(event) {
                    if (!/[\d.]/.test(String.fromCharCode(event.which))) {
                        event.preventDefault();
                    }
                });
            }
        };
    })
    .directive('setFocus', function () {
      return {
        link: function (scope, element, attrs) {
          element.bind('click', function () {
            document.querySelector('#' + attrs.setFocus).focus();
          })
        }
      }
    })
  /*
    .directive('focusToInput',function (){
      return function (scope,element,attrs){
          element.focus()
      }
    })
   */
    .directive('mySticky', function ($mdSticky) {
        return {
            restrict: 'E',
            template: `<div layout="row" style="padding: 0 5px;width: 100%;" id="sticky">
                <md-icon style="margin:20px 13px 0 2px; font-size:xx-large" class="material-symbols-outlined">barcode</md-icon>
                <md-input-container my-enter="vm.prepareListItem()" class=""
                                    flex ng-if="vm.actionPrepareProductListSelect.barcode !== '' && vm.actionPrepareProductListSelect.barcode !== undefined">
                    <label>{{ "BARCODE" | translate }}</label>
                    <input focus-to-input="" type="text" ng-model="vm.productBarcode" />
                </md-input-container>

                <md-input-container layout="row" layout-align="end center">
                    <md-button class="md-raised md-primary md-button md-ink-ripple mini-btn"
                               ng-click="vm.actionPrepareListSection[vm.orderListType]='select';vm.actionList()">
                        <md-icon style="color: #fff" class="material-icons">arrow_back</md-icon>
                        <span hide-xs="">{{ "BACK" | translate }}</span>
                    </md-button>
                </md-input-container>
            </div>`,
            link: function (scope, element) {
                $mdSticky(scope, element);
            }
        }
    })
    .directive('focusMe', function () {
        return {
            scope: {trigger: '=focusMe'},
            link: function (scope, element) {
                scope.$watch('trigger', function (value) {
                    if (value === true) {
                        element[0].focus();
                        scope.trigger = false;
                    }
                });
            }
        };
    })
    .directive('disableVirtualKeyboard', function () {
        return {
            link: function (scope, element) {
                element[0].addEventListener('focusout', function () {
                    element[0].setAttribute('readonly', true)
                })
                element[0].addEventListener('focusin', function () {
                    element[0].removeAttribute('readonly')
                })
            }
        };
    })
    .directive('ppIcon', function() {
      return {
        restrict: 'E',
        template: '<md-icon ng-transclude class="material-symbols-outlined" style="color:#aaa;font-size:21px""></md-icon>',
        transclude: true
      };
    })
    .directive('allowOnly',function (){
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (!ngModel) return;

                const allowedChars = attrs.allowOnly;
                const regex = new RegExp(`[^${allowedChars}]`, 'g');

                const inputHandler = function() {
                    const sanitizedValue = element.val().replace(regex, '');
                    if (element.val() !== sanitizedValue) {
                        ngModel.$setViewValue(sanitizedValue);
                        ngModel.$render();
                    }
                };

                element.on('input', inputHandler);

                // Memory leak önleme
                scope.$on('$destroy', () => {
                    element.off('input', inputHandler);
                });
            }
        };
    })
    .directive('disableOnLoading', ['LoadingService', function(LoadingService) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                scope.$watch(function () {
                    return LoadingService.isLoading;
                }, function (newVal) {
                    element.prop('disabled', newVal);
                });
            }
        };
    }])
    .directive('currencyMask', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelCtrl) {

                // todo: modele decimal değer ne ise onu koyuyor onuda sabit . yapmak lazım
                //       imlecin yerini ayarlamak lazım sürekli render olma durumu olduğu için imleç hep sağa atıyor. Adam arada bi yerde de yazabilir
                const thousandSeparator = localStorage.getItem('thousandSeparator') || '.';  // Binlik ayracı (default: '.')
                const decimalSeparator = localStorage.getItem('decimalSeparator') || ',';  // Küsürat ayracı (default: ',')
                let cursorPosition = 0;

                function formatCurrency(value) {
                    if (!value) return '';

                    value = value.toString().replace(new RegExp(`[^0-9${thousandSeparator}${decimalSeparator}]`, 'g'), '');

                    let parts = value.split(decimalSeparator);
                    let integerPart = parts[0].slice(0, 9);
                    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
                    let decimalPart = parts[1] ? parts[1].slice(0, 5) : '';
                    return decimalPart || parts.length > 1 ? `${integerPart}${decimalSeparator}${decimalPart}` : integerPart;
                }

                function parseCurrency(value) {
                    if (!value) return null;
                    value = value.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');
                    return value;
                }

                ngModelCtrl.$parsers.push(function (value) {
                    let parsedValue = parseCurrency(value);
                    ngModelCtrl.$setViewValue(formatCurrency(parsedValue));
                    ngModelCtrl.$render();

                    return parsedValue;
                });

                ngModelCtrl.$formatters.push(function (value) {
                    return value;
                });

                element.on('input', function (event) {
                    let rawValue = element.val();

                    rawValue = formatCurrency(rawValue);  // Formatlı değeri oluştur

                    // Formatlama sonrası input değerini tekrar ayarlıyoruz
                    ngModelCtrl.$setViewValue(rawValue);
                    ngModelCtrl.$render();
                });

                element.on('keyup', function (event) {})
                element.on('click', function (event) {})

                element.on('blur', function () {
                    let rawValue = element.val();
                    if (rawValue) {
                        rawValue = formatCurrency(rawValue);
                        ngModelCtrl.$setViewValue(rawValue);
                        ngModelCtrl.$render();
                    }
                });
            }
        };
    })
