import "./stock-management.scss";

class PendingPreStockActionListController {
    constructor(
        API,
        ToastService,
        $translate,
        $auth,
        $http,
        DialogService,
        $stateParams,
        $q,
        $state,
        $location,
        $scope,
        $rootScope
    ) {
        "ngInject";

        this.API = API;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$auth = $auth;
        this.$stateParams = $stateParams;
        this.$location = $location;
        this.$state = $state;
        this.$location = $location;

        this.DialogService = DialogService;

        this.$rootScope = $rootScope;

        this.productId = null;
        this.locationId = null;

        this.selectedAction = [];

        this.onlyAction = false;
        this.quantity = 0;
        this.unit = null;

    }

    $onInit() {
        this.getList()
    }

    getList() {
        this.API.all("stock/analytics-not-assign-list/" + this.productId)
            .customGET(null, {
                locationId: this.locationId,
                listType: 'preStock'
            })
            .then((response) => {
                const {analyticsNotAssignList} = response.data;
                this.actionList = analyticsNotAssignList.data.filter(a => a.remainingQuantity > 0).reduce((group, a) => {

                    if (!group[a.location_id]) {
                        group[a.location_id] = [];
                    }
                    a.selected = true;
                    group[a.location_id].push(a);

                    return group;
                }, {});
            }).then(() => {
            this.calcRemaining()
        });
    }

    calcRemaining() {
        this.selectedAction = {};
        Object.values(this.actionList).forEach(location => {
            location.filter(l => l.selected).forEach(action => {

                if (!this.selectedAction[action.location_id]) {
                    this.selectedAction[action.location_id] = {
                        remainingQuantity: 0,
                        unit: action.unit,
                        location: action.location_name,
                    };
                }
                if (this.onlyAction){
                    this.selectedAction[action.location_id]['remainingQuantity'] = this.quantity
                    return true;
                }
                this.selectedAction[action.location_id]['remainingQuantity'] += action.remainingQuantity;
            })
        })
    }

    cancel() {
        this.DialogService.$mdDialog.hide()
    }

    done() {
        let data = []

        if (this.onlyAction) {
            data.push({locationId: this.locationId, quantity: this.quantity})
        }

        if (!this.onlyAction) {
            Object.values(this.actionList).forEach(location => {
                data.push({
                    locationId: location[0].location_id,
                    quantity: location.filter(l => l.selected).reduce((sum, a) => sum + a.remainingQuantity, 0),
                })
            })
        }
        data = data.filter(d => d.quantity>0)

        if (data.length ===0){
            this.ToastService.showHtml('warn', this.$translate.instant('QUICK_MOVEMENT_PRE_STOCK_INFOMSG3'))
        }

        this.API.all(`action-process/prepare/quick/${this.productId}/preStock`)
            .customPUT({locationQuantity:data})
            .then((response) => {
                this.DialogService.$mdDialog.hide(response.data.list)

            })
    }

    showOrderDetail(id) {
        let options = {
            controller: 'InvoicesDetailController',
            controllerAs: 'vm',
            locals: {
                orderId: id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getOrderDetail(controller.orderId)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/invoices-detail/invoices-detail.component.html',
            options
        )
    }

    showActionDetail(action_id) {
        this.selectedDetailActionId = action_id;
        let options = {
            controller: 'ActionDetailController',
            controllerAs: 'vm',
            locals: {
                id: action_id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetail(controller.id)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/order-lists/partials/action-detail.component.html',
            options
        )
    }

}

PendingPreStockActionListController.$inject = ["API", "ToastService", "$translate", "$auth", "$http", "DialogService", "$stateParams", "$q", "$state", "$location", "$scope", "$rootScope"];

const PendingPreStockActionListComponent = {
    templateUrl: "./views/angular/app/components/stock-management/partials/dialog/pending-preStock-action-list.component.html",
    controller: PendingPreStockActionListController,
    controllerAs: "vm",
    bindings: {
        productId: '<',
        locationId: '<?',
        quantity: '<?',
        unit: '<?',
        onlyAction: '<?'
    },
};

export {PendingPreStockActionListComponent, PendingPreStockActionListController}
