var MenuTr = {
  SETTINGS: "Ayarlar",
  HELP: "Yardım",
  QUICK_HELP: "Hızlı Yardım",
  SUPPORT: "Destek",
  DARK_MODE: "Gece Modu",
  MY_SHORTCUT: "Kısayollarım",
  CREATE_SHORTCUT: "Bu sayfanın kısayolunu oluştur",
  RIGHTBAR_INFO: "Not: Yardım videoları bir önceki Polypus sürümüne aittir. Yeni sürüm videoları yakında yayına alınacaktır.",
  SHOW_VIDEO_HELP: "Videolu Yardımı Göster",
  SCREEN_VIEW: "Ekran Görünümü",
  VIEW: "Görünümü",
  FORGOT_PASSWORD: "Şifremi Unuttum"
};

var accountSettingsTr = {
  PERSONEL_INFORMATION: "Kişisel Bilgiler",
  TURKISH: "Türkçe",
  ENGLISH: "English",
  CURRENT_PASSWORD: "Mevcut Şifreniz",
  NEW_PASSWORD: "Yeni Şifre",
  REPEAT_NEW_PASSWORD: "Yeni Şifre Tekrar",
  UPDATE: "Güncelle",
  CHANGE_PASSWORD: "Parola Değiştir",
  LOCALIZATION_SETTINGS: "Bölgesel Ayarlar",
  SCAN_PRIORITY: "Tarama Önceliği",
  PRINTER_SETTINGS: "Yazıcı Ayarları",
  PRINTER_NAME: "Yazıcı Adı",
  PRINTER_CONNECTION_PROTOCOL: "Yazıcı Bağlantı Protokolü",
  PRINTER_IP_ADDRESS: "Yazıcı IP Adresi",
  PRINTER_PORT_ADDRESS: "Yazıcı Bağlantı Portu",
  PRINT_TEST_PAGE: "Sınama sayfası yazdır",
  FIRM_LOGO: "Bu bölümden firma logonuzu güncelleyebilirsiniz.",
  CONTROLLER: "Kontrol Eden",
  QUALITY_CONTROL: "Kalite Kontrol",
  PACKER: "Paketlemeci",
  SHOW: "Göster",
  HIDE: "Gizle",
  CONFIRM_OFFER: "Teklifi Onayla",
  COMPLETE_OFFER: "Teklifi Tamamla",
  CLOSE_OFFER: "Teklifi Kapat",
  CANCEL_ORDER: "Siparişi İptal Et",
  CANCEL_OFFER: "Teklifi İptal Et",
  CONFIRM_QUICK: "Teklifi Hızlı Kapat",
  CREATE_PERMISSION_GROUP: "Yetki Grubu Oluştur",
  OPEN_REQUEST: "Açık Talepler",
  TRANSACTION_STATUS_INFO1: "Sipariş Polypus a düştüğünde",
  TRANSACTION_STATUS_INFO2: "Sipariş kullanıcıya atandığında",
  TRANSACTION_STATUS_INFO3: "Sipariş toplama tamamlandığında",
  TRANSACTION_STATUS_INFO4: "Sipariş kontrol tamamlandığında",
  TRANSACTION_STATUS_INFO5: "Sipariş paketleme tamamlandığında",
  TRANSACTION_STATUS_INFO6: "Sipariş teslimat tamamlandığında (Kargo,Elden Tesim)",
  TRANSACTION_STATUS_INFO7: "Sipariş polypus tarafından silindiğinde",
  TRANSACTION_STATUS_INFO8: "Eksik Bildirimde",
  TRANSACTION_STATUS_INFO9: "iade",
};

var cargoTr = {
  SEARCH_ORDER: "Sipariş Arama",
  UPDATE_ORDER_STATUS: "Sipariş Durumunu Güncelle",
  ORDER_COUNT: "Sipariş Sayısı",
  CARGO_INFO1: "Sipariş durumlarını seçilen kanal üzerinde güncellemek için en az sipariş seçmelisiniz",
  ORDER_NU: "Sipariş No",
  ORDER_NOTE: "Sipariş Notu",
  PACKAGE_CODE: "Paket No",
  PREPARE_IN_CARGO:'Kargodaki Siparişler',
  SUCCESSFUL: "Başarılı",
  UNSUCCESSFUL: "Başarısız",
  PRODUCT_NOT_ADDED_INFO: "Ürün eklenemedi lütfen bilgileri kontrol edin.",
  OFFER_NU: "Teklif Numarası",
  USERNAME: "Kullanıcı Adı",
  BASIC_INFORMATION: "Temel Bilgiler",
  CUSTOMER_NAME: "Müşteri Adı",
  ADD_NEW_CUSTOMER: "Yeni Cari Ekle",
  CARGO_PROCESSES: "Kargo Süreçleri",
  ACTIONS: "İşlemler",
  ACTION: "İşlem",
  ACT: "Aksiyon",
  ACT_NO: "Aksiyon No",
  REMOVE_THE_TABLE: "Masadan Kaldır",
  ERP_NUMBER: "ERP Numarası",
  ERP_LOGS: "ERP Logları",
  REFERENCE: "Referans",
  NO_ERP_LOG_MESSAGE: "ERP Log Mesajı Yoktur",
  SHIP_IT: "Kargoya Gönder",
  CREATED_DATE: "Oluşturulma Tarihi",
  AMOUNT: "Tutar",
  CREATOR: "Oluşturan",
  PRINT_CARGO_BARCODE: "Çoklu Barkodu",
  CREATE_CARGO_PDF: "Kargo PDF Oluştur",
  CREATE_PDF: "PDF Oluştur",

  SELECT: "Seçiniz",
  PRINT_CARGO_REPORT: "Kargo Tutanağı",
  SELECT_DATE_GIVE_CARGO_FIRM: "Kargoya verildiği tarih",
  CARGO_STATUS: "Kargo Durumu",
  CARGO_MESSAGE: "Kargo Mesajı",
  CARGO_INFO: "Kargo Mesajı Bulunmamaktadır",
  ORDER_ID: "Sipariş Numarası",
  SELECT_CARGO_FIRM: "Kargo firması Seçiniz",
  CARGO_FIRM: "Kargo Firması",
  CARGO_PRICE: "Kargo Ücreti",
  EARNINGS: "Kazanç TL",
  SCAN_TYPE_MANUAL: "Elle",
  SCAN_TYPE_TERMINAL: "El Terminali",
  SCAN_TYPE_CAMERA: "Kamera",
  CARGO_MESSAGES: "Kargo Mesajları",
  NO_MESSAGES: "Mesaj Yok",
  SERVICE_PRICE: "Hizmet Ücreti",
  DELIVER:'Teslim Et',
  UPDATE_NOW:'Şimdi güncellendi',
  UPDATE_SECOND_AGO:'{{second}} Saniye önce güncellendi',
  UPDATED_MINUTE_SECOND: "{{minutes}} Dakika {{seconds}} Saniye önce güncellendi",
  DAYS_AGO: "{{days}} gün önce",
  DAYS_HOURS_AGO: "{{days}} gün, {{hours}} saat önce",
  HOURS_AGO: "{{hours}} saat önce",
  HOURS_MINUTES_AGO: "{{hours}} saat, {{minutes}} dakika önce",
  MINUTES_AGO: "{{minutes}} dakika önce",
  MINUTES_SECONDS_AGO: "{{minutes}} dakika, {{seconds}} saniye önce",
  SECONDS_AGO: "{{seconds}} saniye önce",
  DELIVER_TO_SELECTED:'Seçilenleri Teslim Et',
  NO_ORDER_IN_PACKAGE_PROCESS_TITLE: "Paketlemede Sipariş Bulunmamaktadır",
  NO_ORDER_IN_PACKAGE_PROCESS_BODY: "Stok Hareketi Oluştur Bölümünden Yeni Bir Sipariş Oluşturabilirsiniz",
  QUALITY_CONTROL_INFO_TITLE: "Kalite Kontrolü",
  QUALITY_CONTROL_INFO_BODY: "Lütfen Kontrol Etmek İstediğiniz Siparişin Barkodunu Giriniz",
  INVOICES_INFO_TITLE: "Sipariş Arama Bölümü",
  INVOICES_INFO_BODY: "Sipariş NO'su İle İşleminize Devam Edebilirsiniz",
  CHANGE_SHELF_INFO_TITLE: "Raf Değiştir Bölümü",
  CHANGE_SHELF_INFO_BODY: "Hangi Depoda İşlem Yapacağınızı Seçerek Devam Edebilirsiniz",
  PRINT_UNIQUE_CARGO_BARCODE: "Tekli Kargo Barkodu",
  PRODUCT_ERP_CODE: "ERP Kodu",
  START_ADD_PRODUCT_WITH_CAMERA:'Kamera İle Ürün Ekle',
  SEARCH_PRODUCT_WITH_CAMERA:'Kamera İle Ürün Ara',
  SEARCH_PRODUCT_WITH_BARCODE:'Barkod İle Ürün Ara',
  NOT_EXIST_CONTROL_OF_STEP:'Henüz Elleçleme aşamasında iş emri yok'
};

var customersTr = {
  CUSTOMER : 'Cari',
  DEFAULT_COMPANY_CUSTOMER : 'Varsayılan Firma Carisi',
  ONLY_ACCOUNTING_INTEGRATIONS : 'Sadece muasebe entegrasyonlarında',
  CREATE_CUSTOMER: "Cari Oluştur",
  CUSTOMER_NOT_SELECTED: "Cari Seçilmemiş",
  SELECT_CUSTOMER: "Cari Seç",
  CUSTOMER_CODE: "Cari Kod",
  CUSTOMER_NAME_OR_FIRM_LABEL: "Ad Soyad / Firma Ünvanı",
  EMAIL: "E-posta Adresi",
  PHONE_NUMBER: "Telefon Numarası",
  INVOICE_ADDRESS: "Fatura Adresi",
  ADDRESS_TYPE: "Adres Tipi",
  ADDRESS_DETAILS: "Adres Detayları",
  ADDRESS_DESCRIPTION: "Adres Tanımı",
  PERSONAL: "Bireysel",
  CORPORATE: "Kurumsal",
  IDENTITY_NU: "T.C. Kimlik No",
  TAX_NU: "Vergi Numarası",
  PREPARING_STREAM: "Akış Hazırlanıyor",
  GETTING_READY: "Hazırlanıyor",
  NO_MISSING_PRODUCTS_TITLE:'Eksik Ürün Bulunamadı',
  PRODUCT_NOT_FOUND:'Ürün Bulunamadı',
  PRODUCT_NOT_FOUND_INFO:'Arama Yaptığınız Rafta Herhangi Bir Ürün Yoktur Raf İşlemleri Bölümümüzden Rafa İstediğiniz Ürünleri Tanımlayabilirsiniz.',
  NO_MISSING_PRODUCTS_BODY:'Şuanda Eksik Bir Ürün Bulunmamaktadır ,Burayı Kapatıp Ürün Eklemeye Başla Kısmından Ürün ekleyebilirsiniz',
  TAX_DEP: "Vergi Dairesi",
  TRADE_REGISTRATION_ID: "Ticari Sicil No",
  COMPANY_LABEL: "Şirket Ünvanı",
  NAME_SURNAME: "Ad Soyad",
  GSM_NU: "GSM Telefon Numarası",
  ADDRESS: "Adres",
  ACTIVATE: "Aktif Et",
  NOT_ACTIVATE: "Aktif Et",
  MAKE_ACTIVE_PASSIVE: "Aktif / Pasif Yap",
  CITY: "Şehir",
  TOWN: "İlçe",
  COUNTRY: "Ülke",
  NEIGHBOURHOOD: "Mahalle",
  ZIP_CODE: "Posta Kodu",
  SAME_ADDRESS_INFO: "Teslimat adresi fatura adresi ile aynı",
  SAVE: "Kaydet",
  SAVE_CHANGES: "Değişiklikleri Kaydet",
  UNDO_CHANGES: "Değişiklikleri Geri Al",
  SAVE_RECOMMENDED_LOCATIONS: "Önerilen Depoları Ekle",
  DELIVERY_ADDRESS: "Teslimat Adresi",
  ENTER_NUMBER_ONLY: "Yalnız Rakam Giriniz.",
  DELIVERY: "Teslimat",
  ETC: "vb.",
  CUSTOMERS: "Müşteriler",
  CUSTOMER_DETAIL: "Müşteri Detayı",
  CLEAR: "Temizle",
  CLEAR_UPPERCASE: "TEMİZLE",
  SEARCH: "Ara",
  CUSTOMER_NAME: "Cari Adı",
  CUSTOMER_EMAIL: "Cari E-Posta",
  PRINT:'Yazdır',
  ACTIONS: "İşlemler",
  CONTACT: "İletişim",
  FOOTER_MESSAGE: "Alt Bilgi Mesajı",
  SHOW_MISSING_PRODUCTS:'Eksik Ürünleri Göster',
  EDIT: "Düzenle",
  DELETE: "Sil",
  CUSTOMER_INFO1: 'Cari hesap arama, ekleme ve düzenlem için kullanılır',
  CUSTOMER_INFO2: 'Cari kod, kullanıcı adı, cari isim alanlarından arama yapar',
  CUSTOMER_EMPTY_SEARCH_MSG : ' Yapılan aramaya uygun cari bilgisi bulunamadı',
  NO_RECORD_INFO: "Görüntülenecek kayıt yoktur",
  RETURN_TRANSACTION : "İptal Oluştur",
  CANCEL_TRANSACTION : "İade Oluştur",
  RETURN_PROCESS : "İade Süreci",
  MISSING_QUANTITY_REPORT : "Eksik Adet Bildir",
  MISSING_QUANTITY : "Eksik Adet",
  SAME_QUANTITY : "Aynı Adet",
  OFFER_INFO_TITLE: "Teklif Oluşturma",
  OFFER_INFO_BODY: "Yukarıda Gireceğiniz Bilgilerle Teklifinizi Oluşturabilirsiniz",
  NO_PRODUCT_TABLE: "Masada Ürün Bulunmamaktadır",
  GALLERY: "Galeri",
  SELECTED_PHOTO: "Seçilen Fotoğraf",
  MISSING:'Eksik',
  OVERAGE:'Fazladan',
  PARTIAL_MOVEMENT:'Parçalı Giriş',
  PARTIAL_ACTION:'Onay Parçalı',
  PARTIAL_FAULTY:'Arızalı Onay Parçalı',
  PARTIAL_REJECT:'Red Parçalı Girişi',
  PARTIAL_LIST_SUMMERY_INFO : 'Onayladığınızda işlemi geri alamazsınz işaretlemeleriniz ile birlikte ',
  PARTIAL_LIST_SUMMERY_INFO2 : 'Stoğa Girecek Miktar : {{acceptCount}} ',
  PARTIAL_LIST_SUMMERY_INFO3 : 'Arızalıya Sevk Edilecek Miktar : {{faultyCount}} ',
  PARTIAL_LIST_SUMMERY_INFO4 : 'Geri Gönderilecek Miktar : {{rejectCount}} ',
  PARTIAL_LIST_SUMMERY_INFO5 : 'Henüz malkabulü yapılan ürün bulunamadı',

  SHElF_CHANGE_MSG1: 'Ürünlerin alınacağı raf boş bırakılamaz',
  SHElF_CHANGE_MSG2: 'Ürünlerin yerleştirildiği raf boş bırakılamaz',

};

var dashboardIndexTr = {
  TOTAL_ORDERS: "Toplam Siparişler",
  TOTAL_ORDER_QTY: "Toplam Sipariş Adedi",
  COLLECTION: "Toplam Sipariş Adedi",
  PREPARATION_STEP: "Hazırlama Aşaması",
  CONTROL_STEP: "Kontrol Aşaması",
  CARGO_PROCESSES: "Kargo Süreçleri",
  PROCESSES: "Süreçler",
  ORDER_ITEM_QUANTITY: "Sipariş Kalem Adeti",
  ITEM_QUANTITY: "Kalem Adet",
  GENERAL_VIEW: "Genel Görünüm",
  STOCK_TURNOVER_RATE: "Stok Devir Hızı",
  TURNOVER_RATE: "Devir Hızı",
  MOST_ACTIVE_PRODUCTS: "En Çok Hareket Gören Ürünler",
  DATA_NOT_READY_YET: "Bu güne ait veri henüz hazır değil",
  NOT_ENOUGH_DATA_FOR_REPORT: "Rapor için yeterli veriniz yok",
  THE_MOST_MOVING_PRODUCTS: "En Çok Hareket Gören Ürünler",
  REPORT_CREATE_TIME: "Rapor Oluşturma Zamanı",
  REPORT_BEING_PREPARED: "Raporunuz hazırlanıyor. Bittiğinde burada görüntülenecek",
  CLICK_GENERATE_REPORT_NOW: "Raporu hemen oluşturmak için tıklayın",
  HIGHEST: "En Yüksek",
  LOWEST: "En Düşük",
  DAILY: "Günlük",
  DAILY_REVENUE: "Günlük Toplam Ciro",
  REVENUE: "Ciro",
  MONTH: "Ay",
  WEEK: "Hafta",
  DAY: "Gün",
  YEAR: "Yıl",
  NOW_YEAR: "Bu Yıl",
  PREVIOUS_DAYS: "Önceki Günler",
  TODAY: "Bugün",
  YESTERDAY: "Dün",
  RATE_OF_CHANGE: "Değişim Oranı",
  NO_REPORT_DATA_YET: "Henüz rapor verisi yok",
  BETWEEN_DATES: "Tarihler Arası",
  LAST_DAY: "Son 1 Gün",
  LAST_WEEK: "Son 1 Hafta",
  THIS_WEEK: "Bu Hafta",
  LAST_MONTH: "Son 1 Ay",
  THIS_MONTH: "Bu Ay",
  LAST_YEAR: "Son 1 Yıl",
  THIS_YEAR: "Bu Yıl",
  ALL_TIME: "Tüm Zamanlar",
  BRANCH_REVENUE: "Şube Ciroları",
  DATE: "Tarih",
  BRANCH: "Şube",
  PAYMENT_TYPE: "Ödeme Tipi",
  EXAMPLE_PAYMENT_TYPE: "odeme_tipi",
  PREPARED_PRODUCT_COUNT: "Hazırlanan Ürün Adedi",
  PREPARED_PRODUCT_QTY: "Hazırlanan Ürün Sayısı",
  SERVICE: "Servis",
  STATUS: "Durum",
  ELASTIC_SEARCH_ENGINE: "Elastic Arama Motoru",
  ACTIVE: "Aktif",
  MANUEL: "Manuel",
  COMPLETE: "Tamamlandı",
  PASSIVE: "Pasif",
  STOCK_ENTRY: "Stok Giriş",
  STOCK_EXIT: "Stok Çıkış",
  PREVIEW: "Önizleme",
  OPEN_NEW_PAGE: "Yeni Sayfada Aç",
  EXPORT_TSOFT: "T-Soft'a Aktar",
  SELECT_CHANNEL: "Lütfen kanal seçiniz...",
  SELECT_TRANSACTION_MODE: "İşlem Modunu Seçiniz",
  RECORD_COUNT: " kayıt sayısı",
  RETAIL_SALE: "Perakende Satış",
  WHOLESALES: "Toptan Satış",
  SELECT_MONTH: "Ay Seçiniz",
  JAN: "Ocak",
  FEB: "Şubat",
  MARCH: "Mart",
  APR: "Nisan",
  MAY: "Mayıs",
  JUN: "Haziran",
  JULY: "Temmuz",
  AUGUST: "Ağustos",
  SEPTEMBER: "Eylül",
  OCTOBER: "Ekim",
  NOVEMBER: "Kasım",
  DECEMBER: "Aralık",
  TOTAL_AMOUNT: "Toplam Tutar",
  GROSS_TOTAL: "Brüt Toplam",
  GROSS_VAT: "Brüt KDV",
  GROSS_TOTAL_WITH_VAT: "Brüt Toplam Tutar",
  NET_SUBTOTAL: "Net Ara Toplam",
  SEARCH_ENGINE: "Arama Motoru",
  COUNT_PERIOD: "Sayım Periyodu",
  COUNT_PERIOD_NOT_DEFINED: "Sayım Periyodu tanımlanmamış",
  ONCE_A_DAY: "günde bir",
  STOCK_INFO: "Henüz sayılmış stok yok",
  UNPLANNED: "Planlanmamış",
};

var integrationPageTr = {
  SIPARISLER: "Siparişler",
  INTEGRATION_FILTER_1: "Görevin Çalıştığı Başlangıç Tarihi",
  SAAT_SECINIZ: "Saat Seçiniz",
  INTEGRATION_FILTER_2: "Görevin Çalıştığı Bitiş Tarihi",
  SELECT_CHANNEL: "Kanal Seçiniz",
  ORDER_UPDATED_START_DATE_ENTER: "Güncellenme Başlangıç Tarihi",
  ORDER_UPDATED_END_DATE_ENTER: "Güncellenme Bitiş Tarihi",
  SELECT_ORDER_STATUS: "Sipariş durumu seçiniz",
  SALES_CHANNEL: "Satış Kanalı",
  NAME: "İsim",
  USER: "Kullanıcı",
  OFFICIAL: "Yetkili",
  ORDER_START_DATE: "Başlangıç Tarihi",
  BEGINNING: "Başlangıç",
  FINISH: "Bitiş",
  ORDER_END_DATE: "Bitiş Tarihi",
  TRANSFERRED_ORDER_COUNT: "Aktarılan Sipariş Sayısı",
  ORDER_STATUSSES: "Sipariş Durumları",
  WORKING_TIME: "Çalıştığı Saat",
  WORKING_ELAPSED_TIME: "Çalışma Süresi",
  KILL_PROCESS: "İşlemi Sonlandır",
  STOCK: "Stok",
  B2C_TRANSFER: "B2C Transfer",
  PRODUCT_IDS: "Product Ids",
  FILTER_PARAMS: "Filtre Parametreleri",
  SELECT_DATE: "Tarih Seçiniz",
  SELECT_OPERATOR: "Operatör",
  PREV_SELECT_DATE: "seçili tarihten önceki",
  PREV: "Önceki",
  AFTER: "Sonraki",
  PREV_PRODUCT: "Önceki Ürün",
  AFTER_PRODUCT: "Sonraki Ürün",
  MINIMUM_AMOUNT: "Minimum Tutar",
  MAXIMUM_AMOUNT: "Maximum Tutar",
  NEXT_SELECT_DATE: "seçili tarihten sonraki",
  SELECT_CURRENT_DATE: "seçilen tarih",
  SELECT_DATE_FIELD_PROPERTIES: "Tarih Alanı Özelliği",
  BY_CREATED_DATE: "Oluşturulma Tarihine Göre",
  BY_UPDATE_DATE: "Güncelleme Tarihine Göre",
  BY_STOCK_UPDATE_DATE: "Stok Güncelleme Tarihine Göre",
  BY_PRICE_UPDATE_DATE: "Fiyat Güncelleme Tarihine Göre",
  STOCK_START_DATE: "Başlangıç Tarihi",
  STOCK_END_DATE: "Bitiş Tarihi",
  OPERATOR: "Operatör",
  DATE_PROPERTIES_FIELD: "Tarih Türü",
  SESSION_ID: "Oturum Kimliği",
  TRANSFERRED_PRODUCT_COUNT: "Aktarılan Ürün Sayısı",
  UPDATE_CURRENCY: "Kur Bilgilerini Güncelle",
  CURRENCY: "Para Birimi",
  CURRENCY_TO_CONVERT: "Dönüştürülecek Para Birimi",
  EXCHANGE_RATE: "Döviz İşlemleri",
  CURRENCY_RATE: "Döviz Kuru",
  CURRENCY_DECIMAL: "Para Birimi Ondalık Hane",
  INTEGRATION_INFORMATION: "Entegrasyon Bilgileri",
  PROVIDER_PRODUCT_ID: "Entegrasyon ID",
  TRANSFER_MESSAGE: "Transfer Sonucu",
  PREPARER: "Hazırlayan",
  MARKET_PLACE: "Pazar Yeri",
  LAST_STATUS: "Son Durum",
};

var batchOperationsTr = {
  IMPORT_TRANSACTION: "Stok Hareketi İçeri Aktar",
  IMPORT_PRODUCT_SHELF_RELATION: "Ürün-Raf İlişkisi İçeri Aktar",
  IMPORT_SHELF: "Raf İçeri Aktar",
  SHELF_AND_QUANTITY: "Raf Ve Adet",
  RETURN_TO_SHELF: "Rafa Geri Yerleştir",
  UPLOAD: "Yükle",
  BROWSE: "Gözat",
  UPLOADED: "Yüklenen",
  SELECTED_FILE: "Seçilen Dosya",
  PLEASE_WAIT_FOR_PROCESS: "Lütfen bekleyiniz... Dosyanız işleniyor...",
  DOWNLOAD_IMPORT_REPORT: "İçe aktarım raporlarını indirmek için dosya ismine tıklayınız.",
  DOWNLOAD_PREVIEW_REPORT: "Yüklenen dosyanın analizini görmek için lütfen dosya ismine tıklayınız.",
  IMPORT_SHELF_INFO1: "Bu bölümde excel aracılığı ile raf kodları sisteme tanımlanabilmektedir.",
  CLICK: "tıklayınız",
  IMPORT_SHELF_INFO2: "Bu bölümde excel aracılığı ile raf kodları ve ürünler arasındaki bağlantı sisteme tanımlanabilmektedir.",
  IMPORT_SHELF_INFO3: "Bu bölümde excel aracılığı ile işlem listesi oluşturulmaktadır.",
  DOWNLOAD_SAMPLE_EXCEL_FILE: "Örnek excel dosyasını indirmek için",
  UPLOAD_COMPLETED_DOWNLOAD_REPORT: "numaralı işlem oluşturulmuştur. İçe aktarım raporlarını indirmek için dosya ismine tıklayınız.",
  SAMPLE_DATA_SET: "Örnek Veri Seti",
  SAMPLE_DATA: "Örnek Veri",
  DATA_PATTERN: "Veri Deseni",
};

var invoicesTr = {
  ORDERS: "Siparişler",
  CHANNEL_ORDERS: "Kanal Siparişleri",
  STATISTICS: "İstatistikler",
  CHOOSE_USER: "Kullanıcı Seçiniz",
  SHOW_STATISTIC: "İstatistik Göster",
  TRANSFER_EXCEL_BUTTON_NAME: "Eksik Ürünlüler",
  TRANSFER_EXCEL: "Excel",
  CSV: "CSV",
  ORDER_STATUS_HISTORY: "Sipariş Durum Geçmişi",
  NOT_TRANSFERRED_ERP: "Muhasebeye Aktarılmayanlar",
  MANUAL_TRANSFER_ERP: "Muhasebeye Elle Aktar",
  BALANCE_PRODUCT_ORDERS: "Eksik Ürünlü Siparişler",

  ORDER_CURRENCY: "Sipariş Para Birimi",
  SELECTED_CURRENCY: "Seçilen Para Birimi",
  SHOW_PRODUCT_IN_CURRENCY: "Ürün Para Birimi İle Göster",

  SELECT_STATUSSES_ORDER: "Sipariş Durumları",
  SEARCH_STATUS: "Durum Ara",
  REMOVE_STATUS: "Seçimleri Kaldır",
  SELECT_ALL: "Hepsini Seç",
  TAKE_ALL: "Hepsini Al",
  WAS_SELECT_STATUS: "durum seçildi",
  ALL: "Hepsi",
  PRODUCT_DEFINED_FULL_SHELF: "Ürün Tanımlı Dolu Raf",
  PRODUCT_DEFINED_EMPTY_SHELF: "Ürün Tanımlı Boş Raf",
  PRODUCT_UNDEFINED_SHELF: "Ürün Tanımsız Raf",
  TRANSFER_TO_ERP: "ERP'ye Aktar",
  ACCOUNTING: "Muhasebe",
  ACCOUNTING_TRANSFERS: "ERP Aktarımı",
  SHIPMENT_TRANSFERS: "Kargo Aktarımları",
  TAKE_TO_ACCOUNTING: "Muhasebeye Gönder",
  ACTION: "İşlem",
  ORDER_LINE_COUNT: "Sipariş Kalem Adedi",
  TRANSFER: "Aktarım",
  ORDER_STATUS: "Sipariş Durumu",
  ORDER_DATE: "Sipariş Tarihi",
  GENERAL_TOTAL: "Genel Toplam",
  EMAIL_NATIVE: "E-posta",
  ORDER_TYPE: "Sipariş Tipi",
  BALANCE_PRODUCT_NOTIFY: "Eksik Ürün Bildirimi",
  BALANCE_PRODUCT_NOTIFY_INFO: "Listede karşılanmayan adetler için kayıt oluşturulacak ve karşılanmayan siparişler listeden çıkartılacaktır.",
  BALANCE_PRODUCT_NOTIFY_INFO2: "In the system, there are enough products on the shelves to meet the need in the list. For this reason, the notification created will also turn into an automatic counting document",

  NUMBER_OF_LIST_PRODUCT: "Liste Ürün Sayısı",
  QUANTITY_ON_SHELVES: "Raflardaki Adet",
  CREATE_STOCK_ACTION: "Stok Hareketi Yarat",
  ORDER_TRANSFERED_ERP: "Sipariş muhasebe programına aktarıldı.",
  ACCOUNTING_MANUAL_TRANSFER_APPROVAL: "Muhasebe Elle Aktarma Onayı",
  STOCKMOVEMENT_CONFIRM_INFO: "Stok hareketi muhasebeye aktarılacaktır. Onaylamak istiyor musunuz?",
  ORDER_NOT_TRANSFERED_ERP: "Sipariş muhasebe programına aktarılamadı.",
  ERP_INFO: "ERP Sisteminden Ham Verileri Gösterir",
  ERP: "ERP",
  SUPPLIER_PRODUCT_CODE: "Tedarikçi Ürün Kodu",
  CONNECTION_LOST: "Server ile bağlantı kurulamıyor",
  DOWNLOADING: "İndiriliyor",
  INVOICES_INFO:
    "Yukarıdaki form aracılığıyla aktarılan siparişlerinizi sorgulayabilir, yönetebilirsiniz. Lütfen arama kriterinizi genişletiniz.",
  AUTO : 'Otomatik',
  TRIGGERS: 'Tetikleyici',
  ASYNC : 'Paralel',
  RUNTIME : 'Çalışma Zamanı',
  RUNTIME_START : 'Başlangıç',
  RUNTIME_INSIDE : 'Devam Ederken',
  RUNTIME_END : 'Bittiğinde',
};

var locationTr = {
  LOCATIONS: "Depolar",
  CREATE_LOCATION: "Depo Oluştur",
  LOCATION_NAME: "Depo Adı",
  EXAMPLE_LOCATION_NAME: "depo_adi",
  LOCATION_TYPE: "Depo Tipi",
  SELECT_LOCATION_TYPE: "Depo Türü",
  SELECT_REMOVAL_TYPE: "Eksiltme Tipi",
  REMOVAL_TYPE: "Eksiltme Tipi",
  NOT_FOLLOWING: "Takip edilmiyor.",
  CHOOSE_USER: "Kullanıcı Seçiniz",
  SHELF_CODE: "Raf Kodu",
  SHELF: "Raf",
  PREV_QTY: "İlk Miktar",
  NEXT_QTY: "Son Miktar",
  SHELF_LOG: "Raf Hareket Logu",
  SHELF_MOVEMENT: "Raf Hareketi",
  SHELF_NAME: "Raf Adı",
  WAREHOUSE_NAME: "Depo Adı",
  EMPTY_SHELF: "Boş Raf",
  USED_SHELF: "Kullanılan Raf",
  NO_PRODUCT_IN_SHELF: "Ürün Tanımlı Olmayan Raf",
  SUB_LOCATION_STATUS: "Raf Durumu",
  SEARCH: "Ara",
  SAVE: "Kaydet",
  CANCEL: "Vazgeç",
  CANCELLATION: "İptal",
  ORDER_CANCEL: "İptal",
  SHELF_ORDER_NU: "Raf Sıra No",
  SHELVES: "Raflar",
  ALTERNATIVE_SHELVES: "Alternatif Raflar",
  NO_SUITABLE_SHELF_FOUND: "Uygun Raf Bulunamadı",
  NOT_SHELVES: "Tanımlı Raf Bulunmamaktadır",
  ORDER_INFO_MESSAGE: 'Siparişleriniz zamanlanmış görevler bölümünde zaman ayarlı olarak bulunmaktadır. Burası isteğe bağlı elle güncelleme yapabilmeniz içindir.',
  ORDER_INFO_MESSAGE2: 'Stoklarınız zamanlanmış görevler bölümünde zaman ayarlı olarak bulunmaktadır. Burası isteğe bağlı elle güncelleme yapabilmeniz içindir.',
  SCHEDULED_TASKS: 'Zamanlanmış Görevler',
  SHELF_DETAIL: "Raf Detayı",
  CREATE_SHELF: "Raf Oluştur",
  SHELF_OPERATION: "Raf İşlemleri",
  PRODUCT_INFO: "Ürün Bilgileri",
  PRODUCT_CODE: "Ürün Kodu",
  ENTER_CRITERIA_SEARCH_SHELF: "Raf arama kriteri giriniz",
  PRODUCT_VARIATION: "Ürün varyasyonu",
  ENTER_CRITERIA_SEARCH_PRODUCT: "Ürün arama kriteri giriniz",
  FILTER: "Filtrele",
  PRODUCT_SHELF_RELATION: "Ürün-Raf İlişkilendirme",
  TRANSPORTERS: "Taşıyıcılar",
  TRANSPORTER_SECTIONS: "Taşıyıcı Bölümleri",
  TRANSPORTER_NAME: "Taşıyıcı Adı",
  SECTION_NAME: "Bölüm Adı",
  SELECT_TRANSPORTER: "Taşıyıcı Seçiniz",
  VIEW_TRANSACTION: "İşlemi Görüntüle",
  WAREHOUSE: "Depo",
  WAREHOUSES: "Depolar",
  WAREHOUSE_UPDATED: "Depo güncellendi.",
  LOT_SERIAL_WAREHOUSE_NOT_SELECTED: "Deposu seçilmeyen lot/seri no",
  IN_STOCK: "Stokta Olanlar",
  CREATE_NEW_LOT: "Yeni Lot Oluştur",
  SHELF_UPDATED: "Raf kodu güncellendi.",
  SHELF_CREATED: "Raf oluşturuldu.",
  PENDING_SUBMISSION_CONFIRMATION: "Gönderim Onayı Bekleyen",
  SHIPMENT_CONFIRMED: "Gönderim Onayı Verilen",
  CONFIRM: "Onayla",
  CONFIRM_ALL: "Hepsini Onayla",
  DELIVER_ALL:'Hepsini Teslim Et',
  NOT_CONFIRM: "Onaylama",
  CONFIRM_MESSAGE: "Onay Mesajı",
  CONFIRM_MESSAGE_INFO: "Mal kabulu güncellediğinizde, diğer kullanıcıların girişlerini pasif edip bu girişi aktif edecektir onaylıyor musunuz",
  RECEIVING_RESOLVE_MESSAGE_INFO: 'Farklılığını onayladığınızda <b>{{location}}</b> için otomatik işlemeler yapılacak. Detaylar;',
  RECEIVING_RESOLVE_REJECT_MESSAGE_INFO: 'Depoya Geri Gönderilecek  {{unit}}:<b> {{reject}} {{unit}}</b>',
  RECEIVING_RESOLVE_OVERAGE_MESSAGE_INFO: 'Otamatik Stok Düşüş Adeti {{unit}}:  <b>{{overage}} {{unit}}</b>',
  RECEIVING_RESOLVE_MESSAGE_INFO_ALERT:'Onay öncesi <b>{{product}}</b> ürünü <b>{{location}}</b> deponuzda saymanızı tavsiye ediyoruz.',
  CONFIRM_MESSAGE_BODY: "Bu satırı silmek istediğinize emin misiniz?",
  WANT_TO_DELETE_SHELF: "Bu rafı silmek istediğinize emin misiniz?",
  WANT_TO_DELETE_PRODUCT: "Bu ürünü silmek istediğinize emin misiniz?",
  WANT_TO_DELETE_CHANNEL: "Bu kanalı silmek istediğinize emin misiniz?",
  PRODUCT_APPROVAL_CANCELED: "Ürün Onaylama İptal Edildi",
  PACKAGING_PROCESS_STOPPED: "Paketleme İşlemi Durduruldu",
  WANT_TO_DELETE_AUTOMATIC_TRANSFER: "Muhasebeye otomatik aktarım ayarını silmek istediğinize emin misiniz?",
  WANT_TO_DELETE_B2C_SITE: "B2C sitesine otomatik stok aktarım ayarını silmek istediğinize emin misiniz?",
  WANT_TO_DELETE_CUSTOMER: "Bu müşteriyi silmek istediğinize emin misiniz?",
  WANT_TO_DELETE_OFFER: "Teklif silinecek emin misiniz?",
  PRODUCT_NOT_FOUND_SYSTEM: "Ürün sistemde bulunamadı eklemek istermisiniz",
  AUTOMATICALLY_APPROVE: "Bu işlemi yaptığınızda onaylanmamış tüm işlemleri otomatik onaylamış olacaksınız. Emin misiniz ?",
  ARE_YOU_SURE: "Emin misiniz?",
  ONCE_YOU_CONFIRM_THIS_TRANSACTION: "Bu işlemi onayladığınızda geri alamazsınız. Emin misiniz ?",
  WANT_TO_DELETE_TRANSACTION: "Bu işlemi silmek istediğinize emin misiniz?",
  PLACE_COLLECT_ITEMS_BACK_SHELF: "Toplanan Ürünleri Rafa Geri Yerleştir",
  CANNOT_BE_UNDONE: "Bu işlem geri alınamaz. Onayladığınızda topladığınız ürünleri rafa geri yerleştirebilmek için iş emri oluşturulacaktır. Eğer paketinizin topladığınız haliyle kalmasını istiyorsanız vazgeçiniz.",
  START_RETURN_PROCESS_INFO: "Siparişin iade sürecini başlatmak üzeresiniz",

  START_RETURN_PROCESS_INFO_ORDER: "Bu işlem siparişteki bütün hareketlerin iptal/iade sürecini başlatacak. Aksiyonların durumuna göre mal kabul süreci veya rafa yerleştir aksiyonu oluşturacaktır.",
  START_RETURN_PROCESS_INFO_ORDER_PARTIAL: "Bu işlem siparişteki bütün hareketlerin iptal/iade sürecini başlatacak. Aksiyonların durumuna göre mal kabul süreci veya siparişi iade süreçleri bölümüne taşıyacak.",
  START_RETURN_PROCESS_INFO_ACTION: "Bu işlem depo hareketinin iptal/iade sürecini başlatacak. Aksiyonun durumuna göre mal kabul süreci başlayacak veya rafa yerleştir aksiyonu oluşturacaktır.",
  START_RETURN_PROCESS_INFO_ACTION_PARTIAL: "Bu işlem depo hareketinin iptal/iade sürecini başlatacak. Aksiyonun durumuna göre mal kabul süreci başlayacak veya siparişi iade süreçleri bölümüne taşıyacak.",

  SHELF_DELETED_MESSAGE: "Raf silindi.",
  SHELF_PRODUCT_MATCH_MESSAGE: "Raf ve ürün eşleştirildi.",
  SHELF_PRODUCT_RELATION_DELETE: "Raf ve ürün eşleştirilmesi silindi.",
  SELECT_ONE_CRITERIA: "En az bir kriter seçiniz.",
  SECTION_DEFINED: "Bölüm tanımlandı",
  SECTION_DELETED: "Bölüm silindi.",
  ORDER_PREPARATION_TOOL: "Sipariş Hazırlama Aracı",
  GET_PACKED: "Paketlemeye Al",
  PACKED_INFO: "Paketlenme süreci tamamlanmış herhangi bir sipariş bulunmamaktadır.",
  TRANSPORTER_DEFINED: "Sipariş hazırlama aracı tanımlandı.",
  TRANSPORTER_DELETED: "Sipariş hazırlama aracı silindi.",
  REPORT_NAME: "Rapor Adı",
  SHELF_TYPE: "Raf Tipi",
  PACKAGING: "Paketleme",
  PACKAGING_SHELF_INFO: "Lütfen dikkat! Paketleme rafı sipariş hazırlama yaparken elleçleme adımında kullanılır.",
  PACKAGING_DETAIL: "Paketleme Detayı",
  LOCATION: "Depo",
  UNDEFINED: "Tanımsız",
  BARCODE: "Barkod",
  PROCESS: "Süreç",
  PROCESSED: "İşleme alındı",
  LAST_PROCESS: "Son İşlem",
  WIDTH: "Genişlik",
  HEIGHT: "Yükseklik",
  DEPTH: "Derinlik",
  WEIGHT: "Ağırlık",
  DOWNLOAD: "İndir",
  PRODUCT_COUNT: "Ürün Adeti",
  VOLUME: "Hacim (w X h X d)",
  WAREHOUSE_LAYOUT_GUIDE: "Depo Yerleşim Kılavuzu",
  CHANNEL: "Kanal",
  CREATE_GUIDE_REQUEST: "Kılavuz Talebi Oluştur",
  WHATIS_LOCATION_GUIDE: "Depo Yerleşim Kılavuzu Nedir?",
  LOCATION_GUIDE_INFO: "Sipariş verilerinizi analiz ederek deponuzun organize etmenizde size yardımcı olacak bir araçtır.Kılavuz talebi oluşturmak için, kanal seçiniz ve klavuz talebi oluşturunuz.",
  REQUEST_DATE: "Talep Tarihi",
  RESPONSE_DATE: "Sonuçlanma Tarihi",
  TRANSPORTER_ORDER_PREP_MSG: "Kullanıcı seçimi zorunlu değildir",
  TRANSPORTER_INFO_MSG: "Taşıyıcıya toplu sipariş tanımlaması yapıldığı için bu alanı kullanamazsınız",
  PACKING_SHELF: "Sabit(Paketleme Rafı)",
  PACKING_SHELF_INFO1: "Raf ismi barkod olsun",
  PRODUCT: "Ürün",
  PRODUCT_DEFINE_METHOD: "Ürün Tanımlama Yöntemi",
  PRODUCT_DEFINE_TO_SHELVE: "Rafa Ürün Tanımla",
  BARCODE_NU: "Barkod No",
  GET: "Getir",
  PRODUCT_ID: "Ürün Id",
  VARIATION_ID: "Varyasyon Id",
  PRODUCT_NAME: "Ürün Adı",
  VARIATION: "Varyasyon",
  SERIAL_NUMBER: "Seri Numara",
  NOTHING: "Hiçbiri",
  LOCATION_MSG: "Ürün-Raf ilişkisi olsa da stok yoksa o raflar listelenmektedir",
  WAREHOUSE_ANALYSIS: "Depo Ürün Uygunluk Analizi",
  RESPONSE_ITEM_COUNT: "Karşılama Kalem Adedi",
  RESPONSE_ENOUGH: "Uygunluk",
  REQUEST_QUANTITY: "Talep Adet",
  RESPONSE_QUANTITY: "Karşılama Adet",
  PRIORITY: "Öncelik",
  RESPONSE_COUNT: "Karşılama Adedi",
  REQUIRED:'Zorunlu',
  NOT_REQUIRED:'Zorunlu Değil',
  PRODUCT_SEARCH: 'Ürün Ara',
  NOT_FOUND_RESULT: 'kelimesi ile ilgili sonuç bulunamadı',
  APPROVE: 'Onay',
  APPROVED: 'Onaylanan',
  TOTAL_COUNTED: 'Toplam Sayılan',
  THOSE_AWAITING_APPROVAL: 'Onay Bekleyenler',
  WAITING: 'Bekleyen',
  TRANSFERRED: 'Aktarılan',
  NOT_QUEUED: 'Sıraya Alınmayan',
  TRANSFERRED_MANUALLY: 'Elle Aktarılmayı Bekleyen',
  MANUALLY_TRANSFERRED: 'Elle Aktarılan',
  MISTAKE: 'Hata Oldu',
  FAULT: 'Hata',
  DETAIL_VIEW: 'Detaylı Görünüm',
  PRODUCTS_DEFINED: 'Products to be Defined',
  ORDER_WAREHOUSE: "siparis_depo",
  TRIAL_PROCESS: "deneme_islem",
  DOMAIN_NAME: "Alan Adı",
};

var loginTr = {
  COPYRIGHT_RESERVED: "Tüm hakları saklıdır.",
  ENTER: "Giriş",
  PASSWORD: "Şifre",
  EMAIL: "E-posta",
  LOGIN: "Oturum Aç",

  USER_CREATE: "Kullanıcı Oluştur",
  TOTAL_NUMBER_RECORDS: "Toplam Kayıt Sayısı",
  TOTAL_NUMBER_PAGES: "Toplam Sayfa Sayısı",
  NUMBER_RECORD_PAGES: "Sayfadaki Kayıt Sayısı",
  POWERS: "Yetkiler",
  CREATE_AUTHORIZATION_GROUP: "Yetki Grubu Oluştur",
  AUTHORITY_NAME: "Yetki Adı",
  NO_AUTHORITY: "Onaylama yetkiniz yok",

};

var batchOperationsForTsoftTr = {
  IMPORT_PRODUCTS_AND_FILTERS: "Ürünlere Filtre Ekle",
  IMPORT_PRODUCT_FILTER_FOR_TSOFT_INFO1:
    "Bu bölümden excel aracılığı ile ürün filtreleri seçtiğiniz T-Soft B2C kanalında tanımlanacaktır. ",
  IMPORT_EXCEL: "Excel Toplu İşlemler",
  STEP_FILE_IMPORT: "Dosya Yükle",
  STEP_SERVICE_SELECT: "Servisi Seç",
  STEP_PREPARE_TEMPLATE: "Veriyi Hazırla",
  STEP_UPLOAD_DATA: "Veriyi Yükle",
  STEP_SERVICE_FILE_HEADER: "Dosyada Başlık Var",
  STEP_SELECT_TEMPLATE: "Şablon Seç",
  TEMPLATE_EDITING: "Şablon Düzenleme",
  TEMPLATE: "Şablon",
  STEP_NEW_TEMPLATE: "Yeni şablon",
  COLUMN: "KOLON",
  TEMPLATE_NAME: "Şablon İsmi",
  SAMPLE_LINES_WITHOUT_LOADING: "Yüklemeden Örnek Satırlar",
  TOTAL_NUMBER_OF_ROWS: "Toplam Satır Sayısı",
  NUMBER_OF_ROWS: "Satır Sayısı",
  SAVE_TEMPLATE: "Şablonu Kaydet",
  ESTIMATED_DELIVERY_DATE: "Teslimat Tarihi",
  UPDATE_TEMPLATE: "Şablonu Güncelle",
  PROGRESS_SUMMERY: "Yükleme Özeti",
  DOWNLOAD_CENTER: "Yükleme Merkezi",
  STEP_SELECT_EXTRA_FIELD: "Ekstra Alanlar",
  CHOOSE_CARGO_FIRM: "Kargo Firması Seçiniz",
  STEP_SELECT_EXTRA_FIELD_INFO: "Ekstra alanlar yapılan seçimlere göre ihtiyaç olan diğer alanları gösterir",
  NOT_YET_SAVED: "Kaydedilmedi",
  MULTIPLE: "Çoklu",
  OTHER_FILTERS: "Diğer Filtreler",
  QUICK_PROCESS_FILTERS: "Hızlı Süreç Filtreler",
  PROCESS_FILTERS: "Süreç Filtreler",
  CUSTOMER_CONTACT : 'Müşteri Bildirimi',
  RECEIVING_ACCEPT : 'İade Kabul Durumu',
  FIRST_PRICE_CONTROL : 'Teklif Fiyat Onayı',
  FIRST_QUANTITY_CONTROL : 'Teklif Adeti Onayı',
  DOCUMENT_PRICE_CONTROL : 'Belge Fiyat Onayı',
  DOCUMENT_QUANTITY_CONTROL : 'Belge Adeti Onayı',
  LAST_PRICE_CONTROL : 'Gerçekleşen Fiyat Onayı',
  LAST_QUANTITY_CONTROL : 'Gerçekleşen Adeti Onayı',
  FREE_QUANTITY_CONTROL : 'Bedelsiz Ürün Onayı',
  CUSTOMER_CONTROL : 'Cari Onayı',
  DISCOUNT_CONTROL : 'İndirim Onayı Onayı',
  SAVE_SELECTED_SYSTEM: "Seçilenleri Sisteme Kaydet",
  REMOVE_SELECTED: "Seçilenleri Kaldır",
  BACK: "Geri",
  SEND: "Gönder",
  NEXT: "İleri",
  MY_UPLOADS: "Yüklemelerim",
  SHOW_ALL: "Hepsini Göster",
  SHOW_WAITING_ACTIONS: "Sadece İşlem Bekleyenleri Göster",
  FILE_NAME: "Dosya İsmi",
  TITLE_INFORMATION: "Başlık Bilgisi",
  TITLE: "Başlık",
  ERROR_MESSAGE: "Hata Detayı",
  ROW_MATCH: "Sistem Karşılığı",
};

var actionTr = {
  ISSUE: "İş Emri",
  ISSUE_STATUS: "İş Emri Statüsü",
  ONLY_ANOMALY_ISSUE_STATUS: "Sadece farklı statüdeki listeler",
  ISSUE_NO: "İş Emri No",
  ISSUE_CREATE: "İş Emri Oluştur",
  SHELF_EXIT_ISSUE_CREATE: "Raf Çıkış İş Emri Oluştur",
  SHELF_ENTRY_ISSUE_CREATE: "Raf Giriş İş Emri Oluştur",
  FAULTY_ISSUE_CREATE: "Arızalı İş Emri Oluştur",
  ISSUE_DETAIL: "İş Emri Detayı",
  ISSUES: "İş Emirleri",
  ORDER_PREPARE_LIST: "İş Emri",
  ORDER_PREPARE_LIST_CREATE: "İş Emri Oluştur",
  ORDER_PREPARE_LIST_NAME: "İş Emri",
  LIST_TYPE: "İş Emri Tipi",
  LIST_CONTENT: "İş Emri İçeriği",
  MARKETPLACE: "Pazaryerleri",
  ACTON_TYPE: "Sipariş Tipi",
  OFFER_LIST: "Teklifler",
  SALES_OFFER: "Satış Teklifleri",
  RECEIVING: "Mal Kabul",
  RECEIVING_TYPE: "Mal Kabul Tipi",
  RECEIVING_CONFLICT: "Adet Farkı Olan",
  RESOLVE_CONFLICT: "Adet Farklarını Onayla",
  RECEIVING_REJECT_QUANTITY: "Kabul Edilmeyen Ürün Olan",
  RECEIVING_COMPLETED: "Mal Kabul Süreci Tamamlandı",
  RECEIVING_COMPLETED_INFO: "Mal Kabul süreci tamamlandı ancak henüz servis işlemleri devam ediyor. Değiştirmek istediğiniz ürünü barkodu ile seçebilrsiniz. Mal kabul tipi <b>Serbest</b> giriş olduğu için onayı verip işlemi kapatabilirsiniz.",
  OFFER: "Teklif",
  REGULATION_STOCK_ENTRY: "Düzenlenme Stok Giriş",
  REGULATION_STOCK_EXIT: "Düzenlenme Stok Çıkış",
  SHOULD_BE_ENTERED1: "USD/EUR/TL olarak girilmelidir.",
  SHOULD_BE_ENTERED2: "-1/-2/-3 olarak girilmelidir. (-1: Havale / EFT, -2: Kredi Kartı, -3: Nakit )",
  CART: "Sepet",
  NEW_CART: "Sepet",
  PRODUCT_CURRENT_HISTORY: "Ürün Cari Geçmişi (Bu Sene)",
  TOTAL_SALES: "Toplam Satış",
  TOTAL_QUANTITY: "Toplam Adet",
  AVAILABLE_BASKETS: "Mevcut Sepetler",
  OFFER_ID: "Teklif Numarası",
  OFFER_TYPE: "Teklif Tipi",
  OFFER_STATUS: "Teklif Durumu",
  OFFER_EXPIRE_STATUS: "Teklif Zaman Aşımı",
  TIME_PERIOD: "Zaman Aralığı",
  ACTUAL_STATUS: "Gerçekleşen Durumu",
  QTY_OFFER_NOT_YET_FINAL: "Henüz kesinleşmemiş teklif adetleri",
  EXPIRED: "Süresi Dolmuş",
  DONE: "Tamamlandı",

  SALES_STATISTICS: "Satış İstatistikleri",
  PURCHASING_STATISTICS: "Satın Alma İstatistikleri",

  LAST_SALES_QTY: "Son {{months}} Ay Satış Adeti",
  LAST_ORDER_QTY: "Son {{months}} Ay Sipariş Adeti",
  EDIT_ORDER: "{{sipNo}} No' lu Siparişi Düzenle",


  COMPLETE2: "Tamamla",
  REJECT2: "Reddet",
  NOT_PURCHASED2: "Tedarik Edilemiyor",

  COMPLETE_ALL: "Tamamla (Tümü)",
  REJECT_ALL: "Reddet (Tümü)",
  NOT_PURCHASED_ALL: "Tedarik Edilemiyor (Tümü)",
  ASSOCIATED_OFFER: "İlişkili Teklif",
  PRODUCT_TOTAL_OF_ASSOCIATED_OFFER: "İlişkili Tekliflerin Ürün Toplamı",
  COUNT_OF_PURCHASE_OFFER: "Satın Alma Teklif Adedi",

  ASSOCIATED_MOVEMENTS: "İlişkili Hareketler",

  DEMAND_NUMBER: "Talep Numarası",
  DEMAND_TYPE: "Talep Tipi",
  DEMAND_STATUS: "Talep Durumu",
  DEMAND_DATE: "Talep Tarihi",
  DEMANDER: "Talep Eden",

  REJECT: "Reddedilen",
  CREATED_BY: "Oluşturan",
  CREATE_AN_OFFER: "Teklif Oluştur",
  CREATE_STOCK_MOVEMENTS: "Stok Hareketi Oluştur",
  MOVEMENTS: "Hareketler",
  STOCK_MOVEMENT: "Stok Hareketi",
  STOCK_MOVEMENTS: "Stok Hareketleri",
  STOCK_MOVEMENT_DETAIL: "Stok Hareketi Detayı",
  CUSTOMER_CODE: "Cari Kodu",
  EXAMPLE_CUSTOMER_CODE: "cari_kodu",
  WAREHOUSE_FOR_ACTION: "İşlem Yapılacak Depo",
  WAREHOUSE_FOR_ACTION2: "2.Depo",
  SOURCE: "Kaynak",
  TARGET: "Hedef",
  DUAL_SHELF: "İşlem Rafı",
  ACTION_TYPE_PROCESS: "İşlem Tipi Süreçleri",

  STOCKTAKINGS_NOT_FOUND_TITLE: "Stok Sayımı Bulunmamaktadır",
  STOCKTAKINGS_NOT_FOUND_BODY: "'Yeni' Butonuna Tıklayarak Yukarıda Açılan Formdan Yeni Bir Stok Sayımı Oluşturabilirsiniz",
  ACTION_TYPE: "İşlem Tipi",
  EXAMPLE_ACTION_TYPE: "islem_tipi",
  EXAMPLE_ACTION_TYPE_INFO: "Girilebilecek olan işlem tipleri aşağıda listelenmektedir.",
  NEW_ACTION_TYPE: "Yeni İşlem Tipi",
  CHOOSE_ACTION_TYPE: "İşlem tipi seçiniz",
  CHOOSE_REFUND_TYPE: "İade nedenini seçiniz",
  INVOICE_NU: "Fatura No",
  INVOICE_DATE: "Fatura Tarihi",
  CHOOSE_DUE_DATE: "Vade günü seçiniz",
  DUE_DATE: "Vade Günü",
  DAY: "Gün",
  CREATOR_USER: "Oluşturan Kullanıcı",
  SELECT_CREATOR_USER: "Oluşturan Kullanıcı Seçiniz",
  ORDER_PREPARE_USER: "Hazırlayan Kullanıcı",
  SELECT_ORDER_PREPARE_USER: "Hazırlayan Kullanıcı Seçiniz",
  TRANSACTION_NOTE: "İşlem Notu",
  EXAMPLE_TRANSACTION_NOTE: "islem_notu",
  SHIPPING_TYPE: "Teslimat Türü",
  CARGO_TYPE: "Kargo Türü",
  CARGO: "Kargo",
  SHIPMENT_TRACKING_CODE: "Gönderi Takip Kodu",
  SHIPMENT_LOG_MESSAGES: "Kargo Log Mesajları",
  NO_SHIPMENT_LOG_MESSAGES: "Kargo Log Mesajı Yoktur",
  SHIPPING_TYPE1: "Kargo",
  SHIPPING_TYPE2: "Elden Teslim",
  TAX_TYPE: "Vergi Türü",
  ACTION_INFO1: "İşlem listesinin kdv dahil tutarı üzerinden hesaplanmalıdır.",
  ACTION_INFO2: "Kayıt bilgilerini değiştirebilmek için ürün girişini durdurup değişiklik yapabilirsiniz.",
  CONTINUE: "Devam Et",
  SELECTED_PRODUCTS: "Seçilen Ürünler",
  SELECTED_PRODUCT: "Seçilen Ürün",
  SELECTED: "Seçilen",
  SELECTED_ISSUES: "Seçilen İş Emirleri",
  ISSUE_NAME: "İş Emri",
  BASKET: "Sepet",
  BASKET_AMOUNTS: "Sepet Tutarı",
  BASKET_DISCOUNT: "Sepet İndirimi",
  VAT_INCLUDED: "KDV Dahil",
  NET_VAT_INCLUDED: "Net KDV Dahil",
  VAT: "KDV",
  VAT_NOT_INCLUDED: "KDV Hariç",
  NET_VAT_NOT_INCLUDED: "Net KDV Hariç",
  NUMBER_OF_MISSING_PRODUCT: "Eksik Ürün Sayısı",
  BUY_PRODUCT_INFO: "{{productQuantity}} adet ürün almak istediğinize emin misiniz?",
  CLEAR_LIST: "Listeyi Temizle",
  NOTE: "Not",
  STOCK_CODE: "Ürün Kodu....",
  DISCOUNT: "İskonto",
  DISCOUNT_PERCENT: "İskonto Oranı",
  RECOMMENDED_SHELF: "Önerilen Raf",
  PRICE: "Fiyat",
  NEW_PRICE: "Yeni Fiyat",
  QUANTITY: "Adet",
  SUPPLY: "Miktar",
  QTY: "Ad.",
  ORDER_QUANTITY: "Sipariş Adeti",
  QUANTITY_DIFF: "Adet Değişimi",
  LAST_BUYING: "Son Satın Alma",
  FREE: "Bedelsiz",
  ADDITION_FREE_QUANTITY: "Ek Bedelsiz Adet",
  RETURNABLE_QUANTITY: "Geri Alınabilir Adet",
  HOW_MANY_ARE_FREE: "Kaç Tanesi Bedelsiz",
  WILL_BE_SENT_LATER: "Daha Sonra Gönderilecek",

  BBD_LOT: "SKT / LOT",
  FROM_LAST: "Sondan",
  LOT_SERIAL: "Lot/Seri Nu",
  ENTER_LOT_SERIAL: "Lot/Seri nu giriniz",
  CHOOSE_A_LOT_SERIAL: "Lot/Seri No Seçiniz",
  LOT_SERIAL_SELECTION: "Lot/Seri No Seçimi",
  LEAD_TIME_DESC: "Temin Süresi Açıklama",
  OPEN: "Açık",

  SEARCH_RESULT: "Arama Sonuçları",
  PRODUCT_QUANTITY: "Ürün Miktarı",
  NUMBER_OF_TRANSACTION: "İşlem Adeti",
  PLEASE_SEARCH_TO_SELECT_LOT_SERIAL: "Lot/seri nu seçmek için lütfen arama yapınız.",
  LOT_SERIAL_NOT_DEFINED_INFO: "Bu ürün için seçilen depoda lot/seri nu tanımlanmamıştır",
  PRODUCT_FOLLOW: "Stok İzleme",
  SUB_PRODUCT_FEATURE: "Alt Ürün Özelliği",
  STOCK_QTY: "Stok Miktarı",
  WAREHOUSES_QTY: "Depo Dağılımı",
  WITH_DISCOUNT: "İskontolu",
  DISCOUNT_TYPE: "İskonto Tipi",
  ADD: "Ekle",
  BULK_ADD: "Toplu Ekle",
  BULK_ADD_INFO: "Toplu müşteri eklemek için lütfen entegrasyon imkanlarını deneyiniz.",
  ACTION_INFO3: "Görüntülenecek kayıt yoktur",
  TRANSPORTER_OR_SECTION: "Taşıyıcı/Bölüm",
  PREPARING_USER: "Hazırlayan",
  CARGO_DATE: "Sevkiyat Zamanı",
  COMPLETE_TIME:'Tamamlanma Süresi',
  INCOMPLETE:'Tamamlanmayan',
  DOCUMENT:'Döküman',
  DOCUMENT_STATUS:'Belge Durumu',
  DEPTOR:'Borçlu',
  NO_DEPT:'Borcu Yok',
  CREDITOR:'Alacaklı',
  SELECTED_SHELF:'Seçili Raf',
  MORE_SHELVES:'daha fazla raf',
  MORE:'daha fazlası',
  LESS:'daha az',
  DAY_HAS_PASSED:'gün geçmiş',
  BBD:'SKT',
  ENTER_QUANTITY_WITH_BBD:"SKT'li Adet Gir",
  SENT:"Gönderilen",
  BBD_ENTRANCE:"SKT Girişi",
  FAULTY_INPUT: "Arızalı Giriş",
  WAREHOUSE_COUNTING_CONFIRM: "Sayım Onayı",
  WAREHOUSE_COUNTING_CONFIRM_INFO: "Girilen adet ile raftaki ürün adedi uyuşmamaktadır. Onaylamak istiyor musunuz?",

  ACTION_LINE_COUNT: "Kalem Sayısı",
  MOVEMENT_TYPE: "Hareket Tipi",
  MOVEMENT: "Hareket",
  LAST_MOVEMENT: "Son Hareket",
  LAST: "Son",
  VISITED_SHELF_QUANTITY:'Uğranan Raf Sayısı',
  VISITED_SHELF:'Uğranan Raf',
  CHANNEL_TYPE: "Kanal Adı",
  CUSTOMER_INFO: "Cari Bilgileri",
  NOT_ASSIGNED_ORDER: "Kullanıcı Atanmamışlar",
  ORDERS_IN_PACKAGE_PROCESS: "Paketleme Sürecindekiler",
  PREPARED_ORDERS: "Hazırlığı Tamamlananlar",
  NOT_PREPARED_ORDERS: "Hazırlanmayanlar",
  NOT_ASSIGNED_TRANSPORTER: "Taşıyıcıya Atanmayanlar",
  ORDERS_HAS_ONE_PRODUCTS: "Tek Ürünlüler",
  MIXED: "Karışık",
  SINGLE_PRODUCT: "Tek Ürünlü",
  SINGLE_QTY: "Tek Adetli",
  SINGLE_PROD_OR_QTY: "Tek Ürün&Adet",
  UNDER_DECLARED_ORDERS: "Eksik Bildirimliler",
  TRANSACTION_ID: "İşlem No",
  STATUS_FILTERS: "Hareket Durum Filtreleri",
  FILTER_OPTIONS: "Filtreleme Seçenekleri",
  SHIPPING_DATE: "Sevkiyat Tarihi",
  SELECT_WAREHOUSE: "Depo seçiniz",
  USER_WAREHOUSE: "Kullanıcı Depoları",
  MAIN_WAREHOUSE: "Varsayılan Depo",
  USER_PASSWORD_INFO: "Dikkat! Şifre alanını doldurursanız kullanıcının şifresini değiştirmiş olursunuz.",
  SELECT_PAYMENT_TYPE: "Ödeme tipi seçiniz",
  CARE: "DİKKAT",
  SHELF_CODE_INFO: "Bu ürün farklı bir raf koduna tanımlı, yeni bir raf koduna yerleştirmeyi onaylıyor musun?",
  FIELD: "Alan",
  SHIPMENT_CODE : 'Kargo Kodu',
  VALUE: "Değer",
  SELECT_FIELD: "Alan seçiniz",
  NOT_PREPARED_INVOICE: "Faturası Kesilmeyenler",
  ACTION_NOT_IN_VAT: "Vergisiz İşlemler",
  DELETED_ACTIONS: "Silinen İşlemler",
  ARCHIVE: "Arşiv",
  ACTION_LINES_QTY_TRANSFER_EXCEL: "Hareket Ürün Toplamını Aktar",
  ACTIONS_TRANSFER_EXCEL: "Hareketleri Aktar",
  TRANSFER_TO_PDF: "Pdf'e Aktar",
  TRANSFER_PDF_OFFER_MODE: "Teklif Olarak Pdf Hazırla",
  REPREPARE_ACTION: "Siparişi Hazırlamayı Geri Al",
  COPY_ACTION: "Stok Hareketini Kopyala",
  SHOW_ERP_INTEGRATION: "ERP Entegrasyonunu Görüntüle",
  EDIT_ACTION_INFO1: "Stok hareketi oluşturduktan sonra henüz 24 saati geçmediği için düzenleme yapabilirsiniz",
  EDIT_ACTION_INFO3: "Hazırlayan kullanıcıyı liste görünümünden değiştirebilrisiniz",
  EDIT_ACTION_INFO4: "Ürün kalem bazında yapılan değişikliklerin bu işlemde ve siparişler bölümünde aktif olması için lütfen \"Güncelle\" butonuna\n tıklayınız.",
  PRINT: "Yazdır",
  PRODUCTS: "Ürünler",
  INTERNATIONAL_PRICE: "Döviz Fiyatı",
  LOCAL_PRICE: "TL Fiyat",
  CASH_AMOUNT: "Nakit Tutarı",
  CREDIT_CARD_AMOUNT: "Kredi Kartı Tutarı",
  TRANSFER_AMOUNT: "Havale Tutarı",

  ORDER_LIST_INFO1: "Stok hareketi oluşturduktan sonra 24 saati geçtiği için sadece teklif türünü değiştirebilirisiniz.",
  ORDER_LIST_INFO2: "Stok hareketi oluşturduktan sonra 24 saat geçtikten sonra düzenleme yapamazsınız.",
  ENTER_TRANSACTION_NOTE: "Ve İşlem notu girebilirsiniz.",

  TOTAL_STOCK_QTY: "Toplam Stok A.",
  TOTAL: "Toplam",
  SUB_TOTAL: "Ara Toplam",
  SHOWING: "Gösterilen",
  GO_ACTION: "İşleme Git",
  PAGE: "Sayfa",
  PREPARE_LIST_INFO1: "Seçilen siparişler için yeni liste oluştur",
  PREPARE_LIST_INFO2:"Hazırlanmayı Bekleyen Liste",
  PREPARE_LIST_INFO3:"Şuan hazırlanmayı bekleyen bir iş emri yok",

  PREPARE_LIST_INFO4:"Benim Listelerim",
  PREPARE_LIST_INFO5:"Henüz aktif liste yok",

  SHIPMENT_LIST_INFO1:"Kargo Süreçleri",
  SHIPMENT_LIST_INFO2:"Siparişlerinizin kargo entegrasyonu oluşumu ve işlem esnasında olası hatalrın işlemler takip edilir",

  SHIPMENT_LIST_INFO3:"Gönderim Sayım Listesi",
  SHIPMENT_LIST_INFO4:"Kargosu gönderilecek siparişlerin gönderim için son sayımı listesidir.",

  SHIPMENT_LIST_INFO5:"Kargoyu Teslim Et",
  SHIPMENT_LIST_INFO6:"Sayımı tamamlanan sipirişlerin kargo firmasına teslim listesi",


  CART_INFO1:"SEPET",
  CART_INFO2:"Sepette henüz ürün yok",

  REMOVE_PRODUCT_CART:"Ürünü Sepetten Çıkar",
  COMPLETE_CART:"Sepeti Tamamla",


  MISSING_ITEM_INFO1:"Eksik Bildirilen Ürünler",
  MISSING_ITEM_INFO2:"Henüz Eksik Bildirim Bulunmamaktadır",

  ADDED_PRODUCT: "Siparişe sonradan eklenen ürün",
  MODIFIED_QUANTITY_PRODUCT: "Miktarı değişen ürün",
  REMOVED_PRODUCT: "Siparişten çıkarılan ürün",

  ACCOUNTING_LIST_INFO1:"Muahasebe Aktarımları",
  ACCOUNTING_LIST_INFO2:"Siparişlerinizin fatura oluşumu ve işlrm esnasında olası hatalrın işlemler takip edilir",
  ACCOUNTING_LIST_INFO3:"Sipariş Henüz Fatura aşamasına gelmedi",

  SHIPMENT_STATUS:"Kargo Durumu",
  SHIPMENT_INFO1:"Teslim Edilecek Siparişler",
  SHIPMENT_INFO2:"Henüz teslim edilmeyi bekleyen sipariş bulunmuyor",

  ORDER_EDITED:"Düzenlenen Sipariş",

  ISSUE_LIST_INFO1: 'İş Emirleri Listesi',
  ISSUE_LIST_INFO2: 'Seçilen kriterlerde iş emri bulunamadı. Filtre alanlarını değiştirerek arama yapabilirsiniz',

  ISSUE_CREATE_ACTION_LIST_INFO1: 'İş Emirlerine Eklenecek Siparişler',
  ISSUE_CREATE_ACTION_LIST_INFO2: 'İş emrine eklenecek siparişleri listesi. Kayıt alanlarını değiştirerek arama yapabilirsiniz',

  TRY_AGAIN : 'Tekrar Dene',


  CREATE_LIST: "İş Emri Oluştur",
  UPDATE_LIST: "İş Emri Güncellle",
  SINGULAR: "Tekil",
  ADD_ORDER: "Sipariş Ekle",
  CHANGE_USER: "Kullanıcı Değiştir",
  GO_PRODUCT: "Ürüne Git",
  PENDING_ISSUES: 'Bekleyen İş Emirleri',
  OWN_ISSUES: 'Benim İş Emirlerim',
  STOCK_MOVEMENT_FOR_NOT_CREATED_ISSUE: 'İş Emri Oluşturulmamış Ya Da Tamamlanmamış Stok Hareketleri',

  SEARCH_RESULT_MATCH: 'Arama Sonucu "Tam Eşleşme" ile bulundu',
  SEARCH_RESULT_SIMILAR:  'Arama Sonucu "Benzer Eşleşmeler" ile bulundu',

  SELECT_IMAGE_UPLOAD_FORMAT: "Resim Yükleme Biçimi Seçiniz",

  STOCK_MOVEMENT_INFO1: "Bu Stok Hareketi Henüz İşleme Alınmamıştır",
  STOCK_MOVEMENT_INFO2: "Bu Stok Hareketinin İşlemleri Henüz Bitmemiştir",

  TRIGGERING_STAGE_YET: "Henüz tetikleme aşamasına gelmemiş",
  PROCESS_RUN_PARALLEL: "Süreç paralel çalışır",
  PLACE: "Sırada",
  ACCOUNTING_MUST_APPROVE: "Muhasebe onaylamalıdır.",
};

var notificationTr = {
  NOTIFICATIONS: "Bildirimler",
  NOTIFICATION_INFO1: "Bildirim uyarısının hangi seviyelerde görünür olacağını ayarlayabilirsiniz.",
  NOTIFICATION_INFO2: "Son 24 saat dışındaki bildirimler otomatik olarak silinir.",
  NO_NEW_NOTIFICATIONS: "Henüz bildiriminiz yok.",
  NOTIFICATION_SETTINGS: "Bildirim Ayarları",
  GENERAL: "Genel",
  IN_COMPANY: "Şirket İçi",
  PERSONALITY: "Kişisel",
  CLEAR_NOTIFICATIONS: "Bildirimleri Temizle",
  MESSAGE: "Mesaj",
  WHO: "Kime",
  NOTIFICATION_DOWNLOAD_INFO1: "İndirme talebinde bulunduğunuz servislerin indirme bağlantıları.",
  NOTIFICATION_DOWNLOAD_INFO2: "",
  DOWNLOAD_NOTIFICATION: "İndirme Bağlantıları",
};

var ordersPrepareTr = {
  ORDER_PREPARING: "Sipariş Hazırlama",
  ORDER_TRANSPORTER_RELATION: "Sipariş-Taşıyıcı İlişkisi",
  TRANSPORTER: "Taşıyıcı",
  SECTION: "Bölüm",
  EMPTY_SECTION: "Boş Bölüm",
  ORDERS_FOR_WAITING_PRODUCTS: "Ürün Bekleyen Siparişler",
  ORDERS_SECTION_INFOMSG1: "Herhangi bir bölümle sipariş eşleştirilmemiştir",
  ORDERS_SECTION_INFOMSG2: "aracı ile herhangi bir sipariş eşleştirilmemiştir.",
  ORDERS_SECTION_INFOMSG5: "Hazırlığı tamamlanmış herhangi bir sipariş bulunmamaktadır",
  ORDERS_SECTION_INFOMSG6: "Herhangi bir sipariş için hazırlanması gereken ürün bulunmamaktadır",
  ORDERS_SECTION_INFOMSG7: "Lütfen sipariş kutusuna yerleştireceğiniz ürünün stok kodunu okutunuz",
  ORDERS_SECTION_INFOMSG4: "Paketlemedeki bütün raflar kullanılmaktadır <a href=\"/#!/location-management?tab=5\"><b>Linkten</b></a> yeni raf tanımlama yapabilirsiniz.",
  ORDERS_SECTION_INFOMSG3: "Paketleme rafı tanımlanmamıştır. <a href=\"/#!/location-management?tab=5\"><b>Linkten</b></a> tanımlama\n apabilirsiniz.",
  ORDERS_PREPARING_FOR_TRANSPORTER: "Taşıyıcıya Göre Sipariş Hazırlama",
  ORDERS_PREPARING_FOR_LIST: "Listeye Göre Sipariş Hazırlama",
  ORDERS_RECEIVING: "Mal Kabul",
  ORDERS_PICKING: "Sipariş Toplama",
  HANDLING: "Elleçleme",
  CONTROL: "Kontrol",
  DONE_PROCESS : 'Teslimat',
  SHIPPING_ITEMS : 'Gönderim Onayı',
  PREPARED_ORDERS: "Hazırlananlar",
  ORDERS_IN_PACKAGING_PROCESS: "Paketlemedeki Siparişler",
  PACKAGING: "Paketleme",
  PACKAGING_INFO: "Paketleme aşamasındaki listeleriniz gözükür. Şu anda paketleme aşamasında listeniz bulunmamaktadır.",
  WILL_PREPARE_PRODUCT_FOR_ORDERS: "Siparişler İçin Hazırlanacak Ürünler",
  CLOSE_CONTINUE_READ: "Kapat ve Okumaya Devam Et",
  CONTINUE_READ: "Okumaya Devam Et",
  RETURN_SHELF: "Rafa Geri Dön",
  RETURN: "İade",
  RETURN_RECEIVING: "İade Mal Kabul",
  PARTIAL_RETURN: "Parçalı İade",
  SAME_SHELF: "Aynı Raf",
  NEXT_SHELF: "Sonraki Raf",
  DEFINED_SHELF: "Rafta Tanımlı Ürünler",
  MOST_LESS: "En Az",
  MOST_PLUS: "En Fazla",
  PLUS_PREPARING: "TOPLA",
  SELECT_PREPARING_METHOD: "Toplama Yöntemini Seçiniz",
  CAMERA: "Kamera",
  HAND_TERMINAL: "El Terminali",
  HAND: "Elle",
  SELECT_CAMERA: "Kamera Seçiniz",
  START_NEXT_ISSUE: "Sıradaki İş Emrine Başla",
  ENTER_ISSUES: "İş Emrine Girenler",
  NOT_ENTER_ISSUES: "İş Emrine Girmeyenler",
  NO_RESULT: "Sonuç Yok",
  START: "Başla",
  LIST: "Liste",
  LIST_NO: "Liste No",
  LIST_STATUS: "Liste Durumu",
  RECEIVING_ISSUES: "Mal Kabul İş Emirleri",
  SHELF_UP: "Rafa Yerleştir",
  SHELF_ENTRY: "Raf Giriş",
  SHELF_UNDEFINED: "Raf Tanımsız!",
  PLACE_PRODUCTS_WITHOUT_BARCODE: "Barkodsuz Yerleştir",
  GOOD: "Sağlam",
  FAULTY: "Arızalı",
  FAULTY_PRODUCT: "Arızalı Ürün",
  REJECTED_QUANTITY: "Kabul Edilmeyen Adet",
  PICKING_PREVIOUS_ISSUES: "Sipariş Hazırlama İş Emirleri",
  PREPARING: "Hazırlayan",
  TOTAL_PRODUCT_COUNT: "Toplam Ürün Sayısı",
  TOTAL_PRODUCT_QUANTITY: "Toplam Ürün Adedi",
  TOTAL_PRODUCT_SUPPLY: "Toplam Ürün Miktarı",
  TOTAL_PRODUCT_SORT:'Toplam Ürün Çeşidi',
  PRODUCT_ORDER_RELATION: "Ürün / Sipariş Bilgisi",
  LOT_DETAIL_QTY: "Lot Detayı / Miktar",
  LOT_DETAIL: "Lot Detayı",
  LOT_LIST: "Lot Listesi",
  READY: "Hazır",
  REVERT: "Geri Al",
  UNCONFIRM: "Onayı Geri Al",
  TRANSPORTER_SECTION: "Taşıyıcı Bölümü",
  STOP: "Durdur",
  STOP_CAMERA: "Kamerayı Durdur",
  STOPS_THE_CAMERA: "Kamerayı Durdurur",
  PACKAGING_AREA_DETAIL: "Paketleme Alanı Detayı",
  DETAIL: "Detay",
  TRANSPORTER_DETAIL: "Taşıyıcı İçeriği",
  PRODUCT_LIST: "Ürün Listesi",
  PRODUCT_APPROVAL: "Ürün Onayı",
  SHIPPING_READY: "Sevkiyata Hazır",
  SHOW_ACTION_DETAIL: "İşlem Detayı Görüntüle",
  SELECT_ORDER: "Sipariş seçiniz",
  PRODUCT_BARCODE: "Ürün Barkodu",
  ACCEPT: "Onayla",
  ACCEPT_ALL: "Hepsini Onayla",
  SHIPPING_INFO: "Sipariş no, aksiyon no, paket kodu ve kargo kodu kullanabilirsiniz.Barkodu yapıştırdığınız ya da terminal ile okuttuğunuzda siparişin kargo gönderim süreci onaylanmış olacaktır.",
  STOCKTAKING_PRODUCT_INFO: "Ürün seçilen sayımda daha önce okutulmuş. Yenileme tipini seçiniz",
  REFRESH_COUNT: "Sayımı Yenile",
  ADD_TO_COUNT: "Sayıma Ekle",
  GROUP_NAME: "Grup Adı",
  PAST_SKT_INFO1: "Bu rafta SKT si ",
  PAST_SKT_INFO2: " gün geçmiş ürün bulunmakta.",
  EXPIRE_DATE_WARNING: "Bu rafta SKT si {{days}} gün geçmiş ürün bulunmakta.",
  SYSTEM_WAITING_TIME: "Sistem Bekleme Süresi",
  ACCEPTED : 'Onaylanmış',
  REJECTED : 'Reddedilmiş',
  PURCHASE_REQUEST_FAILED : 'Tedarik Edilemeyen',
  CUSTOMER_CONTACTED: 'Müşteriyle Görüşüldü',
  CUSTOMER_NOT_CONTACTED: 'Müşteriyle Görüşülmedi',
  OUTSIDE_RETURN_ACCEPT_STATUS:'İade Kabul Durumu',
  OUTSIDE_RETURN_ACCEPT_STATUS_ACCEPT:'İade Kabul Et',
  OUTSIDE_RETURN_ACCEPT_STATUS_REJECT:'İadeyi Reddet',
  OUTSIDE_RETURN_ACCEPT_STATUS_ONLY_REJECT:'Sadece Hasarlıları Reddet',

  MAIN_PRODUCT_CODE: "Ana Ürün Kodu",
  CONNECTED_MAIN_PRODUCT: "Bağlı Ana Ürün",
  LOT_SERIAL_ENTERED: "Seçilen ürünlerin lot/seri adetleri toplam {{must_qty}} olarak girilmelidir.",
  PRODUCT_SHOULD_BE_PLACED_SECTION: "Ürün {{section_name}} bölümüne yerleştirilmelidir.",
  PRODUCT_SHOULD_BE_PIECES: "Ürün {{qty}} adet olmalıdır.",
  PRODUCT_PLACED_IN_SECTION: "{{product_code}} ürünü bölüme yerleştirildi.",
  ORDER_TRANSFERRED: "{{order_count}} adet sipariş aktarıldı.",
  ENTER_QTY_OR_SCAN_BARCODE: "Adet Giriniz Veya Barkod Okutunuz",

};

var reportsTr = {
  REPORTS: "Raporlar",
  REPORTS_INFOMSG1: "Yetkiniz dahilinde, gösterilebilecek hiçbir rapor bulunamadı",
  REPORTS_INFOMSG2: "Tarihinde Oluşturulan Raporu",
  FILE_TYPE: "Dosya Tipi",
  CREATE_REPORT: "Raporu Oluştur",
  UPDATE_STATUS: "Durumu Güncelle",
  REQUEST_PENDING: "Talep bekliyor",
  REQUEST_QUEUED: "Talep sıraya alındı",
  REPORT_PRODUCED: "Talep edilen rapor üretiliyor",

};

var paginationTr = {
  TOTAL_RECORD_COUNT: "Toplam kayıt",
  TOTAL_PAGE_COUNT: "Toplam sayfa",
  PAGINATION_RECORD_COUNT: "Sayfalama",
  PERIOD: "Periyod",
  CALCULATING: "Hesaplanıyor",
  UPDATED_DATE: "Güncellenme Tarihi"
};

var commonTr = {
  HELP_PAGE: "Sayfa Yardımı",
  HELP_WITH_VIDEO: "Videolu Yardım",
  INSTALL_WIZARD: "Kurulum Sihirbazı",
  SYSTEM_SETTINGS: "Sistem Ayarları",
  MOVEMENT_SYSTEM: "Sistem İçi Hareket",
  CHANNEL_SETTINGS: "Kanal Ayarları",
  PROVIDER: "Sağlayıcı",
  CHANNEL_NAME: "Kanal Adı",
  CHANNEL_NAME_LABEL: "Sağlayıcıdaki Pazaryeri Adınız",
  DEFAULT_CURRENCY: "Varsayılan Kur",
  EXAMPLE_RATE: "kur",
  TAX_STATUS: "Vergi Durumu",
  SET_AS_DEFAULT: "Varsayılan olarak ayarla",
  SPECIAL_ELECTION: "Özel Seçim",
  HAS: "Var",
  NOT_HAS: "Yok",
  PROTOCOL: "Protokol",
  WEB_SERVICE_URL: "Web Servis URL (Örneğin; www.xyz.com)",
  API_URL: "API URL (Örneğin; https://www.xyz.com)",
  API_KEY: "API Key",
  API_SECRET: "API Secret",
  WEB_SERVICE_USERNAME: "Web Servis Kullanıcı Adı",
  WEB_SERVICE_PASSWORD: "Web Servis Kullanıcı Şifre",
  SETTINGS_INFOMSG1: "T-Soft b2c sitelerinizin web servis bilgilerini tanımlayabilirsiniz.<br>\n" +
    "                                Ayar Güncelle butonuyla \"sipariş durumları\" ve \"ödeme tipleri\" güncellemesi\n" +
    "                                yapabilirsiniz.",
  SETTINGS_INFOMSG2: "Seçili kanal ayarınızın Sipariş Durumları yüklenmemiştir. Lütfen tıklayınız.",
  SETTINGS_INFOMSG3: "Entegrasyonlar bölümde kanal seçiminden\n" +
    "                                        sonra\n" +
    "                                        otomatik olarak hangi sipariş durumlarının seçileceğini\n" +
    "                                        ayarlayabilirsiniz. <br/>Ayrıca sisteme otomatik olarak\n" +
    "                                        çekilmesini istediğiniz sipariş durumlarını varsayılan\n" +
    "                                        olarak ayarlamalısınız.",
  SETTINGS_INFOMSG4: "Ödeme tiplerini görüntülemek için lütfen üst bölümden kanal seçimini yapınız.",
  SETTINGS_INFOMSG5: "Bu bölümde B2C sitelerinizden gelen siparişlerin durumuna göre\n" +
    "                                    hangi depo üzerinden eksiltileceğini belirleyebilirsiniz.",
  SETTINGS_INFOMSG6: "Toplam Stok Miktarı Yansıtılsın.",
  SETTINGS_INFOMSG7: "Evet işaretlenmişse \"Toplam Stok Miktarı\" seçilen\n" +
    "                                            kanala gönderilecektir.<br/>\n" +
    "                                            Seçilen depo ise; Aşağıda bulunan bölümde, siparişin durumuna\n" +
    "                                            göre seçilmemiş depo<br/>\n" +
    "                                            ve hareket tipi için manuel aktarımda devreye girecek olan\n" +
    "                                            fallback depo tanımıdır.",
  SETTINGS_INFOMSG8: "B2C sitelerinize aktarılacak stok miktarlarının depo eşleştirmesini\n" +
    "                                    tanımlayabilirsiniz.\n" +
    "                                    Dilerseniz toplam stok adetini sitenize yansıtabilir.",
  SETTINGS_INFOMSG9: "Muhasebe programına aktarılacak siparişlerin kriterlerini tanımlayabilirsiniz.",
  SETTINGS_INFOMSG10: "Otomatik olarak çalışan zamanlanmış görevleri manuel olarak çalıştırabilirsiniz.",
  SETTINGS_INFOMSG11: "Kritik stok adet altındaki ürünlerin bildirimlerini\n" +
    "                                            alacağınız e-posta adreslerinizi tanımlayabilirsiniz. ( Birden\n" +
    "                                            fazla posta adresini <b>\",\"</b> ayıracı kullanarak\n" +
    "                                            tanımlayabilirsiniz. )",
  SETTINGS_INFOMSG12: "Yukarıdaki formu kullanarak siparişi hazırlayacak kullanıcıların grubunu\n" +
    "                                            değiştirebilirsiniz.",
  SETTINGS_INFOMSG13: "Yukarıdaki formu kullanarak hazırlanan siparişleri kontrol edecek kullanıcıların grubunu değiştirebilirsiniz.",
  SETTINGS_INFOMSG14: "Yukarıdaki formu kullanarak hazırlanan siparişleri kontrol edecek kullanıcıların grubunu değiştirebilirsiniz.",
  SETTINGS_INFOMSG15: "Yukarıdaki formu kullanarak sipariş hazırlama süreçlerinde durum değişikliklerini düzenleyebilirsiniz.",
  SETTINGS_INFOMSG16: "kanalında yapacağınız işlemler Polypus üzerinden oluşturulan bütün stok hareketlerinde geçerli olacaktır.",
  SETTINGS_INFOMSG17: "Stok kartında hangi alan sizin için ayırtedici ise onu seçebilirsiniz.",
  UPDATE_PARAMETER: "Parametre Güncelle",
  PROVIDER_ID: "Sağlayıcı ID",
  DEFAULT: "Varsayılan",
  PAYMENT_TYPES: "Ödeme Tipleri",
  FINAL_PAYMENT_ALERT: "Son Ödeme Uyarısı",

  UNPAID_INFO: "Ödenmemiş bir adet faturanız bulunmaktadır. <br> Almış olduğunuz hizmet ödeme yapmamanız halinde 12 saat sonra sonlandırılacaktır.<br> Açma/kapama ücreti bir sonraki faturanıza yansıtılacaktır",

  SELECT_CHANNEL: "Kanal Seçiniz",
  ADDED_DATE: "Eklenme Tarihi",
  VALIDITY_DATE: "Geçerlilik Tarihi",
  LAST_UPDATE: "Son Güncelleme",
  NO_SHELF_CODE: "Raf Kodu Yok",

  PAYMENT_AWAITING: "Ödeme Bekleniyor",
  PAYMENT_DOOR_APPROVED: "Kapıda Ödeme Onaylandı",
  PRODUCT_BEING_PREPARED: "Ürün Hazırlanıyor",
  POS_APPROVAL: "Pos Onayında",
  ALL_PAYMENT_TYPES: "Tüm ödeme tipleri",
  RESERVE_WAREHOUSE: "Rezerv Depo",
  RETAIL_SALES: "Parekende Satış",
  IF_SELECTED_INFO: "Seçim yapılırsa kanalınıza toplam stok alanı yansıtılacaktır",

  AUTOMATIC_TRANSFER_SETTINGS: "Otomatik Aktarım Ayarları",
  DEFINE_DYNAMIC_STOCK_ACTION: "Dinamik Stok Hareketi Tanımlama",
  POSITIVES: "Artılar",
  NEGATIVES: "Eksiler",
  SELECT_ALL: "Tümünü Seç",
  REMOVE_ALL: "Tümünü Kaldır",
  BRING_ALL: "Tümünü Getir",
  QUICK_MOVEMENT_PRE_STOCK: "Bekleyenleri Hızlı İçeri Al",
  SUGGEST_DYNAMIC_ACTIONS: "Önerilen Dinamik Hareketler",
  APPLIED_TRANSACTION_TYPE: "Uygulanacak İşlem Tipi",
  WAREHOUSE_CHANNEL_RELATION: "Kanal Bazlı Varsayılan Depo",
  IS_SEND_TOTAL_STOCK: "Toplam Stok Gönderilsin mi ?",
  YES: "Evet",
  NO: "Hayır",
  ERP_INTEGRATION_SETTINGS: "ERP Aktarım Ayarları",
  MISSION_NAME: "Görev Adı",
  COMMAND: "Komut",
  EXPRESSION: "Expression",
  COMMAND_ARGUMENTS: "Komut Argümanları",
  CRON_JOB: "Zamanlanmış Görevler",
  RUN: "Çalıştır",
  COMPANY_SETTINGS: "Firma Ayarları",
  NOTIFY_EMAIL_SETTING: "Bildirim E-posta Ayarı",
  EMAIL_ADDRESS: "E-posta Adresi",
  COMPANY_UPLOAD_LOGO: "Firma Logo Yükleme",
  ADD_BANKS: "Banka Ekle",
  BANKS: "Banka",
  IBAN: "IBAN",
  BRANCH_BANKS: "Firma Banka Bilgileri",
  NEW_BRANCH_TITLE: "Firma Ekle",
  BRANCH_NAME: "Firma Cari Ünvanı",
  BRANCH_NAME_2: "Ünvan",
  DEFAULT_BRANCH: "Varsayılan firma olarak ayarla",
  PACKAGE_DETAIL: "Paket Detayı",
  PRODUCT_IMAGE: "Ürün Görseli",
  BRANCH_ADDRESS: "Firma Adresi",
  BRANCH_LOGO: "Logo",
  BRANCH_SIGNATURE: "İmza / Kaşe",
  BRANCH_WATERMARK: "Filigran",
  SELECTED_BRANCH: "Firma Seçin",
  BRANCH_INFO_TITLE: "Firma Bilgileri",
  BRANCH_INFO_TITLE_INFO: "Şirketinize bağlı firmaları buradan kayıt edebilirsiniz. Proforma gibi dökümanları özelleştirmek için kullanılır.",
  BRANCH_FORM_DETAIL: "Form Özellikleri",
  BRANCH_FORM_DETAIL_INFO: "Proforma ve sipariş formu gibi formlarla ilgili özellikliker belirlenir",
  ORDER_PREPARE_ROLE: "Sipariş Hazırlayan Yetki Grubu",
  CURRENT_SELECTED_ROLE: "Mevcut Seçili Grup",
  SELECT_USER_ROLE: "Kullanıcı grubu seçiniz",
  CONTROLLER_ROLE: "Sipariş Kontrolcü Yetki Grubu",
  TRANSPORTER_ORGANIZATION: "Taşıyıcı Organizasyonu",
  PREPARATION_TYPE: "Hazırlama Tipi",
  BULK_PREPARATION: "Siparişleri Toplu Hazırlama",
  DIVIDED_PREPARATION: "Siparişleri Bölümlere Ayırarak Toplama",
  MOST_ATTACH_ORDER_TO_TRANSPORTER: "Taşıyıcıya Atanacak En Fazla Sipariş",
  DEFAULT_VALUE: "Varsayılan Değer",
  FREE_TRANSPORTER: "Serbest Taşıyıcı",
  RELATION_WITH_USER: "Kullanıcıyla İlişkilendir",
  USER_RELATION_HEAD: "Kullanıcı İlişkilendirme",
  RELATION: "İlişki",
  ORDER_SYNC: "Sipariş Senkronizasyonu",
  ACTION_STATUS: "İşlem Durumu",
  DASHBOARD: "Genel Görünüm",
  ORDER_PREPARE: "Sipariş Hazırlama",
  STOCK_MANAGEMENT: "Stok Yönetimi",
  SHELF_CHANGE: "Raf Değiştir",
  SHELF_CHANGE_NAME: "Raf Değiştirme Adı",
  CHANGE_SHELF_CODE: "Gidecek Raf Kodu",
  TAKING_SHELF_CODE: "Alınacak Raf Kodu",
  // SHELF_CHANGE_ALL: "Tamamı",
  SHELF_CHANGE_ALL: "Tümü",
  SHELF_CHANGE_ACCEPT: "Rafı Değiştir",
  LOCATION_STOCKTAKING: "Depo Sayım",
  STOCKTAKING: "Stok Sayım",
  COUNTING: "Sayım",
  STOCKTAKING_ITEM_ACCEPT: "Onayla",
  STOCKTAKING_MAKE: "Sayım Yap",
  STOCKTAKING_CREATE: "Stok Sayımı Oluştur",
  STOCKTAKING_LIST_NAME: "Stok Sayım Adı",
  STOCKTAKING_LIST: "Stok Sayım Listesi",
  STOCKTAKING_LISTS: "Stok Sayım Listeleri",
  STOCKTAKING_DESCRIPTION: "Detay",
  BASKET_SUMMARY: "Hesap Özeti",
  STOCKTAKING_COMPLETE: "Sayımı Tamamla",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_1: "Potansiyel Hareketler",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_1_1: "Raf",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_2: "Ürünün Bulunduğu Raflar",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_3: "Giriş",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_4: "Çıkış",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_5: "Raf Adı",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_6: "Adet",
  STOCKTAKING_COMPLETED: "Tamamlandı",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_2_1: "Ürünün Bulunduğu Diğer Raflar",
  PRODUCTION: "Üretim",
  BALANCE: "Bakiye",
  PRODUCTION_STREAMS: "Üretim Akışı",
  PRODUCTION_MOVEMENT: "Üretim Girişi",
  PRODUCTION_MOVEMENTS: "Üretim Hareketleri",
  CARGO_OPERATION: "Kargo ve Taşıma İşlemleri",
  CARGO_BARCODE: "Kargo Barkodu",
  BULK_ACTIONS: "Toplu İşlemler",
  RETURN_BULK_ACTIONS: "İade Toplu İşlemler",
  INTEGRATIONS: "Entegrasyonlar",
  LOCATION_MANAGEMENT: "Depo ve Raf Yönetimi",
  RETURN_MANAGEMENT: "İade Yönetimi",
  SHELF_MANAGEMENT: "Raf Yönetimi",
  CATEGORY_BRAND_MANAGEMENT: "Kategori ve Marka Yönetimi",
  STOCK_ORGANIZATION: "Stok Organizasyonu",
  BRAND_MANAGEMENT: "Marka Yönetimi",
  TAG_MANAGEMENT: "Etiket Yönetimi",
  TAG_MANAGEMENT_INFO: "Etiket Kategorisi zorunlu değildir. Etiket Kategori çözümü ihtiyacınız için lütfen başvurunuz.",
  BRAND: "Marka",
  TAG: "Etiket",
  TAG_NAME: "Etiket",
  TAG_CATEGORY: "Etiket Kategorisi",
  MODEL: "Model",
  BRAND_NAME: "Marka Adı",
  BRAND_LINK: "Marka Link",
  SHELF_SELECT: "Raf Seçiniz...",
  SHELF_SELECTED: "raf seçildi...",

  ORDER_STATUS_SELECT: "Sipariş durumunu Seçiniz...",
  ORDER_STATUS_SELECTED: "durum seçildi...",

  MODEL_SELECT: "Model Seçiniz...",
  MODEL_SELECTED: "model seçildi...",
  CATEGORY_SELECT: "Kategori Seçiniz...",
  CATEGORY_SELECTED: "Kategori Seçildi",
  CATEGORY_TREE: "Kategori Ağacı",
  NEW_MAIN_CATEGORY: "Yeni Ana Kategori",
  NEW_MAIN_CATEGORY_NAME: "Yeni Ana Kategori Adı",
  CATEGORY_NAME: "Kategori Adı",
  NEW_SUBCATEGORY_NAME: "Yeni Alt Kategori Adı",
  USER_AND_ROLES: "Kullanıcılar ve Yetkiler",
  ROLES: "Roller",
  PERSONAL_SETTINGS: "Kişisel Ayarlar",
  CLOSE: "Kapat",
  MODULES: "Modüller",
  LOGOUT: "Çıkış",
  LOGOUT_AND_ALL_DEVICE: "Bütün Cihazlardan Çık",
  ADMIN: "Admin",
  GENERAL_INFORMATION: "Genel Bilgiler",
  IS_WHOLESALE_PRODUCT: "Toptan ürünü mü ?",
  QUICK_PRODUCT:"Hızlı Giriş",
  QUICK_PRODUCT_ADD:"Hızlı Ürün Girişi",
  ADD_PRODUCT:'Ürün Ekle',
  ADD_BULK_PRODUCT_REPORT:'Toplu İşlem Raporu',
  ADD_BULK_PRODUCT:'Toplu Ürün Ekle',
  IS_THERE_TITLE:'Başlık Varmı ?',
  COLUMN_SEPARATOR:'Sütun Ayracı',
  LINE_SEPARATOR:'Satır Ayracı',

  ADD_PRODUCT_INFO:'Eklenecek ürünleri buraya yapıştırın',
  PRODUCT_QUEUE:'Ürün Sırası',
  BARCODE_QTY_PRICE_INFO:'Barkod, Adet, Fiyat şelkinde olmalıdır',

  SELECT_SUB_PRODUCT: 'Alt Ürün Seçin',
  SUPPLIER_CODE: "Tedarikçi Kodu",
  DESCRIPTION: "Açıklama",
  PRODUCT_SHORT_DESCRIPTION: "Ürün Kısa Açıklama",
  ENTER_PRODUCT_TAG: "Ürün etiketi giriniz",
  PRINT_LABEL: "Etiketi Yazdır",
  ADD_TO_MISSING_CART: "Eksik Sepetine At",
  REMOVE_FROM_MISSING_CART: "Eksik Sepetinden Çıkar",
  SUPPLIER: "Tedarikçi",
  LATEST_SUPPLIER: "Son Tedarikçi",
  STOCK_UNIT: "Stok Birimi",
  PRICE_INFORMATION: "Fiyat Bilgileri",
  SELLING_PRICE_WITH_EXAMPLE: "(Örn; 13.34, 1234.5)",
  SELLING_PRICE: "Satış Fiyatı",
  BELOW_SELLING_PRICE: "Satış Fiyatının Altında",
  ABOVE_LAST_PURCHASE_PRICE: "Son satın alma fiyatının üstünde",
  NUMBER_OF_SOLD: "Satılan Ürün Sayısı",
  REMAINING_NUMBER_OF_PRODUCTS: "Kalan Ürün Sayısı",
  SELLING_PRICE_VAT_INCLUDED: "KDV'li Satış Fiyatı",
  DISCOUNTED_SELLING_PRICE: "İndirimli Satış Fiyatı",
  DISCOUNTED_VAT: "İndirimli KDV",
  DISCOUNTED_SELLING_PRICE_VAT_INCLUDED: "KDV'li İndirimli Satış Fiyatı",
  DISCOUNT_APPLIED: "indirim uygulandı",
  BUYING_PRICE: "Alış Fiyatı",
  TAX_PERCENT: "Kdv Oranı",
  CHANGE_TAX_PERCENT: "Kdv Oranı Değiştir",
  CHANGE_TAX_PERCENT_OF_THE_OFFER: "Teklifin Kdv Oranını Değiştir",
  CHANGE_THE_PRODUCT_TAX_PERCENT: "Ürün Kdv'sinide Değiştir",
  PRODUCT_NOT_COLLECTED: "Ürün Toplanamadı",
  COLLECTED_PRODUCT: "Toplanan Ürün",
  COLLECTED_QUANTITY: "Toplanan Miktar",
  PREPARATION_TIME: "Hazırlama Süresi",
  PRODUCT_DEFECTIVE: "Ürün Arızalı",
  RESERVED: "Rezerve",
  RESERVED_STOCK: "Raftan Çıkış",
  PRE_STOCK: "Rafa Giriş",
  ACTUAL: "Fiili",
  REMAINDER: "Kalan",
  LOCATION_INFO: "Depolarda henüz bulunmamaktadır.",
  CARGO_INFORMATION: "Kargo Bilgileri",
  CARGO_AND_ADD_INFORMATION: "Kargo ve Ek Bilgiler",
  SAME_ALL_CHANNEL: "Tüm Kanallar Aynı",
  SALES: "Satış",
  PURCHASE: "Satın Alma",
  PURCHASE_RECEIVING: "Satın Alma Mal Kabul",
  PURCHASE_OFFERS: "Satın Alma Teklifleri",
  PURCHASE_REQUESTS: "Satın Alma Talepleri",
  SALES_OFFER_DRAFT: "Satış Teklifi Taslağı",
  PURCHASE_OFFER_DRAFT: "Satınalma Teklifi Taslağı",
  SALES_OFFER: "Satış Teklifi",
  PURCHASE_OFFER: "Satınalma Teklifi",
  PRODUCTION_OFFER_DRAFT: "Üretim Teklifi Taslağı",
  RETURN_OFFER_DRAFT: "İade Teklifi Taslağı",

  LOCATION_TRANSFER_BETWEEN_OUT :'Depolar Arası Transfer Çıkış',
  LOCATION_TRANSFER_BETWEEN_IN :'Depolar Arası Transfer Giriş',
  LOCATION_TRANSFER_ENTRY_RECEIVING: "Depolar Arası Transfer Giriş Mal Kabul",
  DEPARTMENT_TRANSFER_PICKING: "Bölümler Arsı Transfer Giriş Mal Kabul",
  RECEIVING_CONFIRM_ONLY_CREATE: 'Gelmeyenleri Onayla',

  RECIPE_IN : 'Reçete Girişi',
  RECIPE_OUT : 'Reçete Çıkışı',

  CHANNEL_BASED: "Kanal Bazlı",
  SEO_SETTINGS: "Seo Ayarları",
  SEO_LINK: "Seo Link",
  SEO_HEAD: "Seo Başlık",
  SEO_DESCRIPTION: "Seo Açıklama",
  PRODUCT_INFORMATION: "Ürün Bilgileri",
  PRODUCT_DETAIL: "Ürün Detay",
  ADDITIONAL_INFO1: "Ek Bilgi 1",
  ADDITIONAL_INFO2: "Ek Bilgi 2",
  ADDITIONAL_INFO3: "Ek Bilgi 3",
  ADDITIONAL_INFO4: "Ek Bilgi 4",
  ADDITIONAL_INFO5: "Ek Bilgi 5",
  MIN_ORDER_QTY: "Min. Sipariş Adeti ( Üye İçin )",
  MAX_ORDER_QTY: "Max. Sipariş Adeti ( Üye İçin )",
  LIST_NU: "Liste Numarası",
  OPTIONS: "Seçenekler",
  ATTACHMENTS: "Ekler",
  NO_INSTALLED_FILE_FOUND: "Yüklü Dosya Bulunamadı",

  NEW_PRODUCT: "Yeni Ürün",
  ENTRY_STOCK_QTY: "Giriş Stok A.",
  CRITIC_STOCK_QTY: "Kritik Stok A.",
  C_S: "K.S",
  V_S: "V.S",
  VIRTUAL_STOCK_QTY: "Sanal Stok A.",
  INVENTORY_INFORMATION: "Envanter Bilgisi",
  INVENTORY: "Envanter",
  FOLLOW_METHOD: "İzleme Yöntemi",
  NOT_FOLLOW: "İzleme yapılmıyor",
  UNIQUE_SERIAL_NU: "Tekil Seri Numarası",
  LOT: "Lot",
  SUB_PRODUCT: "Varyasyon",
  HAS_SUB_PRODUCTS: "Varyasyonlu",
  ADD_SUB_PRODUCT: "Varyasyon Ekle",
  PUT_PRODUCTS_ON_SHELF: "Ürünleri Rafa Koy",
  RECIPE: "Ürün Reçetesi",
  RECIPE_NOT_ASSIGN_DEFAULT: "Varasayılan Reçete Seçilmedi",
  RECIPE_CONTENT: "Reçete İçeriği",
  CBM: "CBM",
  UPDATE_RECIPE: "Reçete Güncelle",
  CHANNEL_INFO: "Bu ürüne ait kanal fiyat bilgileri eksik",
  RECIPE_RECEIVING: "Ürün Reçetesi Mal Kabul",
  PRODUCTION_RECEIVING: "Üretim Mal Kabul",
  FREE_ENTRY_RECEIVING: "Serbest Giriş Mal Kabul",
  PACKAGE_PRODUCT_CONTENT: "Bundle",
  ADD_PACKAGE_PRODUCT_CONTENT: "Ürün Ekle",
  CREATE_STOCK: "Stok Oluştur",
  PROPERTY1: "1.Özellik",
  PROPERTY2: "2.Özellik",
  RECIPE_INFO: "Reçeteli Ürünler",
  RECIPE_PRODUCT: "Reçeteli Ürün",
  RECIPE_INFO2: "Ürüne reçete eklerseniz stok arttırımı 'Üretim Reçetesi' bölümünden gerçekleştirilir. Reçeteye konu ürünler azaltılır, bu ürün reçeteye göre artırılır.",
  RECIPE_INFO3: "Bu ürün için bundle veya lot girişi yapıldığı için reçete uygulanamaz",
  VARIATION_PRODUCT: "Varyasyonlu Ürün",
  VARIATION_PRODUCT_LOT_MESSAGE: "Ürün varyasyonu bilgisi girildiği için, envanter bilgisini alt ürünlerde seçmelisiniz",
  VARIATION_PRODUCT_PACKAGE_MESSAGE: "Ürün varyasyonu bilgisi girildiği için, paket ütün tanımlaması yapılamaz",
  PACKAGE_PRODUCT_VARIATION_MESSAGE: "Ürünü paket ürün olarak tanımladığınız için ürün varyasyonu ekleyemezsiniz",
  PACKAGE_NOT_UPDATED_MESSAGE: "Bu ürün daha önce kayıt edildiği için paket düzenlemesi yapılamaz",
  LOT_PRODUCT_VARIATION_MESSAGE: "Ürüne izleme yöntemi tanımladığınız için ürün varyasyonu ekleyemezsiniz",
  STOCK_LIST: "Stok Listesi",
  SEARCH_IN_STOCK: "Stok kartında ara",
  SEARCH_IN_TAG: "Etikette ara",
  SELECT_BRAND: "Marka seçiniz",
  SELECTED_BRAND: "Marka seçildi",
  SEARCH_BRAND: "Marka Arayınız",
  CRITICAL_STOCK: "Kritik Stok",
  OUT_OF_STOCK: "Stokta Olmayan",
  MINUS_STOCK: "Eksi Stok",
  PLUS_STOCK: "Artı Stok",
  VIRTUAL_STOCK: "Sanal Stok",
  ACTIVE_PRODUCT: "Aktif Ürün",
  PROCUREMENT: "Tedarik",
  NOT_SUPPLIED: "Tedarik Edilemedi",
  PACKAGE_PRODUCT: "Paket Ürün",
  EMPTY_STOCK: "Biten",
  NEARLY_EMPTY_STOCK: "Bitmeye Yakın",
  ENOUGH_STOCK: "Yeterli",
  SHOW_PRODUCT_CARD: "Stok Kartında Göster",
  SHOW_PRODUCT_VARIATION: "Ürünün varyasyonlarını gösterir",
  SHOW_PRODUCT_ACTION: "Ürünün stok hareketlerini gösterir",
  UPDATE_PRODUCT_CLICK: "Ürün güncelleme",
  CLONE_STOCK: "Stok Kartı Kopyalama",
  SHOW_MAIN_PRODUCT_BARCODE: "Ana ürün barkodu",
  PRINT_PRODUCT_BARCODE: "Ürün barkodu yazdır",
  PRINT_ACTION_BARCODE: "Hareket barkodu yazdır",
  DELETE_PRODUCT_CLICK: "Ürünü silmek için tıklayınız",
  PASSIVE_PRODUCT_CLICK: "Ürünü pasif etmek için tıklayınız",
  SYNC_PRODUCT_CLICK: "Ürün hedeflerini güncelleme",
  STOCK_INFOMSG1: "Excel olarak indirmek için kuyruğa gönderir",
  STOCK_INFOMSG2: "Herhangi bir ürün için lot/seri nu tanımlanmamıştır",
  STOCK_INFOMSG3: "Bu ürün için varyasyonlara ait stok bilgisi bulunmamaktadır",
  STOCK_INFOMSG4: "Bu stok kartında hiç hareket gözükmemektedir",
  STOCK_INFOMSG5: "Stok hareketi gözükmemektedir",
  STOCK_INFOMSG6: "Herhangi bir ürün için lot/seri nu gözükmemektedir",
  STOCK_INFOMSG7: "Bu üründe lot/seri takibi yapılmamaktadır.",
  STOCK_INFOMSG8: "Bu ürüne ait ürün reçetesi bulunmamaktadır.",
  STOCK_INFOMSG0: "Raf işlemi tamamlanmamış depo harekleri.",
  STOCK_INFOMSG9: "İş emri oluşturulmamış stok hareketi bulunmamaktadır.",
  QUICK_MOVEMENT_PRE_STOCK_INFOMSG0: "Hızlı rafa yerleştir listesi",
  QUICK_MOVEMENT_PRE_STOCK_INFOMSG1: "Bekleyende rafa yerleştir bulunamadı",
  QUICK_MOVEMENT_PRE_STOCK_INFOMSG3: "Gönderilecek veri bulunamadı",
  QUICK_MOVEMENT_PRE_STOCK_INFOMSG4: "Bekleyen Rafa Yerleiştir işlemleri",
  QUICK_MOVEMENT_PRE_STOCK_INFOMSG5: "En Fazla sipariş adeti kadar işlem yap",
  QUICK_MOVEMENT_PRE_STOCK_INFOMSG6: "Hızlı Yerleştirek İçin Seçilen Miktar",

  SHOW_PRODUCT_LOT: "Ürünün Lot/Seri Numaralarını Göster",
  CREATE_LOT_SERIAL: "Lot/Seri Oluştur",
  LOT_SERIAL_NUMBERS: "Lot/Seri Numaraları",
  SELECTED_LOT_SERIAL_NUMBERS: "Seçilen Lot ve Seri Numaraları",
  WITHOUT_WAREHOUSE_INFO: "Depo ataması yapılmayan lot/seri nuları göster.",
  LOT_SERIAL_IN_STOCK: "Stokta olan lot ve seri nu ları gösterir.",
  SERIAL_LOT_BY_LOCATION: "Lokasyona Göre Seri/Lot Durumu",
  LOT_SERIAL_NUMBER: "Lot/Seri Numarası",
  ESTIMATED_DATE: "S.K. Tarihi",
  ENTER_BBD: "S.K.T. Giriniz.",
  VARIATION_DISTRIBUTION: "Varyasyon Dağılımı",
  STOCK_CARD: "Stok Kartı",
  STOCK_CODE: "Stok Kodu",
  STOCK_NAME: "Stok Adı",
  SUB_PRODUCT_CODE: "Alt Ürün Kodu",
  PROPERTIES1: "Özellik 1",
  PROPERTIES2: "Özellik 2",
  PRODUCT_ACTIONS: "Ürün Stok Hareketleri",
  MOVEMENT_DIRECTION: "Hareket Yönü",
  STATISTIC_REPORT: "İstatistik Raporu",
  SELECT_VARIATION: "Varyasyon seçiniz",
  UNIT_PRICE: "Birim Fiyatı",
  PRODUCT_PROPERTIES: "Ürün Özellikleri",
  REAL_STOCK_QUANTITY: "Gerçek Stok Adedi",
  REAL_STOCK_SUPPLY: "Gerçek Stok Miktarı",
  STOCK_UNIT_CHANGE_BLOCKED: "Stok birimi değişikliği engellendi.",

  PRODUCT_DELETED: "Ürün Silinmiş",
  TAG_ORDER: "Etiket Sıralama",
  CATEGORY_TAG_CODE: "Kategorik Etiket Kodu",
  CREATE: "Oluştur",
  ROLE: "Yetki",
  ROLE_LIST: "Rol Listesi",
  CREATE_ROLE_GROUP: "Yeni Rol Oluştur",
  ROLE_NAME: "Rol Adı",
  PERMISSIONS: "İzinler",
  SELECT_NATIVE: "Seç",
  ADD_NEW_CURRENCY: "Yeni Döviz Ekle",
  SELECT_CURRENCY: "Döviz Seç",
  SELECT_FOREIGN_CURRENCY: "Karşı Döviz Seç",
  CURRENCY_INFO: "Tanımlı bütün döviz tipleri eklenmiştir.",
  ORDER_LIST_ACCEPTING: "Sipariş Listesi Onayı",
  START_PREPARING_ORDER: "Siparişi Hazırlamaya Başla",
  PREPARATION_STARTED_ON: "tarihinde hazırlanmaya başlanmıştır.",
  PREPARATION_COMPLETED: "hazırlanma süreci tamamlanmıştır.",


  PAYMENT_INFO: "Ödeme Bilgisi",
  ORDER_NATIVE: "Sıra",
  PRICE_WITH_VAT: "Fiyat (KDV DAHİL)",
  VIRTUAL_STOCK_QUANTITY: "Sanal Stok Adeti",
  STOCK_QUANTITY: "Stok Adeti",
  PRODUCT_TAG: "Ürün Etiketi",
  PRODUCT_TAGS: "Ürün Etiketleri",
  CUSTOMER_NOTE: "Müşteri Notu",
  MISSING_QTY: "Eksik Miktarı",
  MISSING_REPORT: "Eksik Bildirilen",
  MISSING_REPORT_CONFIRM: "Eksik Bildirim Onayı",

  SEND_TO_CUSTOMER: "Müşteriye Gönderilecek",
  PLACED_ON_THE_SHELF_AGAIN: "Tekrar Rafa Yerleştirilecek",
  RESERVE_WILL_BE_REMOVED: "Müşteri Rezervi Kaldırılacak",
  SENT_TO_CUSTOMER_LATER: "Müşteriye Sonra Gönderilecek",


  IS_IN_STOCK: "Ürün Stokta mı?",
  POSSIBLE_STOCK_ERROR: "Olası Stok Hatası",
  POSSIBLE_STOCK_ERROR_INFO: "Ürün stoğu sipariş miktarindan fazla olmasına rağmen eksik bildirilmiş.",
  COMPLETE: "Bitir",
  MISSING_PRODUCT_LIST: "Eksik Ürün Listesi",
  MISSING_PRODUCTS: "Eksik Ürünler",
  MISSING_PRODUCT: "Eksik Ürün",
  CREATE_ORDER: "Sipariş Oluştur",
  REVERT_ACTION_NATIVE: "İşlemi Etkisiz Kıl",
  INVOICE_INFOMSG1: "Sipariş için eksik ürün bilgisi girilmemiştir",
  INVOICE_INFOMSG2: "Stok Hareketi ile sipariş oluşturabilirsiniz",
  ARRIVAL_PERSONAL: "Teslim Alacak Kişi",
  INVOICE_NAME: "Fatura Adı",
  ORDER: "Sipariş",
  ORDER_DETAIL: "Sipariş Detayı",
  CRITICAL_STOCK_LEVEL: "Kritik Stok Seviyesi",
  WAREHOUSE_MANAGEMENT: "Depo Yönetimi",
  WAITING: "Beklemede",
  WAITING_COMPLETED: "Beklemede / Tamamlanan",
  AWAITED: "Bekleniyor",
  MOBILE: "Mobil",
  NET: "Net",
  CARGO_READY: "Kargo Hazır",
  CARGO_TRACKING: "Kargo Takip",
  WAITING_FOR_CARGO: "Kargo Bekliyor",
  NOT_YET_ACCESSIBLE: "Henüz Erişilemez",
  CARGO_COUNTED: "Sayıldı",
  DELIVERED_CARGO_COMPANY: "Kargo Firmasına Teslim Edildi",
  IN_WAITING: "Bekliyor",
  IN_READY: "Hazırlanan",
  NEEDED: "İhtiyaç Olan",
  IN_COMPLETED: "Tamamlanan",
  NOT_PURCHASED: "Tedarik Edilemeyen",
  IN_TRANSFER: "Aktarıldı",
  COMBINE_SHELVES: "Rafları Birleştir",
  AVAILABLE_FROM_SHELF_QUANTITY: "Raftan Kullanılabilir Adet",
  CREATE_OFFER_FROM_SELECTED_PRODUCTS: "Seçilen Ürünlerden Teklif Oluştur",

  DEMAND_QUANTITY: "Talep Miktarı",
  NUMBER_OF_OPEN_DEMAND: "Açık Talep Sayısı",
  MATCHED_PURCHASE: "Eşleşmiş Satınalma",
  FIRST_DEMAND_DATE: "İlk Talep Tarihi",
  LAST_DEMAND_DATE: "Son Talep Tarihi",
  PURCHASE_OFFER_QUANTITY: "Satın Alma Teklif Miktarı",
  START_PURCHASE_INFO: "Bu ürün için satınalması başlatılmış ancak taleple iliştirilmemiş teklifler",
  ASSOCIATE_TO_DEMAND: "Talebe İlişkilendir",
  NOT_PURCHASE_INFO: "Bekleyen Atanmamış Satınalma teklifi yok",
  DEMANDS: "Talepler",


  STATUS_WAIT: "Süreç Devam Ediyor",
  IN_PROGRESS: "Devam Ediyor",
  LAST_COMPLETED: "Son Tamamlanan",
  STATUS_OFFER_APPROVE: "Teklif Onaylandı",
  APPROVED: "Onaylandı",
  STATUS_OFFER_CREATE_ORDER: "Siparişi Oluşturuluyor",
  STATUS_OFFER_DONE: "Teklif Süreci Tamamlandı",
  OFFER_PROCESSES: "Teklif Süreçleri",
  STATUS_OFFER_CANCEL: "İptal Edildi",
  RETURNED: "İade Edildi",
  PAYMENT_MADE: "Ödeme Yapıldı",
  DELIVERED: "Teslim Edildi",
  SHIPPED: "Kargoya Verildi",
  PRODUCT_WAREHOUSE: "Ürün Depo",
  NEUTRAL: "Nötr",
  TASK_ENTRY: "Görev Girişi",
  LOG: "Log",

  MINUTE: "Dakika",
  PLATFORM: "Platform",
  STOP_ADD_PRODUCT: "Ürün Eklemeyi Durdur",
  START_ADD_PRODUCT: "Ürün Eklemeye Başla",
  FILTER_DETAIL: "Detaylı Filtreler",
  OK: "Tamam",
  ATTACH: "Ata",
  BULK_PRINT: "Toplu Baskı Al",
  SELECT_PREPARE_USER: "Hazırlayıcı Seç",
  SELECT_ASSIGN_ACTION_LIST: "Listeler",
  NEW_ACTION_LIST: "Yeni Liste",
  ACTION_LIST_NAME: "Liste İsmi",
  NEW_ACTION_LIST_PRIORITY_NORMAL: "Standart",
  NEW_ACTION_LIST_PRIORITY_HIGH: "Öncelikli",
  PRIORITY_SHELF: "Öncelikli Raf",
  NEW_ACTION_LIST_PRIORITY_VERY_HIGH: "Yüksek Öncelikli",
  NEW_ACTION_LIST_PRIORITY_URGENT: "Acil",
  USERS: "Kullanıcılar",
  ENTER_INVENTORY_SEARCH_CRITERIA: "Aramak için F3 tuşuna basın",
  SHOW_CHANNEL_DETAIL: "Kanal detay bilgisi",
  PROFILE_SETTINGS: "Profil Ayarları",
  ACCOUNT_SETTINGS: "Hesap Ayarları",
  TIMEZONE: "Zaman Dilimi",
  SELECT_TIMEZONE: "Zaman dilimi seçiniz",
  LANGUAGE: "Dil",
  SELECT_LANGUAGE: "Dil seçiniz",
  STOCK_SELECT: "Stok seçiniz...",
  GENERAL_SETTINGS: "Genel Ayarlar",
  RELEASE_NOTES: "Sürüm Notları",
  SUPPORT: "Destek",
  ABOUT_US: "Hakkımızda",
  PREFERRED_CODE: "Ürün Ayırdedici Kod",
  PREFERRED_PRODUCT_CODE: "Tercih Edilen Ürün Ayırdedici Kod",
  RELOAD: "Yenile",
  LOADING: "Yükleniyor",
  SHELVE_SEARCH_INFO: " İşlem Yapılacak Rafı Arama",
  SHELVE_SEARCH_INFO2: "Raf Kodu Arama Bölümümüz ile İşlem Yapmak İstediğiniz Rafı Aratabilirsiniz",
  OFFER_SEARCH_INFO: " Teklif Arama",
  OFFER_SEARCH_INFO2: "Arama Kriterlerini Kullanarak Teklifleri Aratabilirsiniz",

  STOCKTAKING_INFO: "Stok Sayım",
  STOCKTAKING_INFO2: "Stok Sayım",
  STOCKTAKING_PRODUCT_INFO_EMPTY: "Henüz bu ürün için sayım oluşturulmamış",

  OFFER_PRODUCT_INFO: "Aktif Tekliflerdeki Adetler",
  OFFER_PRODUCT_INFO2: "Henüz kesinleşmemiş tekliflerde bulunan, bu ürüne ait adetler",

  RECEIVING_INFO: " Mal Kabul",
  RECEIVING_INFO2: "Henüz Bekleyen mal kabul süreci yok",

  RETURN_OFFER_SCREEN_INFO1: 'İade Oluşturma',
  RETURN_OFFER_SCREEN_INFO2: 'Aramayı sipariş numarası, kargo numarası ve sipairiş id si ile yapabilirsiniz',

  SORTING: "Sıralama",
  CHANGE: "Değiştir",
  CHANGE_LANGUAGE: "Dil Değiştir",
  CHANGE_SORTING: "Sıralamayı Değiştir",
  PRICE_DIFFERENCES: "Fiyat Farklar",
  QUANTITY_DIFFERENCES: "Adet Farklar",
  DEFECTIVE_PIECES: "Arızalı Adetler",
  PRODUCT_IMAGE_PATH: "Ürün Resim Url",
  IMAGE_FROM_PATH: "Url ile resim yükle",
  TAKE_PHOTO: "Fotoğraf Çek",
  NO_DOCUMENT_SELECTED_YET: "Henüz Belge seçilmedi",
  DEFAULT_PRODUCT_IMAGE_UPLOAD: "Varsayılan Resim Yolu",
  ANALYTICS : 'ANALİZ',
  WITH_VAT : 'KDV Dahil',
  VAT_TABLE : 'KDV Tablosu',
  PRODUCT_PRICE_NO_DISCOUNT : 'İndirimsiz Ürün Fiyatı',
  ANALYTICS_EMPTY_INFO : 'Veri Yok',
  ANALYTICS_EMPTY_INFO2 : 'Analizle ilgili henüz veri oluşmamış',
  NEW: 'Yeni',
  EXISTS:'Mevcut',
  MATCH: 'Eşle',
  IMAGE : 'Resim',
  COUNTED : 'Sayılan',
  RECEIVING_MESSAGE_INFO : 'Son kullanma tarihi ve seri numara bilgiler..',
  RECEIVING_MESSAGE_INFO2 : 'Ürünlerin formu bozulması gibi durumlarda göres olarak kanıtı',
  PRODUCT_EXPIRE_DATE : 'Son Kullanma Tarihi',
  SELECT_PRODUCT : 'Ürünü Seç',
  STOCK_MOVEMENT_SUMMARY: 'Stok Hareket Özeti',
  PRODUCT_SALES_REPORT: 'Ürün Satış Raporu',
  TURNOVER_REPORT: 'Ciro Raporu',
  SUMMARY_LIST_TYPE : 'Özet Liste Biçimi',
  SUMMARY_LIST_TYPE_MARKETPLACE : 'Pazaryerleri Sütun Olarak',
  SUMMARY_LIST_TYPE_DEFAULT : 'Varsayılan',
  VENDOR_CODE: 'Tedarikçi Kodu',
  SELECT_VENDOR: 'Tedarikçi Bilgileri',
  VENDOR: 'Tedarikçi',
  EXCHANGE_RATE_INFO : 'Döviz İşlemleri',
  EXCHANGE_RATE_INFO2 : 'Para birimlerinin değişim değerlerini buradan görebilir ve düzeltebilrisiniz. Değer girmediğiniz dövizler varsayılan 1 yazılır',

  EMPTY_PURCHASE_REQUEST_INFO : 'Satın alma Talebi',
  EMPTY_PURCHASE_REQUEST_INFO2 : 'Aradığınız kriterlerde ürün bulunamadı. Arama kriterlerinizi değiştirerek tekrrar deneyiniz.',

  EMPTY_RECIPE_INFO : 'Reçeteler',
  EMPTY_RECIPE_INFO2 : 'Aradığınız kriterlerde ürün bulunamadı. Reçeteli ürün oluşturabilmel için',
  EMPTY_RECIPE_ITEM_INFO : 'Reçeteler Ürünleri',
  EMPTY_RECIPE_ITEM_INFO2 : 'Reçete içerisindeki ürürn yok',

  NEW_RECIPE: 'Yeni Reçete',
  NEW_RECIPE_NAME: 'Yeni Reçete',
  PRODUCT_RECIPES: 'Ürün Reçeteleri',
  ACTIVE_PRODUCT_RECIPES: 'Aktif Ürün Reçeteleri',
  NO_DEFAULT_RECIPE: 'Varsayılan Reçete Yok',
  INCLUDED_RECIPES: 'Dahil Olduğu Reçeteler',
  STATUS : 'Durum',
  TOTAL_PRODUCT_COUNT : 'Toplam Ürün Adeti',
  TOTAL_RECIPE_COUNT : 'Toplam Reçete Adeti',
  SHOW_RECIPE : 'Reçeteyi Göster',
  THIS_DEFAULT_RECIPE_FOR_THIS_PRODUCT : 'Bu ürünün varsayılan reçetesidir',
  DEFAULT_RECIPE : 'Varsayılan Reçete',
};

var offerTypeTr = {
  sales: 'Satış',
  purchase: 'Satın Alma',
  return_inside: 'İçeriden İade',
  return_outside: 'Dönen İade',
  missing_action: 'Eksik Bildirilen',
  REPORT: 'Bildir',
  OFFER_RECIPE : 'Üretim Teklifi',

  PRODUCTION_RECIPE: 'Üretim Reçetesi',
  NEW_PRODUCTION_RECIPE : 'Yeni Üretim Reçetesi',
  NEW_PRODUCTION_PLAN : 'Yeni Üretim Planı',
  PRODUCTION_PLANNING: 'Üretim Planlama',

  PRODUCTION_OFFER : 'Üretim Teklifi Taslğı',
  NEW_PRODUCTION_OFFER : 'Yeni Üretim Teklifi Taslğı',
  PURCHASE_PROCESS: 'Tedarik Sürecinde'
};

var toastServiceMessages = {
  OFFER_SAVED: 'Teklif Kaydedildi.',
  SEARCH_DATA_NOT_PRODUCT: 'Arama verisi ürüne ait değil',
  RETURN_NUMBER_REQUIRED: 'İade numarası gerekli',
  STOCK_CARD_UPDATED: 'Stok kartı güncellendi',
  FILE_UPLOADED: 'Dosya yüklendi...',
  ENTER_THE_VALUE_SEARCH: 'Arananacak değer giriniz',
  SEARCHED_VALUE_WAS_NOT_FOUND: 'Aranan değer ile ilgili sipariş veya aksiyon numarası bulunamadı',
  ENTER_SERIAL_OR_EXPIRATION_DATE: 'Seri numarası veya son kullanma tarihi giriniz.',
  OFFER_COUNTING_COMPLETED: 'Teklif sayımı tamamlandı',
  WAREHOUSE_CREATED_SUCCESSFULLY: 'Depo başarıyla oluşturuldu.',
  WAREHOUSE_DELETED: 'Depo silindi.',
  SHELF_VERIFIED: 'Raf Doğrulandı ürün kodunu okutun',
  NO_SHELF_FOUND: 'Raf Bulunamadı',
  COUNTING_LIST_INFO: 'Sayım listesine istinaden aksiyon oluşturuldu',
  ALL_WAREHOUSES_CREATED: 'Tüm önerilen depolar (rezerv, ürün, arızalı) başarıyla oluşturuldu.',
  GUIDE_REQUEST_CREATED: 'Kılavuz talebi oluşturuldu.',
  GUIDE_DOWNLOADED: 'Kılavuz indirildi.',
  RANK_LEAST_0: 'Sıra en az  0 olabilir',
  WIDTH_LEAST_0: 'Genişlik en az  0 olabilir',
  HEIGHT_LEAST_0: 'Yükseklik en az  0 olabilir',
  DEPTH_LEAST_0: 'Derinlik en az  0 olabilir',
  SHELF_ADDED_SUCC: 'Raf başarıyla eklendi',
  AN_ERROR: 'Bir hata oluştu',
  UPDATED_SUCC: 'Başarıyla güncellendi',
  AN_ERROR_UPDATING: 'Güncellerken bir hata oluştu',
  AN_ERROR_DELETING: 'Silinirken bir hata oluştu',
  BARCODED_PRODUCT_ADDED_LIST: 'Barkodlu Ürün Listeye Eklendi',
  CHECK_SHELF_INFORMATION: 'Raf bilgilerini kontrol edin.',
  SHELF_REPLACEMENT_SUCC: 'Raf Değiştirme Başarılı',
  NO_PRODUCT_WITH_BARCODE: 'Barkoda ait ürün bulunamadı',
  MESSAGE_SENT_SUCC: 'Mesajınız başarıyla gönderildi.',
  MUST_SELECT_1_ORDER: 'En az 1 sipariş seçmelisin',
  PRODUCT_INCREASED_1: 'Ürünün adeti 1 arttırıldı.',
  SELECT_PROD_VARIANT: 'Lütfen ürün varyasyonu seçiniz.',
  TRANSACTION_CREATED_SUCC: 'Hareket başarıyla oluşturuldu.',
  STOCK_MOVEMENT_UPDATED_SUCC: 'Stok hareketi başarıyla güncellendi.',
  ENTERED_INCORRECT_PRODUCT: 'Hatalı Ürün Bilgisi Girdiniz',
  TRANSACTION_COPIED: 'Seçilen işlem kopyalandı.',
  POLYPUS_READY_HANDHELD_TERMINAL: 'Polypus şuan el terminali ile çalışmaya hazır.',
  NOT_STARTED_READING: 'Henüz okuma işlemini başlatmadınız.',
  TOTAL_NUMBERS_INFO: 'Toplam sayılar hazırlanıyor bittiğinde tekrar gözükecek',
  DIDNT_CHOOSE: 'Seçim Yapmadınız',
  APP_READY_OFFLINE: 'Uygulama çevrimdışı kullanıma hazır.',
  SEARCHED_VALUE_NOT_FOUND: 'Aranan değer bulunamadı',
  CHOOSE_VARIANT_PACKAGE_PRODUCT: ' paket ürünleri için varyant seçmelisiniz',
  FILE_SIZE_BE_UP_3MB: 'Dosya boyutu en fazla 3mb olabilir',
  FILE_SIZE_BE_UP_500KB: 'Dosya boyutu en fazla 500 KB olabilir',
  PRODUCT_INCREASED: 'Ürün adeti arttırıldı',
  PRODUCT_ADDED: 'Ürün eklendi',
  UPDATED: 'Güncellendi',
  DOWNLOADING_FILE: 'Dosya indiriliyor...',
  STOCK_MOVEMENT_DOWNLOADED_EXCEL: 'Stok hareketleri excel dosyasına indiriliyor.',
  SUCC_LOGGED: 'Başarıyla giriş yapıldı.',
  ERROR_CREATING_CURRENT_ACCOUNT: 'Cari hesap oluştururken bir hata oluştu.',
  CURRENT_ACCOUNT_CREATED: 'Cari hesap oluşturuldu.',
  NOT_DELETE_THIS_CUSTOMER: 'Bu müşteriyi silemezsiniz veya güncelleyemezsiniz.',
  CURRENT_ACCOUNT_DELETED: 'Cari hesap silindi.',
  ERROR_UPDATING_CURRENT_ACCOUNT: 'Cari hesap güncellenirken bir hata oluştu.',
  CURRENT_ACCOUNT_UPDATING: 'Cari hesap güncellendi.',
  MISSING_PRODUCT_RECORDED: 'Eksik ürün bilgisi kayıt edildi.',
  CREATED_FOR_ORDER: '{{orderNo}} siparişi için hareket oluşturuldu.',
  RETURN_CREATED: 'İadeniz Oluşturuldu',
  NO_AVAILABLE_CAMERA_FOUND: 'Kullanılabilir kamera bulunamadı.',
  PLEASE_ENABLE_BROWSER_PERMISSION: 'Lütfen "Kameralı Barkod Okuma" için browser izinlerini açınız.',
  FAILED_DELETE_SHORTCUT: 'Kısayol silinemedi',
  SHORTCUT_REMOVED: 'Kısayol kaldırıldı',
  SHORTCUT_NOT_FOUND: 'Belirtilen kısayol bulunamadı',
  ADDED_THIS_PRODUCT_BEFORE_INFO: 'Bu Ürünü Daha Önce Eklemişsiniz , Eğer Daha Fazla İstiyorsanız Adetini Arttırabilirsiniz',
  USER_CREATED: 'Kullanıcı oluşturuldu.',
  TOKEN_NOT_RENEWED: 'Token Yenilenemedi',
  SEARCH_DATA_NOT_BELONG_PRODUCT: 'Arama verisi ürüne ait değil',
  PRODUCT_ADD_CART: 'Ürün Sepete Eklendi',
  NEW_CART_CREATED: 'Yeni Sepet Oluşturuldu',
  CART_CLEARED: 'Sepet Temizlendi',
  PRODUCT_REMOVED_CART: 'Ürün Sepetten Çıkartıldı',
  CART_COMPLETE: 'Sepet Tamamlandı',
  SUB_PRODUCT_SELECTED: 'Alt Ürün Seçilmeli',
  DOWNLOADING_STOCK_FILE: 'Stok dosyası indiriliyor.',
  NO_RECORD_FOUND: 'Kayıt bulunamadı.',
  SERIAL_EXPIRATION_REQUIRED: 'Seri numarası ve son kullanma tarihi zorunlu',
  STOCK_CARD_CREATED: 'Stok kartı oluşturuldu.',
  IMAGE_UPLOAD_SUCC: 'Resim Yükleme Başarılı.',
  STOCK_EXCEL_DOWNLOADING: 'Stok excel dosyası indiriliyor.',
  SERIAL_LOT_RECORDED: 'Seri/lot nu kayıt edildi.',
  ACCOUNTING_PROGRAM_INFO: 'Muhasebe Programı için otomatik aktarım ayarları girildi.',
  DEFAULT_CHANNEL_CANNOT_AGAIN: 'Varsayılan kanal tekrar açılamaz',
  WEB_SERVICE_SETTINGS_ENTERED: 'Satış kanalının web servis ayarları girildi.',
  PROBLEM_CHANNEL_SETTINGS: 'Satış kanalının ayarlarında sorun var.',
  ACTIVE_PASSIVE_SETTING: 'Satış kanalının aktif/pasif ayarı yapıldı.',
  ACTIVE_PASSIVE_SETTING_COULD_NOT: 'Satış kanalı aktif/pasif ayarlanamadı',
  YOU_HAVE_MISSING_FIELDS: 'Eksik alanlarınız var',
  COMMAND_QUEUED: 'Komut çalıştırılmak üzere kuyruğa alındı. Size haber vereceğiz.',
  ORDER_STATUS_TRANSFERRED: 'Sipariş durumları aktarıldı.',
  PAYMENT_TYPES_TRANSFERRED: 'Ödeme tipleri aktarıldı.',
  COMPANY_LOGO_UPLOADED: 'Firma logosu yüklendi.',
  LOGO_DELETED: 'Yüklediğiniz logo silindi',
  SETTING_SAVED: 'Ayar Kayıt Edildi',
  COMPANY_INFORMATION_RECORDED: 'Firma bilgileri kayıt edildi',
  COMPANY_INFORMATION_UPDATED: 'Firma bilgileri güncellendi',
  COMPANY_NAME_REQUIRED: 'Firma ismi alanı boş bırakılamaz',
  COMPANY_ADDRESS_REQUIRED: 'Firma Adresi alanı boş bırakılamaz',
  BANK_INFO_MUST_ENTERED: 'Banka bilgileri eksiksiz girilmelidir',
  COMPANY_INFORMATION_DELETED: 'Firma bilgisi silindi',
  DEFAULT_COMPANY_CHANGED: 'Varsayılan Firma değiştirildi',
  REPORT_COPIED_CLIPBOARD: 'Rapor sonucu panoya kopyalandı',
  ERROR_REPORT_COPIED_CLIPBOARD: 'Rapor Sonucu kopyalanırken hata oldu',
  STATUS_UPDATED: "<b>{{label}}</b> &nbsp; durumu güncellendi",
  PAGE_SHORTCUT_NOT_ELIGIBLE: "Sayfa kısayolu oluşturulmaya uygun değildir",
  SHORTCUT_CREATED: "Kısayol oluşturuldu",
  PAGE_WAS_ADDED_SHORTCUT: "Bu sayfa daha önce kısayol olarak eklenmiş",
  SETTINGS_BEEN_CHANGED: "Ayarlar değiştirildi",
  PASSWORD_BEEN_CHANGED: "Parolanız değiştirildi.",
  PRINTER_SETTINGS_DEFINED: "Yazıcı ayarlarınız tanımlandı.",
  ROLE_CREATED: "Rol oluşturuldu.",
  ROLE_UPDATED: " rolü güncellendi.",
  USER_HAS_BEEN_UPDATED: " kullanıcısı güncellendi.",
  APPOINTMENT_PROCESS: "Atama işlemi yapıldı yönlendiriliyorsunuz",
  BARCODE_NOT_DEFINED_SYSTEM: "Bu barkod sistemde tanımlı değil",
  SHELF_REPLACED: "Raf Değiştirildi",
  PRODUCT_PREPARATION_COMPLETED: "Ürün hazırlığı tamamlandığı için sıradaki ürüne geçildi.",
  NO_PRODUCT_SHELF: "Rafta ürün Bulunmamaktadır",
  INCORRECT_PRODUCT_QUANTITY_ENTRY: "Hatalı Ürün Adedi Girişi",
  INCOMPLETE_COMPLETED: "Eksik bildirim tamamlandı",
  PRODUCTS_SUCC_COMPLETED: "Bu listedeki Ürünler başarıyla tamamlanmıştır",
  WAS_ADDED_BEFORE: " Daha önce eklenmiş",
  ASSIGMENT_SUCC: "Atama Başarılı oldu",
  TRANSACTION_COMPLETED_SUCC: "İşlem Başarıyla gerçekleştirildi",
  ORDER_SENT_TO_ACCOUNTING: "{{transactionId}} numaralı siparişiniz muhasebeye gönderilmiştir.",
  INFORMATION_COMPLETELY: "Lütfen Bilgileri Eksiksiz Doldurunuz",
  WRONG_SHELF_CODE: "Yanlış Raf Kodu Girdiniz",
  LEAST_1: "En az 1 adet olabilir",
  MAX_OF_PIECES: "En fazla {{quantity}} adet olabilir.",
  NO_ORDERS_WERE_FOUND: "Bu Taşıyıcıda hazırlanan hiçbir sipariş bulunamadı",
  ORDERS_IN_TOTAL: "Toplamda {{ordersPrepared}} Sipariş var.",
  SELECTED_ORDERS_ERROR: "Seçilen siparişlerin durumu güncellenirken hata oldu",
  NO_PACKAGING_SHELF: "Paketleme Rafı bulunmamaktadır.",
  CART_SECTION_NOT_FOUND: "Sepete ait bölüm bulunamadı",
  ORDER_PREPARED_NOT_FOUND: "Taşıyıcıya ait hazırlanan sipariş bulunamadı",
  PRODUCT_NOT_MATCH: "Bu ürünün sepeteki hiçbir siparişle uyuşmadı",
  CHECKING_STAGE_YET: "Henüz Kontrol aşamasında liste yok",
  ERP_FAILED_AGAIN_LATER: "ERP Aktarımları Yüklenemedi Lütfen Daha Sonra Tekrar deneyiniz",
  BRAND_ADDED_SUCC: "Marka başarıyla eklendi",
  BRAND_UPDATED_SUCC: "Marka başarıyla güncellendi",
  TAG_ADDED_SUCC: "Etiket başarıyla eklendi",
  CATEGORY_UPDATE_SUCC: "Kategori güncelleme başarılı.",
  CATEGORY_CREATE_SUCC: "Kategori ekleme başarılı.",
  MAIN_CATEGORY_CREATE_SUCC: "Ana kategori ekleme başarılı.",
  CATEGORY_DELETE_SUCC: "Kategori silme başarılı.",
  TEXT_COPIED: "\"{{text}}\" metni kopyalandı.",
  RELATED_STOCK_LIST_NOT_LOADED: "İlgili Stok listesi yüklenemedi",
  FILL_ALL_FIELDS: "Bütün alanları doldurunuz.",
  ADDED_TO_COUNT: "Sayıma eklendi",
  WRITE_PRODUCT_BARCODE: "Lütfen ürün barkodu yazınız",
  COPYING_STOCK_CARD: "Stok kartı kopyalanarak yeni bir kart oluşturuldu.",
  TRANSACTION_LIST_CREATED: "İşlem listesi oluşturuldu.",
  TEMPLATE_SUCC_REGISTERED: "Şablon başarıyla kayıt edildi.",
  SUCC_REMOVED_LIST: "Başarıyla listeden çıkarıldı",
  DOWNLOAD_LIST_UPDATED: "Yükleme listesi güncellendi",
  ROW_LIST_UPDATED: ", satır listesi güncellendi",
  PASSWORD_SUCC_CHANGED: "Şifre başarıyla değiştirildi",
  NOT_FOUND_IN_ERP: "ERP DE BULUNAMADI",
  SURE_SCANNING_CORRECT_BARCODE: "Doğru Barkodu Okuttuğunuzdan Emin Olun",

  NOT_FOUND_THIS_LIST: " bu listede bulunamadı.",
  BARCODE_COMPLETED_FOR_ACTIVE_ORDER: " barkod aktif sipariş için tamamlandı",
  BARCODE_NOT_ACTIVE_ORDER: " barkod aktif siparişte yok. Karışık liste olduğu için diğer siparişe geçiş yapılmadı",
  NOT_AUTHORIZED: "Bu işlev için yetkiniz bulunmamaktadır. Lütfen sistem yöneticinizle iletişime geçiniz.",
  UNEXPECTED_ERROR_OCCURRED: "Beklenmeyen bir hata oldu. Bu konuyla hemen ilgileneceğiz. Lütfen daha sonra tekrar deneyiniz.",
  CANNOT_ADD_MORE_PRODUCTS: "Daha Fazla Ürün Ekleyemezsiniz",
  ENOUGH_PRODUCTS: "Gereğinden Fazla {{quantity}} adet ürün işaretlediniz",
  MUST_ENTER_SHELF_CODE: "Raf Kodu Girmeniz Gerekmektedir",
  RECEIVING_PROCESS_STOPPED: "Mal Kabul İşlemi Durduruldu",
  A_NEW_VERSION: "Yeni bir versiyon çıktı. Tarayıcı geçmişinizi silmelisiniz.",
  CHOOSE_SHELF_FIRST: "Önce Rafı Seçin",
  LEAST_1_LOT: "En az 1 lot seçmelisin",
  PRODUCT_CHANGED: "Ürün değişti. Ancak onaylandığı için adet arttırımı yapılmadı",
  NOT_RECEIVING: "Ürün değişti. Ancak tamamlandığı için mal kabul yapılmadı",
  WAS_BBD: "Ürün değişti. Ancak sktli oldupu için giriş yapılmadı",
  ACTIVE_RECEIVING_NOT_SUPPORT_ADDING_PRODUCTS: "Aktif mal kabul ürün eklemeyi desteklemiyor.",
  MISTAKE_IMAGE_UPLOAD: "Resim yüklerken hata meydana geldi. Hata: ",
  WARNING: "Uyarı",
  EMPTY_SERVICE_ID_MESSAGE: "En az 1 tane ürün seçmelisiniz",
  NO_ACTION_TAKEN_YET: "Henüz İşlem Yapılmadı",
  POLYPUS_STATUTE_UPDATED: "Polypus Statüsü üncellendi",
  COUNTED_QUANTITY_NOT_MATCH: "Sayılan adet ile tanımlanan seri numaralarının adetleri tutmuyor. Onaylansın mı ? ",
  WANT_TO_DELETE_STATUS: "Bu durumu silmek istediğinize emin misiniz? Silinecek durum otomatik aktarım ayarlarından kaldırılmayacaktır. Lütfen otomatik aktarım ayarlarınızı gözden geçiriniz.",
  AFTER_THIS_SETTING_DELETED: "Bu ayar silindikten sonra belirtilen durumlarda gelen siparişlerin ürünleri varsayılan olarak yukarıda eşleştirilen depodan sipariş durumu gözetilmeden düşülecektir. Bu ayarı kaldırmak istediğinizde emin misiniz?",
  APPROVED_PRODUCT_TRACKED_MESSAGE: "Onaylanan Ürün takip ediliyor. Ancak Seri numara girişi yapılmadı. Onaylansın mı ?",
  CHANNEL_STATUS_UPDATED: "Kanal üzerindeki statü güncellendi",
  NOTE_ADDED_TO_CHANNEL_RECORD: "Sipariş durumu ile ilgili kanal üzerindeki kayıta not yazıldı",
  SELECTED_ORDERS_WILL_UPDATE: "Seçilen siparişlerin durumları güncellenecektir. Emin misiniz?",
  STATUS_SET_DEFAULT: " durumu varsayılan olarak ayarlandı.",
  STATUS_REMOVED_DEFAULT: " durumu ön seçimden kaldırıldı.",
  PRIORITY_RANKING: "Öncelik Sıralaması",
  MANAGES_YOUR_BUSINESS: "İşinizi yönetir.",
  MOTTO_INFORMATICS: "Motto Bilişim",
  GET_INFORMATION: "Bilgi Al",
  SUITABLE: "Uygun",
  NOT_SUITABLE: "Uygun değil",
  TRANSACTION_ALREADY_ADDED: "{{transactionId}} Daha önce eklenmiş",
  LIST_CREATED: "Liste Oluşturuldu. Liste Adı: {{listName}} Önceliği: {{priority}}",
  SELECT_FILE: "Dosya seçmelisiniz",
  PRINTER_NOT_DEFINED: "{{printerName}} yazıcısı tanımlı değildir.",
  SELECT_SERVICE: "Servisi seçmelisiniz",
  SELECTION_LIST_WILL_EMPTIED: "Seçim listesi boşaltılacaktır. Emin misiniz?",
  ERROR_CONNECT_TERMINAL: "El terminaline bağlanılamadı.",
  ORDER_REFERENCE_REMOVED: "{{orderNumber}} referans numaralı sipariş {{printerName}} yazıcısından çıkarıldı.",
  MAIN_PRODUCT_AFFILIATED_WITH: "Bağlı Olduğu Ana Ürün",
  EMPTY_SELECTION_ALERT : 'Hiçbir seçim yapmadınız en az bir seçim yapmalısınız',
  SEPARATE_CONFIRM_ALERT : 'Seçilen siaparişler listeden çıkatılacak. Bu işlem geri alınamaz',
  SEPARATE_SELECTED : 'Seçilenleri',
  SEPARATE_NEW_ISSUE : 'Yeni İş Emri Yap',
  SEPARATE_ONLY_SEPARATE : 'Listeden Çıkar',

  COUNT_COMPLETED_BY: "Sayım <b>{{name}}</b> tarafından tamamlandı. Ancak adetlerde farklılık var.",
  CONFIRM_QTY: "Adetleri onaylamak için ",
  SEE_UPLOADED_DOCUMENT: "Yüklenene belgeleri görmek için ",
  SEE_SERIAL_EXP_DATE: "Seri numaralar ve son kullanma tarihileri görmek için ",
  CLICK: "Tıklayın",
  DOCUMENT_UPLOADED_COUNT: "Sayım için <b>{{qty}}</b> belge yüklendi.",

  REQUEST_CREATED_FOR_MISSING_NOTIFICATION: "Eksik bildirim için oluşturulan talep şu an <b>{{status}}</b> durumunda ve Onaylanan Adet : <b>{{qty}}</b>",
  B2C_SERVICE_COMPLETED: "B2C hizmetinizden stok aktarımı tamamlandı.",
  CANNOT_PRINT_RESPONSIBLE_USER: "Sorumlu kullanıcıyı atamadan çıktı alamazsınız.",
  CHECK_YOUR_EMAIL_FOR_RESET_PASSWORD: "Şifrenizi nasıl sıfırlayacağınıza ilişkin talimatlar için lütfen e-postanızı kontrol edin.",
  PRODUCT_OF_MOTTO: "Polypus bir <a href='{{url}}' target='_blank' class='motto-link'>Motto Bilişim</a> ürünüdür. <br> © 2023 Tüm hakları saklıdır.",
  IS_USE_SHELF: "Raf Kullanıyor mu?",
  USE_SHELF: "Raf Kullanıyor",
  NOT_USE_SHELF: "Raf Kullanmıyor",
  NEW_VIEW: "Yeni Görünüm",
  LOCATION_ACTION_REPORT: "Depo Aksiyon Raporu",

};

var langTr = angular.merge(
    MenuTr,
    accountSettingsTr,
    cargoTr,
    customersTr,
    dashboardIndexTr,
    integrationPageTr,
    batchOperationsTr,
    invoicesTr,
    locationTr,
    loginTr,
    batchOperationsForTsoftTr,
    actionTr,
    notificationTr,
    ordersPrepareTr,
    reportsTr,
    commonTr,
    paginationTr,
    offerTypeTr,
    toastServiceMessages
);

export {langTr};
