class BottomViewController{
  constructor(API, $scope, $rootScope, $state, $mdBottomSheet, $location, $auth, ToastService, $element, $mdDialog, DialogService) {
    "ngInject";
    this.$scope = $scope;
    this.$mdBottomSheet = $mdBottomSheet;
    this.$mdDialog = $mdDialog;
    this.$auth = $auth
    this.ToastService = ToastService;
    this.API = API
    this.stockMovement = null;
    this.statisticReport = null;
    this.$state = $state
    this.$rootScope = $rootScope;
    this.options = null;
    this.$location = $location;
    this.$element = $element;
    this.DialogService = DialogService;
    this.actionTypes = null;
    this.productList = [];
    this.isShowCamera = false;

    this.disabled = [];
    this.notAssignedToList = false;
    this.recipeList = false;
    this.itemsPerPage = 5;
    this.currentPage = 0;
    this.pagedData = [];
    this.hasMoreData = true;

    this.newWarehousesStatus = true;

    var parent = this;

    this.waitListActionType = 'full'
    this.waitListLocationId = null
    this.waitListMinify = true

  }
  $onInit(){
    var parent = this;

    if (this.term) {
      this.product_code = this.term;
      this.productDataWithSearchValue();
    }
    if (this.id) {
      this.product_id = this.id;
      this.productType = this.type;
      this.showProduct(this.product_id, this.productType);
    }



    angular.element(document).ready(() => {
      // Belirli bir input elemanına odaklanmak için bir fonksiyon
      /*
      let parent = this;
      this.$scope.focusOnInput = function() {
        var inputElement = parent.$element.find('#search_box');
        console.log("fokuslandı uieaueiaueiaueia", inputElement.length, inputElement)
        console.log(inputElement)
        if (inputElement.length > 0) {
          inputElement[0].focus();
          console.log("fokuslandı hesapta")
        }
      };

      // Bottom sheet kapatıldığında çağrılacak fonksiyon
      this.$scope.$on('$mdBottomSheet.hide', function() {
        // Bottom sheet kapatıldığında yapılacak işlemler buraya gelebilir
        // ...
      });

      // Sayfa yüklendiğinde belirli bir input elemanına otomatik odaklan
      this.$scope.focusOnInput();
      */

    });

    this.$scope.$on('childEvent', function (event, decodedTextFromCamera){
      parent.product_code = decodedTextFromCamera;
      parent.productDataWithSearchValue();
    });

  }

  productDataWithSearchValue() {
    var parent = this;
    this.recipeProducts = null;
    this.includedRecipe = null;
    if (this.product_code !== "") {
      let filter = {
        searchValue: this.product_code
      }
      this.API.all("stock/getById")
        .post(filter)
        .then((response) => {
          if (response.response.status == 207){
            this.ToastService.success(response.data.message);
          }

          if(response.data.product){
            this.showMode = "product";
            this.selectedProduct = response.data.product;
            this.product_id = response.data.product.id;
            this.product_code = this.selectedProduct.ts_product_code;
            this.supplier_product_code = this.selectedProduct.ts_supplier_product_code;
            this.ts_product_name = this.selectedProduct.ts_product_name;
            this.barcode = this.selectedProduct.ts_barcode;
            if (response.data.product.has_recipe) {
              this.recipeProducts = response.data.product.recipe.recipes.filter(recipe => recipe.active === 1);
            }
            if(response.data.product.includedRecipe.length > 0){
              this.includedRecipe = response.data.product.includedRecipe;
            }
            this.subProductsOnLocations(this.product_id)
            this.getProductAnalytics(this.product_id)
            this.getProductAnalyticsNotAssignList(this.product_id);
          }
          if(response.data.list){
            parent.showMode = "list";
            parent.productList = response.data.list;
          }
          //this.state.params.tab = wizardItem.link.params.tab;
          //this.$location.search({tab: 3});
        });
    }

  }
  showListDetail(listId) {
    let options = {
      controller: "IssuesDetailController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        listId: listId
      },
      onShowing: function (scope, element, options, controller) {
        controller.getList()
      },
    }
    this.DialogService.fromTemplateV2("./views/angular/app/components/issues/partials/issues-detail.html",options)
        .then((response)=>{
        })
  }
  showItemRecipe(productName,recipeId){
    let parent = this;
    let d = this.DialogService;
    this.API.all('recipe/'+recipeId)
        .customGET()
        .then((response) => {
          let options = {
            controllerAs      : 'vm',
            bindToController  : true,
            clickOutsideToClose: true,
            escapeToClose: true,
            onShowing         : function (scope, element, options, controller){
              scope.productName = productName;
              scope.recipe = response.data.list;
              scope.items = response.data.list.items;
              scope.DialogService = d;
              scope.cancel = () => {
                scope.DialogService.$mdDialog.hide()
              }
              scope.goRecipe = (productId, recipeId=null) => {
                parent.$state.go('app.recipe',{tab:1,productId:productId, recipeId:recipeId}, {reload:true});
                scope.DialogService.$mdDialog.hide()
              }
            },
          }

          this.DialogService.fromTemplateV2(
              './views/angular/app/components/offer/partials/dialog/recipe-item.html',
              options
          )
        });
  }

  goRecipe(productId, recipeId = null){
    this.$state.go('app.recipe',{tab:1,productId:productId, recipeId:recipeId}, {reload:true});
  }

  showProduct(productId, productType) {
    this.product_id = productId;
    this.recipeProducts = null;
    this.includedRecipe = null;
    var parent = this;
    this.API.all("stock/getById")
      .post({
        productId: productId,
        productType: productType
      })
      .then((response) => {
        this.showMode = "product"
        this.selectedProduct = response.data.product;
        this.subProducts = response.data.subProductArray;
        this.product_id = response.data.product.id
        this.product_code = this.selectedProduct.ts_product_code;
        this.supplier_product_code = this.selectedProduct.ts_supplier_product_code;
        this.ts_product_name = this.selectedProduct.ts_product_name;
        this.barcode = this.selectedProduct.ts_barcode;
        this.main_product_code = this.selectedProduct.main_product_code;
        if (response.data.product.has_recipe) {
          this.recipeProducts = response.data.product.recipe.recipes.filter(recipe => recipe.active === 1);
        }
        if(response.data.product.includedRecipe.length > 0){
          this.includedRecipe = response.data.product.includedRecipe;
        }
        //this.subProductsOnLocations(this.product_id)
        this.getProductAnalytics(this.product_id, productType)
        this.getProductAnalyticsNotAssignList(this.product_id);
        //this.state.params.tab = wizardItem.link.params.tab;
        //this.$location.search({tab: 3});

        this.groupedSubProducts = this.subProducts.reduce((groups, item) => {
          const key = item.property1;
          // Eğer grup daha önce oluşturulmadıysa, oluştur
          if (!groups[key]) {
            groups[key] = [];
          }
          // Gruba öğeyi ekle
          groups[key].push(item);

          return groups;
        }, {});

      })
      .catch(function () {
      });

  }

  showCamera(){
    this.isShowCamera = !this.isShowCamera;
  }

  showCamera1(){
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/camera/bottomSheet.component.html",
      controller: "CameraController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
      }
    })
      .then(response => {
        this.product_code = response.decodedText;
        this.productDataWithSearchValue();
        this.$mdBottomSheet.show();
      })
      .catch(function(error) {
        console.error(error,"<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
      });
  }


  getProductAnalytics(product_id, productType) {
    this.product_id = product_id;
    let reqObject = {
      id: product_id,
      per_page: 10,
      page: 1,
    };

    var parent = this;

    this.API.all("stock/analytics-summary")
      .customGET(product_id, {productType})
      .then((response) => {
        parent.productSalesAnalyticsSummaryObject = response.data.analyticsSummary;
        parent.salesAnalyticsProductScreen =
          {
            //title: "Stok Hareketleri : " + response.data.product.ts_product_name,
            productObject: response.data.product,
            subProducts: response.data.subProducts,
            analytics: response.data.analytics,
            analyticsPagesArray: response.data.analyticsPagesArray,
            prev_index: response.data.prevIndex,
            next_index: response.data.nextIndex,
            current_page: response.data.analytics.current_page,
            total_movement_qty: response.data.total_movement_qty
          };
      });
  }

  subProductsOnLocations(product_id) {
    this.product_id = product_id;
    var parent = this;

    this.API.all("stock/sub-products/warehouses")
      .post({product_id: this.product_id})
      .then((response) => {
        parent.subProductScreen =  response.data.subProductOnLocations;
      });
  }

  getClickProduct(productId, productType){
    this.API.all("stock/getById")
        .post({
          productId: productId,
          productType: productType
        })
        .then((response) => {
          this.selectedProduct = response.data.product;
          this.selectProduct()
        })
        .catch(function () {
        });
  }
  selectProduct(product) {
    if (undefined === this.selectedProduct) {
      this.selectedProduct = {
        id: product.productId,
        ts_product_code: product.productCode,
        ts_barcode: product.barcode,
        ts_supplier_product_code: product.supplierProductCode,
        ts_product_name: product.productName,
        images: product.images,
        has_recipe: product.hasRecipe,
        unit: product?.unit
      }
    }
    this.$rootScope.$broadcast('selectProductEvent', this.selectedProduct);
    this.$mdBottomSheet.hide();
  }
  getProductAnalyticsNotAssignList(product_id) {
    this.product_id = product_id;
    var parent = this;
    this.disabled[product_id] = true;
    this.API.all("stock/analytics-not-assign-list/"+product_id)
        .customGET()
        .then((response) => {
          parent.productAnalyticsNotAssignListObject = response.data.analyticsNotAssignList.data;
          parent.originalProductAnalyticsList = [...response.data.analyticsNotAssignList.data];
          this.total_movement_qty = response.data.analyticsNotAssignList.total_movement_qty;
          this.list_length = response.data.analyticsNotAssignList.list_length;
          this.disabled[product_id] = false;
          this.currentPage = 0;
          this.hasMoreData = true;
          this.updatePagedData();
        });
  }

  updatePagedData(){
    let start = this.currentPage * this.itemsPerPage;
    let end = start + this.itemsPerPage;
    this.pagedData = this.productAnalyticsNotAssignListObject.slice(start, end);
    if (end >= this.productAnalyticsNotAssignListObject.length) {
      this.hasMoreData = false;
    }
  }
  showNext() {
    this.currentPage++;
    this.updatePagedData();
  }
  showPrevious () {
    if (this.currentPage > 0) {
      this.hasMoreData = true;
      this.currentPage--;
      this.updatePagedData();
    }
  }

  showOrderDetail(id) {
    let options = {
      controller: 'InvoicesDetailController',
      controllerAs: 'vm',
      locals: {
        orderId: id
      },
      bindToController: true,
      onShowing: function (scope, element, options, controller) {
        controller.getOrderDetail(controller.orderId)
      },
    }
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/invoices-detail/invoices-detail.component.html',
        options
    )
  }

  showActionDetail(action_id) {
    this.selectedDetailActionId = action_id;
    let options = {
      controller: 'ActionDetailController',
      controllerAs: 'vm',
      locals: {
        id: action_id
      },
      bindToController: true,
      onShowing: function (scope, element, options, controller) {
        controller.getDetail(controller.id)
      },
    }
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/order-lists/partials/action-detail.component.html',
        options
    )
  }

  closeQuickView(){
    this.$mdBottomSheet.hide();
  }

  openMenu($mdMenu, ev) {
    this.originatorEv = ev;
    $mdMenu.open(ev);
  };

  quickCreateStock() {
    let parent = this;
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/bottom-quick-create-stock/bottom-quick-create-stock.component.html",
      controller: "BottomQuickCreateStockController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        id: 1
      }
    }).then(response => {
      if (response?.data?.productId) {
        this.selectedProduct = {id:response.data.productId}
        parent.selectProduct()
      }
    })
  }

  filterNotAssignedStockMovement(value, locationId) {
    if (value === this.waitListActionType) {
      this.waitListActionType = 'full';
      this.waitListLocationId = null;
      return true;
    }
    this.waitListActionType = value;
    this.waitListLocationId = locationId;
    this.waitListMinify = false;
  }

  go(tab){
    this.$mdBottomSheet.hide();
    this.options = {tab: parseInt(tab), product_id: this.product_id, editProducts: true }
    if(tab === 2){
      this.options.product_code = this.selectedProduct.ts_product_code;
    }
    if(tab === 1){
      this.options.term = this.term;
    }
    if (tab === 3){
      if (this.$state.current.name === 'app.stock_management') {
        this.$rootScope.$broadcast('updateSelectProductEvent', this.product_id);
      } else {
        this.options.tab = 3
        this.$location.search(this.options);
        this.$state.go('app.stock_management',this.options);
        this.$rootScope.$broadcast('updateSelectProductEvent', this.product_id);
      }
      return;
    }

    if (this.$state.current.name === 'app.stock_management') {
      this.$rootScope.$broadcast('showProduct', this.product_id);
    } else {
      this.$state.go('app.stock_management',this.options);
    }
  }

  goFromList(tab, productId, productCode){
    this.$mdBottomSheet.hide();
    this.options = {tab: tab, product_id: productId }
    if(tab === 2){
      this.options.product_code = productCode;
    }
    this.$state.go('app.stock_management',this.options);
  }
}

BottomViewController.$inject = ["API", "$scope", "$rootScope", "$state", "$mdBottomSheet", "$location", "$auth", "ToastService", "$element","$mdDialog","DialogService"];

const BottomViewComponent = {
  templateUrl:
    "./views/angular/app/components/bottom-view/bottom-view.component.html",
  controller: BottomViewController,
  controllerAs: "vm",
  bindings: {},
};
export {BottomViewComponent, BottomViewController}
