var MenuEn = {
  SETTINGS: "Settings",
  HELP: "Yardım",
  QUICK_HELP: "Quick Help",
  SUPPORT: "Support",
  DARK_MODE: "Gece Modu",
  MY_SHORTCUT: "My Shortcuts",
  SCREEN_VIEW: "Screen View",
  VIEW: "View",

};

var accountSettingsEn = {
  PERSONAL_INFORMATION: "Personal Information",
  TURKISH: "Türkçe",
  ENGLISH: "English",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  REPEAT_NEW_PASSWORD: "New Password Again",
  UPDATE: "Update",
  CHANGE_PASSWORD: "Change Password",
  LOCALIZATION_SETTINGS: "Localization Settings",
  SCAN_PRIORITY: "Scan Priority",
  PRINTER_SETTINGS: "Printer Settings",
  PRINTER_NAME: "Printer Name",
  PRINTER_CONNECTION_PROTOCOL: "Printer Connection Protocol",
  PRINTER_IP_ADDRESS: "Printer IP Address",
  PRINTER_PORT_ADDRESS: "Printer Port Address",
  PRINT_TEST_PAGE: "Print Test Page",
  FIRM_LOGO: "Company Logo",
  CONTROLLER: "Controller",
  QUALITY_CONTROL: "Quality Control",
  PACKER: "Packer",
  SHOW: "Show",
  HIDE: "Hide",
  CONFIRM_OFFER: "Confirm Offer",
  COMPLETE_OFFER: "Complete Offer", // Eklendi
  CLOSE_OFFER: "Close Offer", // Eklendi
  CANCEL_ORDER: "Cancel Order", // Eklendi
  CANCEL_OFFER: "Cancel Offer", // Eklendi
  CONFIRM_QUICK: "Confirm Quickly", // Eklendi
  CREATE_PERMISSION_GROUP: "Create Permission Group", // Eklendi

  TRANSACTION_STATUS_INFO1: "When the order fell to Polypus",
  TRANSACTION_STATUS_INFO2: "When the order is assigned to the user",
  TRANSACTION_STATUS_INFO3: "When order picking is completed",
  TRANSACTION_STATUS_INFO4: "When order checking is completed",
  TRANSACTION_STATUS_INFO5: "When order packaging is completed",
  TRANSACTION_STATUS_INFO6: "When the order delivery is completed (Cargo, Hand Delivery)",
  TRANSACTION_STATUS_INFO7: "When the order is deleted by polypus",
  TRANSACTION_STATUS_INFO8: "Under Notification",
  TRANSACTION_STATUS_INFO9: "return",

  SHOW_PRODUCT_IN_CURRENCY: "Show Product In Currency",

};

var cargoEn = {
  SEARCH_ORDER: "Search Order",
  UPDATE_ORDER_STATUS: "Update Order Status",
  ORDER_COUNT: "Order Count",
  CARGO_INFO1: "To update the order statuses on the selected channel, you must select at least one order.",
  ORDER_NU: "Order No",
  ORDER_NOTE: "Order Note",
  NOTE: "Note",
  PREPARE_IN_CARGO: "Orders in Cargo", // Eklendi
  OFFER_NU: "Offer Nu",
  USERNAME: "Username",
  BASIC_INFORMATION: "Basic Information",
  CUSTOMER_NAME: "Customer Name",
  CUSTOMER_EMAIL: "Cari E-Mail",
  PACKAGE_CODE: "Package No",
  ITEM_QUANTITY: "Item Quantity",
  ADD_NEW_CUSTOMER: "Add New Customer", // Eklendi
  CARGO_PROCESSES: "Cargo Processes",
  ACTIONS: "Actions",
  ACTION: "Action",
  REMOVE_THE_TABLE: "Remove From The Table",
  ERP_NUMBER: "ERP No",
  ERP_LOGS: "ERP Logs",
  REFERENCE: "Reference",
  NO_ERP_LOG_MESSAGE: "No ERP Log Message",
  SHIPMENT_TRACKING_CODE: "Shipment Tracking Code",
  SHIPMENT_LOG_MESSAGES: "Shipment Log Messages",
  NO_SHIPMENT_LOG_MESSAGES: "No Shipping Log Message",

  SHIP_IT: "Ship It",
  CREATED_DATE: "Created Date",
  AMOUNT: "Amount",
  CREATOR: "Creator",
  PRINT_CARGO_BARCODE: "Print Cargo Barcode",
  CREATE_CARGO_PDF: "Create Cargo PDF",
  CREATE_PDF: "Create PDF",
  OFFER_PROCESSES: "Offer Processes",
  NO_INSTALLED_FILE_FOUND: "No Installed File Found",

  SELECT: "Select",
  PRINT_CARGO_REPORT: "Print Cargo Record",
  SELECT_DATE_GIVE_CARGO_FIRM: "Shipping Date",
  CARGO_STATUS: "Cargo Status",
  CARGO_MESSAGE: "Cargo Message",
  CARGO_INFO: "No Cargo Info",
  ORDER_ID: "Order ID",
  SELECT_CARGO_FIRM: "Select Cargo Company",
  CARGO_FIRM: "Cargo Firm",
  CARGO_PRICE: "Cargo Price",
  CHOOSE_CARGO_FIRM: "Choose Cargo Firm",
  SERVICE_PRICE: "Service Price",
  DELIVER: "Deliver", // Eklendi
  DELIVER_TO_SELECTED: "Deliver to Selected", // Eklendi
  NO_ORDER_IN_PACKAGE_PROCESS_TITLE: "No Orders in Packaging Process", // Eklendi
  NO_ORDER_IN_PACKAGE_PROCESS_BODY: "You can create a new order from the Stock Movement section", // Eklendi
  QUALITY_CONTROL_INFO_TITLE: "Quality Control", // Eklendi
  QUALITY_CONTROL_INFO_BODY: "Please enter the barcode of the order you want to check", // Eklendi
  INVOICES_INFO_TITLE: "Order Search Section", // Eklendi
  INVOICES_INFO_BODY: "You can continue your process with the Order No", // Eklendi
  CHANGE_SHELF_INFO_TITLE: "Change Shelf Section", // Eklendi
  CHANGE_SHELF_INFO_BODY: "You can continue by selecting which warehouse you will operate in", // Eklendi
  PRINT_UNIQUE_CARGO_BARCODE: "Print Unique Cargo Barcode",
  PRODUCT_ERP_CODE: "ERP Code",
  START_ADD_PRODUCT_WITH_CAMERA: "Add Product with Camera",
  SEARCH_PRODUCT_WITH_CAMERA: "Search Product with Camera",
  SEARCH_PRODUCT_WITH_BARCODE: "Search Product with Barcode",
  NOT_EXIST_CONTROL_OF_STEP: "No control of the step exists yet" // Eklendi
};

var customersEn = {
  CUSTOMER: "Customer",
  CREATE_CUSTOMER: "Create Customer",
  CUSTOMER_NOT_SELECTED: "Customer Not Selected",
  CUSTOMER_CODE: "Customer Code",
  EXAMPLE_CUSTOMER_CODE: "customer_code",
  SELECT_CUSTOMER: "Select Customer",
  ONLY_ACCOUNTING_INTEGRATIONS : 'Only in accounting integrations',
  MODEL: "Model",
  MORE:'more',
  LESS:'less',
  REMOVE_PRODUCT_CART:"Remove Product from Cart",
  COMPLETE_CART:"Complete Cart",
  FINAL_PAYMENT_ALERT: "Final Payment Alert",
  OPEN_REQUEST: "Open Requests",
  PRODUCT_TOTAL_OF_ASSOCIATED_OFFER: "Product Total of Associated Offers",
  COUNT_OF_PURCHASE_OFFER: "Count of Purchase Offer",
  EXPIRED: "Expired",
  UNPAID_INFO: "You have an unpaid invoice. <br> The service you have received will be terminated after 12 hours if you do not pay.<br> The opening/closing fee will be reflected on your next invoice.",

  PRODUCT_CURRENT_HISTORY: "Product Current History (This Year)",
  TOTAL_SALES: "Total Sales",
  TOTAL_QUANTITY: "Total Quantity",
  QTY: "Qty.",
  LAST_SALES_QTY: "Sales Quantity in the Last {{months}} Months",
  LAST_ORDER_QTY: "Order Quantity in the Last {{months}} Months",
  EDIT_ORDER: "Edit Order No. {{sipNo}}",
  CARGO_TRACKING: "Cargo Tracking",
  NOT_YET_ACCESSIBLE: "Not Yet Accessible",
  PROCESSES: "Processes",
  BEGINNING: "Beginning",
  FINISH: "Finish",
  PARTIAL_RETURN: "Partial Return",

  SALES_STATISTICS: "Sales Statistics",
  PURCHASING_STATISTICS: "Purchasing Statistics",
  CUSTOMER_NAME_OR_FIRM_LABEL: "Name Surname / Company Label",
  EMAIL: "E-mail",
  PHONE_NUMBER: "Phone",
  INVOICE_ADDRESS: "Invoice Address",
  ADDRESS_TYPE: "Address Type",
  PERSONAL: "Individual",
  CORPORATE: "Institutional",
  IDENTITY_NU: "Identity No",
  TAX_NU: "Tax No",
  NO_MISSING_PRODUCTS_TITLE: "No Missing Products",
  NO_MISSING_PRODUCTS_BODY: "Currently, there are no missing products. You can add products from the Add Product section.",
  TAX_DEP: "Tax Department",
  TRADE_REGISTRATION_ID: "Trade Registration ID",
  COMPANY_LABEL: "Company Label",
  NAME_SURNAME: "Name Surname",
  GSM_NU: "Mobile Phone",
  ADDRESS: "Address",
  ADDRESS_DETAILS: "Address Details",
  CITY: "City",
  TOWN: "Town",
  NEIGHBOURHOOD: "Neighbourhood",
  ZIP_CODE: "Zip Code",
  SAME_ADDRESS_INFO: "My delivery address is the same as my billing address",
  SAVE: "Save",
  SAVE_RECOMMENDED_LOCATIONS: "Add Recommended Locations",
  DELIVERY_ADDRESS: "Delivery Address",
  CUSTOMERS: "Customers",
  CLEAR: "Clear",
  CLEAR_UPPERCASE: "CLEAR",
  SEARCH: "Search",
  CUSTOMER_NAME: "Customer Name",
  PRINT: "Print",
  ACTIONS: "Actions",
  CONTACT: "Contact",
  FOOTER_MESSAGE: "Footer Message",
  SHOW_MISSING_PRODUCTS: "Show Missing Products",
  EDIT: "Edit",
  DELETE: "Delete",
  CUSTOMER_INFO1: "Used for searching, adding, and editing customer accounts",
  CUSTOMER_INFO2: "Searches by customer code, username, customer name",
  CUSTOMER_EMPTY_SEARCH_MSG: "No customer information found for the search.",
  NO_RECORD_INFO: "There are no records.",
  RETURN_TRANSACTION: "Create Return",
  CANCEL_TRANSACTION: "Create Refund",
  MISSING_QUANTITY_REPORT: "Report Missing Quantity",
  MISSING_QUANTITY : "Missing Quantity",
  SAME_QUANTITY: "Same Quantity",
  OFFER_INFO_TITLE: "Create Offer",
  OFFER_INFO_BODY: "You can create your offer with the information you enter above",

  NO_PRODUCT_TABLE: "There is no product on the table",
  GALLERY: "Gallery",
  SELECTED_PHOTO: "Selected Photo",
  CREATE_SHORTCUT: "Create a shortcut to this page",
  RIGHTBAR_INFO: "Note: Help videos are from the previous Polypus version. New version videos will be released soon.",
  SHOW_VIDEO_HELP: "Show Video Help",
  CHANGE: "Change",
  CHANGE_LANGUAGE: "Change Language",
  MOVEMENTS: "Movements",
  STOCK_ORGANIZATION: "Stock Organization",
  ADMIN: "Admin",
};

var dashboardIndexEn = {
  TOTAL_ORDERS: "Total Orders",
  COLLECTION: "Collection",
  HANDLING: "Handling",
  DELIVERY: "Delivery",
  TOTAL_ORDER_QTY: "Total Order Quantities",
  PREPARATION_STEP: "Preparation Step",
  CONTROL_STEP: "Control Step",
  CARGO_PROCESSES: "Cargo Processes",
  ORDER_ITEM_QUANTITY: "Item Quantity of Order",
  GENERAL_VIEW: "General View",
  STOCK_TURNOVER_RATE: "Stock Turnover",
  TURNOVER_RATE: "Turnover",
  MOST_ACTIVE_PRODUCTS: "The Most Moving Products",
  DATA_NOT_READY_YET: "Data for this day is not ready yet",
  NOT_ENOUGH_DATA_FOR_REPORT: "You don't have enough data for the report",
  THE_MOST_MOVING_PRODUCTS: "The Most Moving Products",
  REPORT_CREATE_TIME: "Report Generation Time",
  REPORT_BEING_PREPARED: "Your report is being prepared. It will be displayed here when finished",
  CLICK_GENERATE_REPORT_NOW: "Click to generate the report now",
  HIGHEST: "Highest",
  LOWEST: "Lowest",
  DAILY: "Daily",
  DAILY_REVENUE: "Daily Revenue",
  REVENUE: "Revenue",
  MONTH: "Month",
  WEEK: "Week",
  DAY: "Day",
  YEAR: "Year",
  NOW_YEAR: "This Year",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  RATE_OF_CHANGE: "Rate Of Change",
  NO_REPORT_DATA_YET: "No report data yet",
  BETWEEN_DATES: "Between Dates",
  LAST_DAY: "Last Day",
  LAST_WEEK: "Last Week",
  THIS_WEEK: "This Week",
  LAST_MONTH: "Last Month",
  THIS_MONTH: "This Month",
  LAST_YEAR: "Last Year",
  THIS_YEAR: "This Year",
  ALL_TIME: "All Time",
  TIME_PERIOD: "Time Period",
  BRANCH_REVENUE: "Branch Revenue",
  DATE: "Date",
  BRANCH: "Branch",
  PAYMENT_TYPE: "Payment Type",
  PREPARED_PRODUCT_COUNT: "Count of Prepared Products",
  PREPARED_PRODUCT_QTY: "Quantity of Products Prepared",
  SERVICE: "Service",
  STATUS: "Status",
  ACTUAL_STATUS: "Actual Status",
  ELASTIC_SEARCH_ENGINE: "Elastic Search Engine",
  COMPLETE: "Completed",
  VISITED_SHELF:'Visited Shelf',
  COLLECTED_PRODUCT: "Collected Product",
  COLLECTED_QUANTITY: "Collected Quantity",
  PREPARATION_TIME: "Preparation Time",
  ACT_NO: "Action No",
  PASSIVE: "Passive",
  RECORD_COUNT: " number of records",
  WHOLESALES: "Wholesale",
  SELECT_MONTH: "Select Month",
  JAN: "January",
  FEB: "February",
  MARCH: "March",
  APR: "April",
  MAY: "May",
  JUN: "June",
  JULY: "July",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  DECEMBER: "December",
  TOTAL_AMOUNT: "Total Amount",
  GROSS_TOTAL: "Gross Total",
  GROSS_VAT: "Brüt KDV",
  GROSS_TOTAL_WITH_VAT: "Gross Total With Vat",
  NET_SUBTOTAL: "Net Subtotal",
  SEARCH_ENGINE: "Search Engine",
};

var integrationPageEn = {
  SIPARISLER: "Orders",
  INTEGRATION_FILTER_1: "Start Date The Task Runs",
  SAAT_SECINIZ: "Select Hour",
  INTEGRATION_FILTER_2: "End Date The Task Runs",
  SELECT_CHANNEL: "Select Channel",
  ORDER_UPDATED_START_DATE_ENTER: "Update Start Date",
  ORDER_UPDATED_END_DATE_ENTER: "Update End Date",
  SELECT_ORDER_STATUS: "Select Order Status",
  SALES_CHANNEL: "Sales Channel",
  USER: "User",
  OFFICIAL: "Official",
  DOWNLOAD_CENTER: "Download Center",
  ORDER_START_DATE: "Starting Date",
  ORDER_END_DATE: "End Date",
  TRANSFERRED_ORDER_COUNT: "Count of Orders Transferred",
  ORDER_STATUSSES: "Order Statuses",
  WORKING_TIME: "Working Hours",
  WORKING_ELAPSED_TIME: "Operation Time",
  KILL_PROCESS: "Kill Process",
  STOCK: "Stock",
  SELECT_DATE: "Select Date",
  SELECT_OPERATOR: "Operator",
  PREV_SELECT_DATE: "Before the Selected Date",
  PREV: "Previous",
  AFTER: "Next",
  PREV_PRODUCT: "Prev Product",
  AFTER_PRODUCT: "Next Product",
  NEXT_SELECT_DATE: "After the Selected Date",
  SELECT_CURRENT_DATE: "Selected Date",
  SELECT_DATE_FIELD_PROPERTIES: "Date Property",
  BY_CREATED_DATE: "By Created Date",
  BY_UPDATE_DATE: "By Updated Date",
  BY_STOCK_UPDATE_DATE: "By Stock Update Date",
  BY_PRICE_UPDATE_DATE: "By Price Update Date",
  STOCK_START_DATE: "Start Date",
  STOCK_END_DATE: "End Date",
  OPERATOR: "Operator",
  DATE_PROPERTIES_FIELD: "Date Type",
  SESSION_ID: "Session ID",
  TRANSFERRED_PRODUCT_COUNT: "Count of Stock Transferred",
  UPDATE_CURRENCY: "Update Currency",
  CURRENCY: "Currency",
  CURRENCY_TO_CONVERT: "Currency to Convert",
  CURRENCY_RATE: "Currency Rate",

  EXCHANGE_RATE: "Exchange Rate",
  CURRENCY_DECIMAL: "Currency Decimal",
  INTEGRATION_INFORMATION: "Integration Information",
  PROVIDER_PRODUCT_ID: "Integration ID",
  TRANSFER_MESSAGE: "Transfer Result",
  PREPARER: "Preparer",
  CREATED_BY: "Created By",
  MARKET_PLACE: "Marketplace",
  LAST_STATUS: "Last Status",
};

var batchOperationsEn = {
  UPLOADED: "Uploaded",
  IMPORT_TRANSACTION: "Import Stock Movements",
  IMPORT_PRODUCT_SHELF_RELATION: "Import Product-Shelf Relation",
  IMPORT_SHELF: "Import Shelf",
  RETURN_TO_SHELF: "Return To Shelf",
  UPLOAD: "Upload",
  BROWSE: "Browse",
  SELECTED_FILE: "Selected File",
  PLEASE_WAIT_FOR_PROCESS: "Please Wait... Being processed...",
  DOWNLOAD_IMPORT_REPORT: "Click on file name to download import reports.",
  DOWNLOAD_PREVIEW_REPORT: "Please click on the file name to see the analysis of the uploaded file.",
  IMPORT_SHELF_INFO1: "In this section, sub locations can be defined to the system with excel.",
  CLICK: "Click",
  IMPORT_SHELF_INFO2: "In this section, the relations between sub locations and stocks can be defined to the system with excel.",
  IMPORT_SHELF_INFO3: "In this section, a process list is created using excel.",
  DOWNLOAD_SAMPLE_EXCEL_FILE: "Download Sample Excel File",
  UPLOAD_COMPLETED_DOWNLOAD_REPORT: "transaction created. Click on file name to download import reports.",
  SAMPLE_DATA_SET: "Sample Data Set",
  DATA_PATTERN: "Data Pattern",
};

var invoicesEn = {
  ORDERS: "Orders",
  CHANNEL_ORDERS: "Channel Orders",
  STATISTICS: "Statistics",
  CHOOSE_USER: "Select User",
  SHOW_STATISTIC: "View Statistic",
  TRANSFER_EXCEL_BUTTON_NAME: "Export orders with missing stocks to excel",
  TRANSFER_EXCEL: "Transfer to Excel",
  ORDER_STATUS_HISTORY: "Order Status History",
  NOT_TRANSFERRED_ERP: "Not Transferred To ERP",
  MANUAL_TRANSFER_ERP: "Manual Transfer to ERP",
  BALANCE_PRODUCT_ORDERS: "Orders with Missing Stock",
  BALANCE_PRODUCT_NOTIFY_INFO: "A record will be created for the quantities not met in the list and the unmet orders will be removed from the list.",
  BALANCE_PRODUCT_NOTIFY_INFO2: "Sistemde raflarda listedeki ihtiyacı karşılayacak kadar ürün gözüküyor. Bu sebepten oluşturulan bildirim otamatik sayım belgesine de dönüşecektir.",
  NUMBER_OF_LIST_PRODUCT: "Number of List Products",
  QUANTITY_ON_SHELVES: "Quantity on Shelves",
  LAST_DEMAND_DATE: "Last Demand Date",

  ORDER_CURRENCY: "Order Currency",
  SELECTED_CURRENCY: "Local Currency",

  REPORT: 'Report',
  MISSING_REPORT_CONFIRM: "Missing Report Confirm",
  POSSIBLE_STOCK_ERROR: "Possible Stock Error",
  POSSIBLE_STOCK_ERROR_INFO: "Although the product stock is more than the order quantity, it is reported as incomplete.",
  SEND_TO_CUSTOMER: "Will be Sent to Customer",

  PLACED_ON_THE_SHELF_AGAIN: "Will be Placed on the Shelf Again",
  RESERVE_WILL_BE_REMOVED: "Customer Reserve Will Be Removed",
  SENT_TO_CUSTOMER_LATER: "Will Be Sent to Customer Later",

  SELECT_STATUSSES_ORDER: "Select Order Statuses",
  SEARCH_STATUS: "Search Status",
  REMOVE_STATUS: "Remove",
  SELECT_ALL: "Select All",
  TAKE_ALL: "Take All",
  WAS_SELECT_STATUS: "Status Selected",
  ALL: "All",
  PRODUCT_DEFINED_FULL_SHELF: "Product Defined Full Shelf",
  PRODUCT_DEFINED_EMPTY_SHELF: "Product Defined Empty Shelf",
  PRODUCT_UNDEFINED_SHELF: "Product Undefined Shelf",
  TRANSFER_TO_ERP: "Transfer to ERP",
  ACCOUNTING: "Accounting",
  ACCOUNTING_TRANSFERS: "Accounting Transfers",
  SHIPMENT_TRANSFERS: "Shipment Transfers",
  SHIPMENT_CODE: "Shipment Code",
  SHIPMENT_STATUS:"Shipment Status",
  TRY_AGAIN : 'Try Again',
  WAITING_FOR_CARGO: "Waiting for Cargo",
  CARGO_COUNTED: "Counted",
  MOBILE: "Mobile",
  DELIVERED_CARGO_COMPANY: "Delivered to the Cargo Company",
  SHIPMENT_LIST_INFO1:"Shipping Processes",
  SHIPMENT_LIST_INFO2:"The cargo integration of your orders and possible errors during the process are tracked.",

  SHIPMENT_LIST_INFO5:"Deliver Cargo",
  SHIPMENT_LIST_INFO6:"Delivery list of the counted orders to the cargo company",
  THIS_DEFAULT_RECIPE_FOR_THIS_PRODUCT : 'This is the default recipe for this product',
  DEFAULT_RECIPE : 'Default Recipe',
  ACTIVE_PRODUCT_RECIPES: 'Active Product Recipe',
  NO_DEFAULT_RECIPE: 'No Default Recipe',

  TAKE_TO_ACCOUNTING: "Send to Accounting",
  ACTION: "Action",
  ORDER_LINE_COUNT: "Order Line Count",
  TRANSFER: "Transfer",
  ORDER_STATUS: "Order Status",
  ORDER_DATE: "Order Date",
  GENERAL_TOTAL: "Total",
  TOTAL: "Total",
  SHOWING: "Showing",
  EMAIL_NATIVE: "E-mail",
  ORDER_TYPE: "Order Type",
  BALANCE_PRODUCT_NOTIFY: "Missing Stock Notification",
  CREATE_STOCK_ACTION: "Create Stock Movement",
  ORDER_TRANSFERED_ERP: "The order has been transferred to the ERP.",
  ORDER_NOT_TRANSFERED_ERP: "The order could not be transferred to the ERP.",
  NOT_QUEUED: 'Not Queued',
  COMPLETE_TIME:'Complete Time',
  VISITED_SHELF_QUANTITY:'Visited Shelf Quantity',
  TOTAL_PRODUCT_SORT:'Total Product Range',
  CONNECTION_LOST: "Unable to connect to server",
  SYSTEM_WAITING_TIME: "System Waiting Time",
  DOWNLOADING: "Downloading",
  LOADING: "Loading",
  C_S: "C.S",
  V_S: "V.S",
  LAST_UPDATE: "Last Update",
  NO_SHELF_CODE: "No shelf code",
  RECIPE: "Recipe",
  PRODUCT_RECIPES: 'Product Recipes',
  INCLUDED_RECIPES: 'Included Recipes',
  SHOW_PRODUCT_CARD: "Show Product Card",
  PRINT_PRODUCT_BARCODE: "Print Product Barcode",
  ENTRY_STOCK_QTY: "Entry Stock Qty",
  CLONE_STOCK: "Clone Stock Card",
  ERP_INFO: "Shows Raw Data from ERP System",
  ERP: "ERP",
  SUPPLIER_PRODUCT_CODE: "Supplier Product Code",
  PRODUCT_TAGS: "Product Tags",
  OFFER: "Offer",
  OFFER_TYPE: "Offer Type",
  QTY_OFFER_NOT_YET_FINAL: "Quantities of offers not yet finalized",

  OFFER_PRODUCT_INFO: "Quantities in Active Offers",
  OFFER_PRODUCT_INFO2: "Quantities for this product in offers that have not yet been finalized",

  COUNTING: "Counting",
  COUNT_PERIOD: "Count Period",
  COUNT_PERIOD_NOT_DEFINED: "Counting Period is not defined",
  ONCE_A_DAY: "once a day",

  STOCKTAKING_INFO: "Stock Count",
  STOCKTAKING_INFO2: "Stock Count",
  STOCKTAKING_PRODUCT_INFO_EMPTY: "No counts have been created for this product yet",

  AWAITED: "Awaited",
  NET: "Net",
  FAULTY: "Faulty",
  ACTUAL: "Actual",

  LOCATION_INFO: "It is not available in warehouses yet.",

  INVOICES_INFO: "You can query and manage your orders transferred with the form above. Please expand your search criteria.",
};

var locationEn = {
  LOCATIONS: "Locations",
  CREATE_LOCATION: "Create Location",
  LOCATION_NAME: "Location Name",
  EXAMPLE_LOCATION_NAME: "location_name",
  LOCATION_TYPE: "Location Type",
  SELECT_LOCATION_TYPE: "Location Type",
  SELECT_REMOVAL_TYPE: "Reduction Type",
  REMOVAL_TYPE: "Reduction Type",
  NOT_FOLLOWING: "Not followed.",
  CHOOSE_USER: "Select User",
  SHELF_CODE: "Sub Location Code",
  SHELF: "Sub Location",
  SHELF_NAME: "Sub Location Name",
  WAREHOUSE_NAME: "Location",
  EMPTY_SHELF: "Empty Location",
  USED_SHELF: "Used Sub Location",
  NO_PRODUCT_IN_SHELF: "Non-Product Defined Sub Location",
  SUB_LOCATION_STATUS: "Sub Location Status",
  SEARCH: "Search",
  SAVE: "Save",
  CANCEL: "Cancel",
  ORDER_CANCEL: "Cancel",
  GROUP_NAME: "Group Name",
  PACKAGE_DETAIL: "Package Detail",
  PRODUCT_IMAGE: "Product Image",
  SET_AS_DEFAULT: "Set as default",
  SPECIAL_ELECTION: "Special Election",
  IF_SELECTED_INFO: "If selected, the total stock area will be reflected in your channel.",
  MAKE_ACTIVE_PASSIVE: "Make Active / Passive",
  RETURN_PROCESS : "Return Process",
  ARE_YOU_SURE: "Are you sure?",
  START_RETURN_PROCESS_INFO: "You are about to start the return process of the order",

  START_RETURN_PROCESS_INFO_ORDER: "This action will initiate the cancellation/refund process of all transactions in the order. Depending on the status of the actions, the goods acceptance process or the place on the shelf action will be created.",
  START_RETURN_PROCESS_INFO_ORDER_PARTIAL: "This action will initiate the cancellation/refund process of all transactions in the order. Depending on the status of the actions, the goods will be transferred to the acceptance process or the order to the return processes section.",
  START_RETURN_PROCESS_INFO_ACTION: "This process will start the cancellation/refund process of the warehouse transaction. Depending on the status of the action, the goods acceptance process will start or a place on the shelf action will be created.",
  START_RETURN_PROCESS_INFO_ACTION_PARTIAL: "This process will start the cancellation/refund process of the warehouse transaction. Depending on the status of the action, the goods acceptance process will begin or the order will be moved to the returns processes section.",

  STOPS_THE_CAMERA: "Stops The Camera",

  ORDER_EDITED:"Order Edited",

  ISSUE_DETAIL: "Issue Detail",
  ENTER_LOT_SERIAL: "Enter Lot/Serial number",
  WITHOUT_WAREHOUSE_INFO: "Show lot/serial numbers without warehouse assignment.",
  LOT_SERIAL_IN_STOCK: "It shows the lot and serial numbers in stock.",
  SERIAL_LOT_BY_LOCATION: "Serial/Lot Status by Location",
  LOT_SERIAL_NOT_DEFINED_INFO: "No lot/serial are defined in the selected warehouse for this product",
  ACCOUNTING_MANUAL_TRANSFER_APPROVAL: "Accounting Manual Transfer Approval",
  STOCKMOVEMENT_CONFIRM_INFO: "Stock movement will be transferred to accounting. Do you want to confirm?",
  ENTER_ISSUES: "Those Entering Issues",
  NOT_ENTER_ISSUES: "Those Not Included in Issues",
  NO_RESULT: "No Result",
  NEW_BRANCH_TITLE: "Add Company",
  BRANCH_NAME: "Company Current Title",
  BRANCH_ADDRESS: "Company Address",
  BRANCH_BANKS: "Company Bank Information",
  DEFAULT_BRANCH: "Set as default company",
  SELECTED: "Selected",
  NOT_SHELVES: "There Are No Shelves Defined",
  TAKING_SHELF_CODE: "Taking Shelf Code",
  CHANGE_SHELF_CODE: "Change Shelf Code",
  SHELF_CHANGE_ALL: "All",
  SHELF_CHANGE_ACCEPT: "Shelf Change",
  LOCATION_STOCKTAKING: "Location Stocktaking",
  SHELF_ORDER_NU: "Sub location Order Nu",
  SHELVES: "Sub Locations",
  SHELF_DETAIL: "Shelf Detail",
  CREATE_SHELF: "Create Sub Location",
  SHELF_OPERATION: "Shelf Operations",
  SHELF_UP: "Shelf Up",
  PRODUCT_INFO: "Stock Information",
  PRODUCT_CODE: "Stock Code",
  ENTER_CRITERIA_SEARCH_SHELF: "Enter criteria for search sub location",
  PRODUCT_VARIATION: "Stock variation",
  ENTER_CRITERIA_SEARCH_PRODUCT: "Enter stock search criteria",
  FILTER: "Filter",
  PRODUCT_SHELF_RELATION: "Stock-Sub Location Relation",
  TRANSPORTERS: "Transporters",
  TRANSPORTER_SECTIONS: "Section of Transporter",
  TRANSPORTER_NAME: "Transporter Name",
  SECTION_NAME: "Section Name",
  SELECT_TRANSPORTER: "Select Transporter",
  WAREHOUSE: "Warehouse",
  WAREHOUSES: "Warehouses",
  WAREHOUSE_UPDATED: "Warehouse Updated",
  SHELF_UPDATED: "Shelf Updated",
  SHELF_CREATED: "Shelf Created",
  CONFIRM: "Confirm",
  SHIPPING_INFO: "You can use order number, action number, package code and cargo code. When you paste the barcode or scan it with the terminal, the cargo shipping process of the order will be confirmed.",
  CONFIRM_ALL: "Confirm All",
  DELIVER_ALL: "Deliver All",
  NOT_CONFIRM: "Do Not Confirm",
  INCOMPLETE:'Incomplete',
  GETTING_READY: "Getting Ready",
  EARNINGS: "Earnings TL",
  UPDATE_NOW:'Updated now',
  UPDATE_SECOND_AGO:'Updated {{second}} seconds ago',
  UPDATED_MINUTE_SECOND: "Updated {{minutes}} Minutes {{seconds}} Seconds ago",
  SCAN_TYPE_MANUAL: "Manual",
  SCAN_TYPE_TERMINAL: "Handheld Terminal",
  SCAN_TYPE_CAMERA: "Camera",
  CONFIRM_MESSAGE: "Confirm Message",
  CONFIRM_MESSAGE_INFO: "Do you confirm that when you update the receiving, it will deactivate the logins of other users and activate this login?",
  CARGO_MESSAGES: "Cargo Messages",
  NO_MESSAGES: "No Message",
  CONFIRM_MESSAGE_BODY: "Are you sure you want to delete this line??",
  PENDING_SUBMISSION_CONFIRMATION: "Pending Submission Confirmation",
  SHIPMENT_CONFIRMED: "Shipment Confirmed",
  SHELF_DELETED_MESSAGE: "Sub location deleted.",
  SHELF_PRODUCT_MATCH_MESSAGE: "Sub location and product matched.",
  SHELF_PRODUCT_RELATION_DELETE: "Sub location and product relation deleted.",
  SELECT_ONE_CRITERIA: "Please select at one criterion.",
  SECTION_DEFINED: "Section defined.",
  SECTION_DELETED: "Section deleted.",
  TRANSPORTER_DEFINED: "Transporter for order preparing defined.",
  TRANSPORTER_DELETED: "Transporter for order preparing deleted.",
  REPORT_NAME: "Report Name",
  SHELF_TYPE: "Sub Location Type",
  PACKAGING: "Packaging",
  PACKAGING_DETAIL: "Packaging Detail",

  LOCATION: "Location",
  UNDEFINED: "Undefined",
  BARCODE: "Barcode",
  PROCESS: "Processed",
  PROCESSED: "Processed",
  LAST_PROCESS: "Last Process",
  WIDTH: "Width",
  HEIGHT: "Height",
  DEPTH: "Depth",
  WEIGHT: "Weight",
  DOWNLOAD: "Download",
  PRODUCT_COUNT: "Product Count",
  VOLUME: "Volume (w X h X d)",
  WAREHOUSE_LAYOUT_GUIDE: "Location Layout Guide",
  CHANNEL: "Channel",
  CREATE_GUIDE_REQUEST: "Create Guide Request",
  WHATIS_LOCATION_GUIDE: "What is Location Layout Guidelines?",
  LOCATION_GUIDE_INFO: "It is a tool that will help you organize your location by analyzing your order data. To create a guide request, select a channel and create a guide request..",
  REQUEST_DATE: "Request Date",
  RESPONSE_DATE: "Response Date",
  TRANSPORTER_ORDER_PREP_MSG: "User selection is not mandatory",
  TRANSPORTER_INFO_MSG: "You cannot use this field because bulk order definition is made to the transporter.",
  PACKING_SHELF: "Fixed(Packing Rack)",
  PACKING_SHELF_INFO1: "Let the shelf name be the barcode",
  PRODUCT: "Product",
  PRODUCT_DEFINE_METHOD: "Product Define Method",
  PRODUCT_DEFINE_TO_SHELVE: "Define Product to Shelf",
  BARCODE_NU: "Barcode No",
  GET: "Bring",
  PRODUCT_ID: "Product Id",
  PRODUCT_NAME: "Stock Name",
  VARIATION_PRODUCT: "Variation Product",
  RECIPE_INFO: "Recipe Products",
  VARIATION: "Variation",
  SERIAL_NUMBER: "Serial Number",
  NOTHING: "Nothing",
  LOCATION_MSG: "If there is a product-sub location relationship but there is no stock, those sub locations are listed",
  WAREHOUSE_ANALYSIS: "Warehouse Product Compliance Analysis",
  RESPONSE_ITEM_COUNT: "Response Item Count",
  RESPONSE_ENOUGH: "Compliance",
  REQUEST_QUANTITY: "Request Quantity",
  RESPONSE_QUANTITY: "Response Quantity",
  PRIORITY: "Priority",
  RESPONSE_COUNT: "Response Count",
  REQUIRED: "Required",
  NOT_REQUIRED: "Not Required",
  PRODUCT_SEARCH: "Search Product",
  APPROVE: "Approve",
  APPROVED: "Approved",
  THOSE_AWAITING_APPROVAL: 'Those Awaiting Approval',
  UNPLANNED: "Unplanned",

};

var loginEn = {
  COPYRIGHT_RESERVED: "All rights reserved.",
  ENTER: "Enter",
  PASSWORD: "Password",
  EMAIL: "E-mail",
  LOGIN: "Login",

  USER_CREATE: "User Create",
  TOTAL_NUMBER_RECORDS: "Total Number of Records",
  TOTAL_NUMBER_PAGES: "Total Number of Pages",
  NUMBER_RECORD_PAGES: "Number of Records on the Page",
  POWERS: "Powers",
  CREATE_AUTHORIZATION_GROUP: "Create Authorization Group",
  AUTHORITY_NAME: "Authority Name",
  NO_AUTHORITY: "You do not have authority to approve",
};

var batchOperationsForTsoftEn = {
  IMPORT_PRODUCTS_AND_FILTERS: "Add Filters to Stock",
  IMPORT_PRODUCT_FILTER_FOR_TSOFT_INFO1:
    "From this section, the product filters will be defined in the T-Soft B2C channel you have selected with excel. To download the sample excel file",
  UPLOAD: "Upload",
  SELECT_FILE: "Select File",
  DOWNLOAD: "Download",
  UPLOAD_COMPLETED: "Upload Completed",
  DOWNLOAD_REPORT: "Download Report",
};

var actionEn = {
  ISSUE: "Issue",
  ISSUES: "Issues",
  ISSUE_NO: "Issue No",
  ISSUE_STATUS: "Issue Status",
  ONLY_ANOMALY_ISSUE_STATUS: "Only Anomaly Issue Status",
  ISSUE_CREATE: "Issue Create",
  SHELF_EXIT_ISSUE_CREATE: "Shelf Exit Issue Create",
  SHELF_ENTRY_ISSUE_CREATE: "Shelf Entry Issue Create",
  FAULTY_ISSUE_CREATE: "Faulty Issue Create",
  ISSUE_LIST_INFO1: 'Issue Lists',
  ISSUE_LIST_INFO2: 'No issue was found matching the selected criteria. You can search by changing the filter fields',
  ISSUE_CREATE_ACTION_LIST_INFO1: 'Orders to be added to issue',
  ISSUE_CREATE_ACTION_LIST_INFO2: 'List of orders to be added to issue. You can search by changing the record fields',
  PREPARE_LIST_INFO1: "Creating a new list for selected orders",
  UPDATE_LIST: "Issue Update",
  SHIPMENT_LIST_INFO3:"Submission Count List",
  SHIPMENT_LIST_INFO4:"It is the final count list of orders to be shipped.",
  SHIPPING_ITEMS : 'Shipping Confirmation',
  CREATE_AN_OFFER: "Create An Offer",
  CREATE_STOCK_MOVEMENTS: "Create Stock Movements",
  STOCK_MOVEMENTS: "Stok Movements",
  STOCK_MOVEMENT: "Stock Movement",
  CARGO_TYPE: "Cargo Type",

  STOCK_MOVEMENT_DETAIL: "Stock Movement Detail",
  CUSTOMER_CODE: "Customer Code",
  WAREHOUSE_FOR_ACTION: "From Location",
  WAREHOUSE_FOR_ACTION2: "To Location",
  ACTION_TYPE: "Movement Type",
  EXAMPLE_ACTION_TYPE: "action_type",
  TEMPLATE_NAME: "Template Name",
  STEP_SELECT_TEMPLATE: "Select Template",
  TEMPLATE_EDITING: "Template Editing",
  COLUMN: "Column",
  STEP_SELECT_EXTRA_FIELD: "Extra Fields",
  STEP_SELECT_EXTRA_FIELD_INFO: "Extra fields indicate other fields needed based on the selections made",
  SAVE_TEMPLATE: "Save Template",
  EXAMPLE_ACTION_TYPE_INFO: "The transaction types that can be entered are listed below.",
  MY_UPLOADS: "My Uploads",
  FILE_NAME: "File Name",
  REGULATION_STOCK_ENTRY: "Regulation Stock Entry",
  REGULATION_STOCK_EXIT: "Regulation Stock Exit",
  SHOULD_BE_ENTERED1: "It must be entered as USD/EUR/TL.",
  SHOULD_BE_ENTERED2: "It should be entered as -1/-2/-3. (-1: Money Order / EFT, -2: Credit Card, -3: Cash)",
  RETAIL_SALE: "Retail Sale",
  PREVIEW: "Preview",
  EXPORT_TSOFT: "Export to T-Soft",
  SELECT_CHANNEL: "Select Channel...",
  SELECT_TRANSACTION_MODE: "Select Transaction Mode",
  IMPORT_EXCEL: "Excel Batch Operations",
  STEP_FILE_IMPORT: "Upload File",
  ORDER_INFO_MESSAGE: 'Your orders are available in the scheduled tasks section on a timed basis. This is for you to optionally update manually.',
  ORDER_INFO_MESSAGE2: 'Your stocks are available on a scheduled basis in the scheduled tasks section. This is for you to optionally update manually.',
  SCHEDULED_TASKS: 'Scheduled Tasks',
  STEP_PREPARE_TEMPLATE: "Prepare Data",
  STOCK: "Stock",
  B2C_TRANSFER: "B2C Transfer",
  PRODUCT_IDS: "Product Ids",
  FILTER_PARAMS: "Filter Params",
  GET: "Get",
  STEP_UPLOAD_DATA: "Load Data",
  STEP_SERVICE_FILE_HEADER: "There is a Title in the File",
  NEW_ACTION_TYPE: "New Movement Type",
  SAMPLE_LINES_WITHOUT_LOADING: "Sample Lines Without Loading",
  TOTAL_NUMBER_OF_ROWS: "Total Number of Rows",
  NUMBER_OF_ROWS: "Number of Rows",
  CHOOSE_ACTION_TYPE: "Select movement type",
  TEMPLATE: "Template",
  NOT_YET_SAVED: "Not Yet Saved",
  SAVE_SELECTED_SYSTEM: "Save Selected to System",
  REMOVE_SELECTED: "Remove Selected",
  PROGRESS_SUMMERY: "Installation Summary",
  ERROR_MESSAGE: "Error Message",
  ROW_MATCH: "System Equivalent",
  BACK: "Back",
  SEND: "Send",
  NEXT: "Next",
  SHOW_ALL: "Show All",
  SHOW_WAITING_ACTIONS: "Show Only Those Waiting for Transaction",
  TITLE: "Title",
  TITLE_INFORMATION: "Title Information",
  CHOOSE_REFUND_TYPE: "Select refund type",
  INVOICE_NU: "Invoice Nu",
  CHOOSE_DUE_DATE: "Select due date",
  DUE_DATE: "Due Date",
  DAY: "Day",
  CREATOR_USER: "Creator User",
  SELECT_CREATOR_USER: "Select creator user",
  ORDER_PREPARE_USER: "User Who Prepared Order",
  SELECT_ORDER_PREPARE_USER: "Select prepared user",
  TRANSACTION_NOTE: "Transaction Note",
  EXAMPLE_TRANSACTION_NOTE: "transaction_note",
  SHIPPING_TYPE: "Delivery Type",
  SHIPPING_TYPE1: "Cargo",
  SHIPPING_TYPE2: "By Hand",
  TAX_TYPE: "Tax Type",
  ACTION_INFO1: "It must be calculated over the VAT included amount of the movement list.",
  ACTION_INFO2: "In order to change the registration information, you can stop the stock entry and make changes..",
  CONTINUE: "Continue",
  SELECTED_PRODUCTS: "Selected Stocks",
  SELECTED_PRODUCT: "Selected Product",
  SUB_TOTAL: "Sub Total",
  BASKET: "Basket",
  BASKET_AMOUNTS: "Basket Amount",
  BASKET_SUMMARY: "Basket Summary",
  BASKET_DISCOUNT: "Basket Discount",
  VAT_INCLUDED: "VAT included",
  NET_VAT_INCLUDED: "Net VAT included",
  VAT:" VAT",
  VAT_NOT_INCLUDED: "VAT Excluding",
  NET_VAT_NOT_INCLUDED: "Net VAT Not Included",
  OTHER_FILTERS: "Other Filters",
  STOCK_CODE: "Stock Code",
  DISCOUNT: "Discount",
  DISCOUNT_PERCENT: "Discount Rate",
  PRICE: "Price",
  NEW_PRICE: "New Price",
  CASH_AMOUNT: "Cash Amount",
  CREDIT_CARD_AMOUNT: "Credit Card Amount",
  TRANSFER_AMOUNT: "Transfer Amount",

  ORDER_LIST_INFO1: "Since it takes 24 hours after creating a stock transaction, you can only change the offer type.",
  ORDER_LIST_INFO2: "You cannot make edits after 24 hours after creating a stock transaction.",
  ENTER_TRANSACTION_NOTE: "And you can enter Transaction note.",

  QUANTITY: "Quantity",
  SUPPLY: "Supply",
  FREE: "Free",
  ADDITION_FREE_QUANTITY: "Addition Free Quantity",
  HOW_MANY_ARE_FREE: "How Many Are Free",
  WILL_BE_SENT_LATER: "Will Be Sent Later",
  DOCUMENT_STATUS:'Document Status',
  DEPTOR:'Deptor',
  NO_DEPT:'No Dept',
  CREDITOR:'Creditor',
  NOT_SUPPLIED: "Could Not Be Supplied",
  LOT_SERIAL: "Lot/Serial Number",
  CHOOSE_A_LOT_SERIAL: "Select Lot/Serial Number",
  LEAD_TIME_DESC: "Lead Time Description",
  SEARCH_RESULT: "Search Result",
  PRODUCT_FOLLOW: "Stock Following",
  SUB_PRODUCT_FEATURE: "Sub Product Feature",
  STOCK_QTY: "Stock Quantity",
  WAREHOUSES_QTY: "Location Distribution",
  WITH_DISCOUNT: "Discount",
  DISCOUNT_TYPE: "Discount Type",
  ADD: "Add",
  BULK_ADD: "Add Batch",
  BULK_ADD_INFO: "Please try integration options to add bulk customers.",
  ACTION_INFO3: "There are no records.",
  TRANSPORTER_OR_SECTION: "Transporter/Section",
  PREPARING_USER: "Prepared",
  CARGO_DATE: "Shipment Time",
  ACTION_LINE_COUNT: "Order Line Count",
  MOVEMENT_TYPE: "Movement Type",
  MOVEMENT: "Movement",
  LAST_MOVEMENT: "Last Movement",
  CHANNEL_TYPE: "Channel Name",
  CUSTOMER_INFO: "Customer Information",
  NOT_ASSIGNED_ORDER: "User Not Assigned",
  ORDERS_IN_PACKAGE_PROCESS: "In Package Process",
  PREPARED_ORDERS: "Completed Preparations",
  NOT_PREPARED_ORDERS: "Not Prepared",
  NOT_ASSIGNED_TRANSPORTER: "Not Assigned to Transporter",
  ORDERS_HAS_ONE_PRODUCTS: "Single Products",
  MIXED: "Mixed",
  SINGLE_PRODUCT: "Single Product",
  SINGLE_QTY: "Single Quantity",
  SINGLE_PROD_OR_QTY: "Single Product&Quantity",
  NEW_ACTION_LIST_PRIORITY_NORMAL: "Standard",
  NEW_ACTION_LIST_PRIORITY_HIGH: "Priority",
  NEW_ACTION_LIST_PRIORITY_VERY_HIGH: "High Priority",
  NEW_ACTION_LIST_PRIORITY_URGENT: "Urgent",
  TRANSACTION_ID: "Transaction No",
  ACTON_TYPE: "Action Type",
  NUMBER_OF_MISSING_PRODUCT: "Number Of Missing Product",
  STATUS_FILTERS: "Status Filters",
  FILTER_OPTIONS: "Filter Options",
  SHIPPING_DATE: "Shipping Date",
  SELECT_WAREHOUSE: "Select Location",
  USER_WAREHOUSE: "User Warehouse",
  MAIN_WAREHOUSE: "Main Warehouse",
  USER_PASSWORD_INFO: "Attention! If you fill in the password field, you change the user's password.",
  SELECT_PAYMENT_TYPE: "Select Payment Type",
  FIELD: "Field",
  VALUE: "Value",
  SELECT_FIELD: "Select Field",
  NOT_PREPARED_INVOICE: "Unprepared Invoices",
  ACTION_NOT_IN_VAT: "Tax Free Transactions",
  DELETED_ACTIONS: "Deleted Transactions",
  ARCHIVE: "Archive",
  ACTION_LINES_QTY_TRANSFER_EXCEL: "Transfer Order Stock Total",
  ACTIONS_TRANSFER_EXCEL: "Transfer Orders",
  TRANSFER_TO_PDF: "Transfer To PDF",

  REPREPARE_ACTION: "Undo Preparing Order",
  COPY_ACTION: "Copy Stock Movement",
  SHOW_ERP_INTEGRATION: "View Accounting Integration",
  EDIT_ACTION_INFO1: "You can edit it because it hasn't been more than 24 hours after creating a stock movement",
  EDIT_ACTION_INFO3: "You can change the responsible user that movement preparation from the list view",
  EDIT_ACTION_INFO4: "Please click the \"Update\" button so that the changes made on the basis of stock items are active in this movement and in the orders section..",
  PRINT: "Print",
  PRODUCTS: "Stock",
  INTERNATIONAL_PRICE: "International Price",
  LOCAL_PRICE: "Local Price",
  TOTAL_STOCK_QTY: "Total Stock Quantity",

  STOCK_MOVEMENT_INFO1: "This Stock Movement Has Not Been Processed Yet",
  STOCK_MOVEMENT_INFO2: "The Transactions of This Stock Movement Are Not Completed Yet",

  TRIGGERING_STAGE_YET: "It hasn't reached the triggering stage yet",
  PROCESS_RUN_PARALLEL: "The process runs in parallel",
  PLACE: "Place",
  ACCOUNTING_MUST_APPROVE: "Accounting must approve.",

  ADD_ORDER: "Add Order",
  CHANGE_USER: "Change User",
};

var notificationEn = {
  NOTIFICATIONS: "Notifications",
  NOTIFICATION_INFO1: "You can set the levels at which the notification alert will be visible.",
  NOTIFICATION_INFO2: "Notifications outside the last 24 hours are automatically deleted.",
  NO_NEW_NOTIFICATIONS: "You haven't notifications yet.",
  NOTIFICATION_SETTINGS: "Notification Settings",
  GENERAL: "General",
  IN_COMPANY: "In-Company",
  PERSONALITY: "Personal",
  CLEAR_NOTIFICATIONS: "Clear Notifications",
  MESSAGE: "Message",
  WHO: "Who",
  NOTIFICATION_DOWNLOAD_INFO1: "Download links for the services you have requested downloads from.",
  NOTIFICATION_DOWNLOAD_INFO2: "",
  DOWNLOAD_NOTIFICATION: "Download Links",
};

var ordersPrepareEn = {
  ORDER_PREPARING: "Order Preparation",
  ORDER_TRANSPORTER_RELATION: "Order-Transporter Relation",
  TRANSPORTER: "Transporter",
  SECTION: "Section",
  EMPTY_SECTION: "Empty Section",
  ORDERS_FOR_WAITING_PRODUCTS: "Product Pending Orders",
  ORDERS_SECTION_INFOMSG1: "Order not matched with any section",
  ORDERS_SECTION_INFOMSG2: "No order has been matched with the transporter.",
  ORDERS_SECTION_INFOMSG3: "The packaging shelf is not defined. Define from",
  ORDERS_SECTION_INFOMSG4: "All sub locations in the packaging are used. You can define a new shelf from the",
  ORDERS_SECTION_INFOMSG5: "There are no completed orders",
  ORDERS_SECTION_INFOMSG6: "There are no products that need to be prepared for any order",
  ORDERS_SECTION_INFOMSG7: "Please read the stock code of the product you will place in the order box",
  ORDERS_PREPARING_FOR_TRANSPORTER: "Order Preparation by Transporter",
  ORDERS_PICKING: "Orders Picking",
  REMAINDER: "Remainder",
  PENDING_ISSUES: 'Pending Issues',
  START_NEXT_ISSUE: "Start Next Issue",
  PREPARE_LIST_INFO2:"List Waiting to Be Prepared",
  PREPARE_LIST_INFO3:"There are currently no work orders waiting to be prepared.",
  OWN_ISSUES: 'Own Issues',
  PREPARE_LIST_INFO4:"My Lists",
  PREPARE_LIST_INFO5:"No active listings yet",
  LIST_NO: "List Number",
  CONTROL: "Control",
  PREPARED_ORDERS: "Prepared Orders",
  ORDERS_IN_PACKAGING_PROCESS: "Orders in Packaging",
  PACKAGING: "Packaging",
  PACKAGING_SHELF_INFO: "Please attention! The packaging rack is used in the handling step of order preparation.",
  PACKAGING_INFO: "Your lists in the packaging phase appear. You do not currently have a list at the packaging stage.",
  WILL_PREPARE_PRODUCT_FOR_ORDERS: "Products to be Prepared for Orders",
  CLOSE_CONTINUE_READ: "Close and Continue Scanning",
  CONTINUE_READ: "Continue Scan",
  MOST_LESS: "Least",
  MOST_PLUS: "Most",
  PLUS_PREPARING: "Prepare",
  SELECT_PREPARING_METHOD: "Choose Preparation Method",
  CAMERA: "Camera",
  HAND_TERMINAL: "Hand Terminal",
  HAND: "Hand",
  SELECT_CAMERA: "Select Camera",
  START_NEXT_LIST: "Start Next List",
  START: "Start",
  LIST: "List",
  CANCELLATION: "Cancellation",

  PREPARING: "Preparing",
  TOTAL_PRODUCT_COUNT: "Total Line Count of Order",
  TOTAL_PRODUCT_QUANTITY: "Total Product Quantity of Order",
  TOTAL_PRODUCT_SUPPLY: "Total Product Supply of Order",
  PRODUCT_ORDER_RELATION: "Product / Order Information",
  LOT_DETAIL_QTY: "Lot Detail / Quantity",
  LOT_DETAIL: "Lot Detail",
  LOT_LIST: "Lot List",

  READY: "Ready",
  REVERT: "Revert",
  UNCONFIRM: "Unconfirm",
  TRANSPORTER_SECTION: "Transporter Section",
  STOP: "Stop",
  STOP_CAMERA: "Stop Camera",
  PACKAGING_AREA_DETAIL: "Packaging Area Detail",
  DETAIL: "Detail",
  TRANSPORTER_DETAIL: "Transporter Detail",
  PRODUCT_LIST: "Stock List",
  SHIPPING_READY: "Ready to Ship",
  SHOW_ACTION_DETAIL: "View Transaction Detail",
  SELECT_ORDER: "Select Order",
  PRODUCT_BARCODE: "Stock Barcode",
};

var reportsEn = {
  REPORTS: "Reports",
  REPORTS_INFOMSG1: "There are no reports within your role",
  REPORTS_INFOMSG2: "Report Created on",
  FILE_TYPE: "File Type",
  CREATE_REPORT: "Create Report",
  UPDATE_STATUS: "Update Status",
  REQUEST_PENDING: "Request Pending",
  REQUEST_QUEUED: "Request Queued",
  REPORT_PRODUCED: "The requested report is produced",

};

var paginationEn = {
  TOTAL_RECORD_COUNT: "Total record",
  TOTAL_PAGE_COUNT: "Total page",
  PAGINATION_RECORD_COUNT: "Pagination",
  PERIOD: "Period",
  CALCULATING: "Calculating",
  UPDATED_DATE: "Updated Date"
};

var commonEn = {
  HELP_PAGE: "Help Page",
  HELP_WITH_VIDEO: "Video Help",
  INSTALL_WIZARD: "Install Wizard",
  SYSTEM_SETTINGS: "System Settings",
  MOVEMENT_SYSTEM: "Movement Within The System",
  CHANNEL_SETTINGS: "Channel Settings",
  PROVIDER: "Provider",
  CHANNEL_NAME: "Channel Name",
  CHANNEL_NAME_LABEL: "Your Marketplace Name in Provider",
  DEFAULT_CURRENCY: "Default Currency",
  EXAMPLE_RATE: "rate",
  DEFAULT_COMPANY_CUSTOMER : 'Default Company Customer',
  TAX_STATUS: "Tax Status",
  HAS: "Available",
  NOT_HAS: "Not Available",
  PROTOCOL: "Protocol",
  WEB_SERVICE_URL: "Web Service URL (For example; www.xyz.com)",
  WEB_SERVICE_USERNAME: "Username For Web Service",
  WEB_SERVICE_PASSWORD: "Password For Web Service",
  SETTINGS_INFOMSG1: "You can define web service information of T-Soft b2c sites. You can update \"order statuses\" and \"payment types\" with the Update Settings button.",
  SETTINGS_INFOMSG2: "The Order Statuses of your selected channel setting are not loaded. Please click.",
  SETTINGS_INFOMSG3: "In the Integrations section, you can set which order statuses will be automatically selected after the channel selection. <br/>Also, you should set the order statuses that you want to be automatically drawn to the system as default.",
  SETTINGS_INFOMSG4: "To view the payment types, please select the channel from the top page.",
  SETTINGS_INFOMSG5: "In this section, you can determine which warehouse will be deducted according to the status of orders coming from B2C sites.",
  SETTINGS_INFOMSG6: "Transfer the Total Stock Amount.",
  SETTINGS_INFOMSG7: "If Yes is checked, \"Total Stock Amount\" will be sent to the selected channel.<br/> If the selected warehouse; In the section below, it is the definition of the fallback warehouse that will be activated in the manual transfer for the warehouse<br/> and the transaction type that has not been selected according to the status of the order..",
  SETTINGS_INFOMSG8: "You can define the warehouse matching of the stock quantities to be transferred to your B2C sites. If you wish, you can transfer the total stock on your site.",
  SETTINGS_INFOMSG9: "You can define the criteria of the orders to be transferred to the accounting program..",
  SETTINGS_INFOMSG10: "You can manually run scheduled tasks that run automatically.",
  SETTINGS_INFOMSG11: "You can define your e-mail addresses where you will receive notifications of products under critical stock quantity. ( You can define multiple mailing addresses using the <b>\",\"</b> separator. )",
  SETTINGS_INFOMSG12: "You can change the group of users who will prepare the order using the form above.",
  SETTINGS_INFOMSG13: "You can change the group of users who will check the prepared orders using the form above.",
  SETTINGS_INFOMSG14: "You can change the group of users who will check the prepared orders using the form above..",
  SETTINGS_INFOMSG15: "You can edit status changes in order preparation processes using the form above..",
  SETTINGS_INFOMSG16: " the transactions you will make in the channel will be valid for all stock movements created through Polypus.",

  API_KEY: "API Key",
  API_SECRET: "API Secret",
  API_URL: "API URL (For Example; https://www.xyz.com)",

  UPDATE_PARAMETER: "Update Parameter",
  PROVIDER_ID: "Provider ID",
  DEFAULT: "Default",
  PAYMENT_TYPES: "Payment Types",
  EXAMPLE_PAYMENT_TYPE: "payment_type",
  ORDER_WAREHOUSE: "order_warehouse",
  TRIAL_PROCESS: "trial_process",
  ATTACHMENTS: "Attachments",
  PAYMENT_AWAITING: "Payment Awaiting",
  PAYMENT_DOOR_APPROVED: "Payment at the Door Approved",
  PRODUCT_BEING_PREPARED: "Product Is Being Prepared",
  POS_APPROVAL: "At Pos Approval",
  ALL_PAYMENT_TYPES: "All Payment Types",
  RESERVE_WAREHOUSE: "Reserve Warehouse",
  RETAIL_SALES: "Retail Sales",
  PAYMENT_MADE: "Payment Made",
  DELIVERED: "Delivered",
  SHIPPED: "Shipped",
  PRODUCT_WAREHOUSE: "Product Warehouse",
  RETURNED: "Returned",
  NEUTRAL: "Neutral",
  TASK_ENTRY: "Task Entry",
  LOG: "Log",

  BRANCH_INFO_TITLE: "Branch Info",
  BRANCH_LOGO: "Logo",
  BRANCH_SIGNATURE: "Signature / Stamp",
  BRANCH_WATERMARK: "Watermark",
  BRANCH_INFO_TITLE_INFO: "You can register companies affiliated with your company here. It is used to customize documents such as proforma.",

  SELECT_CHANNEL: "Select Channel",
  ADDED_DATE: "Date of Added",
  VALIDITY_DATE: "Date of Validity",
  AUTOMATIC_TRANSFER_SETTINGS: "Automatic Transfer Settings",
  DEFINE_DYNAMIC_STOCK_ACTION: "Dynamic Stock Movement Definition",
  SELECT_ALL: "Select All",
  REMOVE_ALL: "Select Remove",
  BRING_ALL: "Bring All",
  POSITIVES: "Positives",
  NEGATIVES: "Negatives",
  SUGGEST_DYNAMIC_ACTIONS: "Recommended Dynamic Moves",
  APPLIED_TRANSACTION_TYPE: "Type of Transaction to be Applied",
  WAREHOUSE_CHANNEL_RELATION: "Location and B2C Channel Matching",
  IS_SEND_TOTAL_STOCK: "Send Total Stock ?",
  YES: "Yes",
  NO: "No",
  ERP_INTEGRATION_SETTINGS: "Accounting Transfer Settings",
  MISSION_NAME: "Job Name",
  COMMAND: "Command",
  EXPRESSION: "Expression",
  COMMAND_ARGUMENTS: "Command Arguments",
  CRON_JOB: "Jobs",
  RUN: "Run",
  COMPANY_SETTINGS: "Company Settings",
  NOTIFY_EMAIL_SETTING: "Notification Email Setting",
  EMAIL_ADDRESS: "E-mail Address",
  COMPANY_UPLOAD_LOGO: "Company Logo Upload",
  ADD_BANKS: "Add Bank",
  BANKS: "Banks",
  IBAN: "IBAN",


  ORDER_PREPARE_ROLE: "Order Issuer Authority Group",
  CURRENT_SELECTED_ROLE: "Current Selected Group",
  SELECT_USER_ROLE: "Select user group",
  CONTROLLER_ROLE: "Order Control Authority Group",
  TRANSPORTER_ORGANIZATION: "Transporter Organization",
  PREPARATION_TYPE: "Preparation Type",
  BULK_PREPARATION: "Batch Preparation of Orders",
  DIVIDED_PREPARATION: "Segmentation of Orders for Preparation",
  MOST_ATTACH_ORDER_TO_TRANSPORTER: "Maximum Orders to be Assigned to Transporter",
  DEFAULT_VALUE: "Default Value",
  FREE_TRANSPORTER: "Free Transporter",
  RELATION_WITH_USER: "Relation With User",
  USER_RELATION_HEAD: "No Relation with User",
  ORDER_SYNC: "Order Synchronization",
  ACTION_STATUS: "Transaction Status",
  DASHBOARD: "Dashboard",
  ORDER_PREPARE: "Order Preparation",
  STOCK_MANAGEMENT: "Stock Management",
  STOCKTAKING: "Stock Taking",
  SHELF_CHANGE: "Shelf Changing",
  SHELF_CHANGE_NAME: "Shelf Change Name",

  ENTER_BBD: "Enter The BBD.",

  PRODUCTION_STREAMS: "Production Stream",
  PRODUCTION_MOVEMENT: "Production Entry",
  PRODUCTION_MOVEMENTS: "Production Movements",
  PRODUCTION_RECIPE: 'Production Recipe',
  RECIPE_CONTENT: "Recipe Content",
  REJECT: "Reject",
  DONE: "Done",
  DONE_PROCESS : 'Delivery',
  TOTAL_COUNTED: 'Total Counted',
  CARGO_OPERATION: "Kargo ve Taşıma İşlemleri",
  CARGO_BARCODE: "Cargo Barcode",
  BULK_ACTIONS: "Batch Operations",
  INTEGRATIONS: "Integrations",
  LOCATION_MANAGEMENT: "Location and Shelf Management",
  RETURN_MANAGEMENT: "Return Management",
  CATEGORY_BRAND_MANAGEMENT: "Category and Brand Management",
  BRAND_MANAGEMENT: "Brand Management",
  BRAND_NAME: "Brand Name",
  BRAND_LINK: "Brand Link",
  BRAND: "Brand",
  TAG_MANAGEMENT: "Tag Management",
  TAG_MANAGEMENT_INFO: "Tag Category is not required. Please apply for your Tag Category solution needs.",
  TAG_NAME: "Tag Name",
  TAG_CATEGORY: "Tag Category",
  SHELF_SELECT: "Shelf Select...",
  SHELF_SELECTED: "shelf selected...",
  SELECTED_BRANCH: "Select Branch",
  ORDER_STATUS_SELECT: "Order Status Select...",
  ORDER_STATUS_SELECTED: "status selected...",
  MODEL_SELECT: "Model Select...",
  MODEL_SELECTED: "model selected...",
  CATEGORY_SELECT: "Select Category",
  CATEGORY_SELECTED: "Selected Category",
  CATEGORY_TREE: "Category Tree",
  NEW_MAIN_CATEGORY_NAME: "New Main Category Name",
  CATEGORY_NAME: "Category Name",
  NEW_SUBCATEGORY_NAME: "New Subcategory Name",
  USER_AND_ROLES: "Users and Roles",
  ROLES: "Roles",
  ROLE_LIST: "Role List",
  ROLE_EDITING: "Role Editing",
  PERSONAL_SETTINGS: "Personal Settings",
  CLOSE: "Close",
  MODULES: "Modules",
  LOGOUT: "Exit",
  GENERAL_INFORMATION: "General Information",
  ADD_PRODUCT:'Add Product',
  ADD_BULK_PRODUCT:'Add Bulk Product',
  ADD_BULK_PRODUCT_REPORT:'Bulk Product Addition Report',
  SUCCESSFUL: "Successful",
  UNSUCCESSFUL: "Başarısız",
  PRODUCT_NOT_ADDED_INFO: "The product could not be added, please check the information.",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_2_1: "Other Shelves Where the Product Is Located",

  ADD_PRODUCT_INFO:'Paste the products to be added here',
  PRODUCT_QUEUE:'Product Queue',
  BARCODE_QTY_PRICE_INFO:'Must be in the form of Barcode, Quantity, Price',
  IS_THERE_TITLE:'Is There a Title?',
  COLUMN_SEPARATOR:'Column Separator',
  LINE_SEPARATOR:'Line Separator',


  SELECT_SUB_PRODUCT: 'Select Sub Product',
  PACKAGE_PRODUCT_VARIATION_MESSAGE: "You cannot add a product variation because you have defined the product as a packaged product.",
  LOT_PRODUCT_VARIATION_MESSAGE: "You cannot add a product variant because you have defined a tracking method for the product",
  QUICK_PRODUCT:"Quick Product",
  CBM: "CBM",
  VARIATION_PRODUCT_LOT_MESSAGE: "Since the product variation information is entered, you must select the inventory information in the child products",
  RECIPE_INFO3: "A prescription cannot be applied because a bundle or lot entry has been made for this product.",
  UPDATE_RECIPE: "Update Recipe",
  CATEGORY_TAG_CODE: "Category Tag Code",
  CHANNEL_INFO: "Channel price information for this product is missing",
  PACKAGE_NOT_UPDATED_MESSAGE: "Package editing cannot be made because this product has already been registered.",
  IS_WHOLESALE_PRODUCT: "Is it a wholesale product ?",
  SUPPLIER_CODE: "Supplier Code",

  PRODUCT_SHORT_DESCRIPTION: "Product Short Description",
  ENTER_PRODUCT_TAG: "Enter product tag",
  SUPPLIER: "Supplier",
  LATEST_SUPPLIER: "Latest Supplier",
  STOCK_UNIT: "Stock Unit",
  PRICE_INFORMATION: "Price Information",
  SELLING_PRICE_WITH_EXAMPLE: "Sale Price (For example; 13.34, 1234.5)",
  SELLING_PRICE: "Sale Price",
  BELOW_SELLING_PRICE: "Below Selling Price",
  ABOVE_LAST_PURCHASE_PRICE: "Above last purchase price",
  SELLING_PRICE_VAT_INCLUDED: "Sale Price With Vat",
  DISCOUNTED_SELLING_PRICE: "Discounted Selling Price",
  DISCOUNTED_VAT: "Discounted VAT",
  DISCOUNTED_SELLING_PRICE_VAT_INCLUDED: "Discounted Selling Price With VAT",
  DISCOUNT_APPLIED: "discount applied",
  BUYING_PRICE: "Buying Price",
  TAX_PERCENT: "Tax Rate",
  CHANGE_TAX_PERCENT: "Change Tax Percent",
  CHANGE_TAX_PERCENT_OF_THE_OFFER: "Change Tax Percent Of The Offer",
  CHANGE_THE_PRODUCT_TAX_PERCENT: "Change The Product Tax Percent",
  PRODUCT_NOT_COLLECTED: "Product Could Not Be Collected",
  PRODUCT_DEFECTIVE: "Product Defective",
  RESERVED: "Reserved",
  CARGO_INFORMATION: "Cargo information",
  CARGO_AND_ADD_INFORMATION: "Kargo ve Ek Bilgiler",
  SAME_ALL_CHANNEL: "Tüm Kanallar Aynı",
  SALES: "Sales",
  PURCHASE: "Purchase",
  PURCHASE_REQUESTS: "Purchase Request",
  PURCHASE_OFFERS: "Purchase Offers",
  PURCHASE_PROCESS: 'Purchase Process',
  SALES_OFFER: "Sales Offer",
  SALES_OFFER_DRAFT: "Sales Offer Draft",
  PURCHASE_OFFER_DRAFT: "Purchase Offer Draft",
  PRODUCTION_OFFER_DRAFT: "Production Offer Draft",
  CHANNEL_BASED: "Kanal Bazlı",
  SEO_SETTINGS: "Seo Settings",
  SEO_LINK: "Seo Link",
  SEO_HEAD: "Seo Head",
  SEO_DESCRIPTION: "Seo Description",
  PRODUCT_INFORMATION: "Stock Information",
  PRODUCT_DETAIL: "Stock Detail",
  ADDITIONAL_INFO1: "Additional 1",
  ADDITIONAL_INFO2: "Additional 2",
  ADDITIONAL_INFO3: "Additional 3",
  ADDITIONAL_INFO4: "Additional 4",
  ADDITIONAL_INFO5: "Additional 5",
  MIN_ORDER_QTY: "Min. Order Quantity (For Member)",
  MAX_ORDER_QTY: "Max. Order Quantity (For Member)",
  LIST_NU: "List No",
  OPTIONS: "Options",
  ACTIVE: "Active",
  MANUEL: "Manuel",
  NEW_PRODUCT: "New Product",
  STOCK_QTY: "Stock Quantity",
  CRITIC_STOCK_QTY: "Critical Stock Quantity",
  VIRTUAL_STOCK_QTY: "Virtual Stock Quantity",
  INVENTORY_INFORMATION: "Inventory Information",
  INVENTORY: "Inventory",
  FOLLOW_METHOD: "Following Method",
  NOT_FOLLOW: "Not Follow",
  UNIQUE_SERIAL_NU: "Unique Serial Number",
  LOT: "Lot",
  SUB_PRODUCT: "Variation",
  HAS_SUB_PRODUCTS: "Has Variations",
  ADD_SUB_PRODUCT: "Add Variation",
  PACKAGE_PRODUCT_CONTENT: "Bill of Material",
  ADD_PACKAGE_PRODUCT_CONTENT: "Add Bom",
  CREATE_STOCK: "Create Stock",
  PROPERTY1: "Property 1",
  PROPERTY2: "Property 2",
  RECIPE_INFO2: "If you add a bill of materials to the product, stock increase will be carried out from the 'Production Bill of Materials' section. The products involved in the bill of materials will be decreased, and this product will be increased according to the bill of materials.",
  STOCK_LIST: "Stock List",
  SEARCH_IN_STOCK: "Search in Stock",
  SEARCH_IN_TAG: "Search in Tag",
  SELECT_BRAND: "Select Brand",
  SELECTED_BRAND: "Selected Brand",
  SEARCH_BRAND: "Search Brand",
  CRITICAL_STOCK: "Critical Stock",
  OUT_OF_STOCK: "Out of stock",

  MINUS_STOCK: "Minus Stock",
  VIRTUAL_STOCK: "Virtual Stock",
  ACTIVE_PRODUCT: "Active Stock",
  PACKAGE_PRODUCT: "Package Product",
  EMPTY_STOCK: "Empty Stock",
  NEARLY_EMPTY_STOCK: "Nearly Empty Stock",
  ENOUGH_STOCK: "Enough Stock",
  SHOW_PRODUCT_VARIATION: "Show Product Variation",
  SHOW_PRODUCT_ACTION: "Show Product Action",
  UPDATE_PRODUCT_CLICK: "Click to Update Product",
  SHOW_MAIN_PRODUCT_BARCODE: "Click to See Main Product Barcode",
  DELETE_PRODUCT_CLICK: "Click to Delete Product",
  SYNC_PRODUCT_CLICK: "Click to Sync Product",
  STOCK_INFOMSG1: "Sends to Queue for Download as Excel",
  STOCK_INFOMSG2: "No lot/serial number is defined for any product",
  STOCK_INFOMSG3: "There is no stock information for variations for this stock",
  STOCK_INFOMSG4: "No movement is visible on this stock card.",
  STOCK_INFOMSG5: "There isn't stock movement for this stock.",
  STOCK_INFOMSG6: "There isn't lot/serial number for any product",
  STOCK_INFOMSG7: "There isn't lot/serial tracking for this product.",
  SHOW_PRODUCT_LOT: "Show product lot",
  CREATE_LOT_SERIAL: "Create Lot/Serial",
  LOT_SERIAL_NUMBERS: "Lot/Serial Numbers",
  LOT_SERIAL_NUMBER: "Lot/Serial Number",
  ESTIMATED_DATE: "Expiration date",
  ESTIMATED_DELIVERY_DATE: "Estimated Delivery Date",
  INVOICE_DATE: "Invoice Date",
  VARIATION_DISTRIBUTION: "Variation Distribution",
  STOCK_CARD: "Stock",
  STOCK_CODE: "Stock Code",
  STOCK_NAME: "Stock Name",
  STOCK_UNIT_CHANGE_BLOCKED: "Stock unit change is blocked.",

  ASSOCIATED_MOVEMENTS: "Associated Movements",

  SUB_PRODUCT_CODE: "Sub Product Code",
  PROPERTIES1: "Properties 1",
  PROPERTIES2: "Properties 2",
  PRODUCT_ACTIONS: "Product Stock Movements",
  MOVEMENT_DIRECTION: "Movement Direction",
  STATISTIC_REPORT: "Statistics Report",
  SELECT_VARIATION: "Please select variation",
  UNIT_PRICE: "Unit Price",
  PRODUCT_PROPERTIES: "Stock Features",
  REAL_STOCK_QUANTITY: "Actual Stock Quantity",
  REAL_STOCK_SUPPLY: "Actual Stock Supply",
  PRODUCT_DELETED: "Stock Deleted",
  TAG_ORDER: "Label Sorting",
  NAME_SURNAME: "Name Surname",
  ADDRESS_DESCRIPTION: "Address Description",
  COUNTRY: "Country",
  PREPARING_STREAM: "Preparing Stream",
  BALANCE: "Balance",
  LOT_SERIAL_SELECTION: "Lot/Serial Number Selection",
  LOT_SERIAL_WAREHOUSE_NOT_SELECTED: "Lot/serial number whose warehouse is not selected",
  IN_STOCK: "In Stock",
  CREATE_NEW_LOT: "Create New Lot",
  SELECTED_LOT_SERIAL_NUMBERS: "Selected Lot and Serial Numbers",
  PRODUCT_QUANTITY: "Product Quantity",
  NUMBER_OF_TRANSACTION: "Number of Transactions",
  PLEASE_SEARCH_TO_SELECT_LOT_SERIAL: "Please search to select lot/serial number.",

  CREATE: "Create",
  ROLE: "Role",
  CREATE_ROLE_GROUP: "Create Role",
  ROLE_NAME: "Role Name",
  PERMISSIONS: "Permissions",
  SELECT_NATIVE: "Select",
  ORDER_LIST_ACCEPTING: "Order List Confirmation",
  PAYMENT_INFO: "Payment Info",
  ORDER_NATIVE: "Order",
  ADD_NEW_CURRENCY: "Add New Currency",
  SELECT_CURRENCY: "Select Currency",
  SELECT_FOREIGN_CURRENCY: "Select Foreign Currency",
  CURRENCY_INFO: "All defined currency types have been added.",
  ACT: "Action",
  STOCK_ENTRY: "Stock Entry",
  STOCK_EXIT: "Stock Exit",
  STOCK_INFO: "No stock counted yet",
  PRODUCT_SEARCH: 'Product Search',
  NOT_FOUND_RESULT: 'no results found for ',
  PRODUCTS_DEFINED: 'Products to be Defined',
  ACCEPT: "Accept",
  COMBINE_SHELVES: "Combine Shelves",
  PAST_SKT_INFO1: "There is a product on this shelf that is ",
  PAST_SKT_INFO2: " days past its SKT date.",
  BBD_LOT: "BBD / LOT",
  PLACE_PRODUCTS_WITHOUT_BARCODE: "Add Without Barcode",

  RETURN: "Return",
  RETURN_RECEIVING: "Return Receiving",

  OFFER_SEARCH_INFO: " Offer Search",
  OFFER_SEARCH_INFO2: "You Can Search for Offers Using Search Criteria",
  RETURN_OFFER_DRAFT: "Return Offer Draft",

  STOCKTAKING_CREATE: "Stocktaking Create",
  STOCKTAKING_LIST: "Stocktaking List",
  STOCKTAKING_LIST_NAME: "Stocktaking List Name",
  STOCKTAKING_DESCRIPTION: "Stocktaking Description",
  STOCKTAKINGS_NOT_FOUND_TITLE: "No Stock Count",
  STOCKTAKINGS_NOT_FOUND_BODY: "You can create a new stock count from the form opened above by clicking on the 'New' button.",
  STOCKTAKING_LISTS: "Stocktaking Lists",
  STOCKTAKING_MAKE: "Stocktaking Make",
  STOCKTAKING_COMPLETED: "Completed",
  STOCKTAKING_COMPLETE: "Stocktaking Complete",
  NEXT_SHELF: "Next Shelf",
  DEFINED_SHELF: "Defined Products on the Shelf",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_1: "Potential Moves",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_3: "Entry",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_4: "Exit",
  STOCKTAKING_ITEM_ACCEPT: "Accept",
  STOCKTAKING_PRODUCT_INFO: "The product has been read before in the selected count. Select renewal type",
  REFRESH_COUNT: "Refresh Count",
  ADD_TO_COUNT: "Add to Count",

  SHELVE_SEARCH_INFO: " Searching for the Shelf to Process",
  SHELVE_SEARCH_INFO2: "You can search for the shelf you want to trade with our shelf code search section.",

  PRODUCT_NOT_FOUND: 'Product Not Found',
  PRODUCT_NOT_FOUND_INFO: 'There is no product on the shelf you are searching. You can define the products you want on the shelf from our shelf operations section.',

  DOMAIN_NAME: "Domain Name",
  DESCRIPTION: "Description",
  SAMPLE_DATA: "Sample Data",

  PRICE_WITH_VAT: "Price (VAT INCLUDED)",
  PRODUCT_TAG: "Product Tag",
  CUSTOMER_NOTE: "Customer Note",
  MISSING_REPORT: "Missing Report",
  MISSING_QTY: "Missing Amount",
  IS_IN_STOCK: "Is the product in stock?",
  COMPLETE: "Complete",
  MISSING_PRODUCT_LIST: "Missing Product List",
  MARKETPLACE: "Marketplace",
  CLEAR_LIST: "Clear List",
  GET_PACKED: "Get it Packed",
  MISSING_PRODUCTS: "Missing items",
  MISSING_PRODUCT: "Missing item",
  VARIATION_ID: "Variation Id",
  CREATE_ORDER: "Create Order",
  RETURN_SHELF: "Return Shelf",
  DEMAND_NUMBER: "Demand Number",
  DEMAND_TYPE: "Demand Type",
  DEMAND_STATUS: "Demand Status",
  DEMAND_DATE: "Demand Date",
  DEMANDER: "Demander",
  ALTERNATIVE_SHELVES: "Alternative Shelves",
  NO_SUITABLE_SHELF_FOUND: "No Suitable Shelf Found",
  SHELF_AND_QUANTITY: "Shelf And Quantity",
  RECOMMENDED_SHELF: "Recommended Shelf",
  AVAILABLE_FROM_SHELF_QUANTITY: "Available Quantity from the Shelf",
  OPEN: "Open",
  NOT_PURCHASED: "Not Purchased",
  CREATE_OFFER_FROM_SELECTED_PRODUCTS: "Create Offer from Selected Products",

  DEMAND_QUANTITY: "Demand Quantity",
  NUMBER_OF_OPEN_DEMAND: "Number of Open Demand",
  MATCHED_PURCHASE: "Matched Purchase",
  FIRST_DEMAND_DATE: "First Demand Date",
  PURCHASE_OFFER_QUANTITY: "Purchase Offer Quantity",
  START_PURCHASE_INFO: "Offers where purchases have been initiated for this product but are not attached to the requisition",
  ASSOCIATE_TO_DEMAND: "Associate to Demand",
  NOT_PURCHASE_INFO: "No pending Unassigned Purchase quotes",
  DEMANDS: "Demands",
  COMPLETE_ALL: "Complete (All)",
  REJECT_ALL: "Reject (All)",
  NOT_PURCHASED_ALL: "Not Purchased (All)",
  COMPLETE2: "Complete",
  REJECT2: "Reject",
  NOT_PURCHASED2: "Cannot Be Provided",
  ASSOCIATED_OFFER: "Associated Offer",

  NEEDED: "Needed",
  RETURNABLE_QUANTITY: "Returnable Quantity",
  PUT_PRODUCTS_ON_SHELF: "Put Products on the Shelf",
  PRIORITY_SHELF: "Priority Shelf",
  WAREHOUSE_COUNTING_CONFIRM: "Warehouse Counting Confirm",
  WAREHOUSE_COUNTING_CONFIRM_INFO: "The quantity entered does not match the quantity of the product on the shelf. Do you want to confirm?",

  ACCEPT_ALL: "Accept All",
  CARE: "CARE",
  SHELF_CODE_INFO: "This product is assigned to a different shelf code, do you approve of placing it in a new shelf code?",

  VIEW_TRANSACTION: "View Transaction",
  PACKED_INFO: "There are no orders whose packaging process has been completed.",
  START_PREPARING_ORDER: "Start Preparing the Order",
  VIRTUAL_STOCK_QUANTITY: "Virtual Stock Quantity",
  STOCK_QUANTITY: "Stock Quantity",
  PREPARATION_STARTED_ON: "Preparation started on",
  PREPARATION_COMPLETED: "The preparation process has been completed.",
  BUY_PRODUCT_INFO: "Are you sure you want to buy {{productQuantity}} items?",

  REVERT_ACTION_NATIVE: "Delete",
  INVOICE_INFOMSG1: "Missing product information has not been entered for the order.",
  INVOICE_INFOMSG2: "You can create an order with Stock Movement",
  ARRIVAL_PERSONAL: "Receiver",
  INVOICE_NAME: "Invoice Name",
  NAME: "Name",
  ACTIVATE: "Activate",
  PRODUCT_APPROVAL: "Product Approval",

  STOCK_SELECT: "Stock Select...",
  SAVE_CHANGES: "Save Changes",
  UNDO_CHANGES: "Undo Changes",
  ENTER_NUMBER_ONLY: "Please enter numbers only.",
  ORDER_PREPARATION_TOOL: "Order Preparation Tool",
  ETC: "etc",
  ORDER: "Order",
  ORDER_DETAIL: "Order Detail",
  CRITICAL_STOCK_LEVEL: "Critical Stock Level",
  WAREHOUSE_MANAGEMENT: "Location Management",
  WAITING: "Waiting",
  WAITING_COMPLETED: "Waiting / Completed",
  SENT:"Sent",

  RECEIVING_ISSUES: "Receiving Issues",
  TRANSFERRED: "Transferred",
  TRANSFERRED_MANUALLY: 'Waiting To Be Transferred Manually',
  MANUALLY_TRANSFERRED: 'Manually Transferred',
  MISTAKE: 'There was an error',
  PREVIOUS_DAYS: "Previous Days",
  FAULT: 'Fault',
  DETAIL_VIEW: 'Detail View',
  CARGO_READY: "Shipping Ready",
  IN_WAITING: "In Waiting",
  IN_TRANSFER: "In Transfer",

  MINIMUM_AMOUNT: "Minimum Amount",
  MAXIMUM_AMOUNT: "Maximum Amount",

  IN_READY: "In Ready",
  SHELF_UNDEFINED: "Shelf Undefined!",
  SELECTED_SHELF:'Selected Shelf',
  MORE_SHELVES:'more shelves',
  DAY_HAS_PASSED:'the day has passed',
  BBD:'BBD',
  ENTER_QUANTITY_WITH_BBD:"Enter Quantity With BBD",
  BBD_ENTRANCE:"BBD Entrance",
  FAULTY_INPUT: "Faulty Input",
  PRINT_LABEL: "Print Label",
  NEW_CART: "New Cart",
  AVAILABLE_BASKETS: "Available Baskets",

  CART_INFO1:"CART",
  CART_INFO2:"There are no products in the cart yet",

  ADD_TO_MISSING_CART: "Add To Missing Cart",
  REMOVE_FROM_MISSING_CART: "Remove From Missing Cart",
  IN_COMPLETED: "In Completed",
  STATUS_WAIT: "Waiting",
  IN_PROGRESS: "In Progress",
  LAST_COMPLETED: "Last Completed",
  DOCUMENT:'Document',
  RELEASE_NOTES: "Release Notes",
  STATUS_OFFER_APPROVE: "Offer Approve",
  STATUS_OFFER_CREATE_ORDER: "Offer Order Create",
  STATUS_OFFER_DONE: "Offer Done",
  STATUS_OFFER_CANCEL: "Offer Cancel",
  OFFER_STATUS: "Offer Status",
  OFFER_EXPIRE_STATUS: "Offer Expire Status",

  MINUTE: "Minute",
  PLATFORM: "Platform",
  STOP_ADD_PRODUCT: "Stop Adding Products",
  START_ADD_PRODUCT: "Start Adding Products",
  FILTER_DETAIL: "Filter Detail",
  OK: "OK",
  ATTACH: "Attach",
  BULK_PRINT: "Bulk Print",
  SELECT_PREPARE_USER: "Select Prepare User",
  USERS: "Users",
  ENTER_INVENTORY_SEARCH_CRITERIA: "Type F3 for search",
  SHOW_CHANNEL_DETAIL: "Show Channel Detail",
  PROFILE_SETTINGS: "Profile Settings",
  TIMEZONE: "Timezone",
  SELECT_TIMEZONE: "Select Timezone",
  LANGUAGE: "Language",
  SELECT_LANGUAGE: "Select Language",
  GENERAL_SETTINGS: "General Settings",
  PREFERRED_CODE: "Preferred Code",
  PREFERRED_PRODUCT_CODE: "Preferred Product Code",
  RELOAD: "Reload",

  RETURN_OFFER_SCREEN_INFO1: 'Creating a Return',
  RETURN_OFFER_SCREEN_INFO2: 'You can search with order number, cargo number and order ID.',
  NEW_PRODUCTION_OFFER : 'New Production Offer',
  NEW_PRODUCTION_RECIPE : 'New Production Recipe',
  RECIPE_RECEIVING: "Recipe Receiving",
  PRODUCTION_RECEIVING: "Production Receiving",
  PURCHASE_RECEIVING: "Purchase Receiving",
  LOCATION_TRANSFER_ENTRY_RECEIVING: "Inter-Warehouse Transfer Entry Receiving",
  FREE_ENTRY_RECEIVING: "Free Entry Receiving",

  SORTING: "Sorting",
  CHANGE_SORTING: "Change Sorting",
  PRICE_DIFFERENCES: "Price Differences",
  QUANTITY_DIFFERENCES: "Quantity Differences",
  DEFECTIVE_PIECES: "Defective Pieces",
  PRODUCT_IMAGE_PATH: "Product Image URL",
  IMAGE_FROM_PATH: "Upload Image URL",
  TAKE_PHOTO: "Take Photo",
  NO_DOCUMENT_SELECTED_YET: "No Document Selected Yet",
  CARGO: "Cargo",
  DEFAULT_PRODUCT_IMAGE_UPLOAD: "Default Image Path",
  ANALYTICS: "Analytics",
  WITH_VAT: "Including VAT",
  VAT_TABLE : 'VAT Table',
  PRODUCT_PRICE_NO_DISCOUNT: "Non-Discounted Product Price",
  ANALYTICS_EMPTY_INFO: "No Data",
  ANALYTICS_EMPTY_INFO2: "No data has been generated yet related to analytics",
  NEW: "New",
  EXISTS: "Exists",
  MATCH: "Match",
  IMAGE: "Image",
  COUNTED: "Counted",
  RECEIVING: "Receiving",
  RECEIVING_TYPE: "Receiving Type",
  RECEIVING_MESSAGE_INFO: "Expiration date and serial number details..",
  RECEIVING_MESSAGE_INFO2: "Proof in case of issues like product form damage",
  RECEIVING_INFO: " Receiving",
  RECEIVING_INFO2: "There is no receiving process yet",
  RECEIVING_COMPLETED: "Receiving Completed",
  RECEIVING_COMPLETED_INFO: "Receiving has been completed but service operations are still ongoing. You can select the product you want to change by its barcode. Since the receiving type is <b>free</b> entry, you can approve and close the transaction.",

  PRODUCT_EXPIRE_DATE: "Expiration Date",
  SELECT_PRODUCT: "Select Product",
  STOCK_MOVEMENT_SUMMARY: "Stock Movement Summary",
  PRODUCT_SALES_REPORT: "Product Sales Report",
  TURNOVER_REPORT: "Turnover Report",
  SUMMARY_LIST_TYPE: "Summary List Type",
  SUMMARY_LIST_TYPE_MARKETPLACE: "Marketplaces as Columns",
  SUMMARY_LIST_TYPE_DEFAULT: "Default",
  VENDOR_CODE: "Vendor Code",
  SELECT_VENDOR: "Vendor Information",
  VENDOR: "Vendor",
  EXCHANGE_RATE_INFO: "Exchange Rate Transactions",
  EXCHANGE_RATE_INFO2: "You can view and adjust the exchange values of currencies here. Currencies without a value will default to 1.",
  "Etiket Kategorisi zorunlu değildir. Etiket Kategori çözümü ihtiyacınız için lütfen başvurunuz.":"Label Category is not mandatory. Please apply for your Label Category solution needs.",
  EMPTY_PURCHASE_REQUEST_INFO : 'Purchase Request',
  EMPTY_PURCHASE_REQUEST_INFO2 : 'No products were found matching your search criteria. Try again by changing your search criteria.',
};

var toastServiceMessages = {
  OFFER_SAVED: 'Offer Saved.',
  SEARCH_DATA_NOT_PRODUCT: 'Search data does not belong to the product',
  RETURN_NUMBER_REQUIRED: 'Return number required',
  STOCK_CARD_UPDATED: 'Stock card updated',
  FILE_UPLOADED: 'File uploaded...',
  ENTER_THE_VALUE_SEARCH: 'Enter the value to search for',
  SEARCHED_VALUE_WAS_NOT_FOUND: 'The order or action number for the searched value was not found',
  ENTER_SERIAL_OR_EXPIRATION_DATE: 'Enter serial number or expiration date.',
  OFFER_COUNTING_COMPLETED: 'Offer counting completed',
  WAREHOUSE_CREATED_SUCCESSFULLY: 'The warehouse has been created successfully.',
  WAREHOUSE_DELETED: 'The warehouse has been deleted.',
  SHELF_VERIFIED: 'Scan the Shelf Verified product code',
  NO_SHELF_FOUND: 'No Shelf Found',
  COUNTING_LIST_INFO: 'Action was taken based on the counting list',
  ALL_WAREHOUSES_CREATED: 'All suggested warehouses (reserve, product, faulty) have been created successfully.',
  GUIDE_REQUEST_CREATED: 'A guide request has been created.',
  GUIDE_DOWNLOADED: 'The guide has been downloaded.',
  RANK_LEAST_0: 'Rank can be at least 0',
  WIDTH_LEAST_0: 'Width can be at least 0',
  HEIGHT_LEAST_0: 'Height can be at least 0',
  DEPTH_LEAST_0: 'Depth can be at least 0',
  SHELF_ADDED_SUCC: 'Shelf added successfully',
  AN_ERROR: 'An error occurred',
  UPDATED_SUCC: 'Updated successfully',
  AN_ERROR_UPDATING: 'An error occurred while updating',
  AN_ERROR_DELETING: 'An error occurred while deleting',
  BARCODED_PRODUCT_ADDED_LIST: 'Barcoded Product Added to the List',
  CHECK_SHELF_INFORMATION: 'Check shelf information.',
  SHELF_REPLACEMENT_SUCC: 'Shelf Replacement Successful',
  NO_PRODUCT_WITH_BARCODE: 'No product found with barcode',
  MESSAGE_SENT_SUCC: 'Your message has been sent successfully.',
  MUST_SELECT_1_ORDER: 'You must select at least 1 order',
  PRODUCT_INCREASED_1: 'The quantity of the product has been increased by 1.',
  SELECT_PROD_VARIANT: 'Please select product variant.',
  TRANSACTION_CREATED_SUCC: 'Transaction created successfully.',
  STOCK_MOVEMENT_UPDATED_SUCC: 'Stock Movement has been updated successfully.',
  ENTERED_INCORRECT_PRODUCT: 'You Entered Incorrect Product Information',
  WANT_TO_DELETE_SHELF: "Are you sure you want to delete this shelf?",
  WANT_TO_DELETE_PRODUCT: "Are you sure you want to delete this product?",
  WANT_TO_DELETE_CHANNEL: "Are you sure you want to delete this channel?",
  WANT_TO_DELETE_AUTOMATIC_TRANSFER: "Are you sure you want to delete the automatic transfer to accounting setting?",
  WANT_TO_DELETE_B2C_SITE: "Are you sure you want to delete the automatic stock transfer setting to the B2C site?",
  WANT_TO_DELETE_STATUS: "Are you sure you want to delete this status? The status to be deleted will not be removed from the automatic transfer settings. Please review your automatic transfer settings.",
  WANT_TO_DELETE_TRANSACTION: "Are you sure you want to delete this transaction?",
  PRODUCT_NOT_FOUND_SYSTEM: "The product was not found in the system. Would you like to add it?",
  PRODUCT_APPROVAL_CANCELED: "Product Approval Canceled",
  PACKAGING_PROCESS_STOPPED: "Packaging Process Stopped",

  WARNING: "Warning",
  AFTER_THIS_SETTING_DELETED: "After this setting is deleted, products of incoming orders in the specified cases will be deducted from the matching warehouse above by default, regardless of the order status. Are you sure you want to remove this setting?",
  WANT_TO_DELETE_CUSTOMER: "Are you sure you want to delete this customer?",
  WANT_TO_DELETE_OFFER: "Are you sure the offer will be deleted?",
  AUTOMATICALLY_APPROVE: "When you do this, you will automatically approve all unapproved transactions. Are you sure ?",
  PLACE_COLLECT_ITEMS_BACK_SHELF: "Place Collected Items Back on the Shelf",
  CANNOT_BE_UNDONE: "This action cannot be undone. Once you approve, a work order will be created to place the products you collected back on the shelf. If you want your package to remain as you collected it, give up.",
  ONCE_YOU_CONFIRM_THIS_TRANSACTION: "Once you confirm this transaction, you cannot undo it. Are you sure ?",

  TRANSACTION_COPIED: 'The selected transaction has been copied.',
  POLYPUS_READY_HANDHELD_TERMINAL: 'Polypus is now ready to work with the handheld terminal.',
  NOT_STARTED_READING: 'You have not started the reading process yet.',
  TOTAL_NUMBERS_INFO: 'Total numbers are being prepared and will appear again when finished.',
  DIDNT_CHOOSE: "You Didn't Choose",
  APP_READY_OFFLINE: 'The app is ready for offline use.',
  SEARCHED_VALUE_NOT_FOUND: 'Searched value not found',
  CHOOSE_VARIANT_PACKAGE_PRODUCT: ' you must choose variant for package products',
  FILE_SIZE_BE_UP_3MB: 'File size can be up to 3 MB',
  FILE_SIZE_BE_UP_500KB: 'File size can be up to 500 KB',
  PRODUCT_INCREASED: 'The number of products has been increased',
  PRODUCT_ADDED: 'Product added',
  UPDATED: 'Updated',
  DOWNLOADING_FILE: 'Downloading file...',
  STOCK_MOVEMENT_DOWNLOADED_EXCEL: 'Stock movements are downloaded to Excel file.',
  SUCC_LOGGED: 'Successfully logged in.',
  ERROR_CREATING_CURRENT_ACCOUNT: 'An error occurred while creating a current account.',
  CURRENT_ACCOUNT_CREATED: 'A current account has been created.',
  NOT_DELETE_THIS_CUSTOMER: 'You cannot delete or update this customer.',
  CURRENT_ACCOUNT_DELETED: 'The current account has been deleted.',
  ERROR_UPDATING_CURRENT_ACCOUNT: 'An error occurred while updating the current account.',
  CURRENT_ACCOUNT_UPDATING: 'Current account updated.',
  MISSING_PRODUCT_RECORDED: 'Missing product information recorded.',
  CREATED_FOR_ORDER: 'A transaction was created for order {{orderNo}}.',
  RETURN_CREATED: 'Your Refund Has Been Created',
  NO_AVAILABLE_CAMERA_FOUND: 'No available cameras found.',
  PLEASE_ENABLE_BROWSER_PERMISSION: 'Please enable browser permissions for "Camera Barcode Reading".',
  FAILED_DELETE_SHORTCUT: 'Failed to delete shortcut',
  SHORTCUT_REMOVED: 'Shortcut removed',
  SHORTCUT_NOT_FOUND: 'The specified shortcut was not found',
  ADDED_THIS_PRODUCT_BEFORE_INFO: 'You have added this product before, you can increase the quantity if you want more.',
  USER_CREATED: 'User created.',
  TOKEN_NOT_RENEWED: 'Token Could Not Be Renewed',
  SEARCH_DATA_NOT_BELONG_PRODUCT: 'Search data does not belong to the product',
  PRODUCT_ADD_CART: 'Product Added to Cart',
  NEW_CART_CREATED: 'New Cart Created',
  CART_CLEARED: 'Cart Cleared',
  PRODUCT_REMOVED_CART: 'Product Removed from Cart',
  CART_COMPLETE: 'Cart Complete',
  SUB_PRODUCT_SELECTED: 'Sub-product should be selected',
  DOWNLOADING_STOCK_FILE: 'Downloading stock file.',
  NO_RECORD_FOUND: 'No record found.',
  SERIAL_EXPIRATION_REQUIRED: 'Serial number and expiration date required',
  STOCK_CARD_CREATED: 'Stock card created.',
  IMAGE_UPLOAD_SUCC: 'Image Upload Successful.',
  STOCK_EXCEL_DOWNLOADING: 'Stock excel file is downloading.',
  SERIAL_LOT_RECORDED: 'Serial/Lot number was recorded.',
  ACCOUNTING_PROGRAM_INFO: 'Automatic transfer settings have been entered for the Accounting Program.',
  DEFAULT_CHANNEL_CANNOT_AGAIN: 'Default channel cannot be turned on again',
  WEB_SERVICE_SETTINGS_ENTERED: 'Web service settings of the sales channel have been entered.',
  PROBLEM_CHANNEL_SETTINGS: 'There is a problem with the sales channel settings.',
  ACTIVE_PASSIVE_SETTING: 'Active/passive setting of the sales channel has been made.',
  ACTIVE_PASSIVE_SETTING_COULD_NOT: 'Sales channel active/passive could not be set',
  YOU_HAVE_MISSING_FIELDS: 'You have missing fields',
  COMMAND_QUEUED: 'The command is queued to be executed. We will let you know.',
  ORDER_STATUS_TRANSFERRED: 'Order statuses have been transferred.',
  PAYMENT_TYPES_TRANSFERRED: 'Payment types have been transferred.',
  COMPANY_LOGO_UPLOADED: 'Company logo has been uploaded.',
  LOGO_DELETED: 'The logo you uploaded has been deleted',
  SETTING_SAVED: 'Setting Saved',
  COMPANY_INFORMATION_RECORDED: 'Company information has been recorded',
  COMPANY_INFORMATION_UPDATED: 'Company information updated',
  COMPANY_NAME_REQUIRED: 'Company name field cannot be left blank',
  COMPANY_ADDRESS_REQUIRED: 'Company Address field cannot be left blank',
  BANK_INFO_MUST_ENTERED: 'Bank information must be entered completely',
  COMPANY_INFORMATION_DELETED: 'Company information has been deleted',
  DEFAULT_COMPANY_CHANGED: 'Default Company has been changed',
  REPORT_COPIED_CLIPBOARD: 'Report result copied to clipboard',
  ERROR_REPORT_COPIED_CLIPBOARD: 'Error while copying Report Result',
  STATUS_UPDATED: "<b>{{label}}</b> &nbsp; status updated",
  PAGE_SHORTCUT_NOT_ELIGIBLE: "Page shortcut not eligible to be created",
  SHORTCUT_CREATED: "Shortcut created",
  PAGE_WAS_ADDED_SHORTCUT: "This page was previously added as a shortcut",
  SETTINGS_BEEN_CHANGED: "Settings have been changed",
  PASSWORD_BEEN_CHANGED: "Your password has been changed.",
  PRINTER_SETTINGS_DEFINED: "Your printer settings are defined.",
  ROLE_CREATED: "The role has been created.",
  ROLE_UPDATED: " role has been updated.",
  USER_HAS_BEEN_UPDATED: " user has been updated.",
  APPOINTMENT_PROCESS: "The appointment process has been completed and you are being directed",
  BARCODE_NOT_DEFINED_SYSTEM: "This barcode is not defined in the system",
  SHELF_REPLACED: "Shelf Replaced",
  PRODUCT_PREPARATION_COMPLETED: "Since the product preparation was completed, we moved on to the next product.",
  NO_PRODUCT_SHELF: "There is no product on the shelf",
  INCORRECT_PRODUCT_QUANTITY_ENTRY: "Incorrect Product Quantity Entry",
  INCOMPLETE_COMPLETED: "Incomplete notification completed",
  PRODUCTS_SUCC_COMPLETED: "Products in this list have been successfully completed",
  WAS_ADDED_BEFORE: " was added before",
  ASSIGMENT_SUCC: "Assignment Successful",
  TRANSACTION_COMPLETED_SUCC: "Transaction completed successfully",
  ORDER_SENT_TO_ACCOUNTING: "Your order with {{transactionId}} has been sent to accounting.",
  INFORMATION_COMPLETELY: "Please Fill Out the Information Completely",
  WRONG_SHELF_CODE: "You Entered the Wrong Shelf Code",
  LEAST_1: "There can be at least 1",
  MAX_OF_PIECES: "There can be a maximum of {{quantity}} pieces.",
  NO_ORDERS_WERE_FOUND: "No orders were found prepared on this Carrier",
  ORDERS_IN_TOTAL: "There are {{ordersPrepared}} orders in total.",
  SELECTED_ORDERS_ERROR: "An error occurred while updating the status of selected orders",
  NO_PACKAGING_SHELF: "There is no Packaging Shelf.",
  CART_SECTION_NOT_FOUND: "Cart section not found.",
  ORDER_PREPARED_NOT_FOUND: "The order prepared by the carrier was not found.",
  PRODUCT_NOT_MATCH: "This product does not match any orders in the cart",
  CHECKING_STAGE_YET: "There are no listings in the Checking stage yet",
  ERP_FAILED_AGAIN_LATER: "Failed to Load ERP Transfers, Please Try Again Later",
  BRAND_ADDED_SUCC: "Brand added successfully",
  BRAND_UPDATED_SUCC: "Brand updated successfully",
  TAG_ADDED_SUCC: "Tag added successfully",
  CATEGORY_UPDATE_SUCC: "Category update successful.",
  CATEGORY_CREATE_SUCC: "Adding category was successful.",
  MAIN_CATEGORY_CREATE_SUCC: "Adding main category was successful.",
  CATEGORY_DELETE_SUCC: "Category deletion successful.",
  TEXT_COPIED: "The text \"{{text}}\" has been copied",
  RELATED_STOCK_LIST_NOT_LOADED: "Related Stock list could not be loaded",
  FILL_ALL_FIELDS: "Fill in all fields.",
  ADDED_TO_COUNT: "Added to count",
  WRITE_PRODUCT_BARCODE: "Please write the product barcode",
  COPYING_STOCK_CARD: "A new card was created by copying the stock card.",
  TRANSACTION_LIST_CREATED: "The transaction list has been created.",
  TEMPLATE_SUCC_REGISTERED: "The template has been successfully registered.",
  SUCC_REMOVED_LIST: "Successfully removed from list",
  DOWNLOAD_LIST_UPDATED: "Download list updated",
  ROW_LIST_UPDATED: ", row list updated",
  PASSWORD_SUCC_CHANGED: "Password successfully changed",
  NOT_FOUND_IN_ERP: "NOT FOUND IN ERP",
  SURE_SCANNING_CORRECT_BARCODE: "Make Sure You're Scanning the Correct Barcode",

  NOT_FOUND_THIS_LIST: " was not found in this list.",
  BARCODE_COMPLETED_FOR_ACTIVE_ORDER: " barcode completed for active order",
  BARCODE_NOT_ACTIVE_ORDER: " barcode is not in the active order. It was not transferred to the other order because it was a mixed list.",
  UNEXPECTED_ERROR_OCCURRED: "An unexpected error occurred. We will deal with this issue immediately. Please try again later.",
  CANNOT_ADD_MORE_PRODUCTS: "You Cannot Add More Products",
  ENOUGH_PRODUCTS: "You have marked more than enough {{quantity}} products",
  MUST_ENTER_SHELF_CODE: "You must enter the shelf code",
  RECEIVING_PROCESS_STOPPED: "Receiving Process Has Been Stopped",
  A_NEW_VERSION: "A new version has been released. You should delete your browser history.",
  CHOOSE_SHELF_FIRST: "Choose the Shelf First",
  LEAST_1_LOT: "You must choose at least 1 lot",
  PRODUCT_CHANGED: "The product has changed. However, since it was approved, the number was not increased.",
  NOT_RECEIVING: "The product has changed. However, the goods were not receiving because they were completed.",
  WAS_BBD: "The product has changed. However, it was not possible to log in because it was BBD.",
  ACTIVE_RECEIVING_NOT_SUPPORT_ADDING_PRODUCTS: "Active receiving does not support adding products.",
  MISTAKE_IMAGE_UPLOAD: "An error occurred while uploading the image. Mistake: ",
  APPROVED_PRODUCT_TRACKED_MESSAGE: "Approved Product is being tracked. However, serial number entry was not made. Approve?",
  COUNTED_QUANTITY_NOT_MATCH: "The serial numbers identified with the counted quantity do not match. Approve?",
  POLYPUS_STATUTE_UPDATED: "Polypus Statute updated",
  CHANNEL_STATUS_UPDATED: "Status on the channel has been updated",
  NOTE_ADDED_TO_CHANNEL_RECORD: "A note was made to the record on the channel regarding the order status.",
  NO_ACTION_TAKEN_YET: "No action taken yet",

  DAYS_AGO: "{{days}} days ago",
  DAYS_HOURS_AGO: "{{days}} days, {{hours}} hours ago",
  HOURS_AGO: "{{hours}} hours ago",
  HOURS_MINUTES_AGO: "{{hours}} hours, {{minutes}} minutes ago",
  MINUTES_AGO: "{{minutes}} minutes ago",
  MINUTES_SECONDS_AGO: "{{minutes}} minutes, {{seconds}} seconds ago",
  SECONDS_AGO: "{{seconds}} seconds ago",

  SELECTED_ORDERS_WILL_UPDATE: "The statuses of the selected orders will be updated. Are you sure?",
  EXPIRE_DATE_WARNING: "There is a product on this shelf whose SKT date is {{days}} days old.",
  MAIN_PRODUCT_CODE: "Main Product Code",
  CONNECTED_MAIN_PRODUCT: "Connected Main Product",
  LOT_SERIAL_ENTERED: "The lot/series numbers of the selected products must be entered as {{must_qty}} in total.",
  PRODUCT_SHOULD_BE_PLACED_SECTION: "The product should be placed in the {{section_name}} section.",
  PRODUCT_SHOULD_BE_PIECES: "The product should be {{qty}} pieces.",
  PRODUCT_PLACED_IN_SECTION: "Product {{product_code}} has been placed in the section.",
  ORDER_TRANSFERRED: "{{order_count}} orders have been transferred.",
  ENTER_QTY_OR_SCAN_BARCODE: "Enter Quantity or Scan Barcode",
  STATUS_SET_DEFAULT: " status has been set as default.",
  STATUS_REMOVED_DEFAULT: " status has been removed from default.",
  PRIORITY_RANKING: "Priority Ranking",
  SUITABLE: "Suitable",
  NOT_SUITABLE: "Not Suitable",
  TRANSACTION_ALREADY_ADDED: "{{transactionId}} has already been added",
  LIST_CREATED: "List Created. List Name: {{listName}}, Priority: {{priority}}",
  MANAGES_YOUR_BUSINESS: "It manages your business.",
  MOTTO_INFORMATICS: "motto informatics",
  GET_INFORMATION: "Get Information",
  OPEN_NEW_PAGE: "Open in New Page",
  SELECT_SERVICE: "You must select a service",
  SELECTION_LIST_WILL_EMPTIED: "The selection list will be emptied. Are you sure?",
  ERROR_CONNECT_TERMINAL: "The terminal could not be connected.",
  ORDER_REFERENCE_REMOVED: "Order with reference number {{orderNumber}} has been removed from the {{printerName}} printer.",
  PRINTER_NOT_DEFINED: "The printer {{printerName}} is not defined.",
  MAIN_PRODUCT_AFFILIATED_WITH: "Main product it is affiliated with",


  COUNT_COMPLETED_BY: "The count was completed by <b>{{name}}</b>. However, there are differences in the customs.",
  CONFIRM_QTY: "To confirm quantities",
  SEE_UPLOADED_DOCUMENT: "To see uploaded documents",
  SEE_SERIAL_EXP_DATE: "To see serial numbers and expiration dates",
  CLICK: "Click",
  DOCUMENT_UPLOADED_COUNT: "<b>{{qty}}</b> documents uploaded for counting",

  REQUEST_CREATED_FOR_MISSING_NOTIFICATION: "The request created for incomplete notification is currently in <b>{{status}}</b> status and Approved Quantity is: <b>{{qty}}</b>",
  B2C_SERVICE_COMPLETED: "Stock transfer from your B2C service completed.",
  CANNOT_PRINT_RESPONSIBLE_USER: "You cannot print without assigning the responsible user.",
  CHECK_YOUR_EMAIL_FOR_RESET_PASSWORD: "Please check your email for instructions on how to reset your password.",

  PRODUCT_OF_MOTTO: "Polypus is a product of <a href='{{url}}' target='_blank' class='motto-link'>Motto Informatics</a>. <br> © 2023 All rights reserved.",

  LOGOUT_AND_ALL_DEVICE: "Quit All Devices",

  CUSTOMER_CONTACTED: 'Discussed with the Customer',
  CUSTOMER_NOT_CONTACTED: 'The customer was not contacted.',
  OUTSIDE_RETURN_ACCEPT_STATUS:'Return Acceptance Status',
  OUTSIDE_RETURN_ACCEPT_STATUS_ACCEPT:'Accept Returns',
  OUTSIDE_RETURN_ACCEPT_STATUS_REJECT:'Reject Return',
  OUTSIDE_RETURN_ACCEPT_STATUS_ONLY_REJECT:'Reject Only Damaged Items',

  NEW_PRODUCTION_PLAN : 'New Production Plan',
  PRODUCTION_PLANNING: 'Production Planning',

  DOWNLOAD_NOTIFICATION: "Download Links",
  QUICK_PROCESS_FILTERS: "Fast Process Filters",
  PROCESS_FILTERS: "Process Filters",
  CUSTOMER_CONTACT : 'Customer Notice',
  RECEIVING_ACCEPT : 'Return Acceptance Status',
  FIRST_PRICE_CONTROL : 'Offer Price Approval',
  FIRST_QUANTITY_CONTROL : 'Offer Quantity Approval',
  DOCUMENT_PRICE_CONTROL : 'Document Price Approval',
  DOCUMENT_QUANTITY_CONTROL : 'Document Quantity Approval',
  LAST_PRICE_CONTROL : 'Actual Price Confirmation',
  LAST_QUANTITY_CONTROL : 'Actual Quantity Approval',
  FREE_QUANTITY_CONTROL : 'Free Product Approval',
  CUSTOMER_CONTROL : 'Current Approval',
  DISCOUNT_CONTROL : 'Discount Approval Confirmation',

  RECEIVING_CONFLICT: "With Quantity Difference",
  RECEIVING_REJECT_QUANTITY: "Unacceptable Product",

  ACCEPTED : 'Approved',
  REJECTED : 'Rejected',
  PURCHASE_REQUEST_FAILED : 'Purchase Failed',
  IS_USE_SHELF: "Is the shelf used?",
  USE_SHELF: "Using shelves",
  NOT_USE_SHELF: "Does not use shelves",
  NEW_VIEW: "New View"
};


var langEn = angular.merge(
  MenuEn,
  accountSettingsEn,
  cargoEn,
  customersEn,
  dashboardIndexEn,
  integrationPageEn,
  batchOperationsEn,
  invoicesEn,
  locationEn,
  loginEn,
  batchOperationsForTsoftEn,
  actionEn,
  notificationEn,
  ordersPrepareEn,
  reportsEn,
  commonEn,
  paginationEn,
    toastServiceMessages
);

export {langEn};
