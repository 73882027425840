class ShelfOperationAssignController {
    constructor(API, ToastService, DialogService, $state, $translate, $location, $window, $sce, $timeout, $scope, $rootScope, $mdBottomSheet) {
        "ngInject";

        this.API = API;
        this.window = $window;
        this.$scope = $scope;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$translate = $translate;
        this.$location = $location;
        this.sce = $sce
        this.$timeout = $timeout
        this.$rootScope = $rootScope;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$window = $window;

        this.term = '';
        this.notMatch = false;
        this.lot = [];
        this.assignItems = [];
        this.shelfId = null;
    }

    $onInit() {
    }


    shelfAssignProductSearch() {
        this.notMatch = false
        if (2 >= this.term.length) return;
        this.API.all("shelf/assign/product?q=" + this.term)
            .customGET()
            .then((response) => {
                if (response.response.status === 204) {
                    this.notMatch = true
                }
                this.searchResults = response.data.list
            });
    }

    selectedAssignItem(item) {
        this.lot = {}

        if (0 < item.lot.length) {
            this.lot = {
                productId: item.productId,
                productName: item.productName,
                list: item.lot
            };
            return
        }

        let exists = this.assignItems.filter(as => as.productId === item.productId)

        if (0 < exists.length) return;

        this.assignItems.push({
            productId: item.productId,
            productName: item.productName,
        })
    }

    assignedItemSelectedStatus({productId, lotId}) {
        return 0 < this.assignItems.filter(as => {
            if (lotId) {
                return as.productId === productId && as?.lotId === lotId
            }
            if (productId) {
                return as.productId === productId
            }

        }).length
    }

    assignProduct(){
        let data = this.assignItems.map(as=>{
            return {
                productId: as.productId,
                lotId: as?.lotId
            }
        })
        this.API.all('shelf/assign/product')
            .post({
                shelfId:this.shelfId,
                items:data,
            })
            .then((response) => {
                if (response.response.status === 207){
                    let li =''
                    response.data.list.map(list =>{
                        li += '<li>'+list+'</li>'
                    })
                    if (response.data.list.length > 0 ) {
                        this.ToastService.showHtml('warn','<ul>'+li+'</ul>')
                    }
                    else {
                        this.assignItems.map(item => {
                            this.ToastService.showHtml('success',item.productId + ' ' + this.$translate.instant('BARCODED_PRODUCT_ADDED_LIST'));
                        })
                    }
                    this.assignItems    = []
                    this.searchResults  = []
                    this.lot            = {}
                    this.term           = null
                    this.refresh()
                }
            });
    }

    selectedAssignLotItem({item,productId,productName}){

        let exists = this.assignItems.filter(as=>as.productId === productId && as.lotId === item.lotId )
        if (0 < exists.length) return;
        this.assignItems.push({
            productId     : productId,
            productName   : productName,
            serialNumber  : item.serialNumber,
            lotId         : item.lotId,
        })
    }

    deSelectAssignLotItem(item){
        this.assignItems  = this.assignItems.filter(as=> as.productId !== item.productId || as?.lotId !== item?.lotId)
    }


}

ShelfOperationAssignController.$inject = ["API", "ToastService", "DialogService", "$state", "$translate", "$location", "$window", "$sce", "$timeout", "$scope", "$rootScope", "$mdBottomSheet"];
const ShelfOperationAssignComponent = {
    templateUrl: "./views/angular/app/components/location-management/partials/shelf-operation-assign.html",
    controller: ShelfOperationAssignController,
    controllerAs: "vm",
    bindings: {
        shelfId : '<',
        refresh : '&'
    },
};
export {ShelfOperationAssignComponent, ShelfOperationAssignController}
