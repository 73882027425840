import {getToken} from "../../../utils/users";

class ShelfOperationsController {
    constructor(API, ToastService, DialogService, $state, $translate, $location, $window, $sce, $timeout, $scope, $rootScope, $mdBottomSheet) {
        "ngInject";

        this.API = API;
        this.window = $window;
        this.$scope = $scope;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$translate = $translate;
        this.$location = $location;
        this.sce = $sce
        this.$timeout = $timeout
        this.$rootScope = $rootScope;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$window = $window;

        this.locations = [];
        this.products = [];
        this.locationId = null;
        this.shelf = null
        this.page = 1;
        this.perPage = 25;
        this.total = 0;
        this.token = getToken();
        this.shelfBarcode = null

    }

    $onInit() {
        this.getLocation()
    }
    $onChanges(changeList){
        console.log('---------',changeList,this.shelfBarcode,this.locationId)
        if (changeList?.shelfBarcode && changeList?.shelfBarcode?.currentValue ){
            this.shelfSearch(this.locationId,this.shelfBarcode,false)
        }
    }

    getLocation() {
        var parent = this;
        this.API.all("location")
            .customGET()
            .then((response) => {
                if (response.errors === false) {
                    this.locations = response.data.location;
                    this.locationId = this.locations[0].id;
                    let shelf_code = this.$window.document.getElementById('shelf_code');
                    shelf_code.focus();
                }
            });
    }

    shelfSearch(locationId, barcode, report) {
        if (!barcode){
            this.ToastService.error(this.$translate.instant('NO_SUITABLE_SHELF_FOUND'));
            return true;
        }

        this.API.all(`location/${locationId}/shelf/exist`)
            .customGET(null, {barcode})
            .then((response) => {
                if (response.response.status === 204) {
                    this.ToastService.error(this.$translate.instant('NO_SUITABLE_SHELF_FOUND'));
                    this.clear()
                    return true;
                }
                this.shelf = response.data.shelf
                this.getShelf();
                this.filter(1, 25, report);
            })
    }

    getShelf(){
        this.API.all(`location/${this.locationId}/shelf`)
            .customGET(null, {barcode:this.shelf.shelfBarcode})
            .then((response) => {
                const shelfMeta = response.data.shelves.data[0];
                this.shelf.locationName = shelfMeta.locationName
                this.shelf.name = shelfMeta.name
                this.shelf.width = shelfMeta.width
                this.shelf.height = shelfMeta.height
                this.shelf.depth = shelfMeta.depth
                this.shelf.seq = shelfMeta.seq
                this.shelf.barcode = shelfMeta.barcode
                this.shelf.barcodeImage = shelfMeta.barcodeImage
            });
    }

    filter(page = 1, limit = 25, report = false) {
        let header = {}
        let query = {page, limit};
        if (report) {
            header = {'x-api-output': 'excel',}
            query.token = this.token
        }

        this.API.all(`location/${this.locationId}/shelf/${this.shelf.shelfId}/product`)
            .customGET(null, query, header)
            .then((response) => {
                let data = response.data;
                if (report) {
                    this.ToastService.showHtml('success', data.message);
                    this.filter(page, limit, false)
                    return true;
                }
                this.shelfOperations = true;
                this.page = data.page
                this.perPage = data.limit
                this.total = data.total
                this.products = response.data.products
            });
    }


    changeShelfDetails(){
        this.changeShelfDetail = !this.changeShelfDetail;
    }
    unAssignItem(itemId) {
        this.DialogService.confirm(
            this.$translate.instant('CONFIRM_MESSAGE'),
            this.$translate.instant('WANT_TO_DELETE_PRODUCT')
        ).then(()=>{
            this.API.all('shelf/assign/product/'+ this.shelf.shelfId + '/item/' + itemId)
                .customPUT()
                .then((response) => {
                    if (204 === response.response.status){
                        this.refresh()
                    }
                });
        })
    }


    refresh(){
        this.getShelf();
        this.filter(1, 25);
        // aç kapa
        this.changeShelfDetails()
        this.changeShelfDetails()
    }

    showProduct(productId) {
        if (this.$state.is("app.stock_management")) {
            //todo:aynı state için farklı bişey gerekiyor, reload:true yerine
            this.$state.go("app.stock_management", {tab: 1, id: productId}, {reload:true});
        } else {
            // this.$state.go("app.stock_management", {tab: 1, term: this.inputSearchTerm});
            this.$mdBottomSheet.show({
                templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
                controller: "BottomViewController",
                controllerAs: "vm",
                bindToController: true,
                locals: {
                    id: productId
                }
            })
        }
    }


    getShelfLog(shelfId) {
        this.API.all("shelf/log/"+shelfId)
            .customGET()
            .then((response) => {
                this.selectedActions = []
                this.showShelfLog(response.data.list,shelfId)
            })
    }
    showShelfLog(logProducts, shelfId) {
        let d = this.DialogService;
        let parent = this;
        let options = {
            locals: {
            },
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = d
                scope.logProducts = logProducts


                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
                scope.getLog = (productId) => {
                    parent.API.all("shelf/log/"+shelfId+"/"+productId)
                        .customGET()
                        .then((response) => {
                            scope.shelfLogs = response.data.list;
                        })
                }
            },
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/location-management/partials/dialog/log-detail.html",options)
            .then((response)=>{
            })

    }

    showCameraView() {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            parent.shelfBarcode = response;
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }

    clear(){
        this.products = [];
        this.shelf = null
        this.page = 1;
        this.perPage = 25;
        this.total = 0;
        this.shelfOperations = false;
        this.shelfId = null
    }

}

ShelfOperationsController.$inject = ["API", "ToastService", "DialogService", "$state", "$translate", "$location", "$window", "$sce", "$timeout", "$scope", "$rootScope", "$mdBottomSheet"];
const ShelfOperationComponent = {
    templateUrl: "./views/angular/app/components/location-management/partials/shelf-operations.html",
    controller: ShelfOperationsController,
    controllerAs: "vm",
    bindings: {
        shelfBarcode:'<?',
        locationId:'<?'
    },
};
export {ShelfOperationComponent, ShelfOperationsController}
