import "./orders-prepare.scss";
import {getUserInfo, getUserSetting, me} from "../../../utils/users";


class PickingController {
    constructor(API, ToastService, $translate, DialogService, $state, $location, $window, $sce, $timeout, $scope, $rootScope, $mdBottomSheet) {
        "ngInject";

        this.API                = API;
        this.window             = $window;
        this.$scope             = $scope;
        this.ToastService       = ToastService;
        this.$translate         = $translate;
        this.DialogService      = DialogService;
        this.$state             = $state;
        this.$location          = $location;
        this.$rootScope         = $rootScope;
        this.$mdBottomSheet = $mdBottomSheet;

        this.userInfo           = getUserInfo();
        this.boxShowingSettings = getUserSetting('actionProcessesBoxShowing');
        this.boxShowing         = null;
        this.currentUserId      = this.userInfo.uid;

        this.screen                 = 'select'
        this.pendingPrepareList     = []
        this.ownPrepareList         = []
        this.actionPrepareListV2    = [];
        this.orderListType          = 'picking';
        this.productBarcode = null
        this.stocktakingShelfCode = null;

        this.missingFirstShow = true;


        this.priority = {
            1: 'normal',
            2: 'high',
            3: 'very-high',
            4: 'urgent',
        }

        this.falseBeep = new Audio('./files/error.wav');
        this.trueBeep = new Audio('./files/success.wav');
        this.beepSound = new Audio('./files/success.wav');
    }

    $onInit() {
        let parent = this;

        // ilk yükleme
        parent.init()

        parent.$scope.$on('pickingEvent', function (event, data) {
            parent.init()

        });
    }


    init() {
        document.querySelector('md-toolbar').hidden = false;
        document.querySelector('md-tabs-wrapper').hidden = false;
        this.getPrepareProcess();
        this.getTerminalInfo()
    }

    getTerminalInfo() {
        this.ToastService.success(this.$translate.instant('POLYPUS_READY_HANDHELD_TERMINAL'))
        let parent = this;
        let barcode = '';
        let interval;
        const terminalEvent = (e) => {
            if (interval) {
                clearInterval(interval)
            }
            if (e.code == 'Enter' && barcode) {
                parent.scanCompleteCallback(barcode)
                barcode = '';
                return true;
            }
            if ('Shift' !== e.key) {
                barcode += e.key
            }
            interval = setInterval(() => barcode = '', 20)

        }
        this.window.document.addEventListener('keydown', terminalEvent, true)

    }


    scanCompleteCallback = (decodedText, decodedResult) => {

    };

    onSelectTab(obj) {
        this.$location.search(obj);

    }

    closePrepareScreen() {
        this.missingFirstShow = true;
        this.screen = 'select';
        this.getPrepareProcess();

        this.$rootScope.$broadcast('toggleSidebarEvent', {});

        document.querySelector('md-toolbar').hidden = false;
        document.querySelector('md-tabs-wrapper').hidden = false;
    }

    getPrepareProcess() {
        this.productBarcode = null
        let listType = this.orderListType + '|quickPicking';

        this.API.all("action-process/prepare").doGET(null, {
            listType: listType
        })
            .then((response) => {
                let count = {count: {picking: 0}};
                if (204 === response.response.status) {
                    this.pendingPrepareList = []
                    this.ownPrepareList = []
                    this.$rootScope.$emit('prepareChildEvent', count)
                    return;
                }
                count.count.picking = response.data.count;
                this.pendingPrepareList = response.data.list.filter(list => !list.prepareUserId)
                this.ownPrepareList = response.data.list.filter(list => list.prepareUserId === this.currentUserId)
                this.$rootScope.$emit('prepareChildEvent', count)

            });
        this.focusInput('productBarcode');
    }

    listAssignMe(listId = null) {
        let params = {
            listType: this.orderListType,
        }
        this.API.all(`action-process/assignPrepare/${listId ?? '_'}/assign`)
            .customPUT(params)
            .then((response) => {
                this.ToastService.showHtml('success', this.$translate.instant('APPOINTMENT_PROCESS'))
                this.actionDetail(response.data.listId)
            });
    }

    actionDetail(listId, roadStartShelf = null) {
        this.API.all('action-process/prepare/' + listId)
            .doGET(null, {
                grouped: 'product',
                startingShelf: roadStartShelf
            })
            .then((response) => {
                this.prepareScreenData(listId, response.data.list)

                this.$rootScope.$broadcast('toggleSidebarEvent', {});
                document.querySelector('md-toolbar').hidden = true;
                document.querySelector('md-tabs-wrapper').hidden = true;
                document.querySelector('.md-content-scroll').style.height = 'calc(100vh - 90px)';
            });
    }

    prepareScreenData(listId, detail) {
        this.actionPrepareListV2 = detail;

        this.listId = listId
        this.actionPrepareListV2.lines.forEach(l => {
            l.selectedShelf = l.shelves[0]
        })

        this.activeProduct = this.actionPrepareListV2.lines.find(line => {
            return line.remainingQuantity > 0;
        })
        this.stocktakingShelfCode = this.activeProduct?.selectedShelf?.barcode;

        this.boxShowing = this.boxShowingSettings[this.activeProduct.locationId]

        this.screen = 'prepare';
        this.focusInput('productBarcode');

        if (this.missingFirstShow && this.actionPrepareListV2?.completedWithMissing){
            this.missingFirstShow = false;
            this.missingQuantity()
        }

    }

    quantityCheck() {

        this.API.all(`action-process/prepare/${this.listId}/barcode-check`)
            .customGET(null,{
                barcode         : this.productBarcode,
                productBarcode  : this.activeProduct.barcode,
                type            : 'product|shelf|serialNumber'
            })
            .then((response) => {
                let list = response.data.list
                if (list.usable !== true){
                    this.falseBeep.play()
                    this.ToastService.showHtml('error',this.$translate.instant('BARCODE_NOT_DEFINED_SYSTEM'))
                    return
                }

                if ('shelf' === list.barcodeType){
                    this.activeProduct.selectedShelf = this.activeProduct.shelves.filter(shelf=>shelf.barcode ===this.productBarcode)[0]
                    this.ToastService.showHtml('success',this.$translate.instant('SHELF_REPLACED'))
                    this.stocktakingShelfCode = this.productBarcode
                    this.trueBeep.play()
                    return;
                }

                if (this.productBarcode !== this.activeProduct.barcode) {
                    this.changeProduct(this.productBarcode)
                    return;
                }

                this.productQuantity = 1;
                if (list.showQuantityBox ===false){
                     this.preparedListItem();
                     return;
                 }
                this.multipleQuantity()

            }).catch(err => {
            this.falseBeep.play()
            this.productBarcode = null;
            this.focusInput('productBarcode');

            console.log(err);
        });
    }

    changeProduct(barcode) {
        if (barcode === this.activeProduct.barcode){
            this.productBarcode = null;
            return true;
        }

        let index = this.actionPrepareListV2.lines.filter(item => item.barcode === barcode);
        if (index.length === 1){
            if (index[0].remainingQuantity === 0){
                this.ToastService.showHtml('success',this.$translate.instant('PRODUCT_PREPARATION_COMPLETED'))
            }
            this.prepareListTargetProduct(index[0]);
            this.productBarcode = null;
        }
    }

    prepareListTargetProduct(target) {
        this.shelffill = null;
        if (target.remainingQuantity > 0) {
            this.activeProduct = target;
            this.stocktakingShelfCode = this.activeProduct.shelves[0].barcode

            this.productBarcode = this.activeProduct.barcode;
            this.productQuantity = 1;

            this.stockType = this.activeProduct.stockType
            this.selectChange()
            this.productBarcode = null;
            this.focusInput('productBarcode');

        }

    }
    preparedListItem() {
        let _this = this
        let activeShelf;
        let data = {
            shelf     : this.stocktakingShelfCode,
            barcode   : this.productBarcode,
            quantity  : this.productQuantity,
            productId     : this.activeProduct.productId,
            subProductId  : this.activeProduct.subProductId,
        }

        this.API.all('action-process/prepare/' + this.listId)
            .customPOST(data)
            .then((response) => {
                if (response.response.status === 200) {
                    this.trueBeep.pause();
                    this.trueBeep.play();

                    this.focusInput('productBarcode');

                    if (response.data.list?.processComplete){

                        let stockType = this.stockType;

                        var options = {
                            controllerAs      : 'vm',
                            bindToController  : true,
                            onShowing         : function (scope, element, options, controller){
                                scope.reportName          = stockType === 'preStock' ? 'Mal Kabul':'Sipariş Hazırlama';
                                scope.report              = response.data.list.report;
                                scope.collapsed           = false;
                            },
                        };

                        let OPDialogPromise = this.DialogService.fromTemplateV2(
                            './views/angular/app/components/orders-prepare/partials/dialogs/prepare-report.html',
                            options
                        ).then((status) => {
                            _this.getPrepareProcess()
                            _this.closePrepareScreen()
                        }).catch(()=>{
                            _this.getPrepareProcess()
                            _this.closePrepareScreen()
                        })
                        return false
                    }

                    this.prepareScreenData(this.actionPrepareListV2.listId,response.data.list);
                    this.acceptableShelf = response.data.list
                    if (!this.activeProduct.takenShelves){
                        this.activeProduct.takenShelves = []
                    }

                    let exists = this.activeProduct.takenShelves.findIndex(shelf => shelf.shelfId ===  _this.acceptableShelf.shelfId && shelf.lotId === _this.acceptableShelf.lotId)
                    if (exists === -1){
                        this.activeProduct.takenShelves.push({
                            shelfId: _this.acceptableShelf.shelfId,
                            shelfName: _this.acceptableShelf.shelfName,
                            lotId: _this.acceptableShelf.lotId,
                            quantity: _this.productQuantity
                        })
                    } else {
                        this.activeProduct['takenShelves'][exists].quantity += _this.productQuantity;
                    }

                    if (0 === this.activeProduct.remainingQuantity) {
                        this.listCheck();
                        this.nextProduct();
                        // this.ToastService.success('Bu ürünün listedeki toplama süreci tamamlanmıştır.');
                    }
                }
                if (response.response.status === 204) {
                    this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));
                    this.falseBeep.play()
                    this.productQuantity = 1
                }
                this.productBarcode = null;
                this.barcodeFocus = true;
            })
            .catch((response)=>{
                this.falseBeep.play()
                this.productBarcode = null;
                this.barcodeFocus = true;
                this.productQuantity = 1
            })
        //this.productQuantity = 1;
    }

    multipleQuantity(){
        let acceptAllQuantity = 0;
        if (this.activeProduct.selectedShelf.items[0].quantity <= this.activeProduct.remainingQuantity){
            acceptAllQuantity = this.activeProduct.selectedShelf.items[0].quantity;
        } else {
            acceptAllQuantity = this.activeProduct.remainingQuantity;
        }
        this.quantityOpen = true
        var options = {
            controller: "MultipleQuantityController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                stocktakingShelfCode            : this.stocktakingShelfCode,
                actionPrepareProductListSelect  : this.activeProduct,
                quantity                        : this.activeProduct.quantity,
                remainingQuantity               : acceptAllQuantity,
                productQuantity                 : this.productQuantity,
                productBarcode                  : this.productBarcode,
                orderListType                   : this.orderListType
            }
        }
        if (this.activeProduct.shelves[0].items[0].quantity === 0 ){
            this.ToastService.error(this.$translate.instant('NO_PRODUCT_SHELF'));
            return;
        }
        if (this.activeProduct.selectedShelf.items[0].quantity === 0 ){
            this.ToastService.error(this.$translate.instant('NO_PRODUCT_SHELF'));
            return;
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/orders-prepare/partials/multiple-quantity-modal.html",options)
            .then((response)=>{
                this.focusInput('productBarcode');
                if(response.message){
                    this.productBarcode = null;
                    this.ToastService.error(response.message)
                }
                if(response.productQuantity){
                    this.productQuantity = response.productQuantity
                    this.stocktakingShelfCode = response.stocktakingShelfCode
                    this.multipleQuantityCheck();
                }
                this.focusInput('productBarcode');
                this.quantityOpen = false
            })
            .catch(()=>{
                this.focusInput('productBarcode');
                this.productBarcode = null;
            });
    }
    multipleQuantityCheck(){
        if (this.productQuantity === undefined || this.productQuantity === '') {
            this.ToastService.error(this.$translate.instant('INCORRECT_PRODUCT_QUANTITY_ENTRY'));
            return
        }
        if (this.productQuantity > 1) {
            let parent = this;
            var options = {
                controller: "OrdersPrepareController",
                controllerAs: 'vm',
                locals: {
                    dialog: true,
                    productQuantity: this.productQuantity
                },
                bindToController: true,
            };
            let OPDialogPromise = this.DialogService.fromTemplateV2(
                './views/angular/app/components/orders-prepare/partials/orders-prepare-for-list-barcode.component.html',
                options
            ).then((status) => {
                if (status) {
                    this.preparedListItem();
                }
                this.productBarcode = null;
            }).catch(()=>{
                this.productBarcode = null;
                this.barcodeFocus = true;
            })
            this.productBarcode = null;
            this.productOpen = false
            return;
        }else{
            // this.productQuantity = 1;
            this.preparedListItem();
            this.productOpen = false
            this.productBarcode = null;
        }
        this.productBarcode = null;
        this.listCheck();
    }

    showOtherShelves() {
        let shelves = this.activeProduct.shelves;
        let current = this.activeProduct.selectedShelf.shelfId;
        let dialog = this.DialogService
        var options = {
            controllerAs      : 'vm',
            bindToController  : true,
            onShowing         : function (scope, element, options, controller){
                scope.dialog = dialog;
                scope.shelves = shelves;
                scope.current = current;
                scope.selectShelf = (shelf) =>{
                    scope.dialog.$mdDialog.hide(shelf)
                }

            },
        };

        var dialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/orders-prepare/partials/dialogs/other-shelves-dialog.html',
            options
        );
        dialogPromise.then(shelf=>{
            this.activeProduct.selectedShelf = shelf
            this.stocktakingShelfCode = shelf.barcode
            this.trueBeep.play()
            this.focusInput('productBarcode');

        })
        dialogPromise.catch(err=>{
            console.log(err)
            this.focusInput('productBarcode');

        })

    }
    messageModal(notes){
        let options = {
            controller: 'OrdersPrepareController',
            controllerAs: 'vm',
            locals: {
                notes : notes,
            },
            bindToController: true,
        }
        var msgModal = this.DialogService.fromTemplateV2(
            './views/angular/app/components/orders-prepare/partials/dialogs/action-message-dialog.html',
            options
        );
        msgModal.catch((response)=>{
            this.barcodeFocus = true
        })
    }

    printLabel(product){
        var options = {
            controller: 'PrintPreviewDialogController',
            controllerAs: 'vm',
            locals: {
                productId: product.productId,
                productName: product.productName,
            },
            bindToController: true,
            multiple: true,
            onShowing: function (scope, element, options, controller) {
                controller.printLabel(product)
            },
        };
        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/dialogs/printPreview/printPreview.dialog.html',
            options
        );
        customerDialogPromise.catch(err => {
            console.log(err)
        })
    }



    previousProduct() {
        let currentIndex = this.actionPrepareListV2.lines.findIndex(s=>s.barcode === this.activeProduct.barcode);
        currentIndex = currentIndex === 0 ? this.actionPrepareListV2.lines.length - 1 : currentIndex - 1;
        this.activeProduct = this.actionPrepareListV2.lines[currentIndex]
        this.stocktakingShelfCode = this.activeProduct?.selectedShelf?.barcode;
        this.productBarcode = null;
        if (this.activeProduct.remainingQuantity === 0){
            this.previousProduct()
            return
        }
        this.focusInput('productBarcode');

    };

    nextProduct() {
        let currentIndex = this.actionPrepareListV2.lines.findIndex(s=>s.barcode === this.activeProduct.barcode);
        currentIndex = (currentIndex === this.actionPrepareListV2.lines.length - 1) ? 0 : currentIndex + 1;
        this.activeProduct = this.actionPrepareListV2.lines[currentIndex]
        this.stocktakingShelfCode = this.activeProduct?.selectedShelf?.barcode;
        this.productBarcode = null;
        if (this.activeProduct.remainingQuantity === 0){
            this.nextProduct()
            return
        }
        this.focusInput('productBarcode');
    };

    manuelQuantityCheck(){
        this.productQuantity = 1;
        // manuelde box her türlü açışsın
        // let quantityBox = this.boxShowing?.quantityBox
        // if (this.activeProduct.quantity === 1 || this.activeProduct.remainingQuantity === 1 || !quantityBox) {
        //     this.preparedListItem();
        //     return;
        // }
        this.multipleQuantity()
    }

    focusInput(id) {
        document.getElementById(id)?.focus();
    }


    selectChange() {
        this.actionPrepareListV2.lines.map(item => {
            item.select = false;
            if(this.activeProduct.index === item.index) {
                item.select = true;
            }
        })
        this.barcodeFocus = true

    }


    missingQuantity() {
        let parent = this;
        let missingItem = [];

        this.actionPrepareListV2.lines.map(item => {
            if (item.missingQuantity === 0 ){
                return;
            }

            let shelvesQuantity = 0;
            if (item.shelves.length> 0) {
                shelvesQuantity = item.shelves.map(shelf => {
                    return shelf?.items[0]?.quantity;
                }).reduce((sum, shelfQuantity) => sum + shelfQuantity)
            }

            missingItem.push({
                remainingQuantity: item.missingQuantity,
                shelvesQuantity: shelvesQuantity,
                productName: item.productName,
            })

        })

        var options = {
            controllerAs      : 'vm',
            bindToController  : true,
            onShowing         : function (scope, element, options, controller){
                scope.API                 = parent.API
                scope.DialogService       = parent.DialogService
                scope.listId              = parent.listId;
                scope.missingItem         = missingItem;
                scope.sendMissingQuantity= () =>{
                    scope.API.all(`action-process/missing/${scope.listId}`)
                        .customPUT()
                        .then((response) => {
                            scope.DialogService.$mdDialog.hide(response)
                        });
                }

            },
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/orders-prepare/partials/dialogs/missing-quantity-dialog.html',
            options
        );
        customerDialogPromise.then(response=>{
            if (response.response.status === 207){
                this.ToastService.show(this.$translate.instant('INCOMPLETE_COMPLETED'))
                this.closePrepareScreen()
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    showProductBottomSheet(productId) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                id: productId
            }
        })
    }
    listCheck(){
        let sum = 0;
        this.actionPrepareListV2.lines.map(item => {
            if (item.preparedQuantity === item.quantity){
                sum += 1;
            }
        })
        if (sum === this.actionPrepareListV2.lines.length){
            this.ToastService.success(this.$translate.instant('PRODUCTS_SUCC_COMPLETED'));
            this.screen = 'select'
            this.getPrepareProcess();
        }
    }

    showListDetail(listId) {
        let options = {
            controller: "IssuesDetailController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                listId: listId
            },
            onShowing: function (scope, element, options, controller) {
                controller.getList()
            },
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/issues/partials/issues-detail.html",options)
            .then((response)=>{
            })
    }
    showCameraView() {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            parent.productBarcode = response;
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }


    addMissingBasket(listId,productId) {
        console.log(listId,productId)
        this.API.all(`action-process/prepare/${listId}/missing`)
            .patch(null,{
                productId:productId,
            })
            .then((response) => {
                this.prepareScreenData(listId,response.data.list)
            })
    }
    deleteMissingBasket(listId,productId) {
        this.API.all(`action-process/prepare/${listId}/missing`)
            .customDELETE(null,{
                productId:productId,
            })
            .then((response) => {
                this.prepareScreenData(listId,response.data.list)
            })
    }

    quickMovementPreStock() {
        console.log(this.activeProduct.unit)
        this.DialogService.fromTemplateV3({
            template: ` <pending-pre-stock-action-list-component product-id="${this.activeProduct.productId}" location-id="${this.activeProduct.locationId}" quantity="${this.activeProduct.remainingQuantity}" unit="'${this.activeProduct.unit}'" only-action="true"></pending-pre-stock-action-list-component>`,
        })
            .then(response => this.actionDetail(this.listId))
            .catch(err=> console.log(err))
    }

}

PickingController.$inject = ["API", "ToastService", "$translate", "DialogService", "$state", "$location", "$window", "$sce", "$timeout", "$scope", "$rootScope", "$mdBottomSheet"];
const PickingComponent = {
    templateUrl:
        "./views/angular/app/components/orders-prepare/partials/orders-prepare-for-list.component.html",
    controller: PickingController,
    controllerAs: "vm",
    bindings: {},
};
export {PickingComponent, PickingController}
