import {APIService} from './services/API.service';
import {DialogService} from './services/dialog.service';
import {ToastService} from './services/toast.service';
import {UserService} from './services/user.service';
import {PstorageService} from './services/pstorage.service';
import {TabActionService} from "./services/tabAction.service";
import {TimeRangeService} from "./services/timerange.service";
import {TokenService} from "./services/token.service";
import {LoadingService} from "./services/loading.service";

angular.module('app.services')
	.service('API', APIService)
	.service('DialogService', DialogService)
	.service('ToastService', ToastService)
	.service('PstorageService', PstorageService)
	.service('UserService', UserService)
	.service('TabActionService',TabActionService)
	.service('TimeRangeService', TimeRangeService)
	.service('TokenService', TokenService)
	.service('LoadingService', LoadingService)
	.service('markdownService', function () {
		// markdown-it kütüphanesini burada kullanabilir veya dışarıdan bir dosyaya bağlayabilirsiniz
		this.md = require('markdown-it')();
	})
;

