import {DialogService} from "../../../services/dialog.service";

class IssuesCreateController {
    constructor(API,$state,$mdBottomSheet,$translate,ToastService,DialogService,$scope,$rootScope) {
        'ngInject';

        this.API                = API;
        this.$state             = $state;
        this.$mdBottomSheet     = $mdBottomSheet;
        this.ToastService       = ToastService;
        this.DialogService      = DialogService;
        this.$scope             = $scope;
        this.$rootScope         = $rootScope;
        this.page               = 1;
        this.limit              = 20;
        this.total              = 0;
        this.$translate         = $translate;
        this.endDate            = new Date();
        this.startDate          = new Date((new Date()).setDate((new Date()).getDate() - 5));
        this.listPriority       = null;
        this.transactionId      = null;
        this.customerCode       = null;
        this.customer           = null;
        this.allAction          = false;
        this.selectedActions    = [];
        this.pageList           = [];
        this.actionTypes        = [];
        this.singularType       = 'mixed';
        this.listId             = null;
        this.tabScreen          = null;
        this.listPriority       = 1;

        this.type = {
            'createIssue' : ['picking'],
            'createReturnIssue' : ['shelfUp'],
            'createFaultyIssue' : ['faulty']
        }
        this.issueScreen = null;
        this.issueTabScreen = null;
    }
    $onInit() {
        let parent = this;
        parent.$scope.$on('issueEvent', function (event, data) {
            parent.issueScreen = data.screen
            parent.listId = data?.listId

            if (parent.tabScreen === data.screen){
                parent.getFilter().then(() =>{
                    parent.getActions(1)
                    parent.selectedActions = []
                })
            }
        })
    }
    $onDestroy() {
        this.$mdBottomSheet.hide()
        this.listId = null
    }

    getFilter(){
        this.API.all("location")
            .customGET()
            .then((response) => {
                if (response.errors === false) {
                    this.locations = response.data.location;
                }
            });

        return this.API.all("action-prepare/inventory")
            .doGET(null,{
                listTypes : this.type[this.issueScreen].join('|'),
            })
            .then((response) => {
                this.actionFilterInventory = response.data.list
            });
    }
    getActions(page,limit=25){
        let marketplace = this.actionFilterInventory?.marketplaces.data.filter(d=>d.select).map(d=>d.marketplace).join('|');
        let shipments = this.actionFilterInventory?.shipments.data.filter(d=>d.select).map(d=>d.code).join('|');
        let channels = this.actionFilterInventory?.channels.data.filter(d=>d.select).map(d=>d.channelId).join('|');

        this.API.all("action-prepare")
            .doGET(null,{
                marketplaces    : marketplace,
                shipments       : shipments,
                channels        : channels,
                listTypes       : this.type[this.issueScreen].join('|'),
                actionTypes     : this.actionTypes.join('|'),
                limit           : limit,
                page            : page,
                startDate       : this.startDate,
                endDate         : this.endDate,
                transactionId   : this.transactionId,
                singularType    : this.singularType,
                customerCode    : this.customerCode,
                customer        : this.customer,
                locationId      : this.locationId
            })
            .then((response) => {
                if (response.response.status===204){
                    this.actions = null
                    this.limit = 0
                    this.total = 0
                    return;
                }
                this.actions = response.data.list
                this.checkAllSelect()

                this.page = this.actions.page;
                this.perPage = this.actions.limit
                this.total = this.actions.total
            });
    }
    selectAllFilter(filterType,select){
        if (filterType === 'shipments'){
            this.actionFilterInventory.shipments.data.map(d=>d.select= select)
        }
        if (filterType === 'marketplaces'){
            this.actionFilterInventory.marketplaces.data.map(d=>d.select=select)
        }
        if (filterType === 'channels'){
            this.actionFilterInventory.channels.data.map(d=>d.select=select)
        }
        if (filterType === 'actionTypes'){
            if (select){
                this.actionTypes = this.actionFilterInventory.actionTypes.data.map(d=> d.actionType);
            }else {
                this.actionTypes = [];
            }
        }
    }


    returnActionDetail(transactionId){
        this.$state.go('app.order_lists',{actionNu: transactionId, tab: 0}, {reload:true});
    }

    createActionList(){
        let actionIds = this.selectedActions.map(s => s.actionId)

        if (0 === actionIds.length) {
            this.ToastService.show(this.$translate.instant('MUST_SELECT_1_ORDER'));
            return
        }
        this.API.all("action-process/assignList")
            .customPOST({
                priority:this.listPriority,
                actionIds:actionIds
            })
            .then((response) => {
                this.selectedActions = []
                this.getActions(this.page)
                this.listPriority = null
                let list = response.data.list;
                let html = ''
                let theme = 'success'
                if (list?.listMessage){
                    html += '<li>'+list.listMessage+'</li>'
                    theme= 'warning'
                }else {
                    html += '<li>' +
                        this.$translate.instant('LIST_CREATED', { listName: response.data.list.name, priority: response.data.list.priorityText }) +
                        '</li>';
                }

                if (list?.actions){
                    if (0 < list.actions.warning){
                        theme= 'warning'
                    }
                    list.actions.messages.map(message=>{
                        html += '<li>'+message+'</li>'
                    })
                }

                this.ToastService.showHtml(theme,'<ul style="list-style-type: none;">'+html+'</ul>');
            });
    }

    addActionList(){
        let actionIds = this.selectedActions.map(s => s.actionId)

        if (0 === actionIds.length) {
            this.ToastService.show(this.$translate.instant('MUST_SELECT_1_ORDER'));
            return
        }
        this.API.all("action-process/assignList/"+this.listId)
            .customPOST({
                actionIds:actionIds
            })
            .then((response) => {
                this.selectedActions = []
                this.getActions(this.page)
                this.listPriority = null
                let list = response.data.list;
                let html = ''
                let theme = 'success'
                if (list?.listMessage){
                    html += '<li>'+list.listMessage+'</li>'
                    theme= 'warning'
                }

                list?.messages.map(message=>{
                    html += '<li>'+message+'</li>'
                })
                this.ToastService.showHtml(theme,'<ul style="list-style-type: none;">'+html+'</ul>');
            });
    }
    showRelation(table){

        this.DialogService.fromTemplateV2(
            './views/angular/app/components/invoices/partials/dialog/relation-table.html',
            {
                onShowing: function (scope, element, options, controller) {
                    scope.table = table;
                }
            }
        ).catch(err => {
            console.log(err)
        });
    }

    cancelAddActionList(){
        this.$rootScope.$broadcast('issueChildEvent', {listId:this.listId,tab:1,screen:'issueDetail',process:'cancelAddListItem'})
    }
    selectAllAction(){

    }
    selectAction(action,select){
        let exists = this.selectedActions.filter(s=> s.actionId === action.actionId)

        if (select && exists.length>0){
            this.ToastService.showHtml('warn',
                this.$translate.instant('TRANSACTION_ALREADY_ADDED', { transactionId: action.transactionId })
            );
            return true;
        }

        action.select = select
        if (select){
            this.selectedActions.push(action);
        }else {
            this.selectedActions = this.selectedActions.filter(a=>a.actionId !== action.actionId);
        }
        this.checkAllSelect()
    }

    checkAllSelect() {
        let actionLength = this.actions.actions.length;
        let selectedLength = this.selectedActions.length;

        //sonraki kontrol maliyetli olabilir öncesinde belirleyebilirsek hiç girmesin
        if (selectedLength === 0 || selectedLength < actionLength) {
            this.isAllActionSelect = false;
            return true
        }

        let matchItem = 0;
        this.actions.actions.forEach(a => {
            this.selectedActions.forEach(s => {
                if (s.actionId === a.actionId) {
                    ++matchItem;
                }
            })
        })
        this.isAllActionSelect = matchItem === actionLength

    }
    addActionsSelectedList(){
        this.actions.actions.filter(a=> !a.select).forEach(a=>{
            this.selectAction(a,true)
        })
    }

    clearActionsSelectedList(){
        this.actions.actions.filter(a=> a.select).forEach(a=>{
            this.selectAction(a,false)
        })
    }

    showSelectedActions(){
        let parent = this;
        let options = {
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = parent.DialogService;
                scope.list = parent.selectedActions

                scope.remove = (actionId) => {
                    scope.list = scope.list.filter(l => l.actionId !== actionId)
                }
                scope.done = () =>{
                    scope.DialogService.$mdDialog.hide(scope.list)
                }
                scope.clearAll = () =>{
                    scope.list = []
                    scope.done()
                }
            },
        }

        this.DialogService.fromTemplateV2(
            './views/angular/app/components/issues/partials/dialog/selected-action-table.html',
            options
        ).then(list =>{
            this.selectedActions = list
            this.actions.actions.forEach(a => a.select = false)
            list.forEach(l =>{
                this.actions.actions.forEach(a=>{
                    if (a.actionId===l.actionId){
                        a.select=true
                    }
                })
            })
            this.checkAllSelect()
        })
    }

    showOrderDetail(id) {
        let options = {
            controller: 'InvoicesDetailController',
            controllerAs: 'vm',
            locals: {
                orderId: id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getOrderDetail(controller.orderId)
            },
        }
        this.DialogService.fromTemplateV2(
          './views/angular/app/components/invoices-detail/invoices-detail.component.html',
          options
        )
    }
    showActionDetail(action_id) {
        this.selectedDetailActionId = action_id;
        let options = {
            controller: 'ActionDetailController',
            controllerAs: 'vm',
            locals: {
                id: action_id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetail(controller.id)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/order-lists/partials/action-detail.component.html',
            options
        )
    }
}

IssuesCreateController.$inject = ['API','$state','$mdBottomSheet','$translate','ToastService','DialogService','$scope','$rootScope'];

const IssuesCreateComponent = {
    templateUrl: "./views/angular/app/components/issues/partials/issues-create.html",
    controller: IssuesCreateController,
    controllerAs: "vm",
    bindings: {
        tabScreen : '@',
    },
};

export {IssuesCreateController, IssuesCreateComponent}
