import "../invoices/invoices.scss";
import {getUserInfo} from "../../../utils/users";

class ReceivingPartialController {
    constructor(API, ToastService, $mdBottomSheet, $scope, DialogService, $state, $location, $stateParams, $translate) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.$state = $state;
        this.DialogService = DialogService;
        this.$location = $location;
        this.$stateParams = $stateParams;
        this.$translate = $translate;


        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;
        this.userSettings = this.userInfo.settings;

        this.receivingType = null;
        this.serviceId = null;
        this.clickDone = false;

        this.products = [];
        this.allowedPartialQuantityType = [];

        this.allowedAccept = false;
        this.allowedFaulty = false;
        this.allowedReject = false;


        this.acceptCount = 0;
        this.faultyCount = 0;
        this.rejectCount = 0;

    }

    $onInit() {
        this.getReceivingDetail()
    }

    sum(type, product) {
        if (type === 'accept') {
            this.acceptCount += product.maxPatrialAcceptQuantity * (product.selectPartialAccept ? 1 : -1);
        }
        if (type === 'faulty') {
            this.faultyCount += product.maxPatrialFaultyQuantity * (product.selectPartialFaulty ? 1 : -1);
        }
        if (type === 'reject') {
            this.rejectCount += product.maxPatrialRejectQuantity * (product.selectPartialReject ? 1 : -1);
        }

    }

    cancel() {
        this.DialogService.$mdDialog.hide()
    }

    done() {
        let partialList = {
            accept: [],
            faulty: [],
            reject: [],
        };
        this.products.map(product => {

            if (product.selectPartialAccept && this.allowedAccept) {
                partialList.accept.push({
                    productId: product.productId,
                    quantity: product.maxPatrialAcceptQuantity,
                })
            }
            if (product.selectPartialFaulty && this.allowedFaulty) {
                partialList.faulty.push({
                    productId: product.productId,
                    quantity: product.maxPatrialFaultyQuantity,
                })
            }
            if (product.selectPartialReject && this.allowedReject) {
                partialList.reject.push({
                    productId: product.productId,
                    quantity: product.maxPatrialRejectQuantity,
                })
            }

        })


        const promises = Object.keys(partialList)
            .filter(type => partialList[type].length > 0)
            .map(type => this.donePartial(type, partialList[type]));


        Promise.all(promises).then(results => {
            let totalResult = {items: [], success: 0, error: 0, count: 0};
            results.forEach(result => {
                totalResult.success += result.success
                totalResult.error += result.error
                totalResult.count += result.count
                result.items.forEach(item => {
                    totalResult.items.push(item);
                })
                totalResult.items.push({message: '-----------------'}); // kesit at
            })
            return totalResult;
        }).then(log => {
            this.bulkAddItemResult(log)
        });

    }


    getReceivingDetail() {
        this.API.all(`receiving/${this.receivingType}`)
            .customGET(null, {serviceId: this.serviceId})
            .then((response) => {

                this.products = response.data.list.items.filter(i => i.canPartial)
                this.allowedPartialQuantityType = response.data.list.allowedPartialQuantityType;

                this.allowedAccept = this.allowedPartialQuantityType.includes('accept');
                this.allowedFaulty = this.allowedPartialQuantityType.includes('faulty');
                this.allowedReject = this.allowedPartialQuantityType.includes('reject');


                if (this.products.length === 0) {
                    this.ToastService.showHtml('warn', this.$translate.instant('PARTIAL_LIST_SUMMERY_INFO5'))
                    this.cancel()
                    return true;
                }

                this.products.map(product => {
                    if (this.allowedAccept) {
                        product.maxPatrialAcceptQuantity = product.receivingDetail?.partialAccept ? (product.receivingDetail.accept - product.receivingDetail.partialAccept) : product.receivingDetail.accept;
                        product.selectPartialAccept = 0 < product.maxPatrialAcceptQuantity;
                        this.sum('accept', product)
                    }
                    if (this.allowedFaulty) {
                        product.maxPatrialFaultyQuantity = product.receivingDetail?.partialFaulty ? (product.receivingDetail.faulty - product.receivingDetail.partialFaulty) : product.receivingDetail.faulty;
                        product.selectPartialFaulty = 0 < product.maxPatrialFaultyQuantity;
                        this.sum('faulty', product)
                    }
                    if (this.allowedReject) {
                        product.maxPatrialRejectQuantity = product.receivingDetail?.partialReject ? (product.receivingDetail.reject - product.receivingDetail.partialReject) : product.receivingDetail.reject;
                        product.selectPartialReject = 0 < product.maxPatrialRejectQuantity;
                        this.sum('reject', product)
                    }
                    return product;
                })
            })
    }

    donePartial(type, product) {
        this.clickDone = true;
        return this.API.all(`receiving/${this.receivingType}/partial/${type}`)
            .customPUT({
                serviceId: this.serviceId,
                products: product
            })
            .then((response) => {
                this.clickDone = false;
                return response.data.list;
            })
    }


    bulkAddItemResult(report) {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = d
                scope.report = report
                scope.tryErrorRows = false

                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item-result.html',
            options
        );

    }


}

ReceivingPartialController.$inject = ["API", "ToastService", "$mdBottomSheet", "$scope", "DialogService", "$state", "$location", "$stateParams", "$translate"];
const ReceivingPartialComponent = {
    templateUrl: "./views/angular/app/components/receiving/partials/dialog/partial-done-list.html",
    controller: ReceivingPartialController,
    controllerAs: "vm",
    bindings: {
        receivingType: '<',
        serviceId: '<',
    },
};

export {ReceivingPartialComponent, ReceivingPartialController}
